import { installationsByType } from "common/installations";
import { datiByCodiceCatastale } from "common/datiIstat";
import {
  parteComune,
  stringToLength,
  intToString,
  countRecord,
  isPre2021,
} from "./c01Utils";

export default function (
  installation,
  serviceLicense,
  report,
  reportInfo,
  fail
) {
  const installationType = installationsByType[installation.type];

  const comuneSoggetto =
    datiByCodiceCatastale[installation.codiceCatastaleSoggetto];
  const comuneUfficioAmministrativo =
    datiByCodiceCatastale[installation.codiceCatastaleUfficioAmministrativo];

  const info = {
    tipologiaDichiarante: installationType.tipologiaDichiarante,
    anno: report.anno - 1,
    soggettoObbligato: {
      codiceDitta: installation.installationCode.toUpperCase(),
      denominazione: installation.nomeSoggetto,
      comune: comuneSoggetto?.comuneRaw,
      provincia: comuneSoggetto?.provincia,
      indirizzo: installation.indirizzoSoggetto,
      comuneUfficioAmministrativo: comuneUfficioAmministrativo?.comuneRaw,
      provinciaUfficioAmministrativo: comuneUfficioAmministrativo?.provincia,
      indirizzoUfficioAmministrativo:
        installation.indirizzoUfficioAmministrativo,
    },
    tipoImpianto: installationType?.tipoImpianto,
    numeroDiRecord: countRecord(reportInfo) + 1, // compreso frontespizio
  };

  const generateDicC01 = function (isDic) {
    //diventa 1 quando è c01... 0 per il .dic
    const numeroDiFile = isDic ? 0 : 1;

    return (
      parteComune(
        "FRONTESPIZIO",
        installation,
        serviceLicense,
        report,
        0,
        fail
      ) +
      info.tipologiaDichiarante +
      " " +
      stringToLength(info.soggettoObbligato.denominazione, 60) +
      stringToLength(info.soggettoObbligato.comune, 45) +
      stringToLength(info.soggettoObbligato.provincia, 2) +
      stringToLength(info.soggettoObbligato.indirizzo, 50) +
      stringToLength(info.soggettoObbligato.comuneUfficioAmministrativo, 45) +
      stringToLength(info.soggettoObbligato.provinciaUfficioAmministrativo, 2) +
      stringToLength(
        info.soggettoObbligato.indirizzoUfficioAmministrativo,
        50
      ) +
      intToString(countRecord(reportInfo, 11), 5) +
      intToString(countRecord(reportInfo, 12), 5) +
      intToString(countRecord(reportInfo, 13), 5) +
      "00000" +
      intToString(countRecord(reportInfo, 15), 5) +
      "00000" +
      intToString(countRecord(reportInfo, 21), 5) +
      intToString(countRecord(reportInfo, 23), 5) +
      intToString(countRecord(reportInfo, 26), 5) +
      intToString(countRecord(reportInfo, 41), 5) +
      intToString(countRecord(reportInfo, 42), 5) +
      "00000" +
      "00000" +
      intToString(countRecord(reportInfo, 51), 5) +
      intToString(countRecord(reportInfo, 52), 5) +
      intToString(countRecord(reportInfo, 53), 5) +
      intToString(countRecord(reportInfo, 61), 5) +
      intToString(countRecord(reportInfo, 62), 5) +
      intToString(countRecord(reportInfo, 63), 5) +
      "00000" +
      "00000" +
      "00000" +
      intToString(info.numeroDiRecord, 6) + // compreso frontespizio
      intToString(numeroDiFile, 2) +
      intToString(countRecord(reportInfo, 45), 5) +
      intToString(countRecord(reportInfo, 81), 5) +
      intToString(countRecord(reportInfo, 50), 5) +
      intToString(countRecord(reportInfo, 82), 5) +
      info.tipoImpianto
    );
  };

  info.dic = generateDicC01(true);
  info.c01 = generateDicC01(false);

  const codiceDittaShort =
    info.soggettoObbligato.codiceDitta &&
    info.soggettoObbligato.codiceDitta.substring(4);
  if (!codiceDittaShort || codiceDittaShort.length !== 9) {
    if (fail) {
      throw new Error(
        "Il Codice ditta dovrebbe essere IT00 seguito da 9 caratteri"
      );
    }
  }

  info.rootXml = {
    xmlType: "EnergiaElettrica",
    children: [
      {
        xmlType: isPre2021(report) ? "Dichiarante" : "Dich",
        children: [
          {
            xmlType: isPre2021(report) ? "CodiceDitta" : "CodDitta",
            content: codiceDittaShort,
          },
          {
            xmlType: isPre2021(report) ? "TipoSoggetto" : "TipoSogg",
            content: info.tipologiaDichiarante,
          },
          info.tipologiaDichiarante === "L"
            ? {
                xmlType: isPre2021(report) ? "CodiceAttivita" : "CodAtt",
                content: info.tipoImpianto,
              }
            : {
                xmlType: isPre2021(report) ? "CodiceAttivita" : "CodAtt",
                content: "",
              },
          isPre2021(report) && {
            xmlType: "Denominazione",
            content: info.soggettoObbligato.denominazione,
          },
          isPre2021(report) && {
            xmlType: "ComuneSede",
            // content: info.soggettoObbligato.comune
            // non accettano i trattini
            content: (info.soggettoObbligato.comune || "").replace(/-/g, " "),
          },
          isPre2021(report) && {
            xmlType: "ProvinciaSede",
            content: info.soggettoObbligato.provincia,
          },
          isPre2021(report) && {
            xmlType: "IndirizzoSede",
            content: info.soggettoObbligato.indirizzo,
          },
          {
            xmlType: isPre2021(report)
              ? "ComuneUfficioAmministrativo"
              : "ComUffAmm",
            // content: info.soggettoObbligato.comuneUfficioAmministrativo
            // non accettano i trattini
            content: (
              info.soggettoObbligato.comuneUfficioAmministrativo || ""
            ).replace(/-/g, " "),
          },
          {
            xmlType: isPre2021(report)
              ? "ProvinciaUfficioAmministrativo"
              : "ProvUffAmm",
            content: info.soggettoObbligato.provinciaUfficioAmministrativo,
          },
          {
            xmlType: isPre2021(report)
              ? "IndirizzoUfficioAmministrativo"
              : "IndUffAmm",
            content: info.soggettoObbligato.indirizzoUfficioAmministrativo,
          },
        ].filter((x) => x),
      },
      {
        xmlType: "Anno",
        content: info.anno,
      },
    ],
  };

  return info;
}
