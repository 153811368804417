import numeral from "numeral";

// bidimensional => excel table, return rows of cells

export default function parseHelper(
  event,
  isNumeric,
  cb,
  bidimensional = false
) {
  const target = event.target;
  if (
    event.clipboardData &&
    event.clipboardData.items &&
    event.clipboardData.items[0]
  ) {
    event.clipboardData.items[0].getAsString((text) => {
      let is2D = false;
      if (bidimensional) {
        if (/[\n]/.test(text) && /[\t]/.test(text)) {
          is2D = true;
        }
      }
      let values;

      if (is2D) {
        values = text.split(/[\n]/).map((row) => {
          return row.split(/[\t]/);
        });
      } else {
        values = text.split(/[\n\t]/);
      }

      if (values.length <= 1) {
        // normal paste...
        return;
      }

      if (isNumeric) {
        if (is2D) {
          values = values.map((row) =>
            row.map((value) => numeral(value).value())
          );
        } else {
          values = values.map((value) => numeral(value).value());
        }
      }

      cb(values, is2D);

      target.blur();
    });
  }
}
