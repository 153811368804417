import { ModuloAttivoKey } from "energix-types/src/ModuliAttivi";
import { CALL_API } from "src/middleware/api";
import { useDispatch } from "react-redux";

export type ToggleModuloFunction = (
  modulo: ModuloAttivoKey,
  azione: "attiva_rinnovo_automatico" | "disdici_rinnovo_automatico"
) => Promise<true | "tokenInsufficienti" | "error">;

const TOGGLE_MODULO_ATTIVO_REQUEST = "TOGGLE_MODULO_ATTIVO_REQUEST";
export const TOGGLE_MODULO_ATTIVO_SUCCESS = "TOGGLE_MODULO_ATTIVO_SUCCESS";
const TOGGLE_MODULO_ATTIVO_FAILURE = "TOGGLE_MODULO_ATTIVO_FAILURE";

const toggleModuloAttivoApiCall =
  (
    modulo: ModuloAttivoKey,
    azione: "attiva_rinnovo_automatico" | "disdici_rinnovo_automatico"
  ) =>
  (dispatch: any) => {
    return dispatch({
      [CALL_API]: {
        types: [
          TOGGLE_MODULO_ATTIVO_REQUEST,
          TOGGLE_MODULO_ATTIVO_SUCCESS,
          TOGGLE_MODULO_ATTIVO_FAILURE,
        ],
        endpoint: `/api/moduliAttivi/${modulo}`,
        method: "PUT",
        body: { azione },
      },
    });
  };

export function useToggleModuloAttivo(): ToggleModuloFunction {
  const dispatch = useDispatch();
  return async function toggleModuloAttivo(
    modulo: ModuloAttivoKey,
    azione: "attiva_rinnovo_automatico" | "disdici_rinnovo_automatico"
  ) {
    const result: any = await dispatch(
      toggleModuloAttivoApiCall(modulo, azione)
    );
    if (result.type !== TOGGLE_MODULO_ATTIVO_SUCCESS) {
      return result.response.error === "token_insufficienti"
        ? "tokenInsufficienti"
        : "error";
    }
    return true;
  };
}
