import React, { useCallback, useMemo } from "react";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import GseContoEnergiaTableCard from "src/components/features/green/GseContoEnergiaTableCard";
import GseRidTableCard from "src/components/features/green/GseRidTableCard";
import GseSspTableCard from "src/components/features/green/GseSspTableCard";
import {
  GSEFotovoltaicoCorrispettivi,
  GSERidCorrispettivi,
  GSESspCorrispettivi,
} from "energix-types/src/GSE";
import { orderBy, take } from "lodash";
import EDistribuzioneGraficoProduzioneDiEnergiaCard from "src/components/features/green/EDistribuzioneGraficoProduzioneDiEnergiaCard";
import { EDistribuzioneEnergiaMensile } from "energix-types/src/edistribuzione";
import EDistribuzioneProduzioneDiEnergiaTableCard from "src/components/features/green/EDistribuzioneProduzioneDiEnergiaTableCard";
import InfoImpiantoCard from "src/components/features/green/InfoImpiantoCard";
import {
  ImpiantoGreen,
  ReportEdistribuzione,
  ReportGSE,
} from "energix-types/src/Impianto";
import { Azienda } from "energix-types/src/Azienda";
import PodAssociatiCard from "src/components/features/green/PodAssociatiCard";
import { UpdateImpiantoGreenFunction } from "src/actions/green/updateImpiantoGreen";
import {
  ModuliAttivi,
  moduliAttiviByKey,
} from "energix-types/src/ModuliAttivi";
import { isModuloAttivo } from "energix-types/src/utils/moduliAttivi";
import { Link } from "react-router-dom";
import { monthName } from "src/constants/months";
import { DropdownIcon } from "src/components/elements/AppIcons";
import { Notifica } from "energix-types/src/Notifica";
import ImpostazioniNotificheImpiantoCard from "src/components/features/impianto/ImpostazioniNotificheImpiantoCard";
import { UpdateStatoNotificheImpiantoAction } from "src/actions/updateStatoNotificheImpianto";
import {
  ContatoreDto,
  EdistribuzioneContatoreDto,
  EdistribuzioneCredenzialiCreateDto,
  EdistribuzioneCredenzialiDto,
  EdistribuzioneCredenzialiUpdateDto,
  EdistribuzioneExportQuartiOrariGetDto,
  EdistribuzioneExportQuartiOrariStartDto,
  EdistribuzioneLoginTaskCreateDto,
  EdistribuzioneLoginTaskDto,
  EdistribuzioneRinnovaPasswordTaskDto,
  GSEContatoreDto,
  GSECredenzialiCreateDto,
  GSECredenzialiDto,
  GSECredenzialiUpdateDto,
  GSELoginTaskCreateDto,
  GSELoginTaskDto,
} from "src/orval/models";
import { useServizioNonAbilitatoDialog } from "src/components/common/ServiziononAbilitatoDialog";
import { useEdistribuzioneCredenzialiDialog } from "src/components/features/green/EdistribuzioneCredenzialiDialog";
import { useGseCredenzialiDialog } from "src/components/features/green/GSECredenzialiDialog";
import { useExportQuartiOrariDialog } from "src/components/features/green/ExportQuartiOrariDialog";
import { User } from "energix-types/src/User";

export type GreenImpiantoProps = {
  isAdmin: boolean;
  contoEnergiaList: GSEFotovoltaicoCorrispettivi[];
  ridList: GSERidCorrispettivi[];
  sspList: GSESspCorrispettivi[];
  eDistribuzioneEnergiaMensileList: EDistribuzioneEnergiaMensile[];
  adminLoggedAsUserPrefix?: string;
  impianto: ImpiantoGreen;
  azienda: Azienda;
  aziende: Azienda[];
  contatoriEdistribuzione: EdistribuzioneContatoreDto[];
  contatoriGSE: GSEContatoreDto[];
  contatori: ContatoreDto[];
  updateImpiantoGreen: UpdateImpiantoGreenFunction;
  moduliAttivi: ModuliAttivi;
  reportEdistribuzione: ReportEdistribuzione[];
  reportGSE: ReportGSE[];
  notificheAdmin?: Notifica[];
  notifichePartner?: Notifica[];
  updateStatoNotificheImpianto: UpdateStatoNotificheImpiantoAction;
  edistribuzioneCredenziali: EdistribuzioneCredenzialiDto | null;
  gseCredenzali: GSECredenzialiDto | null;
  listaEdistribuzioneCredenziali: EdistribuzioneCredenzialiDto[];
  listaGseCredenziali: GSECredenzialiDto[];
  utente: User;

  edistribuzioneLoginTask: EdistribuzioneLoginTaskDto | null;
  edistribuzioneRinnovoTask: EdistribuzioneRinnovaPasswordTaskDto | null;
  edistribuzioneAvviaTestCredenziali: (
    dto: EdistribuzioneLoginTaskCreateDto
  ) => Promise<void>;
  edistribuzioneCreaCredenziali: (
    dto: EdistribuzioneCredenzialiCreateDto
  ) => Promise<EdistribuzioneCredenzialiDto>;
  edistribuzioneUpdateCredenziali: (
    id: number,
    credenziali: EdistribuzioneCredenzialiUpdateDto
  ) => Promise<EdistribuzioneCredenzialiDto>;
  associaEdistribuzioneCredenzialiAdAzienda:
    | ((id: number, aziendaId: number) => Promise<void>)
    | null;

  gseLoginTask: GSELoginTaskDto | null;
  gseAvviaTestCredenziali: (dto: GSELoginTaskCreateDto) => Promise<void>;
  gseCreaCredenziali: (
    dto: GSECredenzialiCreateDto
  ) => Promise<GSECredenzialiDto>;
  gseUpdateCredenziali: (
    id: number,
    credenziali: GSECredenzialiUpdateDto
  ) => Promise<GSECredenzialiDto>;
  gseAssociaCredenzialiAdAzienda:
    | ((id: number, aziendaId: number) => Promise<void>)
    | null;

  startExport: (dto: EdistribuzioneExportQuartiOrariStartDto) => Promise<void>;
  exportTask: EdistribuzioneExportQuartiOrariGetDto | null;
};

const MAX_ROWS = 3;

export default function GreenImpianto({
  isAdmin,
  contoEnergiaList,
  ridList,
  sspList,
  eDistribuzioneEnergiaMensileList,
  adminLoggedAsUserPrefix,
  impianto,
  azienda,
  aziende,
  contatoriEdistribuzione,
  contatoriGSE,
  contatori,
  updateImpiantoGreen,
  moduliAttivi,
  reportEdistribuzione,
  reportGSE,
  notificheAdmin,
  notifichePartner,
  updateStatoNotificheImpianto,
  edistribuzioneCredenziali,
  gseCredenzali,
  listaEdistribuzioneCredenziali,
  listaGseCredenziali,
  utente,

  edistribuzioneLoginTask,
  edistribuzioneRinnovoTask,
  edistribuzioneAvviaTestCredenziali,
  edistribuzioneCreaCredenziali,
  edistribuzioneUpdateCredenziali,
  associaEdistribuzioneCredenzialiAdAzienda,

  gseLoginTask,
  gseAvviaTestCredenziali,
  gseCreaCredenziali,
  gseUpdateCredenziali,
  gseAssociaCredenzialiAdAzienda,

  startExport,
  exportTask,
}: GreenImpiantoProps) {
  const hasGseData =
    ridList.length > 0 || sspList.length > 0 || contoEnergiaList.length > 0;
  const hasEdistribuzioneData = eDistribuzioneEnergiaMensileList.length > 0;

  const linkAzienda = `${adminLoggedAsUserPrefix}/partner/azienda/${impianto.license_id}/dettaglio`;
  const linkImpianto =
    impianto.type !== "NON_CONFIGURATO"
      ? `${adminLoggedAsUserPrefix}/impianti/${impianto.id}/dettaglio`
      : undefined;

  const { dialog, openServizioNonAbilitatoDialog } =
    useServizioNonAbilitatoDialog(adminLoggedAsUserPrefix);

  const {
    dialog: dialogCredenzialiEdistribuzione,
    openNew: creaCredenzialiEdistribuzione,
    openModifica: openModificaCredenzialiEdistribuzione,
  } = useEdistribuzioneCredenzialiDialog(
    listaEdistribuzioneCredenziali,
    aziende,
    edistribuzioneLoginTask,
    edistribuzioneRinnovoTask,
    utente,
    edistribuzioneAvviaTestCredenziali,
    edistribuzioneCreaCredenziali,
    edistribuzioneUpdateCredenziali,
    associaEdistribuzioneCredenzialiAdAzienda
  );

  const {
    dialog: dialogCredenzialiGse,
    openNew: creaCredenzialiGSE,
    openModifica: openModificaCredenzialiGSE,
  } = useGseCredenzialiDialog(
    listaGseCredenziali,
    aziende,
    gseLoginTask,
    gseAvviaTestCredenziali,
    gseCreaCredenziali,
    gseUpdateCredenziali,
    gseAssociaCredenzialiAdAzienda
  );

  const { dialog: dialogExportQuartiOrari, openForImpianto } =
    useExportQuartiOrariDialog(contatori, startExport, exportTask);

  return (
    <Grid container spacing={2}>
      {dialog}
      {dialogCredenzialiEdistribuzione}
      {dialogCredenzialiGse}
      {dialogExportQuartiOrari}
      <Grid item xs={12} md={6}>
        <InfoImpiantoCard
          impianto={impianto}
          azienda={azienda}
          aziende={aziende}
          contatoriEdistribuzione={contatoriEdistribuzione}
          contatoriGSE={contatoriGSE}
          contatori={contatori}
          updateImpiantoGreen={updateImpiantoGreen}
          linkAzienda={linkAzienda}
          linkImpianto={linkImpianto}
          edistribuzioneCredenziali={edistribuzioneCredenziali}
          gseCredenzali={gseCredenzali}
          listaEdistribuzioneCredenziali={listaEdistribuzioneCredenziali}
          listaGseCredenzali={listaGseCredenziali}
          openEdistribuzioneCredenzialiDialog={useCallback(
            (azienda) => {
              creaCredenzialiEdistribuzione(azienda.id);
            },
            [creaCredenzialiEdistribuzione]
          )}
          openGSECredenzialiDialog={useCallback(
            (azienda) => {
              creaCredenzialiGSE(azienda.id);
            },
            [creaCredenzialiGSE]
          )}
          openModificaCredenzialiEdistribuzione={
            openModificaCredenzialiEdistribuzione
          }
          openModificaCredenzialiGSE={openModificaCredenzialiGSE}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PodAssociatiCard
          impianto={impianto}
          contatori={contatori}
          contatoriEdistribuzione={contatoriEdistribuzione}
          contatoriGSE={contatoriGSE}
          azienda={azienda}
        />
      </Grid>

      {contatori.length <= 0 && (
        <Grid item xs={12}>
          <Alert severity="info">
            Questo impianto non ha contatori associati. Premi il pulsante
            "Modifica Impianto" per configurarli.
          </Alert>
        </Grid>
      )}

      {/* TODO: aggiungere controllo download */}
      {/* {((!hasEdistribuzioneData && azienda.downloadInCorsoEdistribuzione) ||
        (!hasGseData && azienda.downloadInCorsoGse)) && (
        <Grid item xs={12}>
          <Alert severity="warning">Dati in corso di scaricamento.</Alert>
        </Grid>
      )} */}

      {hasGseData && (
        <Grid item xs={12}>
          {isModuloAttivo(moduliAttivi, "monitoraggioGse") ? (
            <Stack direction="column" spacing={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">Monitoraggio GSE</Typography>

                <DownloadReportButton
                  reports={reportGSE}
                  onClickDownload={() => {
                    const abilitato =
                      isAdmin || isModuloAttivo(moduliAttivi, "reportGSE");
                    if (abilitato) {
                      return true;
                    } else {
                      openServizioNonAbilitatoDialog(
                        "Servizio Report GSE",
                        "Per poter scaricare il report con i dati del GSE devi prima acquistare il servizio."
                      );
                      return false;
                    }
                  }}
                />
              </Stack>

              <GseContoEnergiaTableCard
                riepilogo
                contoEnergiaList={take(contoEnergiaList, MAX_ROWS)}
                dettagliLink={`${adminLoggedAsUserPrefix}/impianti-green/${impianto.id}/dettaglio-gse/conto`}
              />
              <GseRidTableCard
                riepilogo
                ridList={take(ridList, MAX_ROWS)}
                dettagliLink={`${adminLoggedAsUserPrefix}/impianti-green/${impianto.id}/dettaglio-gse/rid`}
              />
              <GseSspTableCard
                riepilogo
                sspList={take(sspList, MAX_ROWS)}
                dettagliLink={`${adminLoggedAsUserPrefix}/impianti-green/${impianto.id}/dettaglio-gse/ssp`}
              />
            </Stack>
          ) : (
            <Alert
              severity="info"
              sx={{ "& .MuiAlert-message": { width: "100%" } }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>
                  Per poter visualizzare i dati del GSE, attiva il modulo "
                  {moduliAttiviByKey["monitoraggioGse"].label}".
                </Typography>
                <Button
                  variant="outlined"
                  color="info"
                  component={Link}
                  to={`${adminLoggedAsUserPrefix}/servizi`}
                >
                  Apri Servizi
                </Button>
              </Stack>
            </Alert>
          )}
        </Grid>
      )}

      {hasEdistribuzioneData && (
        <Grid item xs={12}>
          {isModuloAttivo(moduliAttivi, "monitoraggioEdistribuzione") ? (
            <Stack direction="column" spacing={2} mb={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">Monitoraggio Energia</Typography>

                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <Box>
                    <Button
                      onClick={() => {
                        openForImpianto(impianto);
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Esporta quarti orari
                    </Button>
                  </Box>
                  <Box>
                    <DownloadReportButton
                      reports={reportEdistribuzione}
                      onClickDownload={() => {
                        const abilitato =
                          isAdmin ||
                          isModuloAttivo(moduliAttivi, "reportEdistribuzione");
                        if (abilitato) {
                          return true;
                        } else {
                          openServizioNonAbilitatoDialog(
                            "Servizio Report e-distribuzione",
                            "Per poter scaricare il report con i dati di e-distribuzione devi prima acquistare il servizio."
                          );
                          return false;
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Stack>

              <EDistribuzioneGraficoProduzioneDiEnergiaCard
                data={eDistribuzioneEnergiaMensileList}
              />
              <EDistribuzioneProduzioneDiEnergiaTableCard
                data={eDistribuzioneEnergiaMensileList}
              />
            </Stack>
          ) : (
            <Alert
              severity="info"
              sx={{ "& .MuiAlert-message": { width: "100%" } }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>
                  Per poter visualizzare i dati di E-distribuzione, attiva il
                  modulo "
                  {moduliAttiviByKey["monitoraggioEdistribuzione"].label}
                  ".
                </Typography>
                <Button
                  variant="outlined"
                  color="info"
                  component={Link}
                  to={`${adminLoggedAsUserPrefix}/servizi`}
                >
                  Apri Servizi
                </Button>
              </Stack>
            </Alert>
          )}
        </Grid>
      )}

      <Grid item md={12} lg={7} xl={6}>
        <ImpostazioniNotificheImpiantoCard
          impianto={impianto}
          notificheAdmin={notificheAdmin || []}
          notifichePartner={notifichePartner || []}
          updateStatoNotificheImpianto={updateStatoNotificheImpianto}
          targetNotifiche={{
            partner: false,
            impianto: false,
            rendicontazione: false,
            azienda: false,
            alert_edistribuzione: true,
            report_edistribuzione: true,
            report_gse: true,
            // scadenze
            scadenza_antimafia: true,
            scadenza_arera_contributo_funzionamento: true,
            scadenza_spi: true,
            scadenza_arera_indagine: true,
            scadenza_arera_unbundling: true,
            scadenza_fuel_mix: true,
            taratura_contatori: true,
            contatore: true,
            credenziali_edistribuzione: false,
            delega_dogane: false,
          }}
        />
      </Grid>
    </Grid>
  );
}

export function DownloadReportButton<
  T extends ReportEdistribuzione | ReportGSE
>({
  reports: reportEdistribuzione,
  getLinkFunction,
  onClickDownload,
}: {
  reports: T[];
  getLinkFunction?: (r: T) => string;
  onClickDownload?: (r: T) => boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const sortedReport = useMemo(
    () => orderBy(reportEdistribuzione, ["anno", "mese"], ["desc", "desc"]),
    [reportEdistribuzione]
  );

  if (sortedReport.length <= 0) {
    return null;
  }

  const [lastReport, ...otherReports] = sortedReport;

  function downloadReport(report: T) {
    if (onClickDownload) {
      const allow = onClickDownload(report);
      if (!allow) {
        return;
      }
    }

    const downloadLink = document.createElement("a");

    if (getLinkFunction) {
      downloadLink.href = getLinkFunction(report);
    } else {
      downloadLink.href = `/file/${report.fileReportId}/download`;
    }

    downloadLink.target = "_blank";
    downloadLink.click();
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button
          onClick={() => {
            downloadReport(lastReport);
          }}
        >
          Scarica report {reportLabel(lastReport)}
        </Button>

        {otherReports.length > 0 ? (
          <Button size="small" onClick={handleToggle}>
            <DropdownIcon />
          </Button>
        ) : null}
      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => {
                  if (
                    anchorRef.current &&
                    event.target &&
                    anchorRef.current.contains(event.target as any)
                  ) {
                    return;
                  }

                  setOpen(false);
                }}
              >
                <MenuList id="split-button-menu" autoFocusItem>
                  {otherReports.map((report) => (
                    <MenuItem
                      key={report.id}
                      onClick={() => {
                        downloadReport(report);
                      }}
                    >
                      {reportLabel(report)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

function reportLabel(report: ReportEdistribuzione) {
  return `${monthName[report.mese - 1]} ${report.anno}`;
}
