import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Dichiarazione } from "energix-types/src/Dichiarazione";
import Header from "./common/Header";
import { DownloadIcon, fileTypeIcon } from "src/components/elements/AppIcons";
import { Impianto } from "energix-types/src/Impianto";
import { useState } from "react";
import FileDropzone from "src/components/elements/FileDropzone";
import SelectedFileBox from "src/components/elements/SelectedFileBox";
import { toast } from "react-toastify";
import { getPdfDownloadUrl, getXmlToSignDownloadUrl } from "./common/links";

export type FirmaDichiarazioneProps = {
  dichiarazione: Dichiarazione;
  impianto: Impianto;
  uploadSignedXml: (idDichiarazione: number, file: File) => Promise<boolean>;
  token: string;
};

export default function FirmaDichiarazione({
  dichiarazione,
  impianto,
  uploadSignedXml,
  token,
}: FirmaDichiarazioneProps) {
  const [selectedFile, _setSelectedFile] = useState<File | null>(null);
  const [verifyingFile, setVerifyingFile] = useState(false);
  const [fileIsValid, setFileIsValid] = useState<boolean | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  async function setSelectedFileAndVerify(file: File) {
    setFileIsValid(null);
    _setSelectedFile(file);
    setVerifyingFile(true);
    setFileIsValid(await verifySignedXmlFile(file));
    setVerifyingFile(false);
  }

  function cancelSelectedFile() {
    setFileIsValid(null);
    _setSelectedFile(null);
  }

  async function doUploadFile() {
    if (!selectedFile || !fileIsValid) {
      return;
    }
    setUploading(true);
    const result = await uploadSignedXml(dichiarazione.id, selectedFile);
    setUploading(false);
    if (result) {
      toast.success("File salvato con successo");
      setUploadCompleted(true);
    } else {
      toast.error("Errore durante il salvataggio del file");
    }
  }

  if (!dichiarazione.fileXmlId) {
    return <div />;
  }

  const loading = verifyingFile || uploading;

  const xmlUrl = getXmlToSignDownloadUrl(token, dichiarazione, impianto);
  const pdfUrl = getPdfDownloadUrl(token, dichiarazione, impianto);

  return (
    <Stack direction="column">
      <Header title="Firma Dichiarazione" />
      {dichiarazione.fileXmlSignedId || uploadCompleted ? (
        <Alert severity="success">Il file XML firmato è stato caricato.</Alert>
      ) : (
        <Card>
          <CardContent>
            <FileExtensionWarning />

            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <Stack direction="column" spacing={2}>
                  <Typography variant="h5">
                    Scarica qui il file XML da firmare
                  </Typography>
                  <Box>
                    <Button
                      size="small"
                      variant="contained"
                      href={xmlUrl}
                      startIcon={<DownloadIcon />}
                      target="_blank"
                      download
                    >
                      Scarica file XML da firmare
                    </Button>
                  </Box>

                  <Box>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      href={pdfUrl}
                      startIcon={<DownloadIcon />}
                      target="_blank"
                    >
                      Scarica PDF
                    </Button>
                  </Box>
                </Stack>
              </Grid>
              <Grid item sm={12} md={6}>
                <Typography variant="h5">
                  Carica qui il file XML firmato
                </Typography>
                <FileDropzone
                  onFilesSelected={(files) =>
                    files.length >= 1 && setSelectedFileAndVerify(files[0])
                  }
                  icon={fileTypeIcon.xml.upload}
                  disabled={loading}
                >
                  {verifyingFile && (
                    <Alert severity="info">
                      Verifica del file in corso ...
                    </Alert>
                  )}
                  {fileIsValid === false && (
                    <Alert severity="error">
                      Il file non sembra firmato correttamente
                    </Alert>
                  )}
                  <SelectedFileBox
                    file={selectedFile}
                    disabled={loading}
                    removeFile={cancelSelectedFile}
                  />
                </FileDropzone>
                {fileIsValid && (
                  <Button variant="contained" onClick={doUploadFile}>
                    Salva file in modo definitivo
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Stack>
  );
}

function FileExtensionWarning() {
  return (
    <Alert severity="warning" sx={{ marginBottom: 2 }}>
      <strong>Attenzione</strong>
      <br />
      Il file xml deve essere firmato con estensione{" "}
      <strong>XAdES-BES enveloped</strong>.
      <br />
      Il software di firma DYKE nella versione gratuita non permette di firmare
      con tale estensione. È possibile usare il software di firma gratuito{" "}
      <a
        href="https://www.pec.it/download-software-driver.aspx"
        target="_blank"
        rel="noopener noreferrer"
      >
        ARUBASIGN
      </a>
      .
    </Alert>
  );
}

async function verifySignedXmlFile(selectedFile: File): Promise<boolean> {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const xmlContent = fileReader.result;
      if (!xmlContent || typeof xmlContent !== "string") {
        resolve(false);
        return;
      }
      const valid = xmlContent.indexOf("<ds:Signature") !== -1;
      resolve(valid);
    };
    fileReader.readAsText(selectedFile);
  });
}
