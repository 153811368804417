import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type IsEnergixGreenContextType = {
  isEnergixGreen: boolean;
  setEnergixGreen: (isGreen: boolean) => void;
};

const defaultContext: IsEnergixGreenContextType = {
  isEnergixGreen: false,
  setEnergixGreen: () => {},
};

const IsEnergixGreenContext =
  createContext<IsEnergixGreenContextType>(defaultContext);

export function useIsEnergixGreenContext() {
  return useContext(IsEnergixGreenContext).isEnergixGreen;
}

export function useIsEnergixGreenSetter() {
  const context = useContext(IsEnergixGreenContext);
  return context.setEnergixGreen;
}

export function IsEnergixGreenContextProvider({ children }: { children: any }) {
  const [isEnergixGreen, _setEnergixGreen] = useState<boolean>(false);

  const setEnergixGreen = useCallback((isGreen: boolean) => {
    _setEnergixGreen(isGreen);
  }, []);

  const context = useMemo(() => {
    return { isEnergixGreen, setEnergixGreen };
  }, [isEnergixGreen, setEnergixGreen]);

  return (
    <IsEnergixGreenContext.Provider value={context}>
      {children}
    </IsEnergixGreenContext.Provider>
  );
}
