import {
  intToString,
  xml_numToString_IntegerAndDecimalPart,
  getRootXmlTypeForQuadro,
  isPre2021,
} from "./c01Utils";
import { installationsByType } from "common/installations";

export default function generateQuadroEF(
  installation,
  serviceLicense,
  report,
  fail
) {
  const installationType = installationsByType[installation.type];
  if (!installationType || !installationType.chiediQuadroEF) {
    return [];
  }

  const dati = report?.reportInfo?.quadroEF;
  const necessario = dati?.necessario;
  const fornitori = dati?.fornitori || [];
  if (!necessario || fornitori.length === 0) {
    return [];
  }

  const result = [
    {
      fornitori: [],
    },
  ];
  const fornitoriXmls = [];

  fornitori.forEach((fornitore) => {
    const { provenienza, codice, kwh } = fornitore;
    result[0].fornitori.push({ provenienza, codice, kwh });
    fornitoriXmls.push({
      xmlType: "Fornitore",
      children: [
        {
          xmlType: "Proven",
          content: provenienza,
        },
        {
          xmlType: "Id",
          content: codice,
        },
        {
          xmlType: "Qta",
          content: kwh,
        },
      ],
    });
  });

  // xml
  result[0].xml = {
    xmlType: getRootXmlTypeForQuadro("EF", isPre2021(report)),
    children: [...fornitoriXmls],
  };

  return result;
}
