export type DatiComune = {
  comune: string;
  comuneRaw: string;
  provincia: string;
  codiceCatastale: string;
};

const datiComuni: DatiComune[] = require("./datiComuniIstat.json");

export const comuni: string[] = datiComuni.map((d) => d.comune);
export const datiByComune = Object.fromEntries(
  datiComuni.map((d) => [d.comune, d])
);
export const datiByCodiceCatastale = Object.fromEntries(
  datiComuni.map((d) => [d.codiceCatastale, d])
);
