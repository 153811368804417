import React from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "src/components/old/reactstrapFix/reactstrap";
import { cloneDeep } from "lodash";
import { getRiepilogoInfo as _getRiepilogoInfo } from "dogane/fileGenerator/getRiepilogoInfo";

export function ReportStepQuadroEF(props) {
  const _parent = props.parent;

  const renderTabellaFornitori = () => {
    const fornitori = _parent.getValue("reportInfo.quadroEF.fornitori") || [];

    let rows;

    const removeRiga = (index) => {
      const newFornitori = fornitori.filter((_, i) => index !== i);
      const newErrors = cloneDeep(_parent.state.errors);
      const fornitoriErrors = newErrors?.reportInfo?.quadroEF?.fornitori;
      if (fornitoriErrors) {
        newErrors.reportInfo.quadroEF.fornitori = fornitoriErrors.filter(
          (_, i) => index !== i
        );
        _parent.setState({
          errors: newErrors,
        });
      }
      _parent.setValue("reportInfo.quadroEF.fornitori", newFornitori);
    };

    if (fornitori.length) {
      rows = [];

      fornitori.forEach((fornitore, index) => {
        const baseKey = "reportInfo.quadroEF.fornitori." + index;

        rows.push(
          <tr key={index}>
            <td>
              {_parent.renderPureInput(baseKey + ".provenienza", {
                type: "select",
                options: [
                  { value: "A", label: "A - Nazionale" },
                  { value: "B", label: "B - UE" },
                  { value: "C", label: "C - Extra UE" },
                ],
              })}
              {_parent.renderError(baseKey + ".provenienza")}
            </td>
            <td>
              {_parent.renderPureInput(baseKey + ".codice", { type: "text" })}
              {_parent.renderError(baseKey + ".codice")}
            </td>
            <td>
              {_parent.renderPureInput(baseKey + ".kwh", {
                type: "number",
                decimalDigits: null,
              })}
              {_parent.renderError(baseKey + ".kwh")}
            </td>
            <td>
              <Button
                color="link"
                onClick={() => removeRiga(index)}
                style={{ padding: 0 }}
              >
                {"×"}
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      rows = (
        <tr key="no-rows">
          <td colSpan={3}>Aggiungi una riga per ogni fornitore</td>
        </tr>
      );
    }

    return (
      <table className="table table-sm report-card-table-lista-contatori">
        <thead>
          <tr>
            <th>Provenienza</th>
            <th>Codice identificativo fornitore/cedente</th>
            <th>Quantità (kWh)</th>
            <th style={{ width: 30 }}></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  const addFornitore = () => {
    const fornitori = _parent.getValue("reportInfo.quadroEF.fornitori") || [];
    const newFornitore = {};
    const newFornitori = [...fornitori, newFornitore];
    _parent.setValue("reportInfo.quadroEF.fornitori", newFornitori);
  };

  {
    const inserireQuadroEF = _parent.getValue("reportInfo.quadroEF.necessario");

    const onInserireQuadroEFChange = (newInserireQuadroEF) => {
      if (inserireQuadroEF !== newInserireQuadroEF) {
        _parent.setValue("reportInfo.quadroEF.necessario", newInserireQuadroEF);
      }
    };

    return (
      <div>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                Quadro EF - Elenco propri fornitori/cedenti
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <h5 style={{ marginBottom: 10, fontWeight: 600 }}>
                      Chi deve compilare questo quadro
                    </h5>
                    <i>
                      Allo scopo di accertare il rendimento medio nazionale
                      della generazione elettrica da fonte non rinnovabile per i
                      fini dell’art.12, comma 1, lettera a), della legge 9
                      agosto 2023, n.111, gli esercenti officine di produzione
                      da combustibili fossile sono tenuti a compilare
                      l’allegato, dichiarando i propri fornitori di prodotti
                      energetici e le relative quantità (espresse in kWh).
                      <br />
                      L’allegato deve essere, pertanto, compilato:
                      <ul>
                        <li>
                          <strong style={{ fontWeight: 600 }}>
                            dai venditori
                          </strong>{" "}
                          (soggetti obbligati con autorizzazione di cui
                          all’articolo 53, comma 1, lettera a), del TUA);
                        </li>{" "}
                        <li>
                          <strong style={{ fontWeight: 600 }}>
                            dagli acquirenti per uso proprio
                          </strong>{" "}
                          (soggetti obbligati con autorizzazione di cui
                          all’art.53, comma 1, lettera c-bis) del TUA);
                        </li>{" "}
                        <li>
                          dagli esercenti{" "}
                          <strong style={{ fontWeight: 600 }}>
                            officine di produzione per uso proprio da fonte non
                            rinnovabile
                          </strong>
                          , limitatamente ai fornitori dei prodotti energetici
                          utilizzati nella generazione termoelettrica.
                        </li>
                      </ul>
                    </i>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div style={{ marginBottom: 20 }}>
                      <div>
                        <span>
                          Inserire i dati relativi ai propri fornitori/cedenti?
                        </span>
                        <ButtonToolbar
                          style={{ display: "inline-flex", marginLeft: 10 }}
                        >
                          <ButtonGroup type="checkbox">
                            <Button
                              size="sm"
                              color={
                                inserireQuadroEF === true ? "success" : "light"
                              }
                              onClick={() => onInserireQuadroEFChange(true)}
                            >
                              Sì
                            </Button>
                            <Button
                              size="sm"
                              color={
                                inserireQuadroEF === false ? "success" : "light"
                              }
                              onClick={() => onInserireQuadroEFChange(false)}
                            >
                              No
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>

                        <Col sm="12">
                          {_parent.renderError(
                            "reportInfo.quadroEF.necessario"
                          )}
                        </Col>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {inserireQuadroEF && (
            <Col sm="12" style={{ marginTop: 20 }}>
              <Card>
                <CardHeader>Elenco propri fornitori/cedenti</CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">{renderTabellaFornitori()}</Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Button color="secondary" outline onClick={addFornitore}>
                        Aggiungi riga
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
