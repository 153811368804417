import numeral from "numeral";

export function fPercent(number: number): string {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number: number): string {
  return numeral(number).format();
}

export function fPrice(number: number): string {
  return numeral(number).format("0,0.00");
}
