import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { ImpiantoGreen } from "energix-types/src/Impianto";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AppDialogActions from "src/components/elements/AppDialogActions";
import AppDialogTitle from "src/components/elements/AppDialogTitle";
import {
  ContatoreDto,
  EdistribuzioneExportQuartiOrariGetDto,
  EdistribuzioneExportQuartiOrariStartDto,
} from "src/orval/models";
import { range, reverse } from "lodash";
import moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";
import BaseTable from "src/components/BaseTable";
import { toast } from "react-toastify";
import { DownloadIcon } from "src/components/elements/AppIcons";
import {
  StartEdistribuzioneExportQuartiOrariMutationBody,
  useGetEdistribuzioneExportQuartiOrari,
  useStartEdistribuzioneExportQuartiOrari,
} from "src/orval/default/default";

export type ExportQuartiOrariDialogProps = {
  open: boolean;
  contatori: ContatoreDto[];
  impianto: ImpiantoGreen | null | undefined;
  startExport: (dto: EdistribuzioneExportQuartiOrariStartDto) => Promise<void>;
  exportTask: EdistribuzioneExportQuartiOrariGetDto | null;
  handleClose: () => void;
};

function ExportQuartiOrariDialog({
  open,
  impianto,
  handleClose,
  contatori: _contatori,
  exportTask,
  startExport,
}: ExportQuartiOrariDialogProps) {
  const [anni, setAnni] = useState<number[]>([]);
  const [pods, setPods] = useState<string[]>([]);
  const [state, setState] = useState<
    "configure" | "export_loading" | "export_success" | "export_failed"
  >("configure");

  const [errore, setErrore] = useState<string | null>(null);
  const [exportFileId, setExportFileId] = useState<number | null>(null);

  const [minAnno, maxAnno] = useMemo(() => {
    if (impianto) {
      let minAnno: number | null = null;

      if (impianto.eDistribuzioneEnergiaMensileList) {
        for (const d of impianto.eDistribuzioneEnergiaMensileList) {
          if (minAnno === null || d.anno < minAnno) {
            minAnno = d.anno;
          }
        }
      }

      if (!minAnno) {
        minAnno = 2021;
      }

      const maxAnno =
        impianto.sintesiEDistribuzione
          ?.ultimoAnnoScaricatoProduzioneEDistribuzione ?? 2021;

      return [minAnno, maxAnno];
    } else {
      return [2021, moment().year()];
    }
  }, [impianto]);

  const contatoriRef = useRef<ContatoreDto[]>(_contatori);
  contatoriRef.current = _contatori;

  useEffect(() => {
    if (open && impianto) {
      const pods: string[] = [];
      for (const c of contatoriRef.current) {
        if (c.impiantoId === impianto.id) {
          pods.push(c.pod);
        }
      }
      setPods(pods);
      setAnni([]);
    } else if (open && !impianto) {
      setPods(contatoriRef.current.map((c) => c.pod));
      setAnni([]);
    }
  }, [open, impianto, contatoriRef]);

  useEffect(() => {
    if (open) {
      setState("configure");
      setExportFileId(null);
      setErrore(null);
    }
  }, [open]);

  function handleExport() {
    setExportFileId(null);
    setErrore(null);
    setState("export_loading");
    startExport({
      anni: anni,
      pods: pods,
    })
      .then(() => {})
      .catch((e) => {
        console.error(e);
        setErrore("Errore durante la preparazione all'esportazione");
        setState("export_failed");
      });
  }

  function handleDownload(_fileId: number | undefined) {
    const fileId = _fileId ?? exportFileId;
    if (!fileId) {
      return;
    }

    const downloadLink = document.createElement("a");
    downloadLink.href = `/file/${fileId}/download`;

    downloadLink.target = "_blank";
    downloadLink.click();
  }

  useEffect(() => {
    if (state === "export_loading") {
      if (exportTask?.fileId) {
        setState("export_success");
        setExportFileId(exportTask.fileId);
        handleDownload(exportTask.fileId);
        toast.success("Esportazione completata con successo!");
      } else if (exportTask?.errore) {
        setState("export_failed");
        setErrore(exportTask.errore);
        toast.error("Esportazione fallita");
      }
    }
  }, [exportTask, state]);

  // singolo impianto => pod selector
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <AppDialogTitle>
        {impianto
          ? `Esportazione letture impianto ${impianto.installationCode}`
          : "Esportazione massiva letture"}
      </AppDialogTitle>

      <DialogContent>
        {state === "export_failed" && (
          <Alert severity="error" sx={{ my: 1 }}>
            {errore ?? "Si è verificat un errore durante l'esportazione"}
          </Alert>
        )}

        {state === "configure" ||
        state === "export_loading" ||
        state === "export_failed" ? (
          <>
            <Typography sx={{ mb: 1 }}>
              Questa funzionalità ti permette di generare un file ZIP
              contentente un file EXCEL per ogni POD e mensilità, contenente i
              valori di energia prodotta e immessa ad ogni quarto orario.
            </Typography>

            {state === "export_loading" && (
              <Alert severity="info" sx={{ my: 1 }}>
                Esportazione in corso{" "}
                {exportTask?.contatoriTotali
                  ? ` (${exportTask.contatoriEsportati ?? 0} / ${
                      exportTask.contatoriTotali
                    } POD elaborati) `
                  : " "}{" "}
                ...
              </Alert>
            )}

            <AnniPicker
              value={anni}
              onChange={(anni) => {
                setAnni(anni);
              }}
              minAnno={minAnno}
              maxAnno={maxAnno}
              disabled={state === "export_loading"}
            />
            {impianto && (
              <PodsPicker
                value={pods}
                onChange={(pods) => {
                  setPods(pods);
                }}
                impianto={impianto}
                contatori={_contatori}
                disabled={state === "export_loading"}
              />
            )}
          </>
        ) : null}

        {state === "export_success" && exportFileId && (
          <>
            <Alert severity="success" sx={{ my: 1 }}>
              Esportazione completata con successo!
            </Alert>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                m: 2,
              }}
            >
              <Button
                size="large"
                onClick={() => {
                  handleDownload(undefined);
                }}
                startIcon={<DownloadIcon />}
                variant="contained"
              >
                Download
              </Button>
            </Box>
          </>
        )}
      </DialogContent>

      <AppDialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="outlined"
          disabled={state === "export_loading"}
        >
          {state === "export_success" ? "Chiudi" : "Annulla"}
        </Button>

        {state === "configure" ||
        state === "export_loading" ||
        state === "export_failed" ? (
          <Button
            onClick={() => {
              handleExport();
            }}
            variant="contained"
            color="primary"
            disabled={
              anni.length <= 0 || pods.length <= 0 || state === "export_loading"
            }
            startIcon={
              state === "export_loading" && <CircularProgress size={20} />
            }
          >
            Esporta
          </Button>
        ) : null}
      </AppDialogActions>
    </Dialog>
  );
}

function AnniPicker({
  value,
  onChange,
  minAnno,
  maxAnno,
  disabled,
}: {
  value: number[];
  onChange: (vanni: number[]) => void;
  minAnno: number;
  maxAnno: number;
  disabled: boolean;
}) {
  const anni = useMemo(() => {
    return reverse(range(minAnno, maxAnno + 1));
  }, [minAnno, maxAnno]);

  const handleToggle = (anno: number) => {
    if (value.includes(anno)) {
      onChange(value.filter((a) => a !== anno));
    } else {
      onChange([...value, anno]);
    }
  };

  return (
    <Stack>
      <Typography variant="h6">Seleziona gli anni da esportare: </Typography>
      <List dense>
        {anni.map((anno) => (
          <ListItem key={anno} dense sx={{ py: 0.25 }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={value.includes(anno)}
                  onChange={() => handleToggle(anno)}
                  color="primary"
                  disabled={disabled}
                  sx={{ py: 0 }}
                />
              }
              label={anno.toString()}
            />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}

const columnHelper = createColumnHelper<{ pod: string }>();

function PodsPicker({
  contatori,
  value,
  onChange,
  impianto,
  disabled,
}: {
  value: string[] | null;
  onChange: (picked: string[]) => void;
  contatori: ContatoreDto[];
  impianto: ImpiantoGreen | null;
  disabled: boolean;
}) {
  const podSelezionabili = useMemo(() => {
    const pods = new Set<string>();

    for (const c of contatori) {
      if (!impianto || c.impiantoId === impianto.id) {
        pods.add(c.pod);
      }
    }

    return Array.from(pods);
  }, [contatori, impianto]);

  const rows = useMemo(() => {
    return podSelezionabili.map((p) => ({ pod: p }));
  }, [podSelezionabili]);

  const columns = useMemo(() => {
    return [
      columnHelper.display({
        id: "_selected",
        header: "",
        cell: ({ row }) => {
          const checked = value?.includes(row.original.pod);
          return (
            <Checkbox
              checked={checked}
              onChange={(e) => {
                if (e.target.checked) {
                  onChange([...(value ?? []), row.original.pod]);
                } else {
                  onChange(
                    value ? value.filter((p) => p !== row.original.pod) : []
                  );
                }
              }}
              disabled={disabled}
            />
          );
        },
      }),
      columnHelper.accessor("pod", {
        header: "POD",
      }),
    ];
  }, [value, onChange, disabled]);

  return (
    <Stack direction="column">
      <Typography variant="h6">Seleziona i POD da esportare</Typography>
      <BaseTable
        columns={columns}
        data={rows}
        noRowsText="Nessun POD associato a questo impianto"
      />
    </Stack>
  );
}

export function useExportQuartiOrariDialog(
  contatori: ContatoreDto[],
  startExport: (dto: EdistribuzioneExportQuartiOrariStartDto) => Promise<void>,
  exportTask: EdistribuzioneExportQuartiOrariGetDto | null
) {
  const [impianto, setImpianto] = useState<ImpiantoGreen | null | undefined>(
    undefined
  );
  const [open, setOpen] = useState(false);

  return {
    openForImpianto: useCallback((impianto: ImpiantoGreen) => {
      setImpianto(impianto);
      setOpen(true);
    }, []),

    open: useCallback(() => {
      setOpen(true);
    }, []),

    dialog: (
      <ExportQuartiOrariDialog
        open={open}
        contatori={contatori}
        impianto={impianto}
        startExport={startExport}
        exportTask={exportTask}
        handleClose={useCallback(() => {
          setOpen(false);
        }, [])}
      />
    ),
  };
}

export function useExportQuartiOrariDialogProps() {
  const [exportTaskId, setExportTaskId] = useState<number | null>(null);
  const [poolingExportTask, setPoolingExportTask] = useState(false);
  const { mutateAsync: _startExport } =
    useStartEdistribuzioneExportQuartiOrari();
  const startExport = useCallback(
    async (dto: StartEdistribuzioneExportQuartiOrariMutationBody) => {
      setPoolingExportTask(false);
      setExportTaskId(null);

      const result = await _startExport({
        data: dto,
      });
      setPoolingExportTask(true);
      setExportTaskId(result.id);
    },
    [_startExport]
  );
  const exportTask = useGetEdistribuzioneExportQuartiOrari(exportTaskId ?? 0, {
    query: { enabled: poolingExportTask, refetchInterval: 2_000 },
  });
  useEffect(() => {
    if (
      poolingExportTask &&
      (exportTask.data?.errore || exportTask.data?.fileId)
    ) {
      setPoolingExportTask(false);
    }
  }, [poolingExportTask, exportTask, exportTaskId]);

  return {
    startExport,
    exportTask: exportTask.data ?? null,
  } as const;
}
