import { Box, Stack, Theme, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import {
  TrendingDownIcon,
  TrendingUpIcon,
} from "src/components/elements/AppIcons";
import { fPercent } from "src/components/common/formatNumber";
import React from "react";

export type TrendingUpOrDownProps = {
  percentage: number;
  trailingText: string;
};

export default function TrendingUpOrDown({
  percentage,
  trailingText,
}: TrendingUpOrDownProps) {
  const isTrendingUp = percentage >= 0;
  return (
    <Box sx={{ display: "flex" }}>
      <Stack direction="row" alignItems="center" flexWrap="wrap">
        <Box
          sx={{
            ...iconWrapperStyle,
            backgroundColor: (theme: Theme) =>
              alpha(
                isTrendingUp
                  ? theme.palette.success.main
                  : theme.palette.error.main,
                0.16
              ),
          }}
        >
          {isTrendingUp ? (
            <TrendingUpIcon color="success" style={{ fontSize: 20 }} />
          ) : (
            <TrendingDownIcon color="error" style={{ fontSize: 20 }} />
          )}
        </Box>
      </Stack>

      <Typography variant="subtitle2" component="span">
        {isTrendingUp && "+"}
        {fPercent(percentage)}
      </Typography>
      <Typography
        variant="body2"
        component="span"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {trailingText}
      </Typography>
    </Box>
  );
}

const iconWrapperStyle = {
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  marginRight: (theme: Theme) => theme.spacing(1),
};
