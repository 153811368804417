import {
  parteComune,
  stringToLength,
  intToString,
  numToString_IntegerAndDecimalPart,
  xml_numToString_IntegerAndDecimalPart,
  getQuadroTypeForQuadro,
  getRootXmlTypeForQuadro,
  isPre2021,
} from "./c01Utils";
import { getDiffLettureTotaleAnno, getDatiQuadri } from "./lettureUtils";

export default function generateQuadroGH(
  quadroType,
  installation,
  serviceLicense,
  report,
  contatoriDiQuestoQuadro,
  fail
) {
  let nomeQuadro;
  switch (quadroType) {
    case "G":
      nomeQuadro = "energiaElettricaCeduta";
      break;
    case "H":
      nomeQuadro = "energiaElettricaRicevuta";
      break;
  }
  const datiQuadri = getDatiQuadri(report, nomeQuadro, "contatoreQuantita");
  if (contatoriDiQuestoQuadro.length === 0 && datiQuadri.length === 0) {
    return [];
  }
  const result = [];
  const contatoriXmls = [];

  let total_chilowattora = 0;

  const func = (dataFromLetture, dataFromQuadro) => {
    let letturaAttuale,
      letturaPrecedente,
      diff,
      k,
      chilowattora,
      matricola,
      numeroCifreIntere,
      numeroCifreDecimali;
    let tipologiaDiCessione, codiceIdentificativoOfficinaDestinataria;
    let tipologiaDiRicezione, codiceIdentificativoOfficinaFornitrice;
    if (dataFromLetture) {
      const { contatore, from, to } = dataFromLetture;
      ({ letturaAttuale, letturaPrecedente, diff, k, chilowattora } =
        getDiffLettureTotaleAnno(contatore, fail));
      ({ matricola, numeroCifreIntere, numeroCifreDecimali } = contatore);
      ({
        tipologiaDiCessione,
        codiceIdentificativoOfficinaDestinataria,
        tipologiaDiRicezione,
        codiceIdentificativoOfficinaFornitrice,
      } = contatore);
    } else {
      ({
        matricola,
        letturaAttuale,
        letturaPrecedente,
        differenzaLettura: diff,
        costanteLettura: k,
      } = dataFromQuadro);
      const { tipologia, codiceIdentificativo } = dataFromQuadro;
      tipologiaDiCessione = tipologia;
      tipologiaDiRicezione = tipologia;
      codiceIdentificativoOfficinaDestinataria = codiceIdentificativo;
      codiceIdentificativoOfficinaFornitrice = codiceIdentificativo;
      numeroCifreIntere = 8;
      numeroCifreDecimali = 4;
      diff = letturaAttuale - letturaPrecedente;
      if (typeof diff !== "number" || isNaN(diff)) {
        if (fail) {
          throw new Error("Errore letture");
        }
        return;
      }
      if (diff < 0) {
        if (fail) {
          throw new Error("Letture decrescenti");
        }
        return;
      }
      chilowattora = Math.round(diff * k);
      if (typeof chilowattora !== "number" || isNaN(chilowattora)) {
        if (fail) {
          throw new Error("Errore letture");
        }
        return;
      }
      if (chilowattora < 0) {
        if (fail) {
          throw new Error("Letture decrescenti");
        }
        return;
      }
    }

    total_chilowattora += chilowattora;

    const codiceCatastaleComune = installation.codiceCatastaleImpianto;

    const info = {
      matricola: matricola,
      chilowattora: chilowattora,
      letturaAttuale: letturaAttuale,
      letturaPrecedente: letturaPrecedente,
      differenzaLetture: diff,
      numeroCifreIntere: numeroCifreIntere,
      numeroCifreDecimali: numeroCifreDecimali,
      k: k,
    };

    const otherXmls = [];
    if (quadroType === "G") {
      info.tipologiaCessioneRicezione = tipologiaDiCessione;
      info.codiceIdentificativoOfficina =
        codiceIdentificativoOfficinaDestinataria;
      info.codiceCatastaleComune = codiceCatastaleComune;
      if (codiceCatastaleComune) {
        otherXmls.push({
          xmlType: isPre2021(report) ? "CodiceCatastale" : "CodCat",
          content: codiceCatastaleComune,
        });
      } else {
        otherXmls.push({
          xmlType: isPre2021(report) ? "CodiceCatastale" : "CodCat",
          content: "",
        });
      }
    } else if (quadroType === "H") {
      info.tipologiaCessioneRicezione = tipologiaDiRicezione;
      info.codiceIdentificativoOfficina =
        codiceIdentificativoOfficinaFornitrice;
      info.codiceCatastaleComune = "    ";
    }

    info.c01 =
      parteComune(quadroType, installation, serviceLicense, report, 0, fail) +
      quadroType +
      stringToLength(info.matricola, 15) +
      info.tipologiaCessioneRicezione +
      stringToLength(info.codiceIdentificativoOfficina, 16) +
      intToString(info.chilowattora, 13) +
      numToString_IntegerAndDecimalPart(info.letturaAttuale, 8, 3) +
      numToString_IntegerAndDecimalPart(info.letturaPrecedente, 8, 3) +
      numToString_IntegerAndDecimalPart(info.differenzaLetture, 8, 3) +
      numToString_IntegerAndDecimalPart(info.k, 7, 2) +
      stringToLength(info.codiceCatastaleComune, 4);

    contatoriXmls.push({
      xmlType: isPre2021(report) ? "ContatoreQuantita" : "Contatore",
      children: [
        {
          xmlType: isPre2021(report) ? "Matricola" : "Matr",
          content: info.matricola,
        },
        {
          xmlType: isPre2021(report) ? "Tipologia" : "Tipo",
          content: info.tipologiaCessioneRicezione,
        },
        {
          xmlType: isPre2021(report) ? "CodiceIdentificativo" : "Id",
          content: info.codiceIdentificativoOfficina,
        },
        {
          xmlType: isPre2021(report) ? "Chilowattora" : "Kwh",
          content: info.chilowattora,
        },
        {
          xmlType: isPre2021(report) ? "LetturaAttuale" : "LettA",
          content: xml_numToString_IntegerAndDecimalPart(
            info.letturaAttuale,
            8,
            4
          ),
        },
        {
          xmlType: isPre2021(report) ? "LetturaPrecedente" : "LettP",
          content: xml_numToString_IntegerAndDecimalPart(
            info.letturaPrecedente,
            8,
            4
          ),
        },
        {
          xmlType: isPre2021(report) ? "DifferenzaLettura" : "DiffLett",
          content: xml_numToString_IntegerAndDecimalPart(
            info.differenzaLetture,
            8,
            4
          ),
        },
        {
          xmlType: isPre2021(report) ? "CostanteLettura" : "CostLett",
          content: xml_numToString_IntegerAndDecimalPart(info.k, 7, 2),
        },
        ...otherXmls,
      ],
    });

    result.push(info);
  };

  datiQuadri.forEach((x) => {
    func(null, x);
  });
  contatoriDiQuestoQuadro.forEach((x) => {
    func(x);
  });

  const totalInfo = {
    totale: true,
    chilowattora: total_chilowattora,
  };
  totalInfo.c01 =
    parteComune(quadroType, installation, serviceLicense, report, 0, fail) +
    quadroType +
    "999999999999999" +
    " " +
    stringToLength("", 16) +
    intToString(totalInfo.chilowattora, 13) +
    stringToLength("", 8) +
    intToString(0, 3) +
    stringToLength("", 8) +
    intToString(0, 3) +
    intToString(0, 8) +
    intToString(0, 3) +
    intToString(0, 7) +
    intToString(0, 2) +
    stringToLength("", 4); // non sono proprio sicuro...

  result.push(totalInfo);

  if (isPre2021(report)) {
    const otherTotaleXmls = [];
    if (quadroType === "G") {
      otherTotaleXmls.push({
        xmlType: "CodiceCatastale",
        content: "",
      });
    }

    contatoriXmls.push({
      xmlType: "ContatoreQuantita",
      children: [
        {
          xmlType: "Matricola",
          content: "999999999999999",
        },
        {
          xmlType: "Tipologia",
          content: "",
        },
        {
          xmlType: "CodiceIdentificativo",
          content: "",
        },
        {
          xmlType: "Chilowattora",
          content: totalInfo.chilowattora,
        },
        {
          xmlType: "LetturaAttuale",
          content: xml_numToString_IntegerAndDecimalPart(0, 8, 4),
        },
        {
          xmlType: "LetturaPrecedente",
          content: xml_numToString_IntegerAndDecimalPart(0, 8, 4),
        },
        {
          xmlType: "DifferenzaLettura",
          content: xml_numToString_IntegerAndDecimalPart(0, 8, 4),
        },
        {
          xmlType: "CostanteLettura",
          content: xml_numToString_IntegerAndDecimalPart(0, 7, 2),
        },
        ...otherTotaleXmls,
      ],
    });

    // xml
    result[0].xml = {
      xmlType: getRootXmlTypeForQuadro(quadroType, isPre2021(report)),
      children: [
        {
          xmlType: "TipoRecord",
          content: getQuadroTypeForQuadro(quadroType),
        },
        {
          xmlType: "Mese",
          content: 0,
        },
        ...contatoriXmls,
      ],
    };
  } else {
    // xml
    result[0].xml = {
      xmlType: getRootXmlTypeForQuadro(quadroType, isPre2021(report)),
      children: [
        ...contatoriXmls,
        {
          xmlType: "Totale",
          content: totalInfo.chilowattora,
        },
      ],
    };
  }

  return result;
}
