import {
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Dichiarazione } from "energix-types/src/Dichiarazione";
import Header from "./common/Header";
import { Impianto } from "energix-types/src/Impianto";
import { useState } from "react";
import { toast } from "react-toastify";
import { OldReportCardForLettureWrapper } from "../report/OldReportCardForLettureWrapper";

type SaveDichiarazioneFunctionAction = {
  callApiSuccess: boolean;
};

export type SaveDichiarazioneFunction = (
  dichiarazione: Dichiarazione
) => Promise<SaveDichiarazioneFunctionAction>;

export type InserisciLettureDichiarazioneProps = {
  dichiarazione: Dichiarazione;
  impianto: Impianto;
  saveDichiarazione: SaveDichiarazioneFunction;
};

export default function InserisciLettureDichiarazione({
  dichiarazione,
  impianto,
  saveDichiarazione,
}: InserisciLettureDichiarazioneProps) {
  const [open, setOpen] = useState(false);
  const [savedCompleted, setSavedCompleted] = useState(false);

  const { fileXmlId, fileXmlSignedId } = dichiarazione;

  const shouldAddLetture =
    !fileXmlId &&
    !fileXmlSignedId &&
    dichiarazione.reportInfo?.statoReport === "bozza";

  if (!shouldAddLetture && !savedCompleted) {
    return <div />;
  }

  const updateReport = async (data: any) => {
    console.log(data);
    const result = await saveDichiarazione(data);
    if (result.callApiSuccess) {
      toast.success("File salvato con successo");
      setSavedCompleted(true);
    } else {
      toast.error("Errore durante il salvataggio delle letture");
    }
    return result;
  };

  return (
    <Stack direction="column">
      <Header title={`Inserisci letture anno ${dichiarazione.anno - 1}`} />
      {savedCompleted ? (
        <Alert severity="success">Letture salvate</Alert>
      ) : (
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography>
                  Inserisci le letture per i contatori dell'impianto
                </Typography>
              </Grid>

              <Grid item sm={12}>
                <Button variant="contained" onClick={() => setOpen(true)}>
                  Inserisci letture
                </Button>
              </Grid>

              <OldReportCardForLettureWrapper
                open={open}
                installation={impianto}
                report={dichiarazione}
                onClose={() => setOpen(false)}
                updateReport={updateReport}
              />
            </Grid>
          </CardContent>
        </Card>
      )}
    </Stack>
  );
}
