import { Box, Button, Typography } from "@mui/material";
import { Impianto } from "energix-types/src/Impianto";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { LettureType } from "src/actions";
import {
  useContextDichiarazioneSelector,
  useCostoOperazioni,
} from "src/hooks/useContextSelectors";

export type SpalmaDatiLettureProps = {
  impianto: Impianto;
  letture: any;
  matricola: any;
  anno: number;
  onLetture: (letture: any) => void;
};

export function SpalmaDatiLetture({
  impianto,
  anno,
  matricola,
  letture,
  onLetture,
}: SpalmaDatiLettureProps) {
  const dichiarazionePrecedente = useContextDichiarazioneSelector(
    impianto.id,
    anno - 1
  );

  if (!dichiarazionePrecedente) {
    return null;
  }

  const firstKey = `L${anno - 1}0101`;
  const lastKey = `L${anno}0101`;

  const lettura1 = letture && letture[firstKey];
  const lettura2 = letture && letture[lastKey];

  const haLetture =
    typeof lettura1 === "number" && typeof lettura2 === "number";
  const lettureOk = haLetture && lettura1 < lettura2;

  const disabled = !haLetture || !lettureOk;

  const letturePrecedenti =
    dichiarazionePrecedente?.reportInfo?.contatori?.find(
      (x) => x.matricola === matricola
    )?.letture;

  const letturePrecedentiArray: any = letturePrecedenti
    ? new Array(13)
        .fill(0)
        .map((_, i) => {
          const mese = i + 1;
          if (mese === 13) {
            return `L${anno - 1}0101`;
          }
          const key = `L${anno - 2}${mese < 10 ? "0" + mese : mese}01`;
          return key;
        })
        .map((k) => {
          return letturePrecedenti[k];
        })
    : null;

  if (
    !letturePrecedentiArray ||
    !letturePrecedentiArray.every((x: any) => typeof x === "number")
  ) {
    return null; // letture precedenti non vanno bene
  }

  const diffPrecedenti = Array(12)
    .fill(0)
    .map((x, index) => {
      return letturePrecedentiArray[index + 1] - letturePrecedentiArray[index];
    });

  if (diffPrecedenti.some((x) => x < 0)) {
    return null; // letture precedenti non vanno bene: decrescenti
  }

  const totale = diffPrecedenti.reduce((s, x) => s + x, 0);
  const proporzioni = diffPrecedenti.map((x) => x / totale);

  const spalma = () => {
    let prev = lettura1;
    const totale2 = lettura2 - lettura1;
    const newValues = proporzioni.map((x) => {
      const thisValue = prev;
      prev = prev + x * totale2;
      return thisValue;
    });
    newValues.push(lettura2);

    const newValuesObj = {} as any;

    new Array(13)
      .fill(0)
      .map((_, i) => {
        const mese = i + 1;
        if (mese === 13) {
          return `L${anno}0101`;
        }
        const key = `L${anno - 1}${mese < 10 ? "0" + mese : mese}01`;
        return key;
      })
      .forEach((k, i) => {
        newValuesObj[k] = newValues[i];
      });

    onLetture?.(newValuesObj);
  };

  return (
    <div style={{ maxWidth: 370 }}>
      <Box>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            if (disabled) {
              return;
            }

            spalma();
          }}
          disabled={disabled}
        >
          Spalma Letture sull'anno
        </Button>
      </Box>
      <Box>
        <Typography variant="caption" sx={{ mb: 2 }}>
          Data la lettura iniziale e finale dell'anno, il portale compilerà le
          letture dei mesi mantenendo le medesime proporzioni tra le letture
          mensili di quanto presente nella dichiarazione precedente.
          {!lettureOk && (
            <div style={{ fontWeight: "bold" }}>
              Inserisci letture crescenti per il primo e ultimo mese per poter
              usare questa funzionalità
            </div>
          )}
        </Typography>
      </Box>
    </div>
  );
}
