import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Input } from "src/components/old/reactstrapFix/reactstrap";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import moment from "moment";
import { mapValues } from "lodash";

// When clicking on a day cell, overlay will be hidden after this timeout
const HIDE_TIMEOUT = 100;

const LEFT = 37;
const UP = 38;
const RIGHT = 39;
const DOWN = 40;
const ENTER = 13;
const SPACE = 32;
const ESC = 27;
const TAB = 9;

function _formatDate(date, format) {
  if (!date) {
    return null;
  }
  return moment(date).format(format);
}

function _parseDate(text, format) {
  if (!text || text === "") {
    return null;
  }
  var m = moment(text, format, true);
  if (!m.isValid()) {
    return null;
  }
  return m.toDate();
}

class DatetimePicker extends Component {
  static propTypes = {
    date: PropTypes.bool,
    time: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.oneOf([null]),
    ]),
    onChange: PropTypes.func,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onPickerShow: PropTypes.func,
    onPickerHide: PropTypes.func,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.hideAfterDayClick = this.hideAfterDayClick.bind(this);
    this.handleContainerMouseDown = this.handleContainerMouseDown.bind(this);
    this.handleInputClick = this.handleInputClick.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleTimePickerSelectChange =
      this.handleTimePickerSelectChange.bind(this);
    this.handleTimePickerSelectBlur =
      this.handleTimePickerSelectBlur.bind(this);

    this.state = this.getStateFromProps(props);
    this.state.showOverlay = false;

    this.clickTimeout = null;
    this.hideTimeout = null;
    this.blurTimeout = null;
    this.clickedInside = false;
    this.clickedInsideSelect = false;
    this.input = null;
  }

  getStateFromProps(props) {
    const { date, time } = props;
    const format = date && !time ? "L" : time && !date ? "LTS" : "L LTS";
    let value = props.value;
    let month;
    if (value) {
      month = value;
      value = _formatDate(value, format);
    } else {
      month = new Date();
    }
    return {
      value,
      month,
      format,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.date !== this.props.date ||
      nextProps.time !== this.props.time ||
      !this.propsDateEqualsState(nextProps.value)
    ) {
      this.setState(this.getStateFromProps(nextProps));
    }
  }

  componentWillUnmount() {
    clearTimeout(this.clickTimeout);
    clearTimeout(this.hideTimeout);
    clearTimeout(this.blurTimeout);
  }

  propsDateEqualsState(date) {
    const stateDate = this.state.value && this.parseDate(this.state.value);
    if (!date && !stateDate) {
      return true;
    }
    if ((date && !stateDate) || (!date && stateDate)) {
      return false;
    }
    if (
      stateDate.getFullYear() === date.getFullYear() &&
      stateDate.getMonth() === date.getMonth() &&
      stateDate.getDay() === date.getDay() &&
      stateDate.getHours() === date.getHours() &&
      stateDate.getMinutes() === date.getMinutes() &&
      stateDate.getSeconds() === date.getSeconds()
    ) {
      return true;
    }
    return false;
  }

  formatDate(date) {
    return _formatDate(date, this.state.format);
  }

  parseDate(text) {
    return _parseDate(text, this.state.format);
  }

  showDayPicker() {
    this.setState({ showOverlay: true });
    if (this.props.onPickerShow) {
      this.props.onPickerShow();
    }
  }

  hideDayPicker() {
    this.setState({ showOverlay: false });
    if (this.props.onPickerHide) {
      this.props.onPickerHide();
    }
  }

  hideAfterDayClick() {
    if (this.props.time) {
      return;
    }
    this.hideTimeout = setTimeout(() => this.hideDayPicker(), HIDE_TIMEOUT);
  }

  handleContainerMouseDown(event) {
    this.clickedInside = true;
    if (event.target.tagName.toLowerCase() === "select") {
      this.clickedInsideSelect = true;
    }
    // The input's onBlur method is called from a queue right after the onMouseDown event.
    // setTimeout adds another callback in the queue, which is called after the onBlur event.
    this.clickTimeout = setTimeout(() => {
      this.clickedInside = false;
      this.clickedInsideSelect = false;
    }, 0);
  }

  handleInputClick(e) {
    this.showDayPicker();
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  handleInputFocus(e) {
    this.showDayPicker();
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  handleInputBlur(e) {
    if (this.clickedInside) {
      this.showDayPicker();
      // Force input's focus if blur event was caused by clicking inside the overlay
      if (!this.clickedInsideSelect) {
        this.blurTimeout = setTimeout(() => this.input.focus(), 0);
      }
    } else {
      this.setState(this.getStateFromProps(this.props));
      this.hideDayPicker();
    }
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleInputChange(e) {
    const { onChange } = this.props;
    const value = e.target.value;

    if (value.trim() === "") {
      this.setState({ value });
      if (onChange) {
        onChange(null);
      }
      return;
    }

    const date = this.parseDate(value);
    if (!date) {
      this.setState({ value });
      return;
    }

    this.setState({ month: date, value });

    if (onChange) {
      onChange(date);
    }
  }

  handleInputKeyDown(e) {
    if (e.keyCode === TAB) {
      this.setState(this.getStateFromProps(this.props));
      this.hideDayPicker();
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  }

  handleInputKeyUp(e) {
    // Hide the overlay if the ESC key is pressed
    if (e.keyCode === ESC) {
      this.setState(this.getStateFromProps(this.props));
      this.hideDayPicker();
    }
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    }
  }

  handleMonthChange(month) {
    this.setState({ month });
  }

  handleDayClick(day, modifiers, e) {
    const { onChange } = this.props;

    // Do nothing if the day is disabled
    if (modifiers.disabled) {
      return;
    }

    const date = day;

    if (this.props.time && this.state.value) {
      const currentDate = this.parseDate(this.state.value);
      date.setHours(currentDate.getHours());
      date.setMinutes(currentDate.getMinutes());
      date.setSeconds(currentDate.getSeconds());
    }

    const value = this.formatDate(date);

    // // If the clicked day is already selected, just update the input field if the user was writing something
    // if (modifiers.selected) {
    //   this.setState({value}, this.hideAfterDayClick);
    //   return;
    // }

    this.setState({ value, month: date }, this.hideAfterDayClick);

    if (onChange) {
      onChange(date);
    }
  }

  handleTimePickerSelectChange(what, event) {
    const { onChange } = this.props;
    const whatValue = parseInt(event.target.value, 10);

    let date = this.state.value && this.parseDate(this.state.value);
    if (!date) {
      date = new Date();
      date.setHours(0, 0, 0);
    }

    switch (what) {
      case "hour":
        date.setHours(whatValue);
        break;
      case "minute":
        date.setMinutes(whatValue);
        break;
      case "second":
        date.setSeconds(whatValue);
        break;
    }
    const value = this.formatDate(date);

    this.setState({ month: date, value });

    if (onChange) {
      onChange(date);
    }

    this.input.focus();
  }

  handleTimePickerSelectBlur() {
    if (this.clickedInside) {
      this.showDayPicker();
      // Force input's focus if blur event was caused by clicking inside the overlay
      if (!this.clickedInsideSelect) {
        this.blurTimeout = setTimeout(() => this.input.focus(), 0);
      }
    } else {
      this.setState(this.getStateFromProps(this.props));
      this.hideDayPicker();
    }
  }

  handleTimePickerIncrementClick(what, delta) {
    const { onChange } = this.props;
    let date = this.state.value && this.parseDate(this.state.value);
    if (!date) {
      date = new Date();
      date.setHours(0, 0, 0);
    }

    let whatValue;
    switch (what) {
      case "hour":
        whatValue = date.getHours();
        date.setHours(whatValue + delta);
        break;
      case "minute":
        whatValue = date.getMinutes();
        date.setMinutes(whatValue + delta);
        break;
      case "second":
        whatValue = date.getSeconds();
        date.setSeconds(whatValue + delta);
        break;
    }
    const value = this.formatDate(date);

    this.setState({ month: date, value });

    if (onChange) {
      onChange(date);
    }
  }

  renderTimePickerSelect(what) {
    const date = this.state.value && this.parseDate(this.state.value);

    let values;
    let value;
    switch (what) {
      case "hour":
        values = 24;
        value = date && date.getHours();
        break;
      case "minute":
        values = 60;
        value = date && date.getMinutes();
        break;
      case "second":
        values = 60;
        value = date && date.getSeconds();
        break;
    }

    value = value || 0;
    let valueString = "00" + value;
    valueString = valueString.substring(valueString.length - 2);

    const options = [];
    for (let index = 0; index < values; index++) {
      let indexString = "00" + index;
      indexString = indexString.substring(indexString.length - 2);
      options.push(
        <option key={index} value={index}>
          {indexString}
        </option>
      );
    }

    const upDisabled = value === values - 1;
    const downDisabled = value === 0;

    return (
      <div className="time-picker-select-wrapper">
        <div
          className={classNames(
            "time-picker-arrow",
            "time-picker-arrow-up",
            upDisabled && "time-picker-arrow-disabled"
          )}
          onClick={() =>
            !upDisabled && this.handleTimePickerIncrementClick(what, +1)
          }
        />
        <div className="time-picker-label">
          <span>{valueString}</span>
          <select
            value={value}
            onBlur={this.handleTimePickerSelectBlur}
            onChange={(event) => this.handleTimePickerSelectChange(what, event)}
          >
            {options}
          </select>
        </div>
        <div
          className={classNames(
            "time-picker-arrow",
            "time-picker-arrow-down",
            downDisabled && "time-picker-arrow-disabled"
          )}
          onClick={() =>
            !downDisabled && this.handleTimePickerIncrementClick(what, -1)
          }
        />
      </div>
    );
  }

  renderTimePicker() {
    return (
      <div className="time-picker-container">
        {this.renderTimePickerSelect("hour")}
        <span>:</span>
        {this.renderTimePickerSelect("minute")}
        <span>:</span>
        {this.renderTimePickerSelect("second")}
      </div>
    );
  }

  renderOverlay() {
    const { date, time, disabledDays } = this.props;
    const { value, month } = this.state;
    const Overlay = this.props.overlayComponent;

    const selectedDays = this.parseDate(value);

    return (
      <div className="datetime-picker-input-overlay-wrapper">
        <div className="datetime-picker-input-overlay">
          {date && (
            <DayPicker
              ref={(el) => (this.daypicker = el)}
              locale={moment.locale()}
              localeUtils={MomentLocaleUtils}
              month={month}
              selectedDays={selectedDays}
              disabledDays={disabledDays}
              onDayClick={this.handleDayClick}
              onMonthChange={this.handleMonthChange}
            />
          )}
          {time && this.renderTimePicker()}
        </div>
      </div>
    );
  }

  render() {
    const { id, date, time, value, onChange, className, disabled, style } =
      this.props;

    const placeholder = "";

    return (
      <div
        className={classNames(
          "datetime-picker-input",
          "datetime-picker-input-full-width",
          className
        )}
        onMouseDown={this.handleContainerMouseDown}
        style={style}
      >
        <Input
          id={id}
          innerRef={(el) => (this.input = el)}
          placeholder={placeholder}
          disabled={disabled}
          value={this.state.value || ""}
          onChange={this.handleInputChange}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          onKeyDown={this.handleInputKeyDown}
          onKeyUp={this.handleInputKeyUp}
          onClick={this.handleInputClick}
        />
        {this.state.showOverlay && this.renderOverlay()}
      </div>
    );
  }
}

export default DatetimePicker;
