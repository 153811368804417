import { CONFERMA_DATI_PAGAMENTO_SUCCESS } from "src/actions/transazioniToken/confermaDatiPagamento";
import {
  REDUX_INITIAL_STATE_FETCH_SUCCESS,
  setVenditore_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_RESET_PASSWORD_FAILURE,
  USER_RESET_PASSWORD_NEW_PASSWORD_FAILURE,
  USER_RESET_PASSWORD_NEW_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_NEW_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_SIGNUP_FAILURE,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  REFRESH_ACCOUNT_SUCCESS,
} from "../actions";
import { TOGGLE_MODULO_ATTIVO_SUCCESS } from "src/actions/moduliAttivi/toggleModulo";
import { SET_SOGLIA_EDISTRIBUZIONE_SUCCESS } from "src/actions/utente/setSogliaEdistribuzione";

export default function authReducer(state = {}, action) {
  switch (action.type) {
    case REDUX_INITIAL_STATE_FETCH_SUCCESS:
      return action.response.preloadedState.auth || {};

    case USER_LOGIN_REQUEST:
      return {
        loggingIn: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.response.user,
        admin: action.response.user.admin ? action.response.user : null,
      };
    case USER_LOGIN_FAILURE:
      return {};

    case USER_LOGOUT_REQUEST:
      return {
        loggedIn: state && state.loggedIn,
        user: state && state.user,
        admin: state && state.admin,
        loggingOut: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {};
    case USER_LOGOUT_FAILURE:
      return {
        loggedIn: state && state.loggedIn,
        user: state && state.user,
        admin: state && state.admin,
      };

    case USER_SIGNUP_REQUEST:
      return {
        signingUp: true,
      };
    case USER_SIGNUP_SUCCESS:
      return {
        loggedIn: true,
        user: action.response.user,
      };
    case USER_SIGNUP_FAILURE:
      return {};

    case USER_RESET_PASSWORD_REQUEST:
      return {
        resettingPassword: true,
      };
    case USER_RESET_PASSWORD_SUCCESS:
      return {
        passwordReset: true,
      };
    case USER_RESET_PASSWORD_FAILURE:
      return {};

    case USER_RESET_PASSWORD_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        resettingPassword: true,
        passwordReset: false,
      };
    case USER_RESET_PASSWORD_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        resettingPassword: false,
        passwordReset: false,
        newPassword: true,
      };
    case USER_RESET_PASSWORD_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        resettingPassword: false,
        passwordReset: false,
        newPassword: false,
      };

    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        user: action.response,
      };

    case REFRESH_ACCOUNT_SUCCESS:
      return {
        ...state,
        user: action.response,
      };

    case CONFERMA_DATI_PAGAMENTO_SUCCESS:
      return {
        ...state,
        user: action.response,
      };

    case setVenditore_SUCCESS:
      return {
        ...state,
        user: action.response.success ? action.response.data : state.user,
      };

    case TOGGLE_MODULO_ATTIVO_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          moduliAttivi: action.response.moduliAttivi,
        },
      };

    case SET_SOGLIA_EDISTRIBUZIONE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          sogliaEdistribuzione: action.response.sogliaEdistribuzione,
        },
      };

    default:
      return state;
  }
}
