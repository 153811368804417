import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "src/components/old/reactstrapFix/reactstrap";
import classNames from "classnames";
import { HelpdeskIcon } from "../elements/AppIcons";

const helps = {
  // LICENZA
  richiedente: {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/licenza/1.png",
    imageSize: [526, 750],
  },
  codiceAccessoRichiedente: {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/licenza/2.png",
    imageSize: [532, 792],
  },
  autorizzazioniAllAccesso: {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/licenza/3.png",
    imageSize: [555, 788],
  },
  passwordRichiedente: {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/licenza/4.png",
    imageSize: [749, 691],
  },
  accessoAllApplicazione: {
    type: "text",
    title: "Aiuto",
    text: "Inserire la password di accesso all’applicazione più recente. Qualora non sia in vostro possesso, richiederla al soggetto che ha predisposto la dichiarazione annuale precedente.",
  },
  sottoscrittore: {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/licenza/5.png",
    imageSize: [560, 607],
  },
  codiceAccessoSottoscrittore: {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/licenza/6.png",
    imageSize: [531, 775],
  },
  passwordSottoscrittore: {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/licenza/7.png",
    imageSize: [538, 513],
  },

  // CARD IMPIANTO
  installationCode: {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/impianto/8.1.png",
    imageSize: [516, 518],
  },
  soggettoObbligato: {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/impianto/8.2.png",
    imageSize: [508, 560],
  },
  ufficioAmministrativo: {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/impianto/8.3.png",
    imageSize: [514, 547],
  },

  // CARD REPORT
  "rendicontazioneContatore-CESSIONE_TOTALE-produzione": {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/rendicontazione/9.png",
    imageSize: [684, 427],
  },
  "rendicontazioneContatore-CESSIONE_PARZIALE_NON_COMMERCIALE-produzione": {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/rendicontazione/10.1.png",
    imageSize: [714, 730],
  },
  "rendicontazioneContatore-CESSIONE_PARZIALE_NON_COMMERCIALE-ceduta": {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/rendicontazione/10.2.png",
    imageSize: [712, 717],
  },
  "rendicontazioneContatore-CESSIONE_PARZIALE_COMMERCIALE-produzione": {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/rendicontazione/11.1.png",
    imageSize: [678, 833],
  },
  "rendicontazioneContatore-CESSIONE_PARZIALE_COMMERCIALE-ceduta": {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/rendicontazione/11.2.png",
    imageSize: [707, 832],
  },
  accontiAccisaAnnoPrecedente: {
    type: "image",
    title: "Documento esempio",
    imageUrl: "/help/rendicontazione/11.3.png",
    imageSize: [629, 500],
  },
};

class HelpPopover extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      open: false,
    };
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    const { what, inline } = this.props;

    const help = helps[what];

    let title, body;
    const modifiers = {};

    if (!help) {
      console.error('HelpPopover: help named "' + what + '" not found');
      title = "Titolo Aiuto";
      body =
        "Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.";
    } else {
      title = help.title;

      if (help.type === "text") {
        body = help.text;
      } else if (help.type === "image") {
        let [width, height] = help.imageSize;
        let [ow, oh] = help.imageSize;

        if (width > document.body.clientWidth - 30) {
          width = document.body.clientWidth - 30;
          height = Math.round((oh * width) / ow);
        }
        if (height > document.body.clientHeight - 60) {
          height = document.body.clientHeight - 60;
          width = Math.round((ow * height) / oh);
        }

        body = <img width={width} height={height} src={help.imageUrl}></img>;

        // Custom modifier
        function autoSizing(data) {
          data.offsets.popper.width =
            data.styles.width =
            data.styles["maxWidth"] =
              width + 20;
          data.offsets.popper.height = data.styles.height = height + 50;
          return data;
        }

        // Popper instanciation
        modifiers.autoSizing = {
          enabled: true,
          fn: autoSizing,
          order: 840,
        };
      }
    }

    var popoverId = "license-data-card-popover-" + what;

    return (
      <div style={{ display: inline ? "inline-block" : "block" }}>
        <Button
          id={popoverId}
          onClick={this.toggle}
          color="link"
          style={{ marginTop: -14, marginBottom: -10 }}
        >
          <HelpdeskIcon /> Aiuto
        </Button>
        <Popover
          placement="auto"
          isOpen={this.state.open}
          target={popoverId}
          toggle={this.toggle}
          modifiers={modifiers}
        >
          <PopoverHeader>{title}</PopoverHeader>
          <PopoverBody>{body}</PopoverBody>
        </Popover>
      </div>
    );
  }
}

export default HelpPopover;
