import { GSERidCorrispettivi } from "energix-types/src/GSE";
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import BaseTable from "src/components/BaseTable";
import { monthName } from "src/constants/months";
import { fNumber, fPrice } from "src/components/common/formatNumber";
import moment from "moment/moment";

export type RowData = GSERidCorrispettivi;

export type GseRidTableProps = {
  data: RowData[];
  riepilogo?: boolean;
};

const columnHelper = createColumnHelper<RowData>();

export default function GseRidTable({
  data,
  riepilogo = false,
}: GseRidTableProps) {
  const columns = useMemo(
    () => [
      columnHelper.accessor((r) => `${r.anno}-${r.mese}`, {
        header: "Mese",
        cell: ({
          row: {
            original: { anno, mese },
          },
        }) => `${mese ? monthName[mese - 1] : ""} ${anno ?? ""}`.trim(),
      }),
      columnHelper.accessor("energiaImmessa", {
        header: "Energia Immessa",
        cell: ({
          row: {
            original: { energiaImmessa },
          },
        }) =>
          typeof energiaImmessa === "number"
            ? `${fNumber(energiaImmessa)} kWh`
            : "",
      }),
      columnHelper.accessor("energiaNetta", {
        header: "Energia Netta",
        cell: ({
          row: {
            original: { energiaNetta },
          },
        }) =>
          typeof energiaNetta === "number"
            ? `${fNumber(energiaNetta)} kWh`
            : "",
      }),
      columnHelper.accessor("energiaLorda", {
        header: "Energia Lorda",
        cell: ({
          row: {
            original: { energiaLorda },
          },
        }) =>
          typeof energiaLorda === "number"
            ? `${fNumber(energiaLorda)} kWh`
            : "",
      }),
      columnHelper.accessor("naturaEconomica", {
        header: "Natura Economica",
      }),
      columnHelper.accessor("importo", {
        header: "Importo",
        cell: ({
          row: {
            original: { importo },
          },
        }) => (typeof importo === "number" ? `${fPrice(importo)} €` : ""),
      }),
      columnHelper.accessor("dataFatturazione", {
        header: "Data Fatturazione",
        cell: ({
          row: {
            original: { dataFatturazione },
          },
        }) =>
          dataFatturazione ? moment(dataFatturazione).format("DD/MM/YYYY") : "",
      }),
      columnHelper.accessor("dataPagamento", {
        header: "Data Pagamento",
        cell: ({
          row: {
            original: { dataPagamento },
          },
        }) => (dataPagamento ? moment(dataPagamento).format("DD/MM/YYYY") : ""),
      }),
      columnHelper.accessor("statoFattura", {
        header: "Stato",
      }),
      columnHelper.accessor("statoFatturaDettaglio", {
        header: "Dettagli",
      }),
    ],
    []
  );

  return (
    <BaseTable
      columns={columns}
      data={data}
      paginated={!riepilogo}
      sortable={!riepilogo}
    />
  );
}
