// type
// API Call
import { CALL_API } from "src/middleware/api";
import { NotificheSettings } from "energix-types/src/NotificheSettings";
import { useDispatch } from "react-redux";

export type SaveNotificheSettingsFunction = (
  notificheSettings: NotificheSettings
) => Promise<SaveNotificheSettingsResult>;

export type SaveNotificheSettingsResult =
  | SaveNotificheSettingsSuccess
  | SaveNotificheSettingsUnknownError;
export type SaveNotificheSettingsSuccess = {
  resultType: "success";
};
export type SaveNotificheSettingsUnknownError = {
  resultType: "error";
};

const NOTIFICATION_SETTINGS_SET_REQUEST = "NOTIFICATION_SETTINGS_SET_REQUEST";
export const NOTIFICATION_SETTINGS_SET_SUCCESS =
  "NOTIFICATION_SETTINGS_SET_SUCCESS";
const NOTIFICATION_SETTINGS_SET_FAILURE = "NOTIFICATION_SETTINGS_SET_FAILURE";

const saveNotificheSettingsApiCall =
  (notificheSettings: NotificheSettings) => (dispatch: any) => {
    return dispatch({
      [CALL_API]: {
        types: [
          NOTIFICATION_SETTINGS_SET_REQUEST,
          NOTIFICATION_SETTINGS_SET_SUCCESS,
          NOTIFICATION_SETTINGS_SET_FAILURE,
        ],
        endpoint: "/api/notificationSettings/set",
        method: "POST",
        body: notificheSettings,
      },
    });
  };

// Wrapper
export function useSaveNotificheSettings(): SaveNotificheSettingsFunction {
  const dispatch = useDispatch();
  return async function saveNotificheSettings(
    notificheSettings: NotificheSettings
  ) {
    const result = await dispatch(
      saveNotificheSettingsApiCall(notificheSettings)
    );
    if (result.type !== NOTIFICATION_SETTINGS_SET_SUCCESS) {
      return { resultType: "error" };
    }
    return { resultType: "success" };
  };
}
