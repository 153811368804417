// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: theme.palette.primary.actionBackground,
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.action.selected,
            "&:hover": {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
          padding: theme.spacing(2),
        },
        head: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
          "&:first-of-type": {
            paddingLeft: theme.spacing(3),
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
            boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(3),
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
            boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
          },
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
        body: {
          padding: theme.spacing(1, 2),
          "&:first-of-type": {
            paddingLeft: theme.spacing(3),
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(3),
          },
        },
      },
    },
    // header grouping
    MuiTableHead: {
      styleOverrides: {
        root: {
          position: "relative",
          "& .MuiTableRow-root": {
            "&:not(:first-of-type)": {
              "& .MuiTableCell-root": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                paddingTop: 0,
              },
            },
            "&:not(:last-of-type)": {
              "& .MuiTableCell-root": {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                paddingBottom: 0,
              },
            },
            "&:first-of-type:not(:only-child)": {
              "& .MuiTableCell-root:not(:first-of-type)": {
                "&::before": {
                  content: '""',
                  borderLeft: `1px solid ${theme.palette.grey[300]}`,
                  position: "absolute",
                  top: theme.spacing(1),
                  bottom: theme.spacing(1),
                  display: "block",
                  marginLeft: theme.spacing(-3),
                },
              },
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "&:before": {
            content: `""`,
            display: "block",
            width: "100%",
            borderTop: `solid 1px ${theme.palette.divider}`,
          },
        },
        toolbar: {
          height: 64,
        },
        select: {
          "&:focus": {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2,
        },
      },
    },
  };
}
