const titles = {
  A: "PRODUZIONE",
  B: "USO PROMISCUO",
  C: "CONSUMI PROPRI ESENTI DA ACCISA",
  E: "CONSUMI PROPRI ASSOGGETTATI AD ACCISA",
  G: "ENERGIA ELETTRICA CEDUTA",
  H: "ENERGIA ELETTRICA RICEVUTA",
  I: "ENERGIA ELETTRICA FATTURATA",
  PERDITE: "PERDITE",
  J: "CONSUMI NON SOTTOPOSTI AD ACCISA",
  L: "CONSUMI ESENTI DA ACCISA",
  M: "CONSUMI ASSOGGETTATI AD ACCISA",
  K: "RETTIFICHE DI FATTURAZIONE",
  P: "LIQUIDAZIONE DELL'ACCISA",
  Q: "RIEPILOGO E SALDO DELL'ACCISA",
  R: "LIQUIDAZIONE DELL'ADDIZIONALE D.L. 511/88",
  S: "SALDO DELL'ADDIZIONALE D.L. 511/88",
};

export default function getQuadroTitle(quadro) {
  return titles[quadro] || "";
}
