import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Impianto } from "energix-types/src/Impianto";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  GetLettureAziendaFunction,
  GetLettureAziendaPaidFunction,
  LettureType,
} from "src/actions";
import { useCostoOperazioni } from "src/hooks/useContextSelectors";

export type UsaDatiLettureProps = {
  impianto: Impianto;
  anno: number;
  getLetture: GetLettureAziendaFunction;
  getLetturePaid: GetLettureAziendaPaidFunction;
  onLetture: (letture: LettureType) => void;
};

export function UsaDatiLetture({
  impianto,
  anno,
  getLetture,
  getLetturePaid,
  onLetture,
}: UsaDatiLettureProps) {
  const getLetturePaidRef = useRef(getLetturePaid);
  getLetturePaidRef.current = getLetturePaid;
  const getLettureRef = useRef(getLetture);
  getLettureRef.current = getLetture;

  const costiOperazioni = useCostoOperazioni();

  const [paid, setPaid] = useState<null | boolean>(null);

  const refreshCountRef = useRef(0);

  useEffect(() => {
    let mounted = true;
    refreshCountRef.current++;
    setDownloadDatiLettureLoading(false);

    const f = async () => {
      const r = await getLetturePaidRef.current(
        impianto.license_id,
        impianto.id,
        anno
      );
      if (!mounted) {
        return;
      }
      const paid = r.data === true;
      setPaid(paid);
    };
    f();
    return () => {
      mounted = false;
      setPaid(null);
    };
  }, [impianto, anno]);

  const [downloadDatiLettureLoading, setDownloadDatiLettureLoading] =
    useState(false);
  const downloadDatiLetture = async () => {
    setDownloadDatiLettureLoading(true);

    const initial = refreshCountRef.current;

    const r = await getLettureRef.current(
      impianto.license_id,
      impianto.id,
      anno
    );

    if (initial !== refreshCountRef.current) {
      return;
    }

    if (r.data) {
      onLetture?.(r.data);
    } else if (r.error) {
      toast.error(r.error || "Errore");
    }

    setDownloadDatiLettureLoading(false);
  };

  return (
    <div>
      <Box>
        <Button
          variant="contained"
          color="info"
          onClick={downloadDatiLetture}
          disabled={downloadDatiLettureLoading}
        >
          Acquisisci le letture da E-Distribuzione{" "}
          {downloadDatiLettureLoading && (
            <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} />
          )}
        </Button>
      </Box>
      <Box>
        <Typography variant="caption" sx={{ mb: 2 }}>
          {paid === null
            ? "(...)"
            : paid
            ? "(Hai già pagato questo servizio per questo impianto e per quest'anno, quindi non ti verranno addebitati ulteriori token!)"
            : `(Ti verrà addebitato il costo di ${
                costiOperazioni.prelievoDatiDalGestoreDiReteSingoloImpianto ||
                ""
              } token/anno per ogni impianto al primo utilizzo di questo servizio)`}
        </Typography>
      </Box>
    </div>
  );
}
