import { GSESspCorrispettivi } from "energix-types/src/GSE";
import { createColumnHelper } from "@tanstack/react-table";
import BaseTable from "src/components/BaseTable";
import { useMemo } from "react";
import { fPrice } from "src/components/common/formatNumber";
import moment from "moment/moment";

export type RowData = GSESspCorrispettivi;

export type GseSspTableProps = {
  data: RowData[];
  riepilogo?: boolean;
};

const columnHelper = createColumnHelper<RowData>();

export default function GseSspTable({
  data,
  riepilogo = false,
}: GseSspTableProps) {
  const columns = useMemo(
    () => [
      columnHelper.accessor("anno", {
        header: "Anno",
      }),
      columnHelper.accessor("trimestreOSemestre", {
        header: "Trimestre o semestre",
      }),
      columnHelper.accessor("dataEmissione", {
        header: "Data Emissione",
        cell: ({
          row: {
            original: { dataEmissione },
          },
        }) => (dataEmissione ? moment(dataEmissione).format("DD/MM/YYYY") : ""),
      }),
      columnHelper.accessor("corrispettivo", {
        header: "Corrispettivo",
      }),
      columnHelper.accessor("descrizione", {
        header: "Descrizione",
      }),
      columnHelper.accessor("imponibile", {
        header: "Importo",
        cell: ({
          row: {
            original: { imponibile },
          },
        }) => (typeof imponibile === "number" ? `${fPrice(imponibile)} €` : ""),
      }),
    ],
    []
  );

  return (
    <BaseTable
      columns={columns}
      data={data}
      paginated={!riepilogo}
      sortable={!riepilogo}
    />
  );
}
