import { Impianto } from "energix-types/src/Impianto";
import { Dichiarazione } from "energix-types/src/Dichiarazione";
import "energix-old/src/app/App_new_frontend.scss";
import ReportCardSteps from "./old/ReportCardSteps";

type OldReportCardForLettureWrapperProps = {
  open: boolean;
  installation: Impianto;
  report: Dichiarazione;
  updateReport: (body: any) => Promise<any>;
  onClose: () => void;
};

export function OldReportCardForLettureWrapper(
  props: OldReportCardForLettureWrapperProps
) {
  if (!props.open) {
    return null;
  }

  const { open, installation, report, updateReport, onClose } = props;

  const key = "report-" + (report && report.id);

  const closeAction = () => {
    onClose();
  };

  return (
    <div className="energix-old-frontend-wrapper">
      <ReportCardSteps
        key={key}
        report={report}
        installation={installation}
        anno={report.anno}
        dichiarazione={false}
        service={null}
        isAdmin={false}
        isModal={true}
        isModalOpen={open}
        isClienteFinaleWebsite={true}
        updateReport={updateReport}
        onSaveSuccess={closeAction}
        onCancel={closeAction}
      />
    </div>
  );
}
