import { find, findLast, each } from "lodash";
import moment from "moment";

export function getMaxValuePerContatore(contatore) {
  const maxValue = Math.pow(10, contatore.numeroCifreIntere);
  return maxValue;
}

// numeroDiAzzeramentiOrNull = null or 0: calcola se si azzera dalla differenza delle letture
// usare numeroDiAzzeramentiOrNull = null solo per calcoli mese per mese, se si guarda l'anno, va passato numeroDiAzzeramentiOrNull
export function differenzaLetture(
  contatore,
  letturaPrecedente,
  letturaAttuale,
  fail = true,
  numeroDiAzzeramentiOrNull = null
) {
  let diff = letturaAttuale - letturaPrecedente;

  if (numeroDiAzzeramentiOrNull && !contatore.numeroCifreIntere) {
    if (fail) {
      throw new Error(
        "Letture decrescenti (numero di cifre intere non specificato)"
      );
    }
  }

  if (
    contatore.numeroCifreIntere &&
    (contatore.azzerato || numeroDiAzzeramentiOrNull)
  ) {
    if (diff < 0 || numeroDiAzzeramentiOrNull) {
      if (!numeroDiAzzeramentiOrNull) {
        numeroDiAzzeramentiOrNull = 1;
      }
      const maxValue = Math.pow(10, contatore.numeroCifreIntere);
      diff += maxValue * numeroDiAzzeramentiOrNull;
    }
  }

  if (contatore.inserimentoDirettoKwh) {
    // caso particolare in cui inseriscono direttamente mese per mese i kwh
    // letturaAttuale è già il valore corretto
    diff = letturaAttuale;
  }

  if (diff < 0) {
    if (fail) {
      throw new Error("Letture decrescenti");
    }
  }

  //TODO: rounding error!!
  /*
  example:
    > var l1=17396.28
    17396.28
    > var l2=21833.17
    21833.17
    > var diff = l2-l1
    4436.889999999999
    > parseFloat((l2-l1).toFixed(6))
    4436.89
    > parseFloat((diff).toFixed(6))
    4436.89
  */

  // al massimo dovrebbero cmq esserci 3 decimali perche noi gli lasciamo mettere al massimo 3 decimali..
  // quindi cosi non dovrebbero esserci problemi
  diff = parseFloat(diff.toFixed(6));

  return diff;
}

function _firstLastLettura(letture, first) {
  const lettureKeys = Object.keys(letture).sort();

  function isNumber(k) {
    const n = letture[k];
    return typeof n === "number";
  }

  let key;
  if (first) {
    key = find(lettureKeys, isNumber);
  } else {
    key = findLast(lettureKeys, isNumber);
  }

  return letture[key];
}

function countNumeroDiAzzeramenti(letture) {
  const lettureKeys = Object.keys(letture).sort();
  let lastN = null;
  let count = 0;
  lettureKeys.forEach((k) => {
    const n = letture[k];
    if (typeof n === "number") {
      if (lastN !== null) {
        if (n < lastN) {
          count++;
        }
      }
      lastN = n;
    }
  });
  return count;
}

export function firstLettura(letture) {
  return _firstLastLettura(letture, true);
}

export function lastLettura(letture) {
  return _firstLastLettura(letture, false);
}

export function getDiffLettureTotaleAnno(contatore, fail) {
  let letturaAttuale = 0;
  let letturaPrecedente = 0;
  let diff = 0;
  let k = 0;
  let chilowattora = 0;

  if (contatore.inserimentoDirettoKwh) {
    // caso particolare, non ci sono letture, ritornare 0
    chilowattora = 0;
    each(contatore.letture, (lett) => {
      chilowattora += lett || 0;
    });
    return { letturaAttuale, letturaPrecedente, diff, k, chilowattora };
  }

  const numeroDiAzzeramenti = countNumeroDiAzzeramenti(contatore.letture);

  letturaAttuale = lastLettura(contatore.letture);
  letturaPrecedente = firstLettura(contatore.letture);
  diff = differenzaLetture(
    contatore,
    letturaPrecedente,
    letturaAttuale,
    fail,
    numeroDiAzzeramenti
  );
  k = contatore.k;
  chilowattora = Math.round(diff * k);

  return {
    letturaAttuale,
    letturaPrecedente,
    diff,
    k,
    chilowattora,
    numeroDiAzzeramenti,
  };
}

export function getKeyForMeseIndex(anno, meseIndex) {
  // meseIndex = 0  --> 1/1
  // meseIndex = 12 --> 1/1 anno successivo
  let key;
  if (meseIndex === 12) {
    key = "L" + (anno + 1) + "0101";
  } else {
    let mese = meseIndex + 1;
    key = "L" + anno + "" + (mese < 10 ? "0" + mese : mese) + "01";
  }
  return key;
}

export function getKeyFromDateString(dateString) {
  if (dateString) {
    if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(dateString)) {
      return null;
    }
    return "L" + dateString.split("-").join("");
  }
  return null;
}

function getMeseFromKey(key) {
  // LYYYYMMDD
  const m = parseInt(key.substring(5, 7), 10);
  return m;
}

function getNextDateKey(key, frequenzaLetture = 1) {
  // LYYYYMMDD
  const y = parseInt(key.substring(1, 5), 10);
  const m = parseInt(key.substring(5, 7), 10);
  const incr = Math.min(m + frequenzaLetture - 1, 12);
  return getKeyForMeseIndex(y, incr); // aumenta lui il mese! per quello c'è " - 1"
}

function getNextDateLetturaAnnualeKey(key) {
  // LYYYYMMDD
  const y = parseInt(key.substring(1, 5), 10);
  return getKeyForMeseIndex(y + 1, 0);
}

function isConsecutiveDay(key1, key2) {
  const date1 = moment(key1.substring(1), "YYYYMMDD");
  const date1String = "L" + date1.add(1, "day").format("YYYYMMDD");
  return key2 === date1String;
}

export function getDateLabelForKey(key, useLastMonth, format = "D MMMM YYYY") {
  const date = moment(key.substring(1), "YYYYMMDD");
  if (useLastMonth && date.date() === 1) {
    date.add(-1, "day");
  }
  const label = date.format(format);
  return label;
}

export function getMesiPerFormula(anno, formulaObj, report) {
  const frequenzaLetture =
    (report.reportInfo.frequenzaLetture &&
      parseInt(report.reportInfo.frequenzaLetture)) ||
    1;

  const maxEndDate = getKeyForMeseIndex(anno, 12);

  const from = getKeyFromDateString(formulaObj.startDate);
  let to;
  if (formulaObj.endDate === anno + "-12-31") {
    to = maxEndDate;
  } else {
    to = getKeyFromDateString(formulaObj.endDate);
  }

  const result = [];
  let ended = false;
  let last = from;
  let dateNow = getNextDateKey(from, frequenzaLetture);

  while (!ended) {
    if (dateNow > to) {
      ended = true;
      // insert last?
      if (last !== to) {
        dateNow = to;
      } else {
        break;
      }
    }

    let mese;
    if (frequenzaLetture === 1) {
      // bisogna fare cosi perche potrebbe essere per esempio metà mese, in quel caso non bisogna togliere 1
      mese = getMeseFromKey(last);
    } else {
      // caso particolare.. probabilmente non andrà se hanno formule spezzate, però quando capiterò lo vedremo
      const meseFinalePiu1 = getMeseFromKey(dateNow);
      mese = meseFinalePiu1 > 1 ? meseFinalePiu1 - 1 : 12;
    }
    result.push({
      fromKey: last,
      toKey: dateNow,
      mese: mese,
    });

    last = dateNow;
    dateNow = getNextDateKey(dateNow, frequenzaLetture);
  }

  return result;
}

function getDateKeyLettureRichiesteDalleFormule(anno, formuleOrNull) {
  formuleOrNull = formuleOrNull || [];
  const minStartDate = getKeyForMeseIndex(anno, 0);
  const maxEndDate = getKeyForMeseIndex(anno, 12);

  let from = minStartDate;
  let to;
  const result = [];

  formuleOrNull.forEach((formula, index) => {
    if (index === formuleOrNull.length - 1) {
      to = maxEndDate;
    } else {
      to = getKeyFromDateString(formula.endDate);
    }

    result.push({
      fromKey: from,
      toKey: to,
    });

    from = to;
  });

  return result;
}

export function getDateLettureRichieste(
  anno,
  startDateString,
  endDateString,
  letturaAnnuale = false,
  report = null,
  formuleOrNull = null
) {
  const minStartDate = getKeyForMeseIndex(anno, 0);
  const maxEndDate = getKeyForMeseIndex(anno, 12);
  const _inputStartDate = getKeyFromDateString(startDateString);
  const _inputEndDate =
    endDateString && endDateString === anno + "-12-31"
      ? null
      : getKeyFromDateString(endDateString);
  const startDate =
    _inputStartDate && _inputStartDate > minStartDate
      ? _inputStartDate
      : minStartDate;
  const endDate =
    _inputEndDate && _inputEndDate < maxEndDate ? _inputEndDate : maxEndDate;

  const frequenzaLetture = letturaAnnuale
    ? 12
    : (report.reportInfo.frequenzaLetture &&
        parseInt(report.reportInfo.frequenzaLetture)) ||
      1;

  let dateNow = startDate;
  let ended = false;
  let last = null;

  const result = [];

  const altreKeyRichieste = getDateKeyLettureRichiesteDalleFormule(
    anno,
    formuleOrNull
  );
  let indexAltreKeyRichieste = 0;

  while (!ended) {
    if (dateNow > endDate) {
      ended = true;
      // insert last?
      if (last && last !== endDate) {
        dateNow = endDate;
      } else {
        break;
      }
    }

    while (
      indexAltreKeyRichieste < altreKeyRichieste.length &&
      altreKeyRichieste[indexAltreKeyRichieste].toKey <= dateNow
    ) {
      const d = altreKeyRichieste[indexAltreKeyRichieste].toKey;
      if (d > startDate && d < dateNow) {
        const mese = getMeseFromKey(d);
        result.push({
          label: getDateLabelForKey(d, true),
          mese: mese,
          key: d,
        });
      }
      last = d;
      indexAltreKeyRichieste++;
    }

    const useLastMonth =
      dateNow !== _inputStartDate &&
      (!last || !isConsecutiveDay(last, dateNow));
    const label = getDateLabelForKey(dateNow, useLastMonth);

    // console.log(dateNow, label)

    const mese = getMeseFromKey(dateNow);
    result.push({
      label: label,
      mese: mese,
      key: dateNow,
    });

    last = dateNow;
    dateNow = letturaAnnuale
      ? getNextDateLetturaAnnualeKey(dateNow)
      : getNextDateKey(dateNow, frequenzaLetture);
  }

  return result;
}

export function getDatiQuadri(report, nomeQuadro, arrayKey) {
  const datiQuadriAll = report.reportInfo && report.reportInfo.datiQuadri;
  const thisDati = datiQuadriAll && datiQuadriAll[nomeQuadro];
  const dati = thisDati && thisDati[arrayKey];
  return dati || [];
}
