import { FilesServizio } from "./FileServizio";

export interface Contatore {
  id: number;
  contatoreInfo: ContatoreInfo;
  servizioTaraturaAbilitato: boolean;
  ultimaTaratura: string | null;
  frequenzaTaratura: number | null;
  filesServizioTaratura: FilesServizio[] | null;
  created_at: Date | string;
  updated_at: Date | string;
  user_id: number;
  installation_id: number;
  // last_servizio_taratura_contatore_acquistato_id: number | null;
}

export interface ContatoreInfo {
  byAnno: { [key: string]: ByAnno };
}

export interface ByAnno {
  type: TipoContatore;
  matricola?: string;
  codice?: string;
  k?: number;
  letture?: { [key: string]: number | null };
  readOnly: boolean;
  numeroCifreIntere?: number | null;
  numeroCifreDecimali?: number | null;
  azzerato?: boolean;
  startDate?: string | null;
  endDate?: string | null;
  tipologiaDiCessione?: TipologiaDiCessione;
  codiceIdentificativoOfficinaDestinataria?: string;
  tipologiaDiRicezione?: TipologiaDiRicezione;
  codiceIdentificativoOfficinaFornitrice?: string;
  tipologiaDiProduzione?: TipologiaDiProduzione;
  inserimentoDirettoKwh?: boolean;
  statoLetture?: null;
  decimali?: number;
}

export type TipologiaDiCessione = "A" | "B" | "C" | "D" | "E";

export type TipologiaDiProduzione = "" | "non-rinnovabili";

export type TipologiaDiRicezione = "G" | "H" | "I" | "J";

export type TipoContatore =
  | "ceduta"
  | "consumiPropriAssoggettati"
  | "consumiPropriEsenti"
  | "consumiPropriNonSottoposti"
  | "produzione"
  | "promiscuo"
  | "ricevuta";

// eslint-disable-next-line no-unused-vars
export const labelTipoContatori: { [tipo in TipoContatore]: string } = {
  produzione: "A - Produzione",
  promiscuo: "B - Uso promiscuo",
  consumiPropriEsenti: "C - Consumi propri esenti da accisa",
  consumiPropriAssoggettati: "E - Consumi propri assoggettati ad accisa",
  ceduta: "G - Energia elettrica ceduta",
  ricevuta: "H - Energia elettrica ricevuta",
  consumiPropriNonSottoposti: "X - Consumi propri non sottoposti",
};

// eslint-disable-next-line no-unused-vars
export const shortLabelTipoContatori: { [tipo in TipoContatore]: string } = {
  produzione: "A",
  promiscuo: "B",
  consumiPropriEsenti: "C",
  consumiPropriAssoggettati: "E",
  ceduta: "G",
  ricevuta: "H",
  consumiPropriNonSottoposti: "X",
};

export const lettureFornitoriKeyByTipoContatore: {
  // eslint-disable-next-line no-unused-vars
  [tipo in TipoContatore]: string;
} = {
  produzione: "prodotta",
  promiscuo: "", //TODO: da dove leggere i valori?
  consumiPropriEsenti: "", //TODO: da dove leggere i valori?
  consumiPropriAssoggettati: "", //TODO: da dove leggere i valori?
  ceduta: "immessa",
  ricevuta: "", //TODO: da dove leggere i valori?
  consumiPropriNonSottoposti: "", //TODO: da dove leggere i valori?
};

export const curveDiCaricoFornitoriKeyByTipoContatore: {
  // eslint-disable-next-line no-unused-vars
  [tipo in TipoContatore]: string;
} = {
  produzione: "immessa", // contatore di produzione "immette" nell'azienda
  promiscuo: "", //TODO: da dove leggere i valori?
  consumiPropriEsenti: "", //TODO: da dove leggere i valori?
  consumiPropriAssoggettati: "", //TODO: da dove leggere i valori?
  ceduta: "immessa",
  ricevuta: "", //TODO: da dove leggere i valori?
  consumiPropriNonSottoposti: "", //TODO: da dove leggere i valori?
};
