import { Impianto } from "energix-types/src/Impianto";
import { installationsByType } from "common/installations";

type ReportCardTitleProps = {
  anno: number;
  impianto: Impianto;
};

export default function ReportCardTitle({
  anno,
  impianto,
}: ReportCardTitleProps) {
  const installationType = (installationsByType as any)[impianto.type];

  const title = "Rendicontazione anno " + (anno - 1);

  return (
    <div>
      <div>{title}</div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "0.8em",
            fontWeight: "bold",
          }}
        >
          {impianto.name || "Impianto " + impianto.installationCode}
        </div>

        <Divider />

        <span
          style={{
            fontSize: "0.6em",
            paddingRight: 4,
          }}
        >
          Codice ditta/Licenza:
        </span>
        <span
          style={{
            fontSize: "0.8em",
            fontWeight: "bold",
          }}
        >
          {impianto.installationCode}
        </span>

        <Divider />

        <span
          style={{
            fontSize: "0.6em",
            paddingRight: 4,
          }}
        >
          Tipologia:{" "}
        </span>
        <span
          style={{
            fontSize: "0.8em",
            fontWeight: "bold",
          }}
        >
          {installationType.name}
        </span>
      </div>
    </div>
  );
}

function Divider() {
  return (
    <span
      style={{
        paddingLeft: 8,
        paddingRight: 8,
        color: "#00000055",
      }}
    >
      -
    </span>
  );
}
