import { ImpiantoGreen } from "energix-types/src/Impianto";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import BaseTable from "src/components/BaseTable";
import { createColumnHelper } from "@tanstack/react-table";
import {
  ContatoreDto,
  EdistribuzioneContatoreDto,
  GSEContatoreDto,
} from "src/orval/models";
import { Azienda } from "energix-types/src/Azienda";
import { keyBy } from "lodash";

export type PodAssociatiCardProps = {
  impianto: ImpiantoGreen;
  azienda: Azienda;
  contatori: ContatoreDto[];
  contatoriEdistribuzione: EdistribuzioneContatoreDto[];
  contatoriGSE: GSEContatoreDto[];
};

const columnHelper = createColumnHelper<ContatoreDto>();

export default function PodAssociatiCard({
  impianto,
  azienda,
  contatori,
  contatoriEdistribuzione,
  contatoriGSE,
}: PodAssociatiCardProps) {
  const edistribuzioneByPod = useMemo(() => {
    const contatori = contatoriEdistribuzione.filter(
      (c) =>
        c.edistribuzioneCredenzialiId === azienda.credenzialiEdistribuzioneId
    );
    return keyBy(contatori, "pod");
  }, [contatoriEdistribuzione, azienda.credenzialiEdistribuzioneId]);

  const gseByPod = useMemo(() => {
    const contatori = contatoriGSE.filter(
      (c) => c.gseCredenzialiId === azienda.credenzialiGSEId
    );
    return keyBy(contatori, "pod");
  }, [contatoriGSE, azienda.credenzialiGSEId]);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("pod", {
        header: "POD",
        cell: ({
          row: {
            original: { pod },
          },
        }) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>{pod}</Typography>
              {gseByPod[pod] && <Chip label="GSE" />}
              {edistribuzioneByPod[pod] && <Chip label="E-Distribuzione" />}
            </Stack>
          );
        },
      }),
      columnHelper.accessor(
        (c) => edistribuzioneByPod[c.pod]?.matricole?.join(", ") ?? "",
        {
          header: "Matricole",
        }
      ),
    ];
  }, [gseByPod, edistribuzioneByPod]);

  return (
    <Card style={{ height: "100%", paddingBottom: 8 }}>
      <CardHeader title="POD associati" />
      <CardContent sx={{ maxHeight: 350, overflowY: "auto" }}>
        <BaseTable
          columns={columns}
          data={contatori}
          paginated={false}
          mt={0}
        />
      </CardContent>
    </Card>
  );
}
