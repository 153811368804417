import {
  EDistribuzioneEnergiaMensile,
  TipiEDistribuzioneEnergiaMensile,
  TipoEDistribuzioneEnergiaMensile,
} from "energix-types/src/edistribuzione";
import ReactApexChart from "react-apexcharts";
import { useEffect, useMemo, useRef } from "react";
import { ApexOptions } from "apexcharts";
import { monthName } from "src/constants/months";
import { merge, omit } from "lodash";
import { useBaseOptionCharts } from "src/components/common/chart";
import { fNumber } from "src/components/common/formatNumber";
import { Box } from "@mui/material";

export type EDistribuzioneGraficoProduzioneDiEnergiaProps = {
  data: EDistribuzioneEnergiaMensile[];
  anno: number;
};

function getData(
  data: EDistribuzioneEnergiaMensile[],
  tipo: TipoEDistribuzioneEnergiaMensile,
  anno: number
) {
  const values: (number | null)[] = [];
  for (let i = 1; i <= 12; i++) {
    const value = data.find(
      (d) => d.tipo === tipo && d.anno === anno && d.mese === i
    );
    values.push(value?.valore ?? null);
  }
  return values;
}

const labelByTipo: Record<TipoEDistribuzioneEnergiaMensile, string> = {
  autoconsumo: "Autoconsumo",
  immissione: "Immissione",
  prelievo: "Prelievo",
  produzione: "Produzione",
};

const colorsByTipo: Record<TipoEDistribuzioneEnergiaMensile, string> = {
  autoconsumo: "#9E86FF",
  immissione: "#A4F7CC",
  prelievo: "#FF8F6D",
  produzione: "#83CFFF",
};

const rgbByTipo: Record<TipoEDistribuzioneEnergiaMensile, string> =
  Object.fromEntries(
    Object.entries(colorsByTipo).map(([tipo, hex]) => [
      tipo,
      hex
        .replace(/^#/, "")
        .match(/.{2}/g)!
        .map((value) => parseInt(value, 16).toString())
        .join(","),
    ])
  ) as Record<TipoEDistribuzioneEnergiaMensile, string>;

export default function EDistribuzioneGraficoProduzioneDiEnergia({
  data,
  anno,
}: EDistribuzioneGraficoProduzioneDiEnergiaProps) {
  const chartRef = useRef<any>();
  const hiddenTypesRef = useRef(["produzione"]);

  const {
    series,
    hideSeriesLegendIndexes,
    hideSeriesInitially,
    relatedSeries,
  } = useMemo(() => {
    const series: ApexAxisChartSeries = [];
    const hideSeriesLegendIndexes: number[] = [];
    const hideSeriesInitially: string[] = [];
    const relatedSeries: { [k: string | number]: string[] } = {};

    for (const tipo of TipiEDistribuzioneEnergiaMensile) {
      const label = labelByTipo[tipo];
      const group =
        tipo === "produzione"
          ? "produzione"
          : tipo === "prelievo"
          ? "prelievo"
          : "immissione+autoconsumazione";
      const previousYear = {
        name: `${label} (${anno - 1})`,
        tipo,
        group: `${group}${anno - 1}`,
        color: `rgba(${rgbByTipo[tipo]}, 0.45)`,
        data: getData(data, tipo, anno - 1).map((v, m) => ({
          x: monthName[m],
          y: v,
          // zIndex: 10,
          fillColor: `rgba(${rgbByTipo[tipo]}, 0.45)`,
          strokeColor: `rgba(${rgbByTipo[tipo]}, 0.45)`,
          columnWidthOffset: -4,
          //
          // borderRadius: 10,
          // borderRadiusApplication: "end",
          // borderRadiusWhenStacked: "all",
        })),
      } as ApexAxisChartSeries[number];
      series.push(previousYear);
      hideSeriesLegendIndexes.push(series.length - 1);

      const currentYear = {
        name: `${label} (${anno})`,
        tipo,
        group: `${group}${anno}`,
        color: `rgb(${rgbByTipo[tipo]})`,
        data: getData(data, tipo, anno).map((v, m) => ({
          x: monthName[m],
          y: v,
          fillColor: `rgb(${rgbByTipo[tipo]})`,
          strokeColor: `rgb(${rgbByTipo[tipo]})`,
          // zIndex: 20,
          columnWidthOffset: 4,
        })),
      } as ApexAxisChartSeries[number];
      series.push(currentYear);

      if (hiddenTypesRef.current.includes(tipo)) {
        hideSeriesInitially.push(currentYear.name!);
        hideSeriesInitially.push(previousYear.name!);
      }

      relatedSeries[currentYear.name!] = [previousYear.name!];
      relatedSeries[series.length - 1] = [previousYear.name!];
    }
    return {
      series,
      hideSeriesLegendIndexes,
      hideSeriesInitially,
      relatedSeries,
    };
  }, [data, anno, hiddenTypesRef]);

  // we need useRef for series and relatedSeries because _sometimes_ legendClick is not replaced with the newest function
  const seriesRef = useRef(series);
  const relatedSeriesRef = useRef(relatedSeries);
  seriesRef.current = series;
  relatedSeriesRef.current = relatedSeries;

  useEffect(() => {
    const chart = chartRef?.current.chart;
    if (chart) {
      hideSeriesInitially.forEach((name) => {
        try {
          chart.hideSeries(name);
        } catch (e) {}
      });
    }
  }, [chartRef?.current?.chart, hideSeriesInitially]);

  const baseOptions = useBaseOptionCharts();
  const chartOptions: ApexOptions = useMemo(
    () =>
      merge(
        omit(baseOptions, [
          "colors",
          "markers.strokeColors",
          "legend.labels.colors",
        ]),
        {
          chart: {
            type: "bar",
            stacked: true,
            events: {
              legendClick: (chart, seriesIndex, options) => {
                const series = seriesRef.current;
                const relatedSeries = relatedSeriesRef.current;
                if (seriesIndex && series[seriesIndex]?.name) {
                  const name = series[seriesIndex]?.name!;
                  const serie = chart.series.getSeriesByName(name);
                  // @ts-ignore
                  const tipo: string = series[seriesIndex]?.tipo;

                  let isHidden =
                    serie?.classList.contains("apexcharts-series-collapsed") ??
                    true;
                  isHidden = !isHidden;

                  if (tipo) {
                    if (isHidden) {
                      const newHiddenTypes = [...hiddenTypesRef.current, tipo];
                      hiddenTypesRef.current = newHiddenTypes;
                    } else {
                      const newHiddenTypes = hiddenTypesRef.current.filter(
                        (t) => t !== tipo
                      );
                      hiddenTypesRef.current = newHiddenTypes;
                    }

                    relatedSeries[seriesIndex]?.forEach((name2) => {
                      try {
                        if (isHidden) {
                          chart.hideSeries(name2);
                        } else {
                          chart.showSeries(name2);
                        }
                      } catch (err) {}
                    });
                  }
                }
              },
            },
          },
          stroke: {
            width: 0,
          },
          dataLabels: {
            formatter: (val: number | null) => {
              return typeof val === "number"
                ? `${Math.ceil(val / 100) * 100} kWh`
                : "";
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "65%",
              borderRadius: 0,
              borderRadiusApplication: "around",
              borderRadiusWhenStacked: "all",
            },
          },
          xaxis: {
            type: "category",
          },
          yaxis: {
            labels: {
              formatter: (val: number | null) => {
                return typeof val === "number" ? `${fNumber(val)} kWh` : "";
              },
            },
          },
          legend: {
            show: true,
            fontSize: "13px",
            position: "top",
            horizontalAlign: "right",
            markers: {
              radius: 12,
            },
            fontWeight: 500,
            itemMargin: { horizontal: 12 },
            labels: {
              useSeriesColors: true,
            },
            formatter(legendName, opts) {
              return legendName.replace(/\([0-9]+\)/, "");
            },
          },
        } as ApexOptions
      ),
    [baseOptions, series]
  );

  return (
    <Box
      sx={{
        // hide legend for some series
        ...(Object.fromEntries(
          hideSeriesLegendIndexes.map((index) => {
            return [
              `& .apexcharts-legend-series[rel="${index + 1}"]`,
              {
                display: "none !important",
              },
            ];
          })
        ) as any),
      }}
    >
      {/* hideSeriesIndexes */}
      <ReactApexChart
        ref={chartRef}
        series={series}
        type="bar"
        options={chartOptions}
        height={400}
      />
    </Box>
  );
}
