export function isAdminLoggedAsUserPrefix(stateAuth) {
  return stateAuth.admin && !stateAuth.user.admin
    ? "/cliente/" + stateAuth.user.id
    : "";
}

export function isAdminLoggedAsUserQueryComponent(stateAuth) {
  return stateAuth.admin && !stateAuth.user.admin
    ? "clienteId=" + stateAuth.user.id
    : "";
}
