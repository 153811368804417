import { CALL_API } from "../middleware/api";

export const CONTEXT_FETCH_REQUEST = "CONTEXT_FETCH_REQUEST";
export const CONTEXT_FETCH_SUCCESS = "CONTEXT_FETCH_SUCCESS";
export const CONTEXT_FETCH_FAILURE = "CONTEXT_FETCH_FAILURE";

export const REDUX_INITIAL_STATE_FETCH_REQUEST =
  "REDUX_INITIAL_STATE_FETCH_REQUEST";
export const REDUX_INITIAL_STATE_FETCH_SUCCESS =
  "REDUX_INITIAL_STATE_FETCH_SUCCESS";
export const REDUX_INITIAL_STATE_FETCH_FAILURE =
  "REDUX_INITIAL_STATE_FETCH_FAILURE";

export const fetchContext = () => (dispatch: any) => {
  return dispatch({
    [CALL_API]: {
      types: [
        CONTEXT_FETCH_REQUEST,
        CONTEXT_FETCH_SUCCESS,
        CONTEXT_FETCH_FAILURE,
      ],
      endpoint: "/api/context",
      method: "GET",
    },
  });
};

export const fetchReduxInitialState =
  (clienteId: number | null | undefined) => (dispatch: any) => {
    return dispatch({
      [CALL_API]: {
        types: [
          REDUX_INITIAL_STATE_FETCH_REQUEST,
          REDUX_INITIAL_STATE_FETCH_SUCCESS,
          REDUX_INITIAL_STATE_FETCH_FAILURE,
        ],
        endpoint:
          "/redux/initialState" + (clienteId ? `?clienteId=${clienteId}` : ""),
        method: "GET",
      },
    });
  };
