import functionParser from "common/grammar/build/parser";
import {
  getVariables,
  formuleVariablesKeys as _formuleVariablesKeys,
} from "common/formule";
import formuleCalc from "common/formuleCalc";
import moment from "moment";
import { differenzaLetture, getMesiPerFormula } from "./lettureUtils";
import { mapValues, each } from "lodash";

export const formuleVariablesKeys = _formuleVariablesKeys;

export const formuleVariablesKeysPerInfo = mapValues(
  formuleVariablesKeys,
  (formulaKey, formulaName) => {
    if (formulaName === "PERDITE") {
      // non serve! le perdite non hanno usi commerciali e quindi formuleVariablesKeysPerInfo non viene mai usato per loro
      return null;
    }
    return {
      numeroUtenzeKey: formulaKey + "_numeroUtenze",
      isUsiCommercialiKey: formulaKey + "_isUsiCommerciali",
      defaults: {
        isUsiCommerciali: false,
        numeroUtenze: 0,
      },
    };
  }
);

export default function calcoloPerUsoProprio(
  installation,
  serviceLicense,
  report
) {
  const contatori = (
    (report.reportInfo && report.reportInfo.contatori) ||
    []
  ).reduce((obj, contatore) => {
    obj[contatore.codice] = contatore;
    return obj;
  }, {});
  const costanti = (
    (report.reportInfo && report.reportInfo.costanti) ||
    []
  ).reduce((obj, costante) => {
    obj[costante.codice] = costante;
    return obj;
  }, {});

  const context = {
    contatori,
    costanti,
  };

  const formuleCalcolate = (
    (report.reportInfo && report.reportInfo.formule) ||
    []
  ).map((formulaObj) => {
    return calcoloFormule(formulaObj, report, context);
  });

  return aggregateMesi(formuleCalcolate);
}

function aggregateMesi(formuleCalcolate) {
  var result = {};

  each(formuleVariablesKeys, (formulaKey) => {
    const obj = {};
    for (let index = 1; index <= 12; index++) {
      let v = 0;
      formuleCalcolate.forEach((formulaCalcolata) => {
        v += formulaCalcolata[formulaKey][index] || 0;
      });
      obj[index] = v;
    }
    result[formulaKey] = obj;
  });

  // modifica 02/03/2020
  // vedi quadroJLM.js
  // // round number to int
  // result = mapValues(result, obj => {
  //   return mapValues(obj, v => Math.round(v))
  // })

  return result;
}

function calcoloFormule(formulaObj, report, context) {
  // console.log(formulaObj)

  const formuleCalcolate = {};
  const workingOn = {};

  each(formuleVariablesKeys, (formulaKey) => {
    calcolaFormula(
      formulaObj,
      formulaKey,
      formuleCalcolate,
      workingOn,
      context,
      report
    );
  });

  return {
    ...formulaObj,
    ...formuleCalcolate,
  };
}

function calcolaFormula(
  formulaObj,
  formulaKey,
  formuleCalcolate,
  workingOn,
  context,
  report
) {
  if (formuleCalcolate[formulaKey]) {
    return formuleCalcolate[formulaKey];
  }

  if (workingOn[formulaKey]) {
    throw new Error("Riferimento circolare nelle formule.");
  }

  workingOn[formulaKey] = true;

  const formulaCalcolata = {};

  const formulaString = formulaObj[formulaKey];
  const variableValuesMap = {};
  let parsed;
  if (formulaString) {
    parsed = functionParser.parse(formulaString);
    const variables = getVariables(parsed);

    // console.log(formulaKey + ' = ' + formulaString)

    variables.forEach((variable) => {
      var obj;
      if (formuleVariablesKeys[variable]) {
        obj = {
          type: "formula",
          formulaCalcolata: calcolaFormula(
            formulaObj,
            formuleVariablesKeys[variable],
            formuleCalcolate,
            workingOn,
            context,
            report
          ),
        };
      } else if (context.contatori[variable]) {
        obj = {
          type: "contatore",
          contatore: context.contatori[variable],
        };
      } else if (context.costanti[variable]) {
        obj = {
          type: "costante",
          costante: context.costanti[variable],
        };
      } else {
        throw new Error("Variabile sconosciuta in formula: " + variable);
      }
      variableValuesMap[variable] = obj;
    });
  }

  const mesiPerFormule = getMesiPerFormula(report.anno - 1, formulaObj, report);

  mesiPerFormule.forEach(({ mese, fromKey, toKey }) => {
    if (formulaString) {
      const proportionalValue = getProportionalValue(report, formulaObj, mese);
      formulaCalcolata[mese] = execFormula(
        mese,
        parsed,
        variableValuesMap,
        proportionalValue,
        fromKey,
        toKey
      );
    } else {
      formulaCalcolata[mese] = 0;
    }
  });

  formuleCalcolate[formulaKey] = formulaCalcolata;
  return formulaCalcolata;
}

function getProportionalValue(report, formulaObj, mese) {
  const anno = report.anno - 1;
  const minDate = moment(formulaObj.startDate, "YYYY-MM-DD");
  const maxDate = moment(formulaObj.endDate, "YYYY-MM-DD").add(1, "day");

  let m1 = moment(anno + "-01-01", "YYYY-MM-DD")
    .month(mese - 1)
    .date(1);
  let m2 = moment(anno + "-01-01", "YYYY-MM-DD")
    .month(mese - 1)
    .add(1, "month")
    .date(1);
  const totalDays = m2.diff(m1, "days");

  // se minDate > m1 vuole dire che è iniziato in questo mese, bisogna togliere 1 al totale perche quel giorno è già contato
  // se minDate == m1 vuole dire che è iniziato quel giorno, caso particolare, facciamo i mesi normalmente
  // se minDate < m1 vuole dire che non è iniziato in questo mese

  if (m1.isBefore(minDate)) {
    m1 = minDate.add(1, "day");
  }
  if (m2.isAfter(maxDate)) {
    m2 = maxDate;
  }

  const days = m2.diff(m1, "days");

  return days / totalDays;
}

function execFormula(
  mese,
  parsed,
  variableValuesMap,
  proportionalValue,
  fromKey,
  toKey
) {
  const getValue = (variable) => {
    const obj = variableValuesMap[variable];
    switch (obj.type) {
      case "formula":
        return getValueFormula(obj.formulaCalcolata, mese);
      case "contatore":
        return getValueContatore(obj.contatore, mese, fromKey, toKey);
      case "costante":
        return getValueCostante(obj.costante, mese, proportionalValue);
    }
    throw new Error("Unknown variable obj type: " + obj.type);
  };

  const value = formuleCalc(parsed, getValue);
  return value;
}

function getValueFormula(formulaCalcolata, mese) {
  return formulaCalcolata[mese];
}

function getValueContatore(contatore, mese, fromKey, toKey) {
  const letture = contatore.letture;
  const value = differenzaLetture(contatore, letture[fromKey], letture[toKey]);
  return value * contatore.k;
}

function getValueCostante(costante, mese, proportionalValue) {
  var value = costante.value;
  if (costante.proporzionale) {
    value = value * 1.0 * proportionalValue;
  }
  return value;
}
