import BaseTable from "src/components/BaseTable";
import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { GSEFotovoltaicoCorrispettivi } from "energix-types/src/GSE";
import { monthName } from "src/constants/months";
import { fNumber, fPrice } from "src/components/common/formatNumber";
import moment from "moment";

export type RowData = GSEFotovoltaicoCorrispettivi;

export type GseContoEnergiaTableProps = {
  data: RowData[];
  riepilogo?: boolean;
};

const columnHelper = createColumnHelper<RowData>();

export default function GseContoEnergiaTable({
  data,
  riepilogo = false,
}: GseContoEnergiaTableProps) {
  const columns = useMemo(
    () => [
      columnHelper.accessor((r) => `${r.anno}-${r.mese}`, {
        header: "Mese/Anno",
        cell: ({
          row: {
            original: { anno, mese },
          },
        }) => `${mese ? monthName[mese - 1] : ""} ${anno ?? ""}`.trim(),
      }),
      columnHelper.accessor("conguaglio", {
        header: "Conguaglio",
        cell: ({
          row: {
            original: { conguaglio },
          },
        }) => (conguaglio === true ? "Si" : conguaglio === false ? "No" : ""),
      }),
      columnHelper.accessor("naturaEconomica", {
        header: "Natura Economica",
      }),
      columnHelper.accessor("energia", {
        header: "Energia",
        cell: ({
          row: {
            original: { energia },
          },
        }) => (typeof energia === "number" ? `${fNumber(energia)} kWh` : ""),
      }),
      columnHelper.accessor("tariffa", {
        header: "Tariffa",
        cell: ({
          row: {
            original: { tariffa },
          },
        }) => (typeof tariffa === "number" ? `${fPrice(tariffa)} €` : ""),
      }),
      columnHelper.accessor("imponibile", {
        header: "Importo",
        cell: ({
          row: {
            original: { imponibile },
          },
        }) => (typeof imponibile === "number" ? `${fPrice(imponibile)} €` : ""),
      }),
      columnHelper.accessor("dataPagamento", {
        header: "Data Pagamento",
        cell: ({
          row: {
            original: { dataPagamento },
          },
        }) => (dataPagamento ? moment(dataPagamento).format("DD/MM/YYYY") : ""),
      }),
      columnHelper.accessor("ritenutaAcconto", {
        header: "Acconto",
        cell: ({
          row: {
            original: { ritenutaAcconto },
          },
        }) =>
          ritenutaAcconto === true
            ? "Si"
            : ritenutaAcconto === false
            ? "No"
            : "",
      }),
      columnHelper.accessor("stato", {
        header: "Stato",
      }),
    ],
    []
  );

  return (
    <BaseTable
      columns={columns}
      data={data}
      paginated={!riepilogo}
      sortable={!riepilogo}
    />
  );
}
