import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { Dichiarazione } from "energix-types/src/Dichiarazione";
import { useState } from "react";
import { take } from "lodash";
import { SortDownIcon, SortUpIcon } from "src/components/elements/AppIcons";
import Header from "src/components/features/pagina-cliente-finale/common/Header";
import { getPdfDownloadUrl } from "src/components/features/pagina-cliente-finale/common/links";
import { Impianto } from "energix-types/src/Impianto";

export type DownloadDichiarazioniBoxProps = {
  token: string;
  dichiarazioni: Dichiarazione[];
  impianto: Impianto;
};

const maxDichiarazioniNotExpanded = 2;

export default function DownloadDichiarazioniBox({
  token,
  dichiarazioni,
  impianto,
}: DownloadDichiarazioniBoxProps) {
  const [expanded, setExpanded] = useState(false);
  const dichiarazioniSorted = dichiarazioni.sort((a, b) => b.anno - a.anno);
  const dichiarazioniToShow = take(
    dichiarazioniSorted,
    expanded ? dichiarazioniSorted.length : maxDichiarazioniNotExpanded
  );
  const showExpandButton =
    dichiarazioniSorted.length > maxDichiarazioniNotExpanded;

  return (
    <Box>
      <Stack direction="column">
        <Header title="Download AD-1 - Dichiarazioni Annuali dei Consumi" />

        {dichiarazioniToShow.length === 0 && (
          <Typography>
            <em>Nessuna dichiarazione</em>
          </Typography>
        )}

        {dichiarazioniToShow.map((d) => (
          <Stack key={d.id} direction="row">
            <Typography>
              <strong>{d.anno - 1}</strong>|{" "}
              {d.fileDichiarazionePDFId ? (
                <a
                  href={getPdfDownloadUrl(token, d, impianto)}
                  target="_blank"
                  rel="noreferrer"
                >
                  Dichiarazione PDF
                </a>
              ) : (
                <span>Dichiarazione PDF non disponibile</span>
              )}
            </Typography>
          </Stack>
        ))}

        {showExpandButton && (
          <>
            <Divider />
            <Button
              variant="text"
              onClick={() => setExpanded(!expanded)}
              endIcon={expanded ? <SortUpIcon /> : <SortDownIcon />}
            >
              {expanded ? "Vedi i più recenti" : "Vedi Tutto"}
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
}
