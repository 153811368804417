import generateReportInfo from "./generateReportInfo";
import {
  quadriLetture,
  quadriMensili,
  quadroImposta,
  quadroAccisa,
} from "./quadriList";
import { each } from "lodash";
import getQuadroTitle from "./getQuadroTitle";

export function getRiepilogoInfo(installation, serviceLicense, report) {
  let reportInfo;

  try {
    reportInfo = generateReportInfo(
      installation,
      serviceLicense,
      report,
      false
    );
  } catch (error) {
    console.log(error);
    return null;
  }

  let tipoDichiarante;

  if (reportInfo.frontespizio.tipologiaDichiarante === "L") {
    tipoDichiarante = "Soggetto obbligato con Licenza";
    if (reportInfo.frontespizio.tipoImpianto === "01") {
      tipoDichiarante +=
        " - Off. Produzione fonti rinnovabili uso esente (se unico tipo impianto)";
    } else if (reportInfo.frontespizio.tipoImpianto === "02") {
      tipoDichiarante += " - Altre officine";
    }
  } else {
    tipoDichiarante = "Soggetto non obbligato";
  }

  const quadri = [];

  quadriLetture.forEach((q) => {
    each(reportInfo.quadri[q], (obj) => {
      if (obj.totale) {
        quadri.push({
          quadro: q,
          quadroTitle: getQuadroTitle(q),
          totale: obj.chilowattora,
          format: "0,0.00",
          unit: "kWh",
        });
      }
    });
  });

  quadriMensili.forEach((q) => {
    let totale = 0;
    each(reportInfo.quadri[q], (obj) => {
      totale +=
        obj.chilowattora ||
        (obj.consumiUsiPropri || 0) + (obj.consumiUsiCommerciali || 0) ||
        0;
    });
    if (totale) {
      quadri.push({
        quadro: q,
        quadroTitle: getQuadroTitle(q),
        totale: totale,
        format: "0,0.00",
        unit: "kWh",
      });
    }
  });

  each(reportInfo.quadri[quadroImposta], (obj) => {
    if (obj.totale) {
      quadri.push({
        quadro: quadroImposta,
        quadroTitle: getQuadroTitle(quadroImposta),
        totale: obj.imposta,
        format: "0,0.00",
        unit: "€",
      });
    }
  });

  const tabellaMensili = {};

  ["J", "L", "M"].forEach((q) => {
    each(reportInfo.quadri[q], (obj) => {
      const { mese, chilowattora } = obj;
      let rigaQuadro = obj.rigaQuadro;
      if (q === "M" && rigaQuadro >= 9 && rigaQuadro <= 12) {
        rigaQuadro = "9+10+11+12";
      }
      if (mese >= 1 && mese <= 12) {
        tabellaMensili[q] = tabellaMensili[q] || {};
        tabellaMensili[q]["rigaQuadro" + rigaQuadro] =
          tabellaMensili[q]["rigaQuadro" + rigaQuadro] || {};
        tabellaMensili[q]["rigaQuadro" + rigaQuadro][mese] = tabellaMensili[q][
          "rigaQuadro" + rigaQuadro
        ][mese] || {
          totale: 0,
          format: "0,0.00",
          unit: "kWh",
        };
        tabellaMensili[q]["rigaQuadro" + rigaQuadro][mese].totale +=
          chilowattora;
        tabellaMensili[q]["rigaQuadro" + rigaQuadro].totale = tabellaMensili[q][
          "rigaQuadro" + rigaQuadro
        ].totale || {
          totale: 0,
          format: "0,0.00",
          unit: "kWh",
        };
        tabellaMensili[q]["rigaQuadro" + rigaQuadro].totale.totale +=
          chilowattora || 0;
      }
    });
  });

  each(reportInfo.quadri[quadroAccisa], (obj) => {
    const { progressivoRigo } = obj;
    const mese = progressivoRigo > 900 ? progressivoRigo - 900 : -1;
    if (mese >= 1 && mese <= 12) {
      tabellaMensili.Q = tabellaMensili.Q || {};
      tabellaMensili.Q[mese] = {
        totale: obj.importo,
        format: "0,0.00",
        unit: "€",
      };
      tabellaMensili.Q.totale = tabellaMensili.Q.totale || {
        totale: 0,
        format: "0,0.00",
        unit: "€",
      };
      tabellaMensili.Q.totale.totale += obj.importo || 0;
    }
  });

  const riepilogo = {
    info: {
      tipoDichiarante: tipoDichiarante,
      codiceDitta: reportInfo.frontespizio.soggettoObbligato.codiceDitta,
      denominazione: reportInfo.frontespizio.soggettoObbligato.denominazione,
      comune:
        reportInfo.frontespizio.soggettoObbligato.comune +
        " (" +
        reportInfo.frontespizio.soggettoObbligato.provincia +
        ")",
      indirizzo: reportInfo.frontespizio.soggettoObbligato.indirizzo,
      periodoAnno: reportInfo.frontespizio.anno,
    },
    quadri: quadri,
    tabellaMensili: tabellaMensili,
  };

  return riepilogo;
}
