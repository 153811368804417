import React from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { RightArrowIcon } from "../../elements/AppIcons";
import { CardColorName, NumeroScadenzeCardProps } from "./common";

const NumeroScadenzeCard = ({
  title,
  inScadenza,
  color = "lavender",
  link,
}: NumeroScadenzeCardProps) => {
  const { backgroundColor, buttonColor } = colors[color];
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "stretch",
        backgroundColor,
        p: 3,
        boxShadow: "none",
      }}
      // variant="outlined"
    >
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Typography variant="h3" mb={3} sx={{ flexGrow: 1, lineHeight: 1.2 }}>
          {title}
        </Typography>
        <Typography>In scadenza</Typography>
        <Box
          sx={{ display: "flex" }}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h2" component="span">
            {inScadenza}
          </Typography>
          {link && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: buttonColor,
                "&:hover": {
                  color: buttonColor,
                  boxShadow: `inset 0px 0px 0px 1px ${buttonColor}`,
                  backgroundColor,
                },
              }}
              endIcon={<RightArrowIcon />}
              to={link}
              component={Link}
            >
              Vedi Tutti
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
};

const colors: { [key in CardColorName]: CardColor } = {
  lavender: {
    backgroundColor: "#E3F1FC",
    buttonColor: "#1A90FD",
  },
  papaya: {
    backgroundColor: "#FFF5D6",
    buttonColor: "#FFBF00",
  },
  white: {
    backgroundColor: "#FCE9D9",
    buttonColor: "#EB5933",
  },
  softPeach: {
    backgroundColor: "#DEF6DD",
    buttonColor: "#57AC57",
  },
};

type CardColor = {
  backgroundColor: string;
  buttonColor: string;
};

export default NumeroScadenzeCard;
