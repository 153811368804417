import functionParser from "common/grammar/build/parser";
import moment from "moment";
import {
  getKeyForMeseIndex,
  getKeyFromDateString,
  getDateLabelForKey,
} from "dogane/fileGenerator/lettureUtils.js";
import { each } from "lodash";

export const formuleVariablesKeys = {
  CONSUMI_NON_SOTTOPOSTI: "formulaNonSottoposti",
  CONSUMI_ASSOGGETTATI: "formulaAssoggettati",
  CONSUMI_ASSOGGETTATI_M1: "formulaAssoggettatiM1",
  CONSUMI_ASSOGGETTATI_M2: "formulaAssoggettatiM2",
  CONSUMI_ASSOGGETTATI_M3: "formulaAssoggettatiM3",
  CONSUMI_ESENTI_L5: "formulaEsentiL5",
  CONSUMI_ESENTI_L6: "formulaEsentiL6",
  CONSUMI_ESENTI_L8: "formulaEsentiL8",
  PERDITE: "formulaPerdite",
  ENERGIA_ELETTRICA_FATTURATA: "formulaEnergiaFatturata",
};

function _addVariable(variables, v) {
  if (v) {
    variables[v] = (variables[v] || 0) + 1;
  }
}
export const allFormuleVariables = Object.keys(formuleVariablesKeys);

export function getVariablesFromContext(context, variable) {
  const variables = {};

  allFormuleVariables.forEach((v) => {
    if (v !== variable) {
      _addVariable(variables, v);
    }
  });

  (context.contatori || []).forEach((contatore) => {
    const codice = contatore.codice;
    _addVariable(variables, codice);
  });

  (context.costanti || []).forEach((costante) => {
    const codice = costante.codice;
    _addVariable(variables, codice);
  });

  return variables;
}

function _populateVariables(variables, obj) {
  if (!obj) {
    return;
  }
  if (obj.type === "var") {
    variables.push(obj.property);
  } else {
    _populateVariables(variables, obj.left);
    _populateVariables(variables, obj.right);
  }
}

export function getVariables(parsed) {
  const variables = [];
  _populateVariables(variables, parsed);
  return variables;
}

function checkContatoreDate(
  annoRendicontazione,
  variable,
  contatore,
  startDate,
  endDate
) {
  const anno = annoRendicontazione - 1;

  const startKey =
    getKeyFromDateString(startDate) || getKeyForMeseIndex(anno, 0);
  const endKey = getKeyFromDateString(endDate) || getKeyForMeseIndex(anno, 12);

  const startDateString = getDateLabelForKey(startKey, false, "DD/MM/YYYY");
  const endDateString = getDateLabelForKey(endKey, true, "DD/MM/YYYY");

  const minStartDate = getKeyForMeseIndex(anno, 0);
  const maxEndDate = getKeyForMeseIndex(anno, 12);
  const _inputStartDate = getKeyFromDateString(contatore.startDate);
  const _inputEndDate =
    contatore.endDate && contatore.endDate === anno + "-12-31"
      ? null
      : getKeyFromDateString(contatore.endDate);
  const contatoreStartDate =
    _inputStartDate && _inputStartDate > minStartDate
      ? _inputStartDate
      : minStartDate;
  const contatoreEndDate =
    _inputEndDate && _inputEndDate < maxEndDate ? _inputEndDate : maxEndDate;

  if (startKey < contatoreStartDate || endKey > contatoreEndDate) {
    return {
      type: "warning",
      message: `Il contatore "${variable}" dovrebbe essere valido nell'intervallo da ${startDateString} a ${endDateString} o maggiore`,
    };
  }

  return null;
}

function analyzeFormula(
  formulaVariable,
  annoRendicontazione,
  formulaString,
  startDate,
  endDate,
  context
) {
  const usedVariables = {};
  const errors = [];

  if (formulaString) {
    try {
      const parsed = functionParser.parse(formulaString);
      const variables = getVariables(parsed);
      const contextVariables = getVariablesFromContext(
        context,
        formulaVariable
      );
      const contatoriByCode = (context.contatori || []).reduce(
        (obj, contatore) => {
          obj[contatore.codice] = contatore;
          return obj;
        },
        {}
      );
      variables.forEach((variable) => {
        if (!usedVariables[variable]) {
          usedVariables[variable] = true;

          if (!contextVariables[variable]) {
            errors.push({
              type: "error",
              message:
                'La variabile "' +
                variable +
                '" non esiste. Crea un contatore o una costante.',
            });
          } else if (contatoriByCode[variable]) {
            const error = checkContatoreDate(
              annoRendicontazione,
              variable,
              contatoriByCode[variable],
              startDate,
              endDate
            );
            if (error) {
              errors.push(error);
            }
          }
        }
      });
    } catch (err) {
      console.log(err);
      errors.push({
        type: "error",
        message: "Errore nella formula",
      });
    }
  }

  const isAvailable = typeof formulaString === "string";

  return {
    ok: formulaString ? errors.length === 0 : isAvailable ? false : true,
    isAvailable: isAvailable,
    formula: formulaString,
    usedVariables,
    errors,
  };
}

export function analyzeFormule(annoRendicontazione, formule, context) {
  const _analyzeFormule = {};

  each(formuleVariablesKeys, (formulaKey, formulaName) => {
    _analyzeFormule[formulaName] = analyzeFormula(
      formulaName,
      annoRendicontazione,
      formule[formulaKey],
      formule.startDate,
      formule.endDate,
      context
    );
  });

  const addRiferimentiCircolari = (startFrom, nowV = startFrom, done = {}) => {
    const f = _analyzeFormule[nowV];
    for (let index = 0; index < allFormuleVariables.length; index++) {
      const v = allFormuleVariables[index];
      if (!done[v] && f.usedVariables[v]) {
        if (startFrom === v) {
          return nowV;
        }
        done[v] = true;
        var err = addRiferimentiCircolari(startFrom, v, done);
        if (err) {
          if (nowV !== startFrom) {
            return nowV;
          }
          return err;
        } else {
          if (nowV === startFrom) {
            if (!_analyzeFormule[v].isAvailable) {
              f.ok = false;
              f.errors.splice(0, 0, {
                type: "error",
                message: "La formula " + v + " non è definita",
              });
            }
          }
        }
      }
    }
  };

  allFormuleVariables.forEach((v) => {
    var err = addRiferimentiCircolari(v);
    if (err) {
      _analyzeFormule[v].ok = false;
      _analyzeFormule[v].errors.splice(0, 0, {
        type: "error",
        message:
          "Riferimento circolare: non puoi usare la variabile " +
          err +
          " perché dipende da " +
          v,
      });
    }
  });

  return _analyzeFormule;
}
