//TODO: TEMP
// questo serve perche ci sono due moment e due numeral nel progetto,
// uno usato dal codice in energix-old e l'altro dal codice in energix-frontend
// quando non si userà piu codice di energix-old questo potrà essere tolto
import moment from "moment";
import "moment/locale/it";
import numeral from "numeral";
import "numeral/locales/it";
moment.locale("it");
numeral.locale("it");
