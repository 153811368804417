import {
  ClosedSquaredIcon,
  CollapseIcon,
  ExpandIcon,
} from "../../../src/components/elements/AppIcons";

// ----------------------------------------------------------------------

const ICON_STYLE = { fontSize: 20 };

export default function TreeView(theme) {
  return {
    MuiTreeView: {
      defaultProps: {
        defaultCollapseIcon: <CollapseIcon style={ICON_STYLE} />,
        defaultExpandIcon: <ExpandIcon style={ICON_STYLE} />,
        defaultEndIcon: (
          <ClosedSquaredIcon
            style={ICON_STYLE}
            sx={{ color: "text.secondary" }}
          />
        ),
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: { ...theme.typography.body2 },
        iconContainer: { width: "auto" },
      },
    },
  };
}
