import { installationsByType } from "common/installations";
import { datiByCodiceCatastale } from "common/datiIstat";
import { reduce } from "lodash";

const tipiDiRecord = [
  {
    quadro: "A",
    type: 11,
    xmlType: "ContatoriProduzione",
    xmlType2021: "A",
  },
  {
    quadro: "B",
    type: 12,
    xmlType: "ContatoriUsoPromiscuo",
    xmlType2021: "B",
  },
  {
    quadro: "C",
    type: 13,
    xmlType: "ContatoriConsumiEsentiDaAccisa",
    xmlType2021: "C",
  },
  {
    quadro: "E",
    type: 15,
    xmlType: "ContatoriConsumiAssoggettatiAdAccisa",
    xmlType2021: "E",
  },
  {
    quadro: "G",
    type: 21,
    xmlType: "EnergiaElettricaCeduta",
    xmlType2021: "G",
  },
  {
    quadro: "H",
    type: 23,
    xmlType: "EnergiaElettricaRicevuta",
    xmlType2021: "H",
  },
  {
    quadro: "I",
    type: 26,
    xmlType: "EnergiaElettricaFatturata",
    xmlType2021: "I",
  },
  {
    quadro: "PERDITE",
    type: 25,
    xmlType: "Perdite",
    xmlType2021: "Perdite",
  },
  {
    quadro: "J",
    type: 45,
    xmlType: "ConsumiNonSottopostiAdAccisa",
    xmlType2021: "J",
  },
  {
    quadro: "L",
    type: 41,
    xmlType: "ConsumiEsentiDaAccisa",
    xmlType2021: "L",
  },
  {
    quadro: "M",
    type: 42,
    xmlType: "ConsumiAssoggettatiAdAccisa",
    xmlType2021: "M",
  },
  {
    quadro: "P",
    type: 51,
    xmlType: "LiquidazioneAccisa",
    xmlType2021: "P",
  },
  {
    quadro: "Q",
    type: 61,
    xmlType: "RiepilogoSaldoAccisa",
    xmlType2021: "Q",
  },
  {
    quadro: "EF",
    type: 99,
    xmlType: "Fornitore",
    xmlType2021: "EF",
  },
];

const tipiDiRecordByType = {};
const tipiDiRecordByQuadro = {};
const xmlTypesByQuadro = {};
const xmlTypes2021ByQuadro = {};
tipiDiRecord.forEach((t) => {
  tipiDiRecordByType[t.type] = t.quadro;
  tipiDiRecordByQuadro[t.quadro] = t.type;
  xmlTypesByQuadro[t.quadro] = t.xmlType;
  xmlTypes2021ByQuadro[t.quadro] = t.xmlType2021;
});

const nonAsciiMap = {
  À: "A'",
  Á: "A'",
  È: "E'",
  É: "E'",
  Ì: "I'",
  Í: "I'",
  Ò: "O'",
  Ó: "O'",
  Ù: "U'",
  Ú: "U'",
  Â: "A",
};

function isASCII(str) {
  return /^[\x00-\x7F]*$/.test(str);
}

export function toAscii(string) {
  //TODO: to ascii
  string = string.toUpperCase();
  string = string.replace(/[^A-Z0-9]/g, function (l) {
    l = nonAsciiMap[l] || l;
    if (!isASCII(l)) {
      throw new Error(
        "Carattere non riconosciuto (" +
          l +
          "). Tutti i caratteri devono essere ASCII."
      );
    }
    return l;
  });
  return string;
}

export function stringToLength(string, length, fill = " ", fillLeft = false) {
  string = toAscii(string || "");
  if (string.length > length) {
    return string.substring(0, length);
  }
  if (fillLeft) {
    while (string.length < length) {
      string = fill + string;
    }
  } else {
    while (string.length < length) {
      string += fill;
    }
  }
  return string;
}

export function intToString(int, length, fill = "0", fillLeft = true) {
  let string = "" + Math.round(int);
  return stringToLength(string, length, fill, fillLeft);
}

function _numToString_helper(num, decimalLength) {
  const roundedNumber = num.toFixed(decimalLength);
  return roundedNumber.split(".");
}

export function numToString_IntegerPart(
  num,
  length,
  decimalLength,
  fill = "0",
  fillLeft = true
) {
  let string = _numToString_helper(num, decimalLength)[0];
  return stringToLength(string, length, fill, fillLeft);
}

export function numToString_DecimalPart(
  num,
  length,
  decimalLength,
  fill = "0",
  fillLeft = true
) {
  let string = _numToString_helper(num, decimalLength)[1];
  return stringToLength(string, length, fill, fillLeft);
}

export function numToString_IntegerAndDecimalPart(
  num,
  integerLength,
  decimalLength
) {
  let [intString, decString] = _numToString_helper(num, decimalLength);
  return (
    stringToLength(intString, integerLength, "0", true) +
    stringToLength(decString, decimalLength, "0", true)
  );
}

export function xml_numToString_IntegerAndDecimalPart(
  num,
  integerLength,
  decimalLength
) {
  let [intString, decString] = _numToString_helper(num, decimalLength);
  return (
    stringToLength(intString, integerLength, "0", true) +
    "." +
    stringToLength(decString, decimalLength, "0", true)
  );
}

export function parteComune(
  quadro,
  installation,
  serviceLicense,
  report,
  mese,
  fail = true
) {
  const codiceDitta =
    installation.installationCode && installation.installationCode.substring(4);
  if (!codiceDitta || codiceDitta.length !== 9) {
    if (fail) {
      throw new Error(
        "Il Codice ditta dovrebbe essere IT00 seguito da 9 caratteri"
      );
    }
  }

  let tipoRecordCodice =
    quadro === "FRONTESPIZIO" ? 0 : tipiDiRecordByQuadro[quadro];
  if (!tipoRecordCodice && tipoRecordCodice !== 0) {
    throw new Error("C01 Parte comune non implementata per tipo " + quadro);
  }

  let provincia = "  ";
  if (tipoRecordCodice >= 41 && tipoRecordCodice <= 81) {
    const comuneImpianto =
      datiByCodiceCatastale[installation.codiceCatastaleImpianto];
    provincia = comuneImpianto.provincia;
  }

  let meseString = "00";
  const installationType = installationsByType[installation.type];
  if (
    installationType.tipologiaDichiarante === "L" &&
    (tipoRecordCodice === 41 ||
      tipoRecordCodice === 42 ||
      tipoRecordCodice === 45)
  ) {
    meseString = intToString(mese, 2);
  }

  return (
    "ELETTR" +
    codiceDitta +
    (report.anno - 1) +
    intToString(tipoRecordCodice, 2) +
    provincia +
    meseString
  );
}

export function getQuadroTypeForQuadro(quadro) {
  let tipoRecordCodice =
    quadro === "FRONTESPIZIO" ? 0 : tipiDiRecordByQuadro[quadro];
  if (!tipoRecordCodice && tipoRecordCodice !== 0) {
    throw new Error("Quadro type non implementato per tipo " + quadro);
  }
  return tipoRecordCodice;
}

export function getRootXmlTypeForQuadro(quadro, isPre2021) {
  const _xmlTypesByQuadro = isPre2021 ? xmlTypesByQuadro : xmlTypes2021ByQuadro;
  let xmlType = _xmlTypesByQuadro[quadro];
  if (!xmlType) {
    throw new Error("xmlType non implementato per tipo " + quadro);
  }
  return xmlType;
}

export function countRecord(reportInfo, recordType) {
  if (recordType) {
    const quadro = tipiDiRecordByType[recordType];
    const a = quadro && reportInfo.quadri[quadro];
    return a ? a.length : 0;
  }
  return reduce(
    reportInfo.quadri,
    (s, a) => {
      return s + (a ? a.length : 0);
    },
    0
  );
}

export function isPre2021(report) {
  return report.anno - 1 < 2021;
}
