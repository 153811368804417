const quadriList = [
  "A",
  "B",
  "C",
  "E",
  "G",
  "H",
  "I",
  "PERDITE",
  "J",
  "L",
  "M",
  "P",
  "Q",
  "EF",
];

export default quadriList;

export const quadriLetture = ["A", "B", "C", "E", "G", "H", "PERDITE", "I"];

export const quadriMensili = ["J", "L", "M"];

export const quadroImposta = "P";

export const quadroAccisa = "Q";
