import {
  tipologieDiRicezioneSelect,
  tipologieDiCessioneSelect,
  tipologieFornituraSelect,
} from "common/installations";

export const quardiUI = [
  "quadroABCE",
  "quadroGHIePerdite",
  "quadroJ",
  "quadroL",
  "quadroM",
  "quadroPQ",
];

function lettureDiff(letturaAttuale, letturaPrecedente) {
  const v = letturaAttuale - letturaPrecedente;
  if (typeof v !== "number" || isNaN(v)) {
    return "-";
  }
  if (v < 0) {
    return 0;
  }
  let diff = letturaAttuale - letturaPrecedente;

  /*
    example:
      > var t = 3253.14
      > t/12
      271.09499999999997
      > Math.round(t/12*100)/100
      271.09
      > (t/12).toFixed(5)
      '271.09500'
      > Math.round(parseFloat((t/12).toFixed(8))*100)/100
      271.1
    */
  diff = Math.round(parseFloat(diff.toFixed(10)) * 10000000000) / 10000000000;

  return diff;
}

function calcKWh(differenzaLettura, costanteLettura) {
  const v = differenzaLettura * costanteLettura;
  if (typeof v !== "number" || isNaN(v)) {
    return "-";
  }
  if (v < 0) {
    return 0;
  }
  return Math.round(v);
}

const errors = {
  required: (v) => !v && "Dato obbligatorio",
  requiredNGte0: (v) =>
    (typeof v !== "number" && "Dato obbligatorio") ||
    (v < 0 && "Inserire un valore >= 0"),
};

export const quardiUIDetails = {
  quadroABCE: {
    label: "Quadri A+B+C+E",
    sections: [
      {
        title: "Quadro A - Produzione",
        dataInput: {
          type: "table",
          key: "contatoriProduzione",
          arrayKey: "contatore",
          columns: [
            {
              key: "matricola",
              label: "Matricola",
              getError: errors.required,
            },
            {
              key: "letturaAttuale",
              label: "Lettura attuale",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "letturaPrecedente",
              label: "Lettura precedente",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "differenzaLettura",
              label: "Differenza",
              type: "auto",
              valueFunction: (obj) =>
                lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
            },
            {
              key: "costanteLettura",
              label: "Costante di lettura",
              decimalDigits: 2,
              type: "number",
              getError: errors.requiredNGte0,
            },
            {
              key: "chilowattora",
              label: "Chilowattora",
              type: "auto",
              valueFunction: (obj) =>
                calcKWh(
                  lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
                  obj.costanteLettura
                ),
            },
          ],
          footer: {
            showTotal: true,
            totalColumn: 5,
            unit: "kWh",
          },
        },
      },
      {
        title: "Quadro B - Uso promiscuo",
        dataInput: {
          type: "table",
          key: "contatoriUsoPromiscuo",
          arrayKey: "contatore",
          columns: [
            {
              key: "matricola",
              label: "Matricola",
              getError: errors.required,
            },
            {
              key: "letturaAttuale",
              label: "Lettura attuale",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "letturaPrecedente",
              label: "Lettura precedente",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "differenzaLettura",
              label: "Differenza",
              type: "auto",
              valueFunction: (obj) =>
                lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
            },
            {
              key: "costanteLettura",
              label: "Costante di lettura",
              type: "number",
              decimalDigits: 2,
              getError: errors.requiredNGte0,
            },
            {
              key: "chilowattora",
              label: "Chilowattora",
              type: "auto",
              valueFunction: (obj) =>
                calcKWh(
                  lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
                  obj.costanteLettura
                ),
            },
          ],
          footer: {
            showTotal: true,
            totalColumn: 5,
            unit: "kWh",
          },
        },
      },
      {
        title: "Quadro C - Consumi propri esenti da accisa",
        dataInput: {
          type: "table",
          key: "contatoriConsumiEsentiDaAccisa",
          arrayKey: "contatore",
          columns: [
            {
              key: "matricola",
              label: "Matricola",
              getError: errors.required,
            },
            {
              key: "letturaAttuale",
              label: "Lettura attuale",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "letturaPrecedente",
              label: "Lettura precedente",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "differenzaLettura",
              label: "Differenza",
              type: "auto",
              valueFunction: (obj) =>
                lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
            },
            {
              key: "costanteLettura",
              label: "Costante di lettura",
              type: "number",
              decimalDigits: 2,
              getError: errors.requiredNGte0,
            },
            {
              key: "chilowattora",
              label: "Chilowattora",
              type: "auto",
              valueFunction: (obj) =>
                calcKWh(
                  lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
                  obj.costanteLettura
                ),
            },
          ],
          footer: {
            showTotal: true,
            totalColumn: 5,
            unit: "kWh",
          },
        },
      },
      {
        title: "Quadro E - Consumi propri assoggettati ad accisa",
        dataInput: {
          type: "table",
          key: "contatoriConsumiAssoggettatiAdAccisa",
          arrayKey: "contatore",
          columns: [
            {
              key: "matricola",
              label: "Matricola",
              getError: errors.required,
            },
            {
              key: "letturaAttuale",
              label: "Lettura attuale",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "letturaPrecedente",
              label: "Lettura precedente",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "differenzaLettura",
              label: "Differenza",
              type: "auto",
              valueFunction: (obj) =>
                lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
            },
            {
              key: "costanteLettura",
              label: "Costante di lettura",
              type: "number",
              decimalDigits: 2,
              getError: errors.requiredNGte0,
            },
            {
              key: "chilowattora",
              label: "Chilowattora",
              type: "auto",
              valueFunction: (obj) =>
                calcKWh(
                  lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
                  obj.costanteLettura
                ),
            },
          ],
          footer: {
            showTotal: true,
            totalColumn: 5,
            unit: "kWh",
          },
        },
      },
    ],
  },
  quadroGHIePerdite: {
    label: "Quadri G+H+I + Perdite",
    sections: [
      {
        title: "Quadro G - Energia elettrica ceduta",
        dataInput: {
          type: "table",
          key: "energiaElettricaCeduta",
          arrayKey: "contatoreQuantita",
          columns: [
            {
              key: "tipologia",
              label: "Tipologia cessione",
              type: "select",
              options: tipologieDiCessioneSelect,
              getError: errors.required,
            },
            {
              key: "codiceIdentificativo",
              label: "Codice identificativo officina destinataria",
              getError: errors.required,
            },
            {
              key: "matricola",
              label: "Matricola contatore",
              getError: errors.required,
            },
            {
              key: "codiceCatastale",
              label: "Codice catastale comune",
              type: "istat",
              istatType: "codiceCatastale+comune",
              getError: errors.required,
            },
            {
              key: "letturaAttuale",
              label: "Lettura attuale",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "letturaPrecedente",
              label: "Lettura precedente",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "differenzaLettura",
              label: "Differenza",
              type: "auto",
              valueFunction: (obj) =>
                lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
            },
            {
              key: "costanteLettura",
              label: "Costante di lettura",
              type: "number",
              decimalDigits: 2,
              getError: errors.requiredNGte0,
            },
            {
              key: "chilowattora",
              label: "Chilowattora",
              type: "auto",
              valueFunction: (obj) =>
                calcKWh(
                  lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
                  obj.costanteLettura
                ),
            },
          ],
          footer: {
            showTotal: true,
            totalColumn: 8,
            unit: "kWh",
          },
        },
      },
      {
        title: "Quadro H - Energia elettrica ricevuta",
        dataInput: {
          type: "table",
          key: "energiaElettricaRicevuta",
          arrayKey: "contatoreQuantita",
          columns: [
            {
              key: "tipologia",
              label: "Tipologia ricezione",
              type: "select",
              options: tipologieDiRicezioneSelect,
              getError: errors.required,
            },
            {
              key: "codiceIdentificativo",
              label: "Codice identificativo officina fornitrice",
              getError: errors.required,
            },
            {
              key: "matricola",
              label: "Matricola contatore",
              getError: errors.required,
            },
            {
              key: "letturaAttuale",
              label: "Lettura attuale",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "letturaPrecedente",
              label: "Lettura precedente",
              type: "number",
              decimalDigits: 4,
              getError: errors.requiredNGte0,
            },
            {
              key: "differenzaLettura",
              label: "Differenza",
              type: "auto",
              valueFunction: (obj) =>
                lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
            },
            {
              key: "costanteLettura",
              label: "Costante di lettura",
              type: "number",
              decimalDigits: 2,
              getError: errors.requiredNGte0,
            },
            {
              key: "chilowattora",
              label: "Chilowattora",
              type: "auto",
              valueFunction: (obj) =>
                calcKWh(
                  lettureDiff(obj.letturaAttuale, obj.letturaPrecedente),
                  obj.costanteLettura
                ),
            },
          ],
          footer: {
            showTotal: true,
            totalColumn: 7,
            unit: "kWh",
          },
        },
      },
      {
        title: "Quadro I - Energia elettrica fatturata",
        dataInput: {
          type: "table",
          key: "energiaElettricaFatturata",
          arrayKey: "contatoreQuantita",
          columns: [
            {
              key: "tipologia",
              label: "Tipologia fornitura",
              type: "select",
              options: tipologieFornituraSelect,
              getError: errors.required,
            },
            {
              key: "codiceIdentificativo",
              label: "Codice identificativo officina distributrice",
              getError: errors.required,
            },
            {
              key: "codiceCatastale",
              label: "Codice catastale comune",
              type: "istat",
              istatType: "codiceCatastale+comune",
              getError: errors.required,
            },
            {
              key: "chilowattora",
              label: "Chilowattora",
              type: "number",
              decimalDigits: 0,
              getError: errors.requiredNGte0,
            },
          ],
          footer: {
            showTotal: true,
            totalColumn: 3,
            unit: "kWh",
          },
        },
      },
      {
        title: "Perdite",
        dataInput: {
          type: "tableMonth",
          key: "contatoreQuantita",
          arrayKey: "contatoreQuantita",
          rowValueKey: null,
          firstColumnLabel: "",
          rows: [
            {
              value: null,
              label: "",
              descr: "Perdite",
            },
          ],
          columns: [
            {
              key: "chilowattora",
              label: "Chilowattora",
              type: "number",
              decimalDigits: 0,
            },
          ],
        },
      },
    ],
  },
  quadroJ: {
    label: "Quadri J+L+M",
    showMonths: true,
    sections: [
      {
        title: "Quadro J - Consumi non sottoposti ad accisa",
        useMonths: true,
        dataInput: {
          type: "tableMonth",
          key: "consumiNonSottopostiAdAccisa",
          arrayKey: "consumi",
          rowValueKey: "progressivo",
          firstColumnLabel: "TIPOLOGIA DI CONSUMO",
          rows: [
            {
              value: 1,
              label: "J1",
              descr:
                "Energia elettrica utilizzata principalmente per la riduzione chimica e nei processi elettrolitici e metallurgici",
            },
            {
              value: 2,
              label: "J2",
              descr: "Energia elettrica impiegata nei processi mineralogici",
            },
            {
              value: 3,
              label: "J3",
              descr:
                "Energia elettrica impiegata per la realizzazione di prodotti sul cui costo finale, calcolato in media per unità, incida per oltre il 50 per cento",
            },
            {
              value: 4,
              label: "J4",
              descr: "Fornitura a clienti UE",
            },
            {
              value: 5,
              label: "J5",
              descr: "Fornitura a clienti extra UE",
            },
          ],
          columns: [
            {
              key: "consumiUsiPropri",
              label: "Usi propri kWh",
              type: "number",
              decimalDigits: 0,
              getError: errors.requiredNGte0,
            },
            {
              key: "numeroUtenze",
              label: "Numero di utenze",
              type: "number",
              decimalDigits: 0,
              getError: errors.requiredNGte0,
            },
            {
              key: "consumiUsiCommerciali",
              label: "Usi commerciali kWh",
              type: "number",
              decimalDigits: 0,
              getError: errors.requiredNGte0,
            },
          ],
        },
      },
      {
        title: "Quadro L - Consumi esenti da accisa",
        useMonths: true,
        dataInput: {
          type: "tableMonth",
          key: "consumiEsentiDaAccisa",
          arrayKey: "consumi",
          rowValueKey: "progressivo",
          firstColumnLabel: "TIPOLOGIA DI ESENZIONE",
          rows: [
            {
              noValue: true,
              descr: "ARTICOLO 17, COMMA 1 DEL D.LGS. 504/95",
            },
            {
              value: 1,
              label: "L1",
              descr:
                "Forniture nel quadro di relazioni diplomatiche o consolari",
            },
            {
              value: 2,
              label: "L2",
              descr:
                "Forniture ad organizzazioni internazionali riconosciute ed ai membri di dette organizzazioni, nei limiti ed alle condizioni fissate dalle relative convenzioni o accordi",
            },
            {
              value: 3,
              label: "L3",
              descr:
                "Forniture alle forze armate di qualsiasi Stato che sia parte contraente del Trattato del Nord Atlantico, per gli usi consentiti",
            },
            {
              value: 4,
              label: "L4",
              descr:
                "Energia elettrica destinata ad essere consumata nel quadro di un accordo stipulato con Paesi terzi o con organizzazioni internazionali che consenta per i medesimi prodotti anche l’esenzione dall’imposta sul valore aggiunto",
            },
            {
              noValue: true,
              descr: "ARTICOLO 52, COMMA 3 DEL D.LGS. 504/95",
            },
            {
              value: 5,
              label: "L5",
              descr:
                "Energia elettrica utilizzata per l’attività di produzione di elettricità e per mantenere la capacità di produrre elettricità",
            },
            {
              value: 6,
              label: "L6",
              descr:
                "Energia elettrica prodotta con impianti azionati da fonti rinnovabili ai sensi della normativa vigente in materia, con potenza disponibile superiore a 20 kW, consumata dalle imprese di autoproduzione in locali e luoghi diversi dalle abitazioni",
            },
            {
              value: 7,
              label: "L7",
              descr:
                "Impieghi per l’impianto e l’esercizio delle linee ferroviarie adibite al trasporto di merci e passeggeri",
            },
            {
              value: 8,
              label: "L8",
              descr:
                "Impieghi per l’impianto e l’esercizio delle linee di trasporto urbano e interurbano",
            },
            {
              value: 9,
              label: "L9",
              descr:
                "Consumi per qualsiasi applicazione nelle abitazioni di residenza anagrafica degli utenti, con potenza impegnata fino a 3 kW, fino ad un consumo mensile di 150 kWh. Per i consumi superiori ai limiti di 150 kWh per le utenze fino a 1,5 kW e di 220 kWh per quelle oltre 1,5 e fino a 3 kW, si procede al recupero dell’accisa secondo i criteri stabiliti nel capitolo I, punto 2, della deliberazione n. 15 del 14 dicembre 1993 del Comitato interministeriale dei prezzi",
            },
            {
              noValue: true,
              descr: " - ",
            },
            {
              value: 10,
              label: "L10",
              descr:
                "Impieghi in opifici industriali aventi un consumo mensile superiore a 1.200.000 kWh, per i mesi nei quali tale consumo si e’ verificato. Esenzione abrogata con decorrenza 1° giugno 2012 dall'articolo 3-bis, comma 3, del DL 16/2012 convertito in Legge 44/2012 ",
            },
            {
              value: 11,
              label: "L11",
              descr: "Prova e collaudo apparecchi (art. 52 comma 4)",
            },
          ],
          columns: [
            {
              key: "consumiUsiPropri",
              label: "Usi propri kWh",
              type: "number",
              decimalDigits: 0,
              getError: errors.requiredNGte0,
            },
            {
              key: "numeroUtenze",
              label: "Numero di utenze",
              type: "number",
              decimalDigits: 0,
              getError: errors.requiredNGte0,
            },
            {
              key: "consumiUsiCommerciali",
              label: "Usi commerciali kWh",
              type: "number",
              decimalDigits: 0,
              getError: errors.requiredNGte0,
            },
          ],
        },
      },
      {
        title: "Quadro M - Consumi assoggettati ad accisa",
        useMonths: true,
        dataInput: {
          type: "tableMonth",
          key: "consumiAssoggettatiAdAccisa",
          arrayKey: "consumi",
          rowValueKey: "progressivo",
          firstColumnLabel: "TIPOLOGIA DI UTILIZZO",
          rows: [
            {
              noValue: true,
              descr: "Abitazioni",
            },
            {
              value: 1,
              label: "M1",
              descr: "Di residenza anagrafica",
            },
            {
              value: 2,
              label: "M2",
              descr: "Seconde case",
            },
            {
              value: 3,
              label: "M3",
              descr:
                'Recupero di imposta articolo 52 comma 3 lettera "e" D.Lgs. 504/95',
            },
            {
              value: 4,
              label: "M4",
              descr:
                "Locali e luoghi diversi dalle abitazioni (fino al 31/05/2012)",
            },
            {
              noValue: true,
              descr: "RECUPERO PER FRODI",
            },
            {
              value: 5,
              label: "M5",
              descr: "Abitazioni - Di residenza anagrafica",
            },
            {
              value: 6,
              label: "M6",
              descr: "Abitazioni - Seconde case",
            },
            {
              value: 7,
              label: "M7",
              descr:
                'Abitazioni - Recupero di imposta articolo 52 comma 3 lettera "e" D.Lgs. 504/95',
            },
            {
              value: 8,
              label: "M8",
              descr: "Locali e luoghi diversi dalle abitazioni",
            },
            {
              noValue: true,
              descr:
                "Locali e luoghi diversi dalle abitazioni (dal 01/06/2012)",
            },
            {
              value: 9,
              label: "M9",
              descr:
                "Consumi fino a 1,200,000 kWh nel mese - Per i primi 200,000 kWh/mese",
            },
            {
              value: 10,
              label: "M10",
              descr:
                "Consumi fino a 1,200,000 kWh nel mese - Per gli ulteriori consumi mensili",
            },
            {
              value: 11,
              label: "M11",
              descr:
                "Consumi superiori a 1,200,000 kWh nel mese - Per i primi 200,000 kWh/mese",
            },
            {
              value: 12,
              label: "M12",
              descr:
                "Consumi superiori a 1,200,000 kWh nel mese - Per gli ulteriori consumi mensili",
            },
          ],
          columns: [
            {
              key: "consumiUsiPropri",
              label: "Usi propri kWh",
              type: "number",
              decimalDigits: 0,
              getError: errors.requiredNGte0,
            },
            {
              key: "numeroUtenze",
              label: "Numero di utenze",
              type: "number",
              decimalDigits: 0,
              getError: errors.requiredNGte0,
            },
            {
              key: "consumiUsiCommerciali",
              label: "Usi commerciali kWh",
              type: "number",
              decimalDigits: 0,
              getError: errors.requiredNGte0,
            },
          ],
        },
      },
    ],
  },
  // quadroL: {},
  // quadroM: {},
  quadroPQ: {
    label: "Quadri P+Q",
    sections: [
      {
        title: "Quadro P - Liquidazione dell'accisa",
        dataInput: {
          type: "tableMonth",
          key: "liquidazioneAccisa",
          arrayKey: "liquidazione",
          rowValueKey: "progressivo",
          firstColumnLabel: "CAPITOLO",
          rows: [
            {
              value: 1,
              label: "P1",
              descr: "Abitazioni di residenza anagrafica (M1+M3)",
              filterColumn: {
                consumi: true,
                aliquota: true,
                imposta: "readonly",
              },
              suggestion: {
                consumi: "P.1.chilowattora",
                imposta: "P.1.imposta",
                aliquota: "P.1.aliquotaPerChilowattora",
              },
            },
            {
              value: 2,
              label: "P2",
              descr: "Seconde case (M2)",
              filterColumn: {
                consumi: true,
                aliquota: true,
                imposta: "readonly",
              },
              suggestion: {
                consumi: "P.2.chilowattora",
                imposta: "P.2.imposta",
                aliquota: "P.2.aliquotaPerChilowattora",
              },
            },
            {
              value: 3,
              label: "P3",
              descr:
                "Locali e luoghi diversi dalle abitazioni - fino al 31/05/2012 (M4)",
              filterColumn: {
                consumi: true,
                aliquota: true,
                imposta: "readonly",
              },
              suggestion: {
                consumi: "P.3.chilowattora",
                imposta: "P.3.imposta",
                aliquota: "P.3.aliquotaPerChilowattora",
              },
            },
            {
              noValue: true,
              descr:
                "Locali e luoghi diversi dalle abitazioni - dal 01/06/2012",
            },
            {
              value: 4,
              label: "P4",
              descr: "primi 200,000 kWh/mese (M9 + M11)",
              filterColumn: {
                consumi: true,
                aliquota: true,
                imposta: "readonly",
              },
              suggestion: {
                consumi: "P.4.chilowattora",
                imposta: "P.4.imposta",
                aliquota: "P.4.aliquotaPerChilowattora",
              },
            },
            {
              value: 5,
              label: "P5",
              descr:
                "consumi ecced. i 200.000 kWh per forniture mens. non sup. a 1.200.000 kWh (M10)",
              filterColumn: {
                consumi: true,
                aliquota: true,
                imposta: "readonly",
              },
              suggestion: {
                consumi: "P.5.chilowattora",
                imposta: "P.5.imposta",
                aliquota: "P.5.aliquotaPerChilowattora",
              },
            },
            {
              value: 6,
              label: "P6",
              descr:
                "consumi eccedenti i 200.000 kWh per forniture mensili superiori a 1.200.000 kWh",
              filterColumn: { imposta: true },
              suggestion: {
                imposta: "P.6.imposta",
              },
            },
            {
              noValue: true,
              descr: "RECUPERO PER FRODI",
            },
            {
              value: 7,
              label: "P7",
              descr: "Abitazioni di residenza anagrafica (M5+M7)",
              filterColumn: {
                consumi: true,
                aliquota: true,
                imposta: "readonly",
              },
              suggestion: {
                consumi: "P.7.chilowattora",
                imposta: "P.7.imposta",
                aliquota: "P.7.aliquotaPerChilowattora",
              },
            },
            {
              value: 8,
              label: "P8",
              descr: "Seconde case (M6)",
              filterColumn: {
                consumi: true,
                aliquota: true,
                imposta: "readonly",
              },
              suggestion: {
                consumi: "P.8.chilowattora",
                imposta: "P.8.imposta",
                aliquota: "P.8.aliquotaPerChilowattora",
              },
            },
            {
              value: 9,
              label: "P9",
              descr: "Locali e luoghi diversi dalle abitazioni (M8)",
              filterColumn: {
                consumi: true,
                aliquota: true,
                imposta: "readonly",
              },
              suggestion: {
                consumi: "P.9.chilowattora",
                imposta: "P.9.imposta",
                aliquota: "P.9.aliquotaPerChilowattora",
              },
            },
            {
              value: 10,
              label: "P10",
              descr: "Rettifiche di fatturazione ordinarie (K1+K2+K3+K4)",
              filterColumn: { imposta: true },
            },
            {
              value: 11,
              label: "P11",
              descr: "Rettifiche di fatturazione per frodi (K5+K6+K7+K8)",
              filterColumn: { imposta: true },
            },
            {
              value: 12,
              label: "P12",
              descr: "",
              hidden: true,
            },
            {
              value: 13,
              label: "P13",
              descr: "Arrotondamenti",
              filterColumn: { imposta: true },
              suggestion: {
                imposta: "P.13.imposta",
              },
            },
            {
              value: 14,
              label: "P14",
              descr: "TOTALE ACCISA LIQUIDATA",
              filterColumn: { imposta: "readonly" },
              suggestion: {
                imposta: "P.14.imposta",
              },
            },
          ],
          columns: [
            {
              key: "consumi",
              label: "Consumi kWh",
              type: "number",
              decimalDigits: 0,
              getError: errors.requiredNGte0,
            },
            {
              key: "aliquota",
              label: "Aliquota Euro/kWh",
              type: "number",
              decimalDigits: 7,
              getError: errors.requiredNGte0,
            },
            {
              key: "imposta",
              label: "Imposta Euro",
              type: "number",
              decimalDigits: 2,
              getError: errors.requiredNGte0,
            },
          ],
        },
      },
      {
        title:
          "Quadro X - Riepilogo e saldo dell'accisa - Conguaglio anno 2021",
        onlyAnno: 2021,
        dataInput: {
          type: "tableMonth",
          key: "riepilogoSaldoAccisaX",
          arrayKey: "riepilogoSaldo",
          rowValueKey: "progressivo",
          firstColumnLabel: "",
          rows: [
            {
              value: 1,
              label: "X1",
              descr: "ACCISA LIQUIDATA (rigo P14)",
              // filterColumn: {importo: 'readonly'},
              suggestion: {
                importo: "Q.1.importo",
              },
            },
            {
              value: 2,
              label: "X2",
              descr: "RATEI D'ACCONTO CALCOLATI NELLA PRECEDENTE DICHIARAZIONE",
              suggestion: {
                importo: "Q.2.importo",
              },
            },
            {
              noValue: true,
              descr:
                "SALDO DELL'ACCISA (sottrarre il rigo X2 dal rigo X1 e riportare il valore al rigo X3, se positivo, ovvero al rigo X4, se negativo)",
            },
            {
              value: 3,
              label: "X3",
              descr: "CONGUAGLIO ACCISA A DEBITO",
              // filterColumn: {importo: 'readonly'},
              suggestion: {
                importo: "Q.X3.importo",
                // importo: "Q.3.importo",
              },
            },
            {
              value: 4,
              label: "X4",
              descr: "CONGUAGLIO ACCISA A CREDITO",
              // filterColumn: {importo: 'readonly'},
              suggestion: {
                // importo: "Q.X4.importo",
                importo: "Q.4.importo",
              },
            },
            {
              value: 5,
              label: "X5",
              descr: "CREDITO PREGRESSO NON UTILIZZATO AL 31/12/2021",
              suggestion: {
                importo: "X.5.importo",
              },
            },
          ],
          columns: [
            {
              key: "importo",
              label: "Importo Euro",
              type: "number",
              decimalDigits: 2,
              getError: errors.requiredNGte0,
            },
          ],
        },
      },
      {
        title: "Quadro Q - Riepilogo e saldo dell'accisa",
        dataInput: {
          type: "tableMonth",
          key: "riepilogoSaldoAccisa",
          arrayKey: "riepilogoSaldo",
          rowValueKey: "progressivo",
          firstColumnLabel: "",
          rows: [
            {
              value: 1,
              label: "Q1",
              descr: "ACCISA LIQUIDATA (rigo P14)",
              // filterColumn: {importo: 'readonly'},
              suggestion: {
                importo: "Q.1.importo",
              },
            },
            {
              value: 2,
              label: "Q2",
              descr: "RATEI D'ACCONTO CALCOLATI NELLA PRECEDENTE DICHIARAZIONE",
              suggestion: {
                importo: "Q.2.importo",
              },
              hideIfAnno: {
                importo: 2021,
              },
            },
            {
              noValue: true,
              descr:
                "SALDO DELL'ACCISA (sottrarre il rigo Q2 dal rigo Q1 e riportare il valore al rigo Q3, se positivo, ovvero al rigo Q4, se negativo)",
            },
            {
              value: 3,
              label: "Q3",
              descr: "CONGUAGLIO ACCISA A DEBITO",
              // filterColumn: {importo: 'readonly'},
              suggestion: {
                importo: "Q.3.importo",
              },
              hideIfAnno: {
                importo: 2021,
              },
            },
            {
              value: 4,
              label: "Q4",
              descr: "CONGUAGLIO ACCISA A CREDITO",
              // filterColumn: {importo: 'readonly'},
              suggestion: {
                importo: "Q.4.importo",
              },
            },
            {
              noValue: true,
              descr: "RATEI MENSILI IN ACCONTO",
            },
            {
              value: 901,
              descr: "Gennaio",
              suggestion: {
                importo: "Q.901.importo",
              },
            },
            {
              value: 902,
              descr: "Febbraio",
              suggestion: {
                importo: "Q.902.importo",
              },
            },
            {
              value: 903,
              descr: "Marzo",
              suggestion: {
                importo: "Q.903.importo",
              },
            },
            {
              value: 904,
              descr: "Aprile",
              suggestion: {
                importo: "Q.904.importo",
              },
            },
            {
              value: 905,
              descr: "Maggio",
              suggestion: {
                importo: "Q.905.importo",
              },
            },
            {
              value: 906,
              descr: "Giugno",
              suggestion: {
                importo: "Q.906.importo",
              },
            },
            {
              value: 907,
              descr: "Luglio",
              suggestion: {
                importo: "Q.907.importo",
              },
            },
            {
              value: 908,
              descr: "Agosto",
              suggestion: {
                importo: "Q.908.importo",
              },
            },
            {
              value: 909,
              descr: "Settembre",
              suggestion: {
                importo: "Q.909.importo",
              },
            },
            {
              value: 910,
              descr: "Ottobre",
              suggestion: {
                importo: "Q.910.importo",
              },
            },
            {
              value: 911,
              descr: "Novembre",
              suggestion: {
                importo: "Q.911.importo",
              },
            },
            {
              value: 912,
              descr: "Dicembre",
              suggestion: {
                importo: "Q.912.importo",
              },
            },
            {
              value: 5,
              label: "Q5",
              descr: "TOTALE ACCONTI",
              filterColumn: { importo: "readonly" },
              suggestion: {
                importo: "Q.5.importo",
              },
            },
          ],
          columns: [
            {
              key: "importo",
              label: "Importo Euro",
              type: "number",
              decimalDigits: 2,
              getError: errors.requiredNGte0,
            },
          ],
        },
      },
    ],
  },
};
