import "./initSentry";
import React from "react";
import ReactDOM from "react-dom";
import store from "src/store";
import AppNascosta from "src/AppNascosta";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { closeLoadingScreen } from "src/loadingScreen";
import "./numpadFix";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/impianto/:token" element={<AppNascosta />} />

          {/* REDIRECT FOR OLD PATHS */}
          <Route
            path="/dichiarazione/firma/:oldToken"
            element={<RedirectOldToken />}
          />
          <Route
            path="/dichiarazione/letture/:oldToken"
            element={<RedirectOldToken />}
          />
          <Route
            path="/dichiarazione/dashboard/:oldToken"
            element={<RedirectOldToken />}
          />

          <Route path="*" element={<WrongOrMissingToken />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement
);

function WrongOrMissingToken() {
  closeLoadingScreen();
  return <p>L'indirizzo della pagina non contiene un token valido</p>;
}

function RedirectOldToken() {
  const { oldToken } = useParams();

  if (!oldToken || !oldToken.startsWith("r-")) {
    return <Navigate to="/" />;
  }

  const token = oldToken.substring(2);
  const newUrl = "/impianto/" + token;

  return <Navigate to={newUrl} />;
}
