import React, { CSSProperties, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Stack } from "@mui/material";
import { UploadIcon } from "src/components/elements/AppIcons";

export type FileDropzoneProps = {
  onFilesSelected: (files: File[]) => void;
  icon?: string;
  children?: React.ReactNode;
  multiple?: boolean;
  disabled?: boolean;
};

export default function FileDropzone({
  onFilesSelected,
  icon,
  children,
  multiple = false,
  disabled = false,
}: FileDropzoneProps) {
  const { dropzoneElementProps, inputProps, openFileDialog } =
    useFileDropzone(onFilesSelected);

  return (
    <Stack direction="row" alignItems="center" {...dropzoneElementProps}>
      {icon && (
        <Box
          sx={{ mx: 4, my: 2 }}
          style={{ height: "180px", maxHeight: "180px" }}
        >
          <img src={icon} height="100%" alt="Carica file" />
        </Box>
      )}
      <Stack
        direction="column"
        flex={1}
        spacing={1}
        sx={{ minHeight: "180px", my: 2 }}
      >
        <Stack
          flex={1}
          direction="column"
          spacing={1}
          style={{
            paddingRight: ".5em",
          }}
        >
          {children}
        </Stack>

        {!disabled && (
          <Box>
            <input
              {...inputProps}
              type="file"
              multiple={multiple}
              style={{ display: "none" }}
            />
            <Button
              color="primary"
              size="small"
              variant="outlined"
              startIcon={<UploadIcon />}
              onClick={(e) => {
                e.stopPropagation();
                openFileDialog();
              }}
            >
              {multiple
                ? "Carica o trascina qui i file"
                : "Carica o trascina qui il file"}
            </Button>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}

const baseStyle: CSSProperties = {
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle: CSSProperties = {
  borderColor: "#1890FF",
};

const acceptStyle: CSSProperties = {
  borderColor: "#54D62C",
};

const rejectStyle: CSSProperties = {
  borderColor: "#FF4842",
};

export function useFileDropzone(onDrop: (files: File[]) => void) {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    onDrop: onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const dropzoneElementProps = getRootProps({ style });
  const inputProps = getInputProps();

  return {
    dropzoneElementProps,
    inputProps,
    openFileDialog: open,
  };
}
