import {
  UI_SET_PAYMENT_CANCEL,
  REDUX_INITIAL_STATE_FETCH_SUCCESS,
} from "../actions";

export default function uiReducer(state = {}, action) {
  switch (action.type) {
    case REDUX_INITIAL_STATE_FETCH_SUCCESS:
      return action.response.preloadedState.ui || {};

    case UI_SET_PAYMENT_CANCEL:
      return {
        ...state,
        paymentCancel: action.cancel,
      };

    default:
      return state;
  }
}
