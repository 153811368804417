import { CardHeader, Grid, Stack } from "@mui/material";
import { Dichiarazione } from "energix-types/src/Dichiarazione";
import { Impianto } from "energix-types/src/Impianto";
import { Azienda } from "energix-types/src/Azienda";
import { getRiepilogoInfo } from "energix-old/src/dogane/fileGenerator/getRiepilogoInfo";
import { quadriMensili } from "energix-old/src/dogane/fileGenerator/quadriList";
import QuadroCard from "src/components/consumo-by-quadro/QuadroCard";
import { find, some } from "lodash";
import getQuadroTitle from "energix-old/src/dogane/fileGenerator/getQuadroTitle";
import React from "react";

export type ConsumiByQuadroCardProps = {
  dichiarazione: Dichiarazione;
  impianto: Impianto;
  azienda: Azienda;
  dichiarazioneAnnoPrecedente?: Dichiarazione;
  header?: React.ReactNode;
};

export default function ConsumiByQuadroCard({
  dichiarazione,
  impianto,
  azienda,
  dichiarazioneAnnoPrecedente,
  header = (
    <CardHeader
      sx={{ mb: 2 }}
      title={`Riepilogo dichiarazione anno ${dichiarazione.anno - 1}`}
    />
  ),
}: ConsumiByQuadroCardProps) {
  const riepilogoInfo = getRiepilogoInfo(impianto, azienda, dichiarazione);
  const riepilogoInfoAnnoPrecedente =
    dichiarazioneAnnoPrecedente &&
    getRiepilogoInfo(impianto, azienda, dichiarazioneAnnoPrecedente);

  const displayYear = dichiarazione.anno - 1;
  const displayPreviousYear = dichiarazioneAnnoPrecedente
    ? dichiarazioneAnnoPrecedente?.anno - 1
    : undefined;

  if (!riepilogoInfo) {
    return null;
  }

  function getAnnoPrecedente(...path: any) {
    let x: any = riepilogoInfoAnnoPrecedente;
    path.forEach((p: any) => {
      if (typeof p === "function") {
        x = x && find(x, p);
        return;
      }
      x = x && x[p];
    });
    return x;
  }

  return (
    <>
      {header}
      <Grid container spacing={2} alignItems="stretch">
        {riepilogoInfo.quadri
          ?.filter(({ quadro }) => {
            const isQuadroMensile = quadriMensili.indexOf(quadro) >= 0;
            return !isQuadroMensile;
          })
          ?.map((quadro) => {
            return (
              <Grid key={quadro.quadro} {...gridItemProps}>
                <QuadroCard
                  title={quadro.quadroTitle}
                  subtitle={`Quadro ${quadro.quadro}`}
                  value={quadro.totale}
                  previousValue={
                    getAnnoPrecedente(
                      "quadri",
                      (x: any) => x.quadro === quadro.quadro,
                      "totale"
                    ) || null
                  }
                  unit={quadro.unit}
                  year={displayYear}
                  previousYear={displayPreviousYear}
                />
              </Grid>
            );
          })}

        {Object.entries(riepilogoInfo.tabellaMensili)
          .flatMap(([quadro, byRiga]: [string, any]) =>
            Object.entries(byRiga).map(
              ([rigaQuadroKey, monthsDataObj]: [string, any]) => {
                const totale =
                  (monthsDataObj.totale && monthsDataObj.totale.totale) || 0;
                const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                  (x) => {
                    return (monthsDataObj[x] && monthsDataObj[x].totale) || 0;
                  }
                );

                const title = getQuadroTitle(quadro);
                const rigo = rigaQuadroKey.replace("rigaQuadro", "");
                const subtitle = `Quadro ${quadro} - Rigo ${rigo}`;

                const monthsDataObjPrecedente: any =
                  getAnnoPrecedente("tabellaMensili", quadro, rigaQuadroKey) ||
                  null;
                const totalePrecedente =
                  monthsDataObjPrecedente?.totale?.totale;
                const monthsPrecedenti =
                  monthsDataObjPrecedente &&
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => {
                    return (
                      (monthsDataObjPrecedente[x] &&
                        monthsDataObjPrecedente[x].totale) ||
                      0
                    );
                  });

                const shouldRenderCard =
                  totale &&
                  some(months) &&
                  totalePrecedente &&
                  some(monthsPrecedenti);

                return shouldRenderCard ? (
                  <Grid key={subtitle} {...gridItemProps}>
                    <QuadroCard
                      title={title}
                      subtitle={subtitle}
                      value={totale}
                      previousValue={totalePrecedente}
                      unit={monthsDataObj.unit}
                      year={displayYear}
                      previousYear={
                        dichiarazioneAnnoPrecedente && displayPreviousYear
                      }
                      valuesByMonth={months}
                      previousValuesByMonth={monthsPrecedenti}
                    />
                  </Grid>
                ) : null;
              }
            )
          )
          .filter((card) => !!card)}
      </Grid>
    </>
  );
}

const gridItemProps = {
  item: true,
  xs: 6,
  md: 4,
};
