import {
  parteComune,
  stringToLength,
  intToString,
  getRootXmlTypeForQuadro,
  getQuadroTypeForQuadro,
  isPre2021,
} from "./c01Utils";
import { datiByCodiceCatastale } from "common/datiIstat";
import { differenzaLetture, getDatiQuadri } from "./lettureUtils";
import {
  formuleVariablesKeys,
  formuleVariablesKeysPerInfo,
} from "./calcoloPerUsoProprio";
import { each } from "lodash";
import { getDateLettureRichieste } from "dogane/fileGenerator/lettureUtils";
import { applyOverrideQuadri, haQuadro, checkHasFormula } from "./quadriUtils";

function roundFloat0DecWithFix(t) {
  /*
  example:
    > var t = 3253.14
    > t/12
    271.09499999999997
    > Math.round(t/12*100)/100
    271.09
    > (t/12).toFixed(5)
    '271.09500'
    > Math.round(parseFloat((t/12).toFixed(8))*100)/100
    271.1
  */
  return Math.round(parseFloat(t.toFixed(8)));
}

function meseFromDate(d) {
  return parseInt(d.substring(5, 7), 10);
}

function filterBasedOnFrequenzaLetture(report, obj) {
  const frequenzaLetture =
    (report.reportInfo.frequenzaLetture &&
      parseInt(report.reportInfo.frequenzaLetture)) ||
    1;
  if (frequenzaLetture && frequenzaLetture > 1) {
    const chilowattora = obj.chilowattora;
    if (chilowattora === 0) {
      return false;
    }
  }
  return true;
}

// ARROTONDAMENI
// MODIFICATO DATA: 02/03/2020
// richiesta:
// non arrotondare i chilowattora a livello di contatore, ma solo a livello di mese

function calculateResultAggregated_consumiEsenti(
  installation,
  serviceLicense,
  report,
  contatoriProduzione,
  contatoriCessione,
  fail,
  quadro,
  quadroERigoQuadro
) {
  const result = [];
  var quadroLHaRinnovabili = false;
  var func = ({ contatore, from, to }, sum = true) => {
    if (
      quadroERigoQuadro === "L6" &&
      contatore.type === "produzione" &&
      contatore.tipologiaDiProduzione === "non-rinnovabili"
    ) {
      return;
    }
    if (
      quadroERigoQuadro === "L6" &&
      contatore.type === "produzione" &&
      contatore.tipologiaDiProduzione !== "non-rinnovabili"
    ) {
      quadroLHaRinnovabili = true;
    }

    const lettureRichieste = getDateLettureRichieste(
      report.anno - 1,
      from,
      to,
      false,
      report,
      null
    );

    for (let index = 0; index < lettureRichieste.length - 1; index++) {
      const { key: dateKeyFrom, mese } = lettureRichieste[index];
      const { key: dateKeyTo } = lettureRichieste[index + 1];

      const letturaAttuale = contatore.letture[dateKeyTo];
      const letturaPrecedente = contatore.letture[dateKeyFrom];

      const diff = differenzaLetture(
        contatore,
        letturaPrecedente,
        letturaAttuale,
        fail
      );
      const k = contatore.k;
      // const chilowattora = roundFloat0DecWithFix(diff * k)
      // modifica 02/03/2020
      const chilowattora = diff * k;

      const codiceCatastaleComune = installation.codiceCatastaleImpianto;

      const info = {
        mese: mese,
        codiceCatastaleComune: codiceCatastaleComune,
        chilowattora: sum ? chilowattora : -chilowattora,
      };

      result.push(info);
    }
  };

  contatoriProduzione.forEach((r) => func(r, true));
  contatoriCessione.forEach((r) => func(r, false));

  if (quadroERigoQuadro === "L6" && !quadroLHaRinnovabili) {
    // non c'è quadro L6 se non ha almeno un rinnovabile
    return [];
  }
  if (quadroERigoQuadro === "L5" && contatoriProduzione.length === 0) {
    // per L5, contatoriProduzione in realta sono i contatori di tipo C
    return [];
  }

  let resultAggregated = [];
  const resultAggregatedByKey = {};

  // aggregate
  result.forEach((row) => {
    const key = row.codiceCatastaleComune + "--" + row.mese;
    let obj = resultAggregatedByKey[key];
    if (!obj) {
      obj = {
        mese: row.mese,
        codiceCatastaleComune: row.codiceCatastaleComune,
        chilowattora: 0,
      };
      var c = datiByCodiceCatastale[row.codiceCatastaleComune];
      obj.comune = c.comuneRaw;
      obj.provincia = c.provincia;

      resultAggregatedByKey[key] = obj;
      resultAggregated.push(obj);
    }

    obj.chilowattora += row.chilowattora;
  });

  // modifica 02/03/2020
  // arrotonda solo ora i chilowattora, cosi sono arrotondati a livello di mese ma non prima di fare la somma dei chilowattora di tutti i contatori
  resultAggregated.forEach((resultObj) => {
    resultObj.chilowattora = roundFloat0DecWithFix(resultObj.chilowattora);
  });

  // sort
  resultAggregated = resultAggregated.sort(
    (
      { codiceCatastaleComune: codiceCatastaleComune1, mese: mese1 },
      { codiceCatastaleComune: codiceCatastaleComune2, mese: mese2 }
    ) => {
      if (codiceCatastaleComune1 !== codiceCatastaleComune2) {
        return codiceCatastaleComune1 < codiceCatastaleComune2 ? -1 : 1;
      }
      return mese1 - mese2;
    }
  );

  return resultAggregated;
}

function calculateResultAggregated_casoConFormule(
  installation,
  serviceLicense,
  report,
  contatoriProduzione,
  contatoriCessione,
  formulaType,
  calcoloPerUsoProprio,
  fail
) {
  if (!calcoloPerUsoProprio) {
    return [];
  }
  if (!checkHasFormula(formulaType, report)) {
    return [];
  }

  const key = formuleVariablesKeys[formulaType];
  const values = calcoloPerUsoProprio[key];

  const codiceCatastaleComune = installation.codiceCatastaleImpianto;

  const result = [];
  each(values, (value, mese) => {
    mese = parseInt(mese);

    const info = {
      mese: mese,
      codiceCatastaleComune: codiceCatastaleComune,
      chilowattora: value, // value is not rounded! (modifica 02/03/2020)
    };

    result.push(info);
  });

  let resultAggregated = [];
  const resultAggregatedByKey = {};

  // aggregate
  result.forEach((row) => {
    const key = row.codiceCatastaleComune + "--" + row.mese;
    let obj = resultAggregatedByKey[key];
    if (!obj) {
      obj = {
        mese: row.mese,
        codiceCatastaleComune: row.codiceCatastaleComune,
        chilowattora: 0,
      };
      var c = datiByCodiceCatastale[row.codiceCatastaleComune];
      obj.comune = c.comuneRaw;
      obj.provincia = c.provincia;

      resultAggregatedByKey[key] = obj;
      resultAggregated.push(obj);
    }

    obj.chilowattora += row.chilowattora;
  });

  // modifica 02/03/2020
  // arrotonda solo ora i chilowattora, cosi sono arrotondati a livello di mese ma non prima di fare la somma dei chilowattora di tutti i contatori
  resultAggregated.forEach((resultObj) => {
    resultObj.chilowattora = roundFloat0DecWithFix(resultObj.chilowattora);
  });

  // sort
  resultAggregated = resultAggregated.sort(
    (
      { codiceCatastaleComune: codiceCatastaleComune1, mese: mese1 },
      { codiceCatastaleComune: codiceCatastaleComune2, mese: mese2 }
    ) => {
      if (codiceCatastaleComune1 !== codiceCatastaleComune2) {
        return codiceCatastaleComune1 < codiceCatastaleComune2 ? -1 : 1;
      }
      return mese1 - mese2;
    }
  );

  return resultAggregated;
}

function checkResultAggregatedGreaterThen0(resultAggregated, prefix, fail) {
  //TODO:
  resultAggregated.forEach((resultObj) => {
    if (resultObj.chilowattora < 0) {
      if (fail) {
        throw new Error(
          prefix + " Il totale dei chilowattora è minore di zero."
        );
      }
    }
  });
}

function _findFormulaObj(report, mese) {
  const formule = report.reportInfo.formule;
  let result = formule[0];
  for (let index = 0; index < formule.length; index++) {
    const formulaObj = formule[index];
    const m = meseFromDate(formulaObj.startDate);
    if (m <= mese) {
      result = formulaObj;
    } else {
      break;
    }
  }
  return result;
}

function _getValueOrDefault(formulaObj, key, defaultValue) {
  const v = formulaObj[key];
  if (v == null) {
    return defaultValue;
  }
  return v;
}

function fixAggregatedResultUsiPropri(formulaType, report, info) {
  const infoKeys = formuleVariablesKeysPerInfo[formulaType];
  const { numeroUtenzeKey, isUsiCommercialiKey, defaults } = infoKeys;
  const formulaObj = _findFormulaObj(report, info.mese);
  info.numeroUtenze = _getValueOrDefault(
    formulaObj,
    numeroUtenzeKey,
    defaults.numeroUtenze
  );
  info.isUsiCommerciali = _getValueOrDefault(
    formulaObj,
    isUsiCommercialiKey,
    defaults.isUsiCommerciali
  );
}

function makeXml(report, resultAggregated, quadroType) {
  const xmlInfoByKey = {};
  const infoToUpdate = [];

  resultAggregated.forEach((info) => {
    const key = info.codiceCatastaleComune + "-" + info.mese;
    let xmlInfo = xmlInfoByKey[key];

    if (!xmlInfo) {
      var c = datiByCodiceCatastale[info.codiceCatastaleComune];

      xmlInfo = {
        xmlType: getRootXmlTypeForQuadro(quadroType, isPre2021(report)),
        children: [
          isPre2021(report) && {
            xmlType: "TipoRecord",
            content: getQuadroTypeForQuadro(quadroType),
          },
          {
            xmlType: isPre2021(report) ? "Provincia" : "Prov",
            content: c.provincia,
          },
          {
            xmlType: isPre2021(report) ? "CodiceCatastale" : "CodCat",
            content: c.codiceCatastale,
          },
          {
            xmlType: "Mese",
            content: info.mese,
          },
        ].filter((x) => x),
        _consumi: [],
      };

      xmlInfoByKey[key] = xmlInfo;
      info.xml = xmlInfo;
      infoToUpdate.push(xmlInfo);
    }

    var usiPropi = 0;
    var usiCommerciali = 0;
    if (info.usaDatiQuadro) {
      usiPropi = info.consumiUsiPropri || 0;
      usiCommerciali = info.consumiUsiCommerciali || 0;
    } else {
      if (info.isUsiCommerciali) {
        usiCommerciali = info.chilowattora;
      } else {
        usiPropi = info.chilowattora;
      }
    }

    xmlInfo._consumi.push({
      xmlType: "Consumi",
      children: [
        {
          xmlType: isPre2021(report) ? "Progressivo" : "Progr",
          content: info.rigaQuadro,
        },
        {
          xmlType: isPre2021(report) ? "ConsumiUsiPropri" : "QtaUP",
          content: usiPropi,
        },
        {
          xmlType: isPre2021(report) ? "NumeroUtenze" : "NumUt",
          content: info.numeroUtenze,
        },
        {
          xmlType: isPre2021(report) ? "ConsumiUsiCommerciali" : "QtaUC",
          content: usiCommerciali,
        },
      ],
    });
  });

  infoToUpdate.forEach((xmlInfo) => {
    xmlInfo._consumi.forEach((x) => xmlInfo.children.push(x));
    delete xmlInfo._consumi;
  });
}

export function generateQuadroJ(
  installation,
  serviceLicense,
  report,
  contatoriProduzione,
  contatoriCessione,
  calcoloPerUsoProprio,
  fail
) {
  let resultAggregated;
  let rigaQuadro = 1;

  const datiQuadri = getDatiQuadri(
    report,
    "consumiNonSottopostiAdAccisa",
    "consumi"
  );
  if (datiQuadri.length) {
    // inserimento manuali quadri

    resultAggregated = [];
    datiQuadri.forEach((riga) => {
      if (!riga) {
        return;
      }
      const {
        mese,
        progressivo,
        numeroUtenze,
        consumiUsiPropri,
        consumiUsiCommerciali,
      } = riga;
      const codiceCatastaleComune = installation.codiceCatastaleImpianto;

      const r = {
        codiceCatastaleComune,
        mese,
        rigaQuadro: progressivo,
        numeroUtenze,
        consumiUsiPropri,
        consumiUsiCommerciali,
        usaDatiQuadro: true,
      };

      resultAggregated.push(r);
    });
  } else {
    // metodo energix

    if (!haQuadro("J", installation.type, report)) {
      return [];
    }

    if (
      !report.reportInfo ||
      !/^J[1-5]$/.test(report.reportInfo.tipologiaDiConsumo)
    ) {
      if (fail) {
        throw new Error("L'impianto deve avere una tipologia di consumo");
      }
    }
    rigaQuadro = parseInt(
      (
        (report.reportInfo && report.reportInfo.tipologiaDiConsumo) ||
        "J1"
      ).substring(1)
    );

    resultAggregated = calculateResultAggregated_casoConFormule(
      installation,
      serviceLicense,
      report,
      contatoriProduzione,
      contatoriCessione,
      "CONSUMI_NON_SOTTOPOSTI",
      calcoloPerUsoProprio,
      fail
    );

    resultAggregated.forEach((obj) => {
      obj.rigaQuadro = rigaQuadro;
      fixAggregatedResultUsiPropri("CONSUMI_NON_SOTTOPOSTI", report, obj);
    });

    resultAggregated = applyOverrideQuadri(
      "J",
      report,
      resultAggregated,
      "chilowattora"
    );

    resultAggregated = resultAggregated.filter((obj) =>
      filterBasedOnFrequenzaLetture(report, obj)
    );

    checkResultAggregatedGreaterThen0(resultAggregated, "Quadro J:", fail);

    // fine metodo energix
  }

  // create c01
  resultAggregated.forEach((info) => {
    var usiPropi = 0;
    var usiCommerciali = 0;
    if (info.usaDatiQuadro) {
      usiPropi = info.consumiUsiPropri || 0;
      usiCommerciali = info.consumiUsiCommerciali || 0;
    } else {
      if (info.isUsiCommerciali) {
        usiCommerciali = info.chilowattora;
      } else {
        usiPropi = info.chilowattora;
      }
    }

    info.c01 =
      parteComune("J", installation, serviceLicense, report, info.mese, fail) +
      "J" +
      intToString(rigaQuadro, 2) +
      // Consumi per usi propri (kWh):
      (usiPropi >= 0 ? "+" : "-") +
      intToString(Math.abs(usiPropi), 13) +
      intToString(info.numeroUtenze, 8) +
      // Consumi per usi commerciali (kWh):
      (usiCommerciali >= 0 ? "+" : "-") +
      intToString(Math.abs(usiCommerciali), 13) +
      info.codiceCatastaleComune;
  });

  // xml
  makeXml(report, resultAggregated, "J");

  return resultAggregated;
}

export function generateQuadroL(
  installation,
  serviceLicense,
  report,
  contatoriProduzione,
  contatoriCessione,
  contatoriConsumiPropriEsenti,
  calcoloPerUsoProprio,
  fail
) {
  let resultAggregated;

  const datiQuadri = getDatiQuadri(report, "consumiEsentiDaAccisa", "consumi");
  if (datiQuadri.length) {
    // inserimento manuali quadri

    resultAggregated = [];
    datiQuadri.forEach((riga) => {
      if (!riga) {
        return;
      }
      const {
        mese,
        progressivo,
        numeroUtenze,
        consumiUsiPropri,
        consumiUsiCommerciali,
      } = riga;
      const codiceCatastaleComune = installation.codiceCatastaleImpianto;

      const r = {
        codiceCatastaleComune,
        mese,
        rigaQuadro: progressivo,
        numeroUtenze,
        consumiUsiPropri,
        consumiUsiCommerciali,
        usaDatiQuadro: true,
      };

      resultAggregated.push(r);
    });
  } else {
    // metodo energix

    if (!haQuadro("L", installation.type, report)) {
      return [];
    }

    if (installation.type === "CESSIONE_PARZIALE_NON_COMMERCIALE") {
      // tipologiaDiConsumoEsente può essere L5, L6 o Perdite
      const tipologiaDiConsumoEsente =
        report.reportInfo && report.reportInfo.tipologiaDiConsumoEsente;

      // se c'è L5 e L6: L6 = L6-L5
      let L5ByCodiceCatastaleComuneEMese = {};

      // L5 si calcola uguale a L6, ma solo considerando i contatori di tipo C
      // quindi riuso lo stesso calcolo ma con solo i contatori C meno array vuoto
      let resultAggregatedL5 = calculateResultAggregated_consumiEsenti(
        installation,
        serviceLicense,
        report,
        contatoriConsumiPropriEsenti,
        [],
        fail,
        "L",
        "L5"
      );
      resultAggregatedL5.forEach((obj) => {
        obj.rigaQuadro = 5;
        obj.isUsiCommerciali = false;
        obj.numeroUtenze = 0;
        const key = obj.codiceCatastaleComune + "--" + obj.mese;
        L5ByCodiceCatastaleComuneEMese[key] =
          L5ByCodiceCatastaleComuneEMese[key] || 0;
        L5ByCodiceCatastaleComuneEMese[key] += obj.chilowattora;
      });
      if (tipologiaDiConsumoEsente === "L5") {
        // La differenza tra produzione e cessione viene inserita in L5. Eventuali contatori C vengono riportati solamente nel quadro C.
        resultAggregatedL5 = [];
        L5ByCodiceCatastaleComuneEMese = {};
      }
      let resultAggregatedL6 = calculateResultAggregated_consumiEsenti(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        fail,
        "L",
        "L6"
      );
      resultAggregatedL6.forEach((obj) => {
        if (tipologiaDiConsumoEsente === "L5") {
          // La differenza tra produzione e cessione viene inserita in L5. Eventuali contatori C vengono riportati solamente nel quadro C.
          obj.rigaQuadro = 5;
        } else {
          obj.rigaQuadro = 6;
        }
        obj.isUsiCommerciali = false;
        obj.numeroUtenze = 0;
        const key = obj.codiceCatastaleComune + "--" + obj.mese;
        const diff = L5ByCodiceCatastaleComuneEMese[key] || 0;
        obj.chilowattora -= diff;
      });
      if (tipologiaDiConsumoEsente === "Perdite") {
        // La differenza tra produzione e cessione viene inserita come Perdite, senza inserimento di consumi mensili esenti.
        resultAggregatedL6 = [];
      }

      resultAggregated = [...resultAggregatedL5, ...resultAggregatedL6];
    } else {
      const resultAggregatedL5 = calculateResultAggregated_casoConFormule(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        "CONSUMI_ESENTI_L5",
        calcoloPerUsoProprio,
        fail
      );
      resultAggregatedL5.forEach((obj) => {
        obj.rigaQuadro = 5;
        fixAggregatedResultUsiPropri("CONSUMI_ESENTI_L5", report, obj);
      });
      const resultAggregatedL6 = calculateResultAggregated_casoConFormule(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        "CONSUMI_ESENTI_L6",
        calcoloPerUsoProprio,
        fail
      );
      resultAggregatedL6.forEach((obj) => {
        obj.rigaQuadro = 6;
        fixAggregatedResultUsiPropri("CONSUMI_ESENTI_L6", report, obj);
      });
      const resultAggregatedL8 = calculateResultAggregated_casoConFormule(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        "CONSUMI_ESENTI_L8",
        calcoloPerUsoProprio,
        fail
      );
      resultAggregatedL8.forEach((obj) => {
        obj.rigaQuadro = 8;
        fixAggregatedResultUsiPropri("CONSUMI_ESENTI_L8", report, obj);
      });

      resultAggregated = [
        ...resultAggregatedL5,
        ...resultAggregatedL6,
        ...resultAggregatedL8,
      ];
    }

    resultAggregated = applyOverrideQuadri(
      "L",
      report,
      resultAggregated,
      "chilowattora"
    );

    resultAggregated = resultAggregated.filter((obj) =>
      filterBasedOnFrequenzaLetture(report, obj)
    );

    if (!(report.reportInfo && report.reportInfo.allowNegativoL)) {
      checkResultAggregatedGreaterThen0(resultAggregated, "Quadro L:", fail);
    }

    // fine metodo energix
  }

  // create c01
  resultAggregated.forEach((info) => {
    var usiPropi = 0;
    var usiCommerciali = 0;
    if (info.usaDatiQuadro) {
      usiPropi = info.consumiUsiPropri || 0;
      usiCommerciali = info.consumiUsiCommerciali || 0;
    } else {
      if (info.isUsiCommerciali) {
        usiCommerciali = info.chilowattora;
      } else {
        usiPropi = info.chilowattora;
      }
    }

    info.c01 =
      parteComune("L", installation, serviceLicense, report, info.mese, fail) +
      "L" +
      intToString(info.rigaQuadro, 2) +
      // Consumi per usi propri (kWh):
      (usiPropi >= 0 ? "+" : "-") +
      intToString(Math.abs(usiPropi), 13) +
      intToString(info.numeroUtenze, 8) +
      // Consumi per usi commerciali (kWh):
      (usiCommerciali >= 0 ? "+" : "-") +
      intToString(Math.abs(usiCommerciali), 13) +
      info.codiceCatastaleComune;
  });

  // xml
  makeXml(report, resultAggregated, "L");

  return resultAggregated;
}

export function generateQuadroM(
  installation,
  serviceLicense,
  report,
  contatoriProduzione,
  contatoriCessione,
  calcoloPerUsoProprio,
  fail
) {
  const resultAggregatedDivisi = [];

  const datiQuadri = getDatiQuadri(
    report,
    "consumiAssoggettatiAdAccisa",
    "consumi"
  );
  if (datiQuadri.length) {
    // inserimento manuali quadri

    datiQuadri.forEach((riga) => {
      if (!riga) {
        return;
      }
      const {
        mese,
        progressivo,
        numeroUtenze,
        consumiUsiPropri,
        consumiUsiCommerciali,
      } = riga;
      const codiceCatastaleComune = installation.codiceCatastaleImpianto;

      const r = {
        codiceCatastaleComune,
        mese,
        rigaQuadro: progressivo,
        numeroUtenze,
        consumiUsiPropri,
        consumiUsiCommerciali,
        usaDatiQuadro: true,
      };

      resultAggregatedDivisi.push(r);
    });
  } else {
    // metodo energix

    if (!haQuadro("M", installation.type, report)) {
      return [];
    }

    let resultAggregated;
    if (
      installation.type === "CESSIONE_PARZIALE_COMMERCIALE" ||
      (report.type === "USO_PROPRIO" &&
        report.reportInfo.metodoDiCompilazioneSemplificato)
    ) {
      // caso semplice, i consumi assoggettati sono uguali ai consumi esenti
      resultAggregated = calculateResultAggregated_consumiEsenti(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        fail,
        "M"
      );

      const quadroMInfo = report.reportInfo.quadroMInfo || {
        isUsiCommerciali: true,
        numeroUtenze: 1,
      };
      resultAggregated.forEach((info) => {
        info.isUsiCommerciali = quadroMInfo.isUsiCommerciali;
        info.numeroUtenze = quadroMInfo.numeroUtenze;
        info.rigaQuadro = "9+10+11+12";
      });
    } else {
      const resultAggregated1 = calculateResultAggregated_casoConFormule(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        "CONSUMI_ASSOGGETTATI_M1",
        calcoloPerUsoProprio,
        fail
      );
      resultAggregated1.forEach((info) => {
        fixAggregatedResultUsiPropri("CONSUMI_ASSOGGETTATI_M1", report, info);
        info.rigaQuadro = 1;
      });
      const resultAggregated2 = calculateResultAggregated_casoConFormule(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        "CONSUMI_ASSOGGETTATI_M2",
        calcoloPerUsoProprio,
        fail
      );
      resultAggregated2.forEach((info) => {
        fixAggregatedResultUsiPropri("CONSUMI_ASSOGGETTATI_M2", report, info);
        info.rigaQuadro = 2;
      });
      const resultAggregated3 = calculateResultAggregated_casoConFormule(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        "CONSUMI_ASSOGGETTATI_M3",
        calcoloPerUsoProprio,
        fail
      );
      resultAggregated3.forEach((info) => {
        fixAggregatedResultUsiPropri("CONSUMI_ASSOGGETTATI_M3", report, info);
        info.rigaQuadro = 3;
      });
      const resultAggregated9 = calculateResultAggregated_casoConFormule(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        "CONSUMI_ASSOGGETTATI",
        calcoloPerUsoProprio,
        fail
      );
      resultAggregated9.forEach((info) => {
        fixAggregatedResultUsiPropri("CONSUMI_ASSOGGETTATI", report, info);
        info.rigaQuadro = "9+10+11+12";
      });
      resultAggregated = [
        ...resultAggregated1,
        ...resultAggregated2,
        ...resultAggregated3,
        ...resultAggregated9,
      ];
    }

    resultAggregated = applyOverrideQuadri(
      "M",
      report,
      resultAggregated,
      "chilowattora"
    );

    resultAggregated = resultAggregated.filter((obj) =>
      filterBasedOnFrequenzaLetture(report, obj)
    );

    checkResultAggregatedGreaterThen0(resultAggregated, "Quadro M:", fail);

    // separa consumi in base ai kw + create c01
    resultAggregated.forEach((info) => {
      var createC01 = function (infoX) {
        var usiPropi = 0;
        var usiCommerciali = 0;
        if (infoX.usaDatiQuadro) {
          usiPropi = infoX.consumiUsiPropri || 0;
          usiCommerciali = infoX.consumiUsiCommerciali || 0;
        } else {
          if (infoX.isUsiCommerciali) {
            usiCommerciali = infoX.chilowattora;
          } else {
            usiPropi = infoX.chilowattora;
          }
        }

        return (
          parteComune(
            "M",
            installation,
            serviceLicense,
            report,
            infoX.mese,
            fail
          ) +
          "M" +
          intToString(infoX.rigaQuadro, 2) +
          // Consumi per usi propri (kWh):
          (usiPropi >= 0 ? "+" : "-") +
          intToString(Math.abs(usiPropi), 13) +
          intToString(infoX.numeroUtenze, 8) +
          // Consumi per usi commerciali (kWh):
          (usiCommerciali >= 0 ? "+" : "-") +
          intToString(Math.abs(usiCommerciali), 13) +
          infoX.codiceCatastaleComune
        );
      };

      if (info.rigaQuadro === "9+10+11+12") {
        let righeQuadro;

        let primiChilowattora = 200000;
        let limite1M200k = 1200000;
        if (info.isUsiCommerciali) {
          const numUsi =
            typeof info.numeroUtenze === "number" ? info.numeroUtenze : 1;
          primiChilowattora = primiChilowattora * Math.max(numUsi, 1);
          limite1M200k = limite1M200k * Math.max(numUsi, 1);
        }

        if (info.chilowattora <= limite1M200k) {
          righeQuadro = [9, 10];
        } else {
          righeQuadro = [11, 12];
        }

        let info1, info2;

        if (info.chilowattora <= primiChilowattora) {
          info1 = {
            ...info,
            rigaQuadro: righeQuadro[0],
          };
        } else {
          info1 = {
            ...info,
            chilowattora: primiChilowattora,
            rigaQuadro: righeQuadro[0],
          };
          info2 = {
            ...info,
            chilowattora: info.chilowattora - primiChilowattora,
            rigaQuadro: righeQuadro[1],
          };
        }

        // add info1 and info2

        info1.c01 = createC01(info1);
        resultAggregatedDivisi.push(info1);

        if (info2) {
          info2.c01 = createC01(info2);
          resultAggregatedDivisi.push(info2);
        }
      } else {
        info.c01 = createC01(info);
        resultAggregatedDivisi.push(info);
      }
    });

    // fine metodo energix
  }

  // xml
  makeXml(report, resultAggregatedDivisi, "M");

  return resultAggregatedDivisi;
}
