const minLoadingTime = 1000;

const windowAsAny = window as any;

export function setLoadingScreenLogo(logo: string) {
  document.getElementById("loading-screen-logo-container")!.innerHTML = logo;
}

export function closeLoadingScreen() {
  if (!windowAsAny.loading_screen) {
    return;
  }

  windowAsAny.loading_screen_end = new Date();
  const loadingTime =
    windowAsAny.loading_screen_end.getTime() -
    windowAsAny.loading_screen_start.getTime();

  const seconds = loadingTime / 1000;
  console.log("Loading in " + seconds + " s");

  function finishLoading() {
    if (windowAsAny.loading_screen) {
      windowAsAny.loading_screen.finish();
      windowAsAny.loading_screen = null;
    }
  }

  if (loadingTime < minLoadingTime) {
    setTimeout(finishLoading, minLoadingTime - loadingTime);
  } else {
    finishLoading();
  }
}
