import { Impianto } from "energix-types/src/Impianto";
import moment from "moment/moment";

export default function getScadenzaSPI({
  ultimaTaraturaSPI,
  frequenzaTaraturaSPI,
}: Impianto): string | null {
  if (!ultimaTaraturaSPI || !frequenzaTaraturaSPI) {
    return null;
  }
  return moment(ultimaTaraturaSPI)
    .add(frequenzaTaraturaSPI, "years")
    .format("YYYY-MM-DD");
}
