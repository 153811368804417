import {
  parteComune,
  stringToLength,
  intToString,
  countRecord,
} from "./c01Utils";
import moment from "moment";

export function generateNomeFlusso(
  info,
  progressivoGiorno = 1,
  datePart = moment().format("MMDD")
) {
  return (
    info.codiceUtenteAbilitato +
    datePart +
    ".C" +
    intToString(progressivoGiorno, 2)
  );
}

export function generateNomeFlussoFromDic(
  installation,
  serviceLicense,
  report,
  dicContent,
  progressivoGiorno = 1,
  datePart = moment().format("MMDD")
) {
  return (
    serviceLicense.richiedenteCodiceUtenteAbilitato +
    datePart +
    ".C" +
    intToString(progressivoGiorno, 2)
  );
}

export function generateC01(info, nomeFlusso) {
  // let progressivoSede = info.progressivoSede
  // let progressivoSedeString
  // if(typeof progressivoSede === 'string') {
  //   if(progressivoSede.length === 3){
  //     progressivoSedeString = progressivoSede
  //   }else{
  //     // try parse
  //     progressivoSede = parseInt(progressivoSede, 10)
  //     if(isNaN(progressivoSede)) {
  //       progressivoSede = 1
  //     }
  //     progressivoSedeString = intToString(progressivoSede, 3)
  //   }
  // }else{
  //   progressivoSedeString = intToString(progressivoSede, 3)
  // }

  return (
    stringToLength(info.codiceUtenteAbilitato, 4) +
    stringToLength("", 12) +
    stringToLength(nomeFlusso, 12) +
    stringToLength("", 12) +
    info.codiceSezioneDoganale +
    stringToLength("", 4) +
    stringToLength(info.partitaIva, 16) +
    intToString(info.progressivoSede, 3) + // progressivoSedeString +
    stringToLength("", 1) +
    intToString(info.numeroDiRecord, 5)
  );
}

export function generateC01TestataFromDic(
  installation,
  serviceLicense,
  report,
  dicContent,
  nomeFlusso
) {
  const numeroDiRecordIndex = 391;
  const numeroDiRecordString = dicContent.substring(
    numeroDiRecordIndex,
    numeroDiRecordIndex + 6
  );

  const progressivoSede =
    serviceLicense.richiedentePostazioneDiCollegamento || 1;

  const info = {
    progressivoSede: progressivoSede,
    codiceUtenteAbilitato: serviceLicense.richiedenteCodiceUtenteAbilitato,
    codiceSezioneDoganale: "ITU000",
    partitaIva:
      serviceLicense.richiedenteCF || serviceLicense.richiedentePartitaIva,
    numeroDiRecord: parseInt(numeroDiRecordString, 10) + 1, // compresa testata
  };
  return generateC01(info, nomeFlusso);
}

export function generateC01BodyFromDic(
  installation,
  serviceLicense,
  report,
  dicContent
) {
  const numeroDiFileIndex = 397;
  const numeroDiFile = 1;
  return (
    dicContent.substring(0, numeroDiFileIndex) +
    intToString(numeroDiFile, 2) +
    dicContent.substring(numeroDiFileIndex + 2)
  );
}

export default function (installation, serviceLicense, report, reportInfo) {
  const progressivoSede =
    serviceLicense.richiedentePostazioneDiCollegamento || 1;

  // const hasSottoscrittore = !!serviceLicense.sottoscrittoreCodiceUtenteAbilitato;
  let codiceUtenteAbilitato;

  // if(hasSottoscrittore) {
  //   codiceUtenteAbilitato = serviceLicense.sottoscrittoreCodiceUtenteAbilitato
  // }else{
  codiceUtenteAbilitato = serviceLicense.richiedenteCodiceUtenteAbilitato;
  // }

  const info = {
    codiceUtenteAbilitato,
    partitaIva:
      serviceLicense.richiedenteCF || serviceLicense.richiedentePartitaIva,
    codiceSezioneDoganale: "ITU000",
    progressivoSede,
    numeroDiRecord: reportInfo.frontespizio.numeroDiRecord + 1, // compresa testata
  };

  return info;
}
