import { Impianto } from "energix-types/src/Impianto";

export async function checkFileXmlIfNeeded(file: File, impianto: Impianto) {
  if (!impianto || impianto.type === "IMPIANTO_DI_VENDITA") {
    return true;
  }

  const name = file.name || "";

  if (name.endsWith(".xml")) {
    let xml: string;
    try {
      const buffer = await file.arrayBuffer();
      xml = new TextDecoder("utf8").decode(buffer);
    } catch (err) {
      return true;
    }

    debugger;

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, "text/xml");

    const codDitta = xmlDoc
      ?.querySelector("EnergiaElettrica")
      ?.querySelector("Dich, Dichiarazione")
      ?.querySelector("CodDitta, CodiceDitta")?.textContent;

    if (!codDitta) {
      const ok = window.confirm(
        "Il file caricato non sembra essere un xml valido (codice ditta non trovato). Vuoi continuare?"
      );
      return ok;
    }

    if (
      codDitta !== impianto.installationCode &&
      codDitta !== `IT00${impianto.installationCode}` &&
      `IT00${codDitta}` !== impianto.installationCode
    ) {
      const ok = window.confirm(
        `Il file caricato fa riferimento al codice ditta: ${codDitta} che è diverso da quello specificato per questo impianto (${impianto.installationCode}). Vuoi continuare ugualmente?`
      );
      return ok;
    }
  }

  return true;
}
