import { Typography } from "@mui/material";
import { titleTypographyStyle } from "./style";

export type HeaderProps = { title: string };

export default function Header({ title }: HeaderProps) {
  return (
    <Typography variant="h4" sx={titleTypographyStyle} gutterBottom>
      {title}
    </Typography>
  );
}
