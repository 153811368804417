import {
  USER_SIGNUP_SUCCESS,
  SEND_VERIFY_EMAIL_REQUEST,
  SEND_VERIFY_EMAIL_SUCCESS,
  SEND_VERIFY_EMAIL_FAILURE,
  REDUX_INITIAL_STATE_FETCH_SUCCESS,
} from "../actions";

import { verifyPermlinkForUser } from "energix-old/src/common/utils";

function getStateFromSignupResponse(response) {
  const emailSendingError = response.info && response.info.emailSendingError;
  const tokenPermlink = verifyPermlinkForUser(response.user);
  return {
    emailSent: !emailSendingError,
    emailSendingError: emailSendingError,
    tokenPermlink: tokenPermlink,
  };
}

export default function verifyReducer(state = {}, action) {
  switch (action.type) {
    case REDUX_INITIAL_STATE_FETCH_SUCCESS:
      return action.response.preloadedState.verify || {};

    case SEND_VERIFY_EMAIL_REQUEST:
      return {
        sendingEmail: true,
        tokenPermlink: state.tokenPermlink,
      };
    case SEND_VERIFY_EMAIL_SUCCESS:
    case SEND_VERIFY_EMAIL_FAILURE:
      return (
        (action.response && action.response.verifyState) || {
          verificationFailed: true,
        }
      );

    case USER_SIGNUP_SUCCESS:
      return getStateFromSignupResponse(action.response);

    default:
      return state;
  }
}
