import {
  CostoOperazione,
  CostoOperazioni,
  operazioniLabel,
} from "energix-types/src/CostoOperazioni";
import { useMemo } from "react";

export function useCostoOperazioniObjectFromCostoOperazioneArray(
  costoOperazioniList: CostoOperazione[]
) {
  return useMemo(() => {
    const costoOperazioni: Partial<CostoOperazioni> = {};
    for (const operazione of operazioniLabel) {
      costoOperazioni[operazione.key] = 0;
    }

    for (const costoOperazione of costoOperazioniList) {
      costoOperazioni[costoOperazione.operazione as keyof CostoOperazioni] =
        costoOperazione.valore;
    }

    return costoOperazioni as CostoOperazioni;
  }, [costoOperazioniList]);
}
