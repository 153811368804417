import {
  EDistribuzioneEnergiaMensile,
  TipoEDistribuzioneEnergiaMensile,
} from "energix-types/src/edistribuzione";
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import BaseTable from "src/components/BaseTable";
import { fNumber } from "src/components/common/formatNumber";
import { sortBy } from "lodash";
import { Typography } from "@mui/material";

export type RowData = Record<TipoEDistribuzioneEnergiaMensile, number> & {
  anno: number | string;
};

export type EDistribuzioneProduzioneDiEnergiaTableProps = {
  data: RowData[];
  totale: Record<TipoEDistribuzioneEnergiaMensile, number>;
};

const columnHelper = createColumnHelper<RowData>();

export function groupEDistribuzioneData(
  rawData: EDistribuzioneEnergiaMensile[]
) {
  const dataByAnno: Record<number, RowData> = {};
  for (const d of rawData) {
    if (!dataByAnno[d.anno]) {
      dataByAnno[d.anno] = {
        prelievo: 0,
        immissione: 0,
        autoconsumo: 0,
        produzione: 0,
        anno: d.anno,
      };
    }
    dataByAnno[d.anno][d.tipo] += d.valore;
  }
  return sortBy(Object.values(dataByAnno), "anno").reverse();
}

export function getTotalEDistribuzioneData(
  rawData: EDistribuzioneEnergiaMensile[]
) {
  const total = {
    prelievo: 0,
    immissione: 0,
    autoconsumo: 0,
    produzione: 0,
  };
  for (const d of rawData) {
    total[d.tipo] += d.valore;
  }
  return total;
}

export default function EDistribuzioneProduzioneDiEnergiaTable({
  data,
  totale,
}: EDistribuzioneProduzioneDiEnergiaTableProps) {
  const columns = useMemo(
    () => [
      columnHelper.accessor("anno", {
        header: "Anno",
        cell: ({
          row: {
            original: { anno },
          },
        }) => <Typography style={getCellStyle(anno)}>{anno}</Typography>,
      }),
      columnHelper.accessor("produzione", {
        header: "Produzione",
        cell: ({
          row: {
            original: { anno, produzione },
          },
        }) => (
          <Typography style={getCellStyle(anno)}>
            {fNumber(produzione)} kWh
          </Typography>
        ),
      }),
      columnHelper.accessor("autoconsumo", {
        header: "Autoconsumo",
        cell: ({
          row: {
            original: { anno, autoconsumo },
          },
        }) => (
          <Typography style={getCellStyle(anno)}>
            {fNumber(autoconsumo)} kWh
          </Typography>
        ),
      }),
      columnHelper.accessor("immissione", {
        header: "Immissione",
        cell: ({
          row: {
            original: { anno, immissione },
          },
        }) => (
          <Typography style={getCellStyle(anno)}>
            {fNumber(immissione)} kWh
          </Typography>
        ),
      }),
      columnHelper.accessor("prelievo", {
        header: "Prelievo",
        cell: ({
          row: {
            original: { anno, prelievo },
          },
        }) => (
          <Typography style={getCellStyle(anno)}>
            {fNumber(prelievo)} kWh
          </Typography>
        ),
      }),
    ],
    []
  );

  return (
    <BaseTable
      columns={columns}
      sortable={false}
      paginated={false}
      data={useMemo(
        () => [
          ...data,
          {
            anno: "Totale",
            ...totale,
          },
        ],
        [data, totale]
      )}
    />
  );
}

function getCellStyle(anno: string | number) {
  return { fontWeight: anno === "Totale" ? "bold" : undefined };
}
