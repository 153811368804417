// Action key that carries API call info interpreted by this Redux middleware.
import { CALL_API } from "./api";

import { isAdminLoggedAsUserQueryComponent } from "../isAdminLoggedAsUserPrefix";

export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  const state = store.getState();

  let { endpoint } = callAPI;

  if (typeof endpoint === "function") {
    endpoint = endpoint(state);
  }

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }

  const qc = isAdminLoggedAsUserQueryComponent(state.auth);
  if (!qc) {
    return next(action);
  }

  let hasQ = endpoint.indexOf("?");
  if (hasQ >= 0) {
    endpoint += "&";
  } else {
    endpoint += "?";
  }

  endpoint = endpoint + qc;

  const modifiedAction = {
    ...action,
    [CALL_API]: {
      ...callAPI,
      endpoint: endpoint,
    },
  };

  return next(modifiedAction);
};
