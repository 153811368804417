import { CALL_API } from "../middleware/api";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const login = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGIN_FAILURE],
      endpoint: "/api/login",
      method: "POST",
      body: params,
    },
  });
};

export const logout = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [USER_LOGOUT_REQUEST, USER_LOGOUT_SUCCESS, USER_LOGOUT_FAILURE],
      endpoint: "/api/logout",
      method: "GET",
    },
  });
};
