import { Notifica } from "energix-types/src/Notifica";
import { CALL_API } from "src/middleware/api";
import { useDispatch } from "react-redux";

// types
export type CreateNotificaFunction = (
  notifica: Notifica
) => Promise<CreateNotificaResult>;
export type CreateNotificaResult = CreateNotificaSuccess | CreateNotificaError;

export type CreateNotificaSuccess = {
  resultType: "success";
  notifica: Notifica;
};

export type CreateNotificaError = {
  resultType: "error";
};

// API call
const NOTIFICA_PARTNER_CREATE_REQUEST = "NOTIFICA_PARTNER_CREATE_REQUEST";
export const NOTIFICA_PARTNER_CREATE_SUCCESS =
  "NOTIFICA_PARTNER_CREATE_SUCCESS";
const NOTIFICA_PARTNER_CREATE_FAILURE = "NOTIFICA_PARTNER_CREATE_FAILURE";

const createNotificaPartnerApiCall =
  (notifica: Notifica) => (dispatch: any) => {
    return dispatch({
      [CALL_API]: {
        types: [
          NOTIFICA_PARTNER_CREATE_REQUEST,
          NOTIFICA_PARTNER_CREATE_SUCCESS,
          NOTIFICA_PARTNER_CREATE_FAILURE,
        ],
        endpoint: "/api/notification/create",
        method: "POST",
        body: notifica,
      },
    });
  };

const NOTIFICA_ADMIN_CREATE_REQUEST = "NOTIFICA_ADMIN_CREATE_REQUEST";
export const NOTIFICA_ADMIN_CREATE_SUCCESS = "NOTIFICA_ADMIN_CREATE_SUCCESS";
const NOTIFICA_ADMIN_CREATE_FAILURE = "NOTIFICA_ADMIN_CREATE_FAILURE";

export const createNotificaAdminApiCall =
  (notifica: Notifica) => (dispatch: any) => {
    return dispatch({
      [CALL_API]: {
        types: [
          NOTIFICA_ADMIN_CREATE_REQUEST,
          NOTIFICA_ADMIN_CREATE_SUCCESS,
          NOTIFICA_ADMIN_CREATE_FAILURE,
        ],
        endpoint: "/api/notification-default/create",
        method: "POST",
        body: notifica,
      },
    });
  };

// Wrapper
export function useCreateNotificaPartner(): CreateNotificaFunction {
  const dispatch = useDispatch();
  return async function createNotificaPartner(notifica: Notifica) {
    const result = await dispatch(createNotificaPartnerApiCall(notifica));
    if (result.type !== NOTIFICA_PARTNER_CREATE_SUCCESS) {
      return { resultType: "error" };
    }
    return {
      resultType: "success",
      notifica: result.response.notification,
    };
  };
}

export function useCreateNotificaAdmin(): CreateNotificaFunction {
  const dispatch = useDispatch();
  return async function createNotificaAdmin(notifica: Notifica) {
    const result = await dispatch(createNotificaAdminApiCall(notifica));
    if (result.type !== NOTIFICA_ADMIN_CREATE_SUCCESS) {
      return { resultType: "error" };
    }
    return {
      resultType: "success",
      notifica: result.response.notification,
    };
  };
}
