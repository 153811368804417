import { CALL_API } from "../middleware/api";
import { useDispatch } from "react-redux";

// types

export type ResetPasswordAction = (
  email: string
) => Promise<ResetPasswordResult>;

export type ResetPasswordResult =
  | ResetPasswordSuccess
  | ResetPasswordValidationError
  | ResetPasswordError
  | ResetPasswordUnknownError;

export type ResetPasswordSuccess = {
  resultType: "success";
};

export type ResetPasswordValidationError = {
  resultType: "validationError";
  message: string;
  errors: { email?: string };
};

export type ResetPasswordError = {
  resultType: "error";
  message: string;
};

export type ResetPasswordUnknownError = {
  resultType: "unknownError";
};

// API call
export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAILURE = "USER_RESET_PASSWORD_FAILURE";

const resetPasswordApiCall = (params: { email: string }) => (dispatch: any) => {
  return dispatch({
    [CALL_API]: {
      types: [
        USER_RESET_PASSWORD_REQUEST,
        USER_RESET_PASSWORD_SUCCESS,
        USER_RESET_PASSWORD_FAILURE,
      ],
      endpoint: "/api/resetPassword",
      method: "POST",
      body: params,
    },
  });
};

// Wrapper
export function useResetPasswordAction() {
  const dispatch = useDispatch();
  return function resetPassword(email: string) {
    return new Promise((resolve: (result: ResetPasswordResult) => void) => {
      dispatch(resetPasswordApiCall({ email })).then((result: any) => {
        if (result.type === USER_RESET_PASSWORD_FAILURE) {
          const error = result.response;
          const isValidationError = !!error.errors;
          resolve({
            resultType: isValidationError ? "validationError" : "error",
            message: error.message,
            errors: error.errors,
          });
        } else {
          resolve({ resultType: "success" });
        }
      });
    });
  };
}
