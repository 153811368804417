// custom-instance.ts

import Axios, { AxiosRequestConfig } from "axios";

export const AXIOS_INSTANCE = Axios.create({}); // use your own URL here or environment variable

let axiosClienteId = 1;

export function configureAxiosClienteId(clienteId: number) {
  console.log("Axios using clienteId", clienteId);
  axiosClienteId = clienteId;
}

AXIOS_INSTANCE.interceptors.request.use(
  (config) => {
    // Check if the config has a params object, if not create one
    config.params = config.params || {};

    if (axiosClienteId) {
      config.params["clienteId"] = axiosClienteId;
    }

    // Return the modified config
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// add a second `options` argument here if you want to pass extra options to each generated query
export const customAxiosInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};

// // In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
// export type ErrorType<Error> = AxiosError<Error>;

// export type BodyType<BodyData> = BodyData;

// // Or, in case you want to wrap the body type (optional)
// // (if the custom instance is processing data before sending it, like changing the case for example)
// export type BodyType<BodyData> = CamelCase<BodyData>;
