import PropTypes from "prop-types";

import { useMemo } from "react";
// material
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { itIT } from "@mui/material/locale";
//
import shape from "./shape";
import typography from "./typography";
import breakpoints from "./breakpoints";
import GlobalStyles from "./globalStyles";
import componentsOverride from "./overrides";

import { createPalette } from "./palette";
import {
  createCustomShadowsFromPalette,
  createShadowFromPalette,
} from "./shadows";

import {
  useIsEnergixGreenContext,
  IsEnergixGreenContextProvider,
} from "./isEnergixGreenContext";

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default function ThemeConfig({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <IsEnergixGreenContextProvider>
        <EnergixThemeProvider>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </EnergixThemeProvider>
      </IsEnergixGreenContextProvider>
    </StyledEngineProvider>
  );
}

export function EnergixThemeProvider({ isEnergixGreen, children }) {
  const globalIsEnergixGreen = useIsEnergixGreenContext();

  if (typeof isEnergixGreen === "undefined") {
    isEnergixGreen = globalIsEnergixGreen;
  }

  const themeMode = "light";
  const themeDirection = "ltr";
  const isLight = themeMode === "light";

  const themeOptions = useMemo(() => {
    const palette = createPalette(isEnergixGreen);

    const shadows = createShadowFromPalette(palette);
    const customShadows = createCustomShadowsFromPalette(palette);

    return {
      palette: isLight
        ? { ...palette.light, mode: "light" }
        : { ...palette.dark, mode: "dark" },
      shape,
      typography,
      breakpoints,
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    };
  }, [isLight, themeDirection, isEnergixGreen]);

  const theme = useMemo(() => createTheme(themeOptions, itIT), [themeOptions]);
  theme.components = useMemo(() => componentsOverride(theme), [theme]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
