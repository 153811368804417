import { Azienda } from "energix-types/src/Azienda";
import { Contatore } from "energix-types/src/Contatore";
import { Impianto } from "energix-types/src/Impianto";
import { Dichiarazione } from "energix-types/src/Dichiarazione";
import { Notifica } from "energix-types/src/Notifica";
import { useSelector } from "react-redux";
import { find, isEqual } from "lodash";
import { useCallback } from "react";
import { ServizioAcquistatoOld } from "energix-types/src/ServizioAcquistatoOld";
import { NotificheSettings } from "energix-types/src/NotificheSettings";
import { ModuliAttivi } from "energix-types/src/ModuliAttivi";
import {
  CostoOperazione,
  CostoOperazioni,
} from "energix-types/src/CostoOperazioni";
import { PacchettoToken } from "energix-types/src/PacchettoToken";
import { useCostoOperazioniObjectFromCostoOperazioneArray } from "src/hooks/utils";

export function useContextContatoriSelector(): Contatore[] | undefined {
  return useSelector<any, any>((state: any) => state.context.contatori);
}

export function useContextImpiantiSelector(): Impianto[] | undefined {
  return useSelector<any, any>((state: any) => state.context.installations);
}

export function useContextImpiantoSelector(
  impiantoId: number
): Impianto | undefined {
  const selectorFunction = useCallback(
    (state: any) => {
      const installations = state.context.installations;
      return find(installations, (i) => i.id === impiantoId) ?? undefined;
    },
    [impiantoId]
  );
  return useSelector<any, any>(selectorFunction);
}

export function useContextAziendeSelector(): Azienda[] | undefined {
  return useSelector<any, any>((state: any) => state.context.serviceLicenses);
}

export function useContextAziendaSelector(
  aziendaId: number
): Azienda | undefined {
  const selectorFunction = useCallback(
    (state: any) => {
      const aziende = state.context.serviceLicenses;
      return find(aziende, (i) => i.id === aziendaId) ?? undefined;
    },
    [aziendaId]
  );
  return useSelector<any, any>(selectorFunction);
}

export function useContextDichiarazioniSelector(): Dichiarazione[] | undefined {
  return useSelector<any, any>((state: any) => state.context.reports);
}

export function useContextDichiarazioneSelector(
  impiantoId: number,
  anno: number
): Dichiarazione | undefined {
  const selectorFunction = useCallback(
    (state: any) =>
      state.context.reports?.find(
        (d: Dichiarazione) =>
          d.installation_id === impiantoId && d.anno === anno
      ),
    [impiantoId, anno]
  );
  return useSelector<any, any>(selectorFunction);
}

export function useContextNotificheSelector(): {
  notifications?: Notifica[];
  notificationsDefault?: Notifica[];
} {
  return useSelector<any, any>((state: any) => {
    const { notifications, notificationsDefault } = state.context;
    return { notifications, notificationsDefault };
  }, isEqual);
}

export function useContextNotificheSettingsSelector():
  | NotificheSettings
  | undefined {
  return useSelector<any, any>(
    (state: any) => state.context.notificationSettings,
    isEqual
  );
}

export function useContextServiziAcquistatiSelector(
  userId: number
): ServizioAcquistatoOld[] | undefined {
  const selectorFunction = useCallback(
    (state: any) =>
      state.context.serviziAcquistati.filter(
        (s: ServizioAcquistatoOld) => s.user_id === userId
      ),
    [userId]
  );
  return useSelector<any, any>(selectorFunction);
}

export function useContextPartnerPreregistrati() {
  const selectorFunction = useCallback(
    (state: any) => state.context.partnerPreregistrati,
    []
  );
  return useSelector<any, any>(selectorFunction);
}

export function useSaldoToken(): number {
  return useSelector<any, any>(
    useCallback((state: any) => state.context.token, [])
  );
}

export function useModuliAttivi(): ModuliAttivi {
  return useSelector<any, any>(
    useCallback((state: any) => state.context.moduliAttivi, [])
  );
}

export function useCostoOperazioni(): CostoOperazioni {
  const costoOperazioniList = useSelector<any, any>(
    useCallback(
      (state: any) => state.context.costoOperazioni as CostoOperazione[],
      []
    )
  );
  return useCostoOperazioniObjectFromCostoOperazioneArray(costoOperazioniList);
}

export function usePacchettiToken(): PacchettoToken[] {
  return useSelector<any, any>(
    useCallback((state: any) => state.context.pacchettiToken, [])
  );
}
