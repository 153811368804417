import { CALL_API } from "src/middleware/api";
import { useDispatch } from "react-redux";

export type setSogliaEdistribuzioneFunction = (
  discostamento: number
) => Promise<setSogliaEdistribuzioneResult>;

export type setSogliaEdistribuzioneResult =
  | setSogliaEdistribuzioneSuccess
  | setSogliaEdistribuzioneUnknownError;

export type setSogliaEdistribuzioneSuccess = {
  resultType: "success";
};

export type setSogliaEdistribuzioneUnknownError = {
  resultType: "unknownError";
};

const SET_SOGLIA_EDISTRIBUZIONE_REQUEST = "SET_SOGLIA_EDISTRIBUZIONE_REQUEST";
export const SET_SOGLIA_EDISTRIBUZIONE_SUCCESS =
  "SET_SOGLIA_EDISTRIBUZIONE_SUCCESS";
const SET_SOGLIA_EDISTRIBUZIONE_FAILURE = "SET_SOGLIA_EDISTRIBUZIONE_FAILURE";

const setDiscostamentoApiCall = (discostamento: number) => (dispatch: any) => {
  return dispatch({
    [CALL_API]: {
      types: [
        SET_SOGLIA_EDISTRIBUZIONE_REQUEST,
        SET_SOGLIA_EDISTRIBUZIONE_SUCCESS,
        SET_SOGLIA_EDISTRIBUZIONE_FAILURE,
      ],
      endpoint: "/api/user/setSogliaEdistribuzione",
      method: "POST",
      body: { discostamento: discostamento },
    },
  });
};

export function useSetSogliaEdistribuzione(): setSogliaEdistribuzioneFunction {
  const dispatch = useDispatch();
  return async function setDiscostamento(discostamento: number) {
    const result = await dispatch(setDiscostamentoApiCall(discostamento));
    if (result.type !== SET_SOGLIA_EDISTRIBUZIONE_SUCCESS) {
      return { resultType: "unknownError" };
    }
    return {
      resultType: "success",
    };
  };
}
