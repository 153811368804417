if (process.env.REACT_APP_APP_NASCOSTA === "true") {
  require("./indexAppNascosta");
} else {
  const { wrappedLogoSvgAsString } = require("../logo/logo");
  const { setLoadingScreenLogo } = require("src/loadingScreen");

  setLoadingScreenLogo(wrappedLogoSvgAsString);

  // We use async import to split the generated bundle file in more than one:
  // if we don't do it, we will only see the spinner for most of the time (without energix logo),
  // because the logo is inside the same big bundle as the whole app.
  // By splitting it, we will have this file and the logo in the first bundle, and the rest of the app in second bundle.
  // Doing this, we will be able to show the logo while loading the second big bundle.
  import("./indexApp");
}

// required to make this a js module
export const dummyExport = {};
export default dummyExport;
