import { CardColorName } from "./counter-card/common";

type Servizio = {
  name: string;
  type: string;
  cardColor: CardColorName;
};

export const servizi: Servizio[] = [
  {
    name: "Taratura Contatori",
    type: "taratura_contatori",
    cardColor: "lavender",
  },
  {
    name: "SPI",
    type: "scadenza_spi",
    cardColor: "papaya",
  },
  {
    name: "Fuel Mix",
    type: "scadenza_fuel_mix",
    cardColor: "white",
  },
  {
    name: "Arera - Indagine Annuale",
    type: "scadenza_arera_indagine",
    cardColor: "white",
  },
  {
    name: "Arera - Unbundling",
    type: "scadenza_arera_unbundling",
    cardColor: "papaya",
  },
  {
    name: "Arera - Controllo per funzionamento dell'autorità",
    type: "scadenza_arera_contributo_funzionamento",
    cardColor: "lavender",
  },
  {
    name: "Antimafia",
    type: "scadenza_antimafia",
    cardColor: "softPeach",
  },
  {
    name: "Registri Letture",
    type: "scadenza_registri",
    cardColor: "softPeach",
  },
];
