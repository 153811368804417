import React, { Component } from "react";
import PropTypes from "prop-types";
import ace from "../../../libs/brace";
import "brace/theme/chrome";
import "brace/ext/language_tools";
import AceEditor from "./AceEditor";
import AceModeGenerator from "common/grammar/AceModeGenerator";
import classNames from "classnames";
import { getVariablesFromContext } from "common/formule";

// uniqueIdPrefix for form tags
let nextUniqueId = 1;

class FunctionEditor extends Component {
  static propTypes = {
    context: PropTypes.object.isRequired,
    functionString: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    minLines: PropTypes.number,
    maxLines: PropTypes.number,
    width: PropTypes.any,
    height: PropTypes.any,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.uniqueIdPrefix = "FunctionEditor-unique-id-" + nextUniqueId++;
    this.customEditorSettings = this.customEditorSettings.bind(this);
    this.state = this.createState(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.context !== this.props.context) {
      this.setState(this.createState(nextProps));
    }
  }

  createState(props) {
    const { context, variable } = props;

    const variables = getVariablesFromContext(context, variable);

    const mode = AceModeGenerator.generator(variables);

    return {
      mode: mode,
    };
  }

  customEditorSettings(editor) {
    const { mode } = this.state;
    ace.config.loadModule("ace/ext/language_tools", function () {
      editor.setOptions({
        enableBasicAutocompletion: true,
        //enableSnippets: true,
        //enableSnippets: false,
        enableLiveAutocompletion: true,
      });
      if (editor.completers && editor.completers.length >= 3) {
        var completer = editor.completers[2];
        editor.completers = [
          {
            getCompletions: function (_editor, session, pos, prefix, callback) {
              var lex = mode.$tokenizer.$grammar.Lex;
              try {
                var result = completer.getCompletions(
                  editor,
                  session,
                  pos,
                  prefix,
                  function (err, results) {
                    if (results) {
                      results.forEach(function (r) {
                        if (
                          r.meta &&
                          lex[r.meta] &&
                          typeof lex[r.meta].autocompleteMeta !== "undefined"
                        ) {
                          r.meta = lex[r.meta].autocompleteMeta;
                        }
                      });
                    }
                    return callback(err, results);
                  }
                );
                return result;
              } catch (err) {
                console.log(err);
              }
              return;
            },
            identifierRegexps: [/[a-zA-Z_0-9]/],
          },
        ];
      }
    });
  }

  render() {
    const {
      functionString,
      minLines,
      maxLines,
      width,
      height,
      onChange,
      disabled,
      className,
    } = this.props;
    const { mode } = this.state;

    return (
      <div>
        <AceEditor
          className={classNames(
            className,
            disabled && "function-editor-disabled"
          )}
          value={functionString}
          onChange={onChange}
          name={this.uniqueIdPrefix + "-ace-editor"}
          width={typeof width === "number" ? width + "px" : width}
          height={typeof height === "number" ? height + "px" : height}
          // Ace settings
          mode={mode}
          theme="chrome"
          editorProps={{
            $blockScrolling: Infinity,
          }}
          setOptions={{
            useWorker: false,
          }}
          wrapEnabled={true}
          minLines={disabled ? 1 : minLines || 5}
          maxLines={maxLines || 8}
          showPrintMargin={false}
          highlightActiveLine={false}
          enableBasicAutocompletion={true}
          enableLiveAutocompletion={true}
          customEditorSettings={this.customEditorSettings}
          readOnly={disabled}
        />
      </div>
    );
  }
}

export default FunctionEditor;
