import { Azienda } from "energix-types/src/Azienda";
import moment, { Moment } from "moment/moment";

export function getScadenzaIndagineAnnuale(
  azienda: Azienda,
  today: Moment = moment()
) {
  // ARERA: Indagine annuale -> nel mese di aprile ma il giorno esatto è variabile ogni anno
  const nextScadenzeAreraIndagine = [
    moment("10-04-2020", "DD-MM-YYYY"),
    moment("01-04-2023", "DD-MM-YYYY"),
  ];

  return _getScadenzaArera(
    nextScadenzeAreraIndagine,
    azienda.ultimoTaraturaAreraIndagine,
    azienda.scadenzaOverrideTaraturaAreraIndagine,
    today
  );
}

export function getScadenzaAreraContributoFunzionamento(
  azienda: Azienda,
  today: Moment = moment()
) {
  // ARERA: Contributo per funzionamento dell'autorità -> nel mese di dicembre ma la scadenza è variabile
  const nextScadenzeAreraContributoFunzionamento = [
    moment("30-10-2020", "DD-MM-YYYY"),
    moment("01-12-2023", "DD-MM-YYYY"),
  ];

  return _getScadenzaArera(
    nextScadenzeAreraContributoFunzionamento,
    azienda.ultimoTaraturaAreraContributoFunzionamento,
    azienda.scadenzaOverrideAreraContributoFunzionamento,
    today
  );
}

function _getScadenzaArera(
  nextScadenzeArera: Moment[],
  lastDate: Date | string | null,
  scadenzaOverride: Date | string | null,
  today: Moment = moment()
) {
  // indica il prossimo anno in cui va fatta!
  const y = lastDate ? moment(lastDate).year() + 1 : today.year();

  if (scadenzaOverride) {
    nextScadenzeArera = [moment(scadenzaOverride), ...nextScadenzeArera];
  }

  for (let index = 0; index < nextScadenzeArera.length; index++) {
    const nextScadenzaArera = nextScadenzeArera[index];

    if (y <= nextScadenzaArera.year()) {
      // c'è da farla per l'anno nextScadenzaArera.year()
      return nextScadenzaArera.toDate();
    }
  }

  // non c'è ancora la data per quest'anno!
  return null;
}

export function getScadenzaAreraUnbundling(azienda: Azienda) {
  const scadenzaOverride = azienda.scadenzaOverrideTaraturaAreraUnbundling;
  return scadenzaOverride ? moment(scadenzaOverride).toDate() : null;
}
