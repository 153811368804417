import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { fNumber } from "src/components/common/formatNumber";
import TrendingUpOrDown from "src/components/elements/TrendingUpOrDown";
import { merge } from "lodash";
import moment from "moment/moment";
import ReactApexChart from "react-apexcharts";
import { ChartStyle, useBaseOptionCharts } from "src/components/common/chart";
import React from "react";

export type QuadroCardProps = {
  title: string;
  subtitle: string;
  unit?: string;
  value: number;
  valuesByMonth?: number[];
  year: number;

  previousValue?: number;
  previousValuesByMonth?: number[] | null;
  previousYear?: number;
};

export default function QuadroCard({
  title,
  subtitle,
  unit,
  value,
  valuesByMonth,
  year,

  previousValue,
  previousValuesByMonth,
  previousYear,
}: QuadroCardProps) {
  const percentChange =
    typeof previousValue === "number" && previousValue !== 0
      ? ((value - previousValue) / previousValue) * 100
      : undefined;

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader title={title} subheader={subtitle} />

      <CardContent style={{ paddingTop: "12px" }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Typography variant="h3">
              {fNumber(value)} {unit}
            </Typography>
            {percentChange && (
              <TrendingUpOrDown
                percentage={percentChange}
                trailingText="rispetto all'anno precedente"
              />
            )}
          </Stack>
          {valuesByMonth && (
            <ConsumoChart
              valuesLabel={`${year}`}
              previousValuesLabel={previousValuesByMonth && `${previousYear}`}
              values={valuesByMonth}
              previousValues={previousValuesByMonth}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

function ConsumoChart({
  valuesLabel,
  values,
  previousValuesLabel,
  previousValues,
}: {
  valuesLabel: string;
  values: number[];
  previousValuesLabel?: string | null;
  previousValues?: number[] | null;
}) {
  const theme = useTheme();
  const chartOptions = merge(useBaseOptionCharts(), {
    chart: { animations: { enabled: true }, sparkline: { enabled: true } },
    colors: [theme.palette.primary.main, theme.palette.primary.main],
    stroke: {
      width: [2, 1],
      dashArray: [0, 4],
    },
    tooltip: {
      x: {
        show: true,
        title: {
          formatter: () => "",
        },
      },
      y: [valuesLabel, previousValuesLabel].map((label) => {
        return {
          formatter: fNumber,
          title: {
            formatter: () => label,
          },
        };
      }),
      marker: { show: false },
    },
    xaxis: {
      categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) =>
        moment("2021-" + (x < 10 ? "0" : "") + x + "-01").format("MMMM")
      ),
    },
    legend: {
      show: false,
    },
  });
  return (
    <>
      <ChartStyle />
      <ReactApexChart
        type="line"
        series={
          previousValues
            ? [{ data: values }, { data: previousValues }]
            : [{ data: values }]
        }
        options={chartOptions}
        width={120}
        height={80}
      />
    </>
  );
}
