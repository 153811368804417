import { each } from "lodash";
import ace from "../../../libs/brace";
ace.require = ace.acequire;
import AceGrammar from "../../../libs/ace-grammar/build/ace_grammar";

function generator(variables, options) {
  var startFrom = "start";

  var variableTokens = [];

  each(variables, function (value, key) {
    variableTokens.push(key);
  });

  // var constants = [
  // ];

  var grammar = {
    // prefix ID for regular expressions, represented as strings, used in the grammar
    RegExpID: "RE::",

    Extra: {
      fold: "brace",
    },

    // Style model
    Style: {
      expression_operator: "keyword",

      integer: "constant.numeric",
      double: "constant.numeric",
      constant: "constant.language",

      variable: "constant.language",
    },

    // Lexical model
    Lex: {
      expression_operator: {
        tokens: "RE::/[+\\-\\*\\/\\^]/",
      },
      plus_minus_op: {
        tokens: "RE::/[+\\-]/",
      },

      integer: "RE::/[0-9]\\d*/",

      double: "RE::/\\d+\\.\\d+/",

      // constant: {
      //   autocomplete: true,
      //   tokens: constants,
      //   autocompleteMeta: ''
      // },

      variable: {
        autocomplete: variableTokens,
        tokens: "RE::/[a-zA-Z_][a-zA-Z_0-9]*/",
        autocompleteMeta: "",
      },
    },

    // Syntax model (optional)
    Syntax: {
      start: "expression{1}",

      expression: "term (expression_operator term)*",
      term: "'(' expression ')'|double|integer|variable |integer|minus_expression",

      minus_expression_term: "'(' expression ')'|variable|double|integer",
      minus_expression: "plus_minus_op minus_expression_term",
    },

    // what to parse and in what order
    Parser: [startFrom],
  };

  var mode = AceGrammar.getMode(grammar, null, ace);

  // enable user-defined code folding in the specification (new feature)
  mode.supportCodeFolding = true;

  // enable syntax lint-like validation in the grammar
  // mode.supportGrammarAnnotations = true;

  // enable user-defined autocompletion (if defined)
  mode.supportAutoCompletion = true;

  mode.autocompleter.options = {
    prefixMatch: true,
    caseInsesitiveMatch: true, //the ace_grammar code has this typo
    caseInsensitiveMatch: true,
    inContext: false,
  };

  return mode;
}

const AceMode = {
  generator: generator,
};

export default AceMode;
