import { Azienda } from "energix-types/src/Azienda";
import moment from "moment/moment";

export default function getScadenzaAntimafia({
  ultimoCaricamentoAntimafia,
}: Azienda): string | null {
  const frequenzaTaratura = 1;
  if (!ultimoCaricamentoAntimafia) {
    return null;
  }
  return moment(ultimoCaricamentoAntimafia)
    .add(frequenzaTaratura, "years")
    .format("YYYY-MM-DD");
}
