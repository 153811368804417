import { CALL_API } from "src/middleware/api";
import { useDispatch } from "react-redux";
import { AccountValidationErrors } from "../saveAccount";
import { TipologiaFiscale } from "energix-types/src/User";

export type ConfermaDatiPagamentoData = {
  ragioneSociale: string;

  partitaIva: string;
  codiceFiscale: string | null;
  codiceUnivoco: string;

  indirizzo: string;
  comune: string;
  provincia: string;
  cap: string;
  stato: string;

  pec: string;
  tipologiaFiscale: TipologiaFiscale | null;
};

export type ConfermaDatiPagamentoFunction = (
  data: ConfermaDatiPagamentoData
) => Promise<ConfermaDatiPagamentoResult>;

export type ConfermaDatiPagamentoResult =
  | ConfermaDatiPagamentoSuccess
  | {
      resultType: "validationError";
      message: string;
      errors: AccountValidationErrors;
    };

export type ConfermaDatiPagamentoSuccess = {
  resultType: "success";
};

const CONFERMA_DATI_PAGAMENTO_REQUEST = "CONFERMA_DATI_PAGAMENTO_REQUEST";
export const CONFERMA_DATI_PAGAMENTO_SUCCESS =
  "CONFERMA_DATI_PAGAMENTO_SUCCESS";
const CONFERMA_DATI_PAGAMENTO_FAILURE = "CONFERMA_DATI_PAGAMENTO_FAILURE";

const confermaDatiPagamentoApiCall =
  (data: ConfermaDatiPagamentoData) => (dispatch: any) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CONFERMA_DATI_PAGAMENTO_REQUEST,
          CONFERMA_DATI_PAGAMENTO_SUCCESS,
          CONFERMA_DATI_PAGAMENTO_FAILURE,
        ],
        endpoint: "/api/transazioniToken/confermaDatiDiPagamento",
        method: "POST",
        body: data,
      },
    });
  };

export function useConfermaDatiPagamento(): ConfermaDatiPagamentoFunction {
  const dispatch = useDispatch();
  return async function confermaDatiPagamento(
    data: ConfermaDatiPagamentoData
  ): Promise<ConfermaDatiPagamentoResult> {
    const result = await dispatch(confermaDatiPagamentoApiCall(data));
    if (result.type !== CONFERMA_DATI_PAGAMENTO_SUCCESS) {
      const error = result.response;
      return {
        resultType: "validationError",
        message: error.message,
        errors: error.errors,
      };
    }
    return {
      resultType: "success",
    };
  };
}
