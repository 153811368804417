export const SERVER_CONNECTED = "SERVER_CONNECTED";
export const SERVER_DISCONNECTED = "SERVER_DISCONNECTED";

export const serverConnected = () => (dispatch) => {
  return dispatch({
    type: SERVER_CONNECTED,
  });
};
export const serverDisconnected = () => (dispatch) => {
  return dispatch({
    type: SERVER_DISCONNECTED,
  });
};
