import { NOTIFICATION_DELETE_SUCCESS } from "energix-old/src/app/actions";
import {
  CONTEXT_FETCH_FAILURE,
  CONTEXT_FETCH_REQUEST,
  CONTEXT_FETCH_SUCCESS,
  NOTIFICATION_DEFAULT_DELETE_SUCCESS,
  REDUX_INITIAL_STATE_FETCH_SUCCESS,
  SERVICE_LICENSE_CREATE_SUCCESS,
  SERVICE_LICENSE_UPDATE_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_SIGNUP_SUCCESS,
} from "../actions";
import {
  NOTIFICA_ADMIN_CREATE_SUCCESS,
  NOTIFICA_PARTNER_CREATE_SUCCESS,
} from "../actions/notifiche/createNotifica";
import {
  NOTIFICA_ADMIN_UPDATE_SUCCESS,
  NOTIFICA_PARTNER_UPDATE_SUCCESS,
} from "../actions/notifiche/saveNotifica";
import { NOTIFICATION_SETTINGS_SET_SUCCESS } from "../actions/notifiche/saveNotificheSettings";
import { Azienda } from "energix-types/src/Azienda";
import { Notifica } from "energix-types/src/Notifica";

export default function contextReducer(state: any = {}, action: any) {
  switch (action.type) {
    case REDUX_INITIAL_STATE_FETCH_SUCCESS:
      return action.response.preloadedState.context || {};

    case CONTEXT_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case CONTEXT_FETCH_SUCCESS:
      return {
        fetched: true,
        fetchFailed: false,
        ...action.response,
      };
    case CONTEXT_FETCH_FAILURE:
      return {
        ...state,
        fetchFailed: action.response,
        fetching: false,
      };

    case SERVICE_LICENSE_CREATE_SUCCESS:
      return {
        ...state,
        serviceLicenses: [...state.serviceLicenses, action.response],
      };
    case SERVICE_LICENSE_UPDATE_SUCCESS: {
      return {
        ...state,
        serviceLicenses: state.serviceLicenses.map((s: Azienda) => {
          if (s.id === action.response.id) {
            return action.response;
          }
          return s;
        }),
      };
    }

    case NOTIFICA_PARTNER_CREATE_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, action.response.notification],
      };
    case NOTIFICA_PARTNER_UPDATE_SUCCESS: {
      return {
        ...state,
        notifications: state.notifications.map((s: Notifica) => {
          if (s.id === action.response.notification.id) {
            return action.response.notification;
          }
          return s;
        }),
      };
    }
    case NOTIFICATION_DELETE_SUCCESS: {
      return {
        ...state,
        notifications: state.notifications.filter((s: Notifica) => {
          return s.id !== action.notificationId;
        }),
      };
    }

    case NOTIFICA_ADMIN_CREATE_SUCCESS:
      return {
        ...state,
        notificationsDefault: [
          ...state.notificationsDefault,
          action.response.notification,
        ],
      };
    case NOTIFICA_ADMIN_UPDATE_SUCCESS: {
      return {
        ...state,
        notificationsDefault: state.notificationsDefault.map((s: Notifica) => {
          if (s.id === action.response.notification.id) {
            return action.response.notification;
          }
          return s;
        }),
      };
    }
    case NOTIFICATION_DEFAULT_DELETE_SUCCESS: {
      return {
        ...state,
        notificationsDefault: state.notificationsDefault.filter(
          (s: Notifica) => {
            return s.id !== action.notificationId;
          }
        ),
      };
    }

    case NOTIFICATION_SETTINGS_SET_SUCCESS: {
      return {
        ...state,
        notificationSettings: action.response.notificationSettings,
      };
    }

    case USER_LOGIN_SUCCESS:
    case USER_SIGNUP_SUCCESS:
      return {
        ...state,
        fetching: true,
        fetchFailed: false,
      };

    case USER_LOGOUT_SUCCESS:
      return {
        fetching: false,
        fetchFailed: false,
      };

    default:
      return state;
  }
}
