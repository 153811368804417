export const datiComuni = require("./datiIstatArray.json");

const _datiByComune = {};
const _datiByCodiceCatastale = {};
datiComuni.forEach((d) => {
  _datiByComune[d.comune] = d;
  _datiByCodiceCatastale[d.codiceCatastale] = d;
});

export const datiByComune = _datiByComune;
export const datiByCodiceCatastale = _datiByCodiceCatastale;
