import { Global } from "@emotion/react";
import { useTheme } from "@mui/material";

// ----------------------------------------------------------------------
const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={{
        "*": {
          // margin: 0,
          // padding: 0,
          boxSizing: "border-box",
        },
        html: {
          width: "100%",
          height: "100%",
          MsTextSizeAdjust: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          width: "100%",
          height: "100%",
          // marginTop: '70px',
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
        input: {
          "&[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
            "&::-webkit-inner-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
          },
        },
        textarea: {
          "&::-webkit-input-placeholder": {
            color: theme.palette.text.disabled,
          },
          "&::-moz-placeholder": {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
          "&:-ms-input-placeholder": { color: theme.palette.text.disabled },
          "&::placeholder": { color: theme.palette.text.disabled },
        },
        a: { color: theme.palette.primary.main },
        img: { display: "block", maxWidth: "100%" },

        // Lazy Load Img
        ".blur-up": {
          WebkitFilter: "blur(5px)",
          filter: "blur(5px)",
          transition: "filter 400ms, -webkit-filter 400ms",
        },
        ".blur-up.lazyloaded ": {
          WebkitFilter: "blur(0)",
          filter: "blur(0)",
        },

        // SCROLLBAR

        "::-webkit-scrollbar": {
          width: "var(--scroll-size, 10px)",
          height: "var(--scroll-size, 10px)",
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: "var(--scroll-track, transparent)",
          borderRadius: "var(--scroll-track-radius, var(--scroll-radius))",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "var(--scroll-thumb-color, grey)",
          backgroundImage: "var(--scroll-thumb, none)",
          borderRadius: "var(--scroll-thumb-radius, var(--scroll-radius))",
        },

        ":root": {
          "--scroll-size": "8px",
          "--scroll-radius": "8px",
          "--scroll-track": "rgb(223 223 223 / 80%)",
          "--scroll-thumb-color": "rgb(0 0 0 / 30%)",
        },
      }}
    />
  );
};

export default GlobalStyles;
