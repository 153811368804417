export enum ImpiantoTypeEnum {
  // eslint-disable-next-line no-unused-vars
  // CESSIONE_PARZIALE_COMMERCIALE = "CESSIONE_PARZIALE_COMMERCIALE",
  // eslint-disable-next-line no-unused-vars
  CESSIONE_PARZIALE_NON_COMMERCIALE = "CESSIONE_PARZIALE_NON_COMMERCIALE",
  // eslint-disable-next-line no-unused-vars
  CESSIONE_TOTALE = "CESSIONE_TOTALE",
  // eslint-disable-next-line no-unused-vars
  IMPIANTO_DI_VENDITA = "IMPIANTO_DI_VENDITA",
  // eslint-disable-next-line no-unused-vars
  USO_PROPRIO = "USO_PROPRIO",
  // eslint-disable-next-line no-unused-vars
  NON_CONFIGURATO = "NON_CONFIGURATO",
}
export type ImpiantoType = keyof typeof ImpiantoTypeEnum;

export type DefinizioneImpianto = {
  type: ImpiantoType;
  name: string;
  docsUltimaDichiarazione: boolean;
  hasPotenzaNominale: boolean;
  docsCreditiAccise: boolean;
  //letturaAnnuale: boolean;
  docsRelazioneTecnica: boolean;
  helpContatore: boolean;
  chiedeDatiAcciseAnnoPrecedente: boolean;
  chiediNumeroDiContatori: boolean;
  tipologiaDichiarante: string;
  tipoImpianto: string;
  // eslint-disable-next-line no-unused-vars
  canChangeTypeTo: { [targetType in ImpiantoType]?: boolean };
  //haCeduta: boolean;
  hidden?: "venditore";
  chiediQuadroEF?: boolean;
};

const canChangeTypeToAll = {
  CESSIONE_PARZIALE_NON_COMMERCIALE: true,
  CESSIONE_PARZIALE_COMMERCIALE: true,
  CESSIONE_TOTALE: true,
  USO_PROPRIO: true,
};

export const tipiDiImpianto: DefinizioneImpianto[] = [
  {
    type: "CESSIONE_PARZIALE_NON_COMMERCIALE",
    name: "Cessione Parziale Esente da Accisa",
    //haCeduta: true,
    //letturaAnnuale: false,
    hasPotenzaNominale: true,
    docsUltimaDichiarazione: true,
    docsCreditiAccise: false,
    docsRelazioneTecnica: false,
    helpContatore: true,
    chiedeDatiAcciseAnnoPrecedente: false,
    chiediNumeroDiContatori: true,
    //tipologiaDiCessione: (contatoreType) => contatoreType === "ceduta",
    //tipologiaDiRicezione: (contatoreType) => contatoreType === "ricevuta",
    // per generazione file
    tipologiaDichiarante: "L",
    tipoImpianto: "01",
    //tipiDiContatori: tipiDiContatoriWithCedutaEContatoriC_H,
    canChangeTypeTo: canChangeTypeToAll,
  },
  // {
  //   type: "CESSIONE_PARZIALE_COMMERCIALE",
  //   name: "Cessione Parziale Assoggettata ad Accisa",
  //   //haCeduta: true,
  //   //letturaAnnuale: false,
  //   hasPotenzaNominale: true,
  //   docsUltimaDichiarazione: true,
  //   docsCreditiAccise: true,
  //   docsRelazioneTecnica: false,
  //   helpContatore: true,
  //   chiedeDatiAcciseAnnoPrecedente: true,
  //   chiediNumeroDiContatori: true,
  //   //tipologiaDiCessione: (contatoreType) => contatoreType === "ceduta",
  //   //tipologiaDiRicezione: (contatoreType) => contatoreType === "ricevuta",
  //   // per generazione file
  //   tipologiaDichiarante: "L",
  //   tipoImpianto: "02",
  //   //tipiDiContatori: tipiDiContatoriWithCeduta,
  //   canChangeTypeTo: canChangeTypeToAll,
  //   chiediQuadroEF: true,
  // },
  {
    type: "CESSIONE_TOTALE",
    name: "Cessione Totale",
    //haCeduta: false,
    //letturaAnnuale: true,
    hasPotenzaNominale: true,
    docsUltimaDichiarazione: true,
    docsCreditiAccise: false,
    docsRelazioneTecnica: false,
    helpContatore: true,
    chiedeDatiAcciseAnnoPrecedente: false,
    chiediNumeroDiContatori: true,
    // tipologiaDiCessione: (contatoreType, reportInfo) => {
    //   if (reportInfo && reportInfo.haContatoriDiversi) {
    //     return contatoreType === "ceduta";
    //   }
    //   return true; // il contatore di produzione è anche di cessione
    // },
    // tipologiaDiRicezione: (contatoreType) => contatoreType === "ricevuta",
    // per generazione file
    tipologiaDichiarante: "N",
    tipoImpianto: "00",
    //tipiDiContatori: tipiDiContatoriOnlyProd,
    canChangeTypeTo: canChangeTypeToAll,
  },
  {
    type: "USO_PROPRIO",
    name: "Altre officine",
    //necessitaAlgoritmo: true,
    //letturaAnnuale: false,
    hasPotenzaNominale: false,
    docsUltimaDichiarazione: true,
    docsCreditiAccise: true,
    docsRelazioneTecnica: true,
    chiedeDatiAcciseAnnoPrecedente: true,
    // tipologiaDiCessione: (contatoreType) => contatoreType === "ceduta",
    // tipologiaDiRicezione: (contatoreType) => contatoreType === "ricevuta",
    //tipologiaDiConsumo: true,
    tipologiaDichiarante: "L",
    tipoImpianto: "02",
    //tipiDiContatori: tipiDiContatoriAll,
    canChangeTypeTo: canChangeTypeToAll,
    helpContatore: false, // TODO: check
    chiediNumeroDiContatori: false, // TODO: check
    chiediQuadroEF: true,
  },
  {
    type: "IMPIANTO_DI_VENDITA",
    hidden: "venditore",
    name: "Impianto di vendita",
    // TODO: per ora tanto è solo con caricamento dell'xml per l'invio
    // necessitaAlgoritmo: false,
    // letturaAnnuale: false,
    hasPotenzaNominale: false,
    docsUltimaDichiarazione: false,
    docsCreditiAccise: false,
    docsRelazioneTecnica: false,
    chiedeDatiAcciseAnnoPrecedente: false,
    // tipologiaDiCessione: contatoreType => contatoreType === 'ceduta',
    // tipologiaDiRicezione: contatoreType => contatoreType === 'ricevuta',
    // tipologiaDiConsumo: false,
    tipologiaDichiarante: "",
    tipoImpianto: "",
    // tipiDiContatori: ...,
    helpContatore: false,
    chiediNumeroDiContatori: false,
    canChangeTypeTo: {},
  },
  // }, {
  //   type: 'CESSIONE_PARZIALE_NON_COMMERCIALE_E_USO_PROPRIO',
  //   name: 'Cessione Parziale Esente da Accisa + Uso proprio',
  //   necessitaAlgoritmo: true,
  //   letturaAnnuale: false,
  //   hasPotenzaNominale: true,
  //   docsUltimaDichiarazione: true,
  //   docsCreditiAccise: true,
  //   docsRelazioneTecnica: true,
  //   chiedeDatiAcciseAnnoPrecedente: true,
  //   tipologiaDiCessione: contatoreType => contatoreType === 'ceduta',
  //   tipologiaDiRicezione: contatoreType => contatoreType === 'ricevuta',
  //   tipologiaDiConsumo: true,
  //   tipologiaDichiarante: 'L',
  //   tipoImpianto: '02',
  //   tipiDiContatori: tipiDiContatoriAll
  // }, {
  //   type: 'CESSIONE_PARZIALE_COMMERCIALE_E_USO_PROPRIO',
  //   name: 'Cessione Parziale Assoggettata ad Accisa + Uso proprio',
  //   necessitaAlgoritmo: true,
  //   letturaAnnuale: false,
  //   hasPotenzaNominale: true,
  //   docsUltimaDichiarazione: true,
  //   docsCreditiAccise: true,
  //   docsRelazioneTecnica: true,
  //   chiedeDatiAcciseAnnoPrecedente: true,
  //   tipologiaDiCessione: contatoreType => contatoreType === 'ceduta',
  //   tipologiaDiRicezione: contatoreType => contatoreType === 'ricevuta',
  //   tipologiaDiConsumo: true,
  //   tipologiaDichiarante: 'L',
  //   tipoImpianto: '02',
  //   tipiDiContatori: tipiDiContatoriAll
  // }, {
  //   type: 'CESSIONE_TOTALE_E_USO_PROPRIO',
  //   name: 'Cessione Totale + Uso proprio',
  //   necessitaAlgoritmo: true,
  //   letturaAnnuale: false,
  //   hasPotenzaNominale: true,
  //   docsUltimaDichiarazione: true,
  //   docsCreditiAccise: true,
  //   docsRelazioneTecnica: true,
  //   chiedeDatiAcciseAnnoPrecedente: true,
  //   tipologiaDiCessione: contatoreType => contatoreType === 'ceduta',
  //   tipologiaDiRicezione: contatoreType => contatoreType === 'ricevuta',
  //   tipologiaDiConsumo: true,
  //   tipologiaDichiarante: 'L',
  //   tipoImpianto: '02',
  //   tipiDiContatori: tipiDiContatoriAll
  // }
];

export const impiantiByType: { [type: string]: DefinizioneImpianto } =
  Object.fromEntries(
    tipiDiImpianto.map((impianto) => [impianto.type, impianto])
  );
