/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Energix
 * Energix API
 * OpenAPI spec version: 6.5.4
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AbilitaDisabilitaScadenzaDto,
  AcquistaTokenDto,
  CertificatoMetadataDto,
  ChangePasswordDto,
  ConfermaDatiDiPagamentoDto,
  ContatoreDto,
  CreateContrattoDto,
  CreateDichiarazioneRequestDto,
  CreateEmailDto,
  CreateImpiantoDto,
  CreatePacchettoTokenDto,
  CreatePartnerDto,
  CreateTransazioneTokenDto,
  DeleteImpiantoResponseDto,
  Dichiarazione,
  DuplicaDichiarazioneRequestDto,
  EdistribuzioneExportQuartiOrariGetDto,
  EdistribuzioneExportQuartiOrariStartDto,
  GetAccontiXlsContentDto,
  GetAllFaqDto,
  GetAllFunzionalitaDto,
  GetAvvisiParams,
  GetCertificatoMetadataParamsDto,
  GetContextDto,
  GetInitialStateParams,
  Impianto,
  ImportDichiarazioneRequestDto,
  ImportDichiarazioneXmlFirmatiRequestDto,
  ImpostazioniEmailDto,
  LogAttivitaDto,
  LoginAsUserParams,
  PostSetPartnerDto,
  PreregistraPartnerDto,
  PutAvvisoDto,
  ResetPasswordRequestDto,
  SetFunzionalitaMostrataParams,
  SetNewPasswordRequestDto,
  SetSignedXmlRequestDto,
  SetSogliaEdistribuzioneDto,
  ToggleModuloAttivoDto,
  TrasferisciImpiantoDto,
  UpdateAziendaDto,
  UpdateDichiarazioneRequestDto,
  UpdateEmailDto,
  UpdateImpiantoDto,
  UpdateImpiantoNoteDto,
  UpdateImpiantoNotificationsEnabledDto,
  UpdateImpiantoScadenzaRegistriDto,
  UpdateImpiantoSpiDto,
  UpdateImpiantoStateDto,
  UpdatePacchettoTokenDto,
  UpdateServiziAziendaRequestDto,
  UpdateTaraturaDto,
  UpdateUtenteDto,
  UtilizzaPromozione20PercentoEntroFineSettembreDto,
} from ".././models";
import { customAxiosInstance } from "../../custom-axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const create = (
  createImpiantoDto: CreateImpiantoDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Impianto>(
    {
      url: `/api/installation/create`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createImpiantoDto,
    },
    options
  );
};

export const getCreateMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof create>>,
    TError,
    { data: CreateImpiantoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof create>>,
  TError,
  { data: CreateImpiantoDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof create>>,
    { data: CreateImpiantoDto }
  > = (props) => {
    const { data } = props ?? {};

    return create(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof create>>
>;
export type CreateMutationBody = CreateImpiantoDto;
export type CreateMutationError = void;

export const useCreate = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof create>>,
    TError,
    { data: CreateImpiantoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof create>>,
  TError,
  { data: CreateImpiantoDto },
  TContext
> => {
  const mutationOptions = getCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const update = (
  updateImpiantoDto: UpdateImpiantoDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Impianto>(
    {
      url: `/api/installation/update`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateImpiantoDto,
    },
    options
  );
};

export const getUpdateMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof update>>,
    TError,
    { data: UpdateImpiantoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof update>>,
  TError,
  { data: UpdateImpiantoDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof update>>,
    { data: UpdateImpiantoDto }
  > = (props) => {
    const { data } = props ?? {};

    return update(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof update>>
>;
export type UpdateMutationBody = UpdateImpiantoDto;
export type UpdateMutationError = void;

export const useUpdate = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof update>>,
    TError,
    { data: UpdateImpiantoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof update>>,
  TError,
  { data: UpdateImpiantoDto },
  TContext
> => {
  const mutationOptions = getUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateNotificationEnabled = (
  updateImpiantoNotificationsEnabledDto: UpdateImpiantoNotificationsEnabledDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Impianto>(
    {
      url: `/api/installation/update/notificationEnabled`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateImpiantoNotificationsEnabledDto,
    },
    options
  );
};

export const getUpdateNotificationEnabledMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotificationEnabled>>,
    TError,
    { data: UpdateImpiantoNotificationsEnabledDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateNotificationEnabled>>,
  TError,
  { data: UpdateImpiantoNotificationsEnabledDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateNotificationEnabled>>,
    { data: UpdateImpiantoNotificationsEnabledDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateNotificationEnabled(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateNotificationEnabledMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateNotificationEnabled>>
>;
export type UpdateNotificationEnabledMutationBody =
  UpdateImpiantoNotificationsEnabledDto;
export type UpdateNotificationEnabledMutationError = void;

export const useUpdateNotificationEnabled = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotificationEnabled>>,
    TError,
    { data: UpdateImpiantoNotificationsEnabledDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateNotificationEnabled>>,
  TError,
  { data: UpdateImpiantoNotificationsEnabledDto },
  TContext
> => {
  const mutationOptions = getUpdateNotificationEnabledMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateInstallationState = (
  updateImpiantoStateDto: UpdateImpiantoStateDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Impianto>(
    {
      url: `/api/installation/update/installationState`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateImpiantoStateDto,
    },
    options
  );
};

export const getUpdateInstallationStateMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInstallationState>>,
    TError,
    { data: UpdateImpiantoStateDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInstallationState>>,
  TError,
  { data: UpdateImpiantoStateDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInstallationState>>,
    { data: UpdateImpiantoStateDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateInstallationState(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateInstallationStateMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateInstallationState>>
>;
export type UpdateInstallationStateMutationBody = UpdateImpiantoStateDto;
export type UpdateInstallationStateMutationError = void;

export const useUpdateInstallationState = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInstallationState>>,
    TError,
    { data: UpdateImpiantoStateDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateInstallationState>>,
  TError,
  { data: UpdateImpiantoStateDto },
  TContext
> => {
  const mutationOptions = getUpdateInstallationStateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateSPI = (
  updateImpiantoSpiDto: UpdateImpiantoSpiDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Impianto>(
    {
      url: `/api/installation/updateSPI`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateImpiantoSpiDto,
    },
    options
  );
};

export const getUpdateSPIMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSPI>>,
    TError,
    { data: UpdateImpiantoSpiDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSPI>>,
  TError,
  { data: UpdateImpiantoSpiDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSPI>>,
    { data: UpdateImpiantoSpiDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateSPI(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSPIMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSPI>>
>;
export type UpdateSPIMutationBody = UpdateImpiantoSpiDto;
export type UpdateSPIMutationError = void;

export const useUpdateSPI = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSPI>>,
    TError,
    { data: UpdateImpiantoSpiDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateSPI>>,
  TError,
  { data: UpdateImpiantoSpiDto },
  TContext
> => {
  const mutationOptions = getUpdateSPIMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateScadenzaRegistri = (
  updateImpiantoScadenzaRegistriDto: UpdateImpiantoScadenzaRegistriDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Impianto>(
    {
      url: `/api/installation/updateScadenzaRegistri`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateImpiantoScadenzaRegistriDto,
    },
    options
  );
};

export const getUpdateScadenzaRegistriMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateScadenzaRegistri>>,
    TError,
    { data: UpdateImpiantoScadenzaRegistriDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateScadenzaRegistri>>,
  TError,
  { data: UpdateImpiantoScadenzaRegistriDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateScadenzaRegistri>>,
    { data: UpdateImpiantoScadenzaRegistriDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateScadenzaRegistri(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateScadenzaRegistriMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateScadenzaRegistri>>
>;
export type UpdateScadenzaRegistriMutationBody =
  UpdateImpiantoScadenzaRegistriDto;
export type UpdateScadenzaRegistriMutationError = void;

export const useUpdateScadenzaRegistri = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateScadenzaRegistri>>,
    TError,
    { data: UpdateImpiantoScadenzaRegistriDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateScadenzaRegistri>>,
  TError,
  { data: UpdateImpiantoScadenzaRegistriDto },
  TContext
> => {
  const mutationOptions = getUpdateScadenzaRegistriMutationOptions(options);

  return useMutation(mutationOptions);
};
export const _delete = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<DeleteImpiantoResponseDto>(
    { url: `/api/installation/${id}/delete`, method: "POST" },
    options
  );
};

export const getDeleteMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof _delete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof _delete>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof _delete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return _delete(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type _DeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof _delete>>
>;

export type _DeleteMutationError = void;

export const useDelete = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof _delete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof _delete>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateNote = (
  updateImpiantoNoteDto: UpdateImpiantoNoteDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Impianto>(
    {
      url: `/api/installation/note`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateImpiantoNoteDto,
    },
    options
  );
};

export const getUpdateNoteMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNote>>,
    TError,
    { data: UpdateImpiantoNoteDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateNote>>,
  TError,
  { data: UpdateImpiantoNoteDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateNote>>,
    { data: UpdateImpiantoNoteDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateNote(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateNoteMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateNote>>
>;
export type UpdateNoteMutationBody = UpdateImpiantoNoteDto;
export type UpdateNoteMutationError = void;

export const useUpdateNote = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNote>>,
    TError,
    { data: UpdateImpiantoNoteDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateNote>>,
  TError,
  { data: UpdateImpiantoNoteDto },
  TContext
> => {
  const mutationOptions = getUpdateNoteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAdminInstallations = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/installations`, method: "GET", signal },
    options
  );
};

export const getGetAdminInstallationsQueryKey = () => {
  return [`/api/admin/installations`] as const;
};

export const getGetAdminInstallationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminInstallations>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdminInstallations>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAdminInstallationsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminInstallations>>
  > = ({ signal }) => getAdminInstallations(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminInstallations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminInstallationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminInstallations>>
>;
export type GetAdminInstallationsQueryError = unknown;

export function useGetAdminInstallations<
  TData = Awaited<ReturnType<typeof getAdminInstallations>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdminInstallations>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAdminInstallationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const trasferisciImpianto = (
  trasferisciImpiantoDto: TrasferisciImpiantoDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/impianti/trasferisci`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: trasferisciImpiantoDto,
    },
    options
  );
};

export const getTrasferisciImpiantoMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof trasferisciImpianto>>,
    TError,
    { data: TrasferisciImpiantoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof trasferisciImpianto>>,
  TError,
  { data: TrasferisciImpiantoDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof trasferisciImpianto>>,
    { data: TrasferisciImpiantoDto }
  > = (props) => {
    const { data } = props ?? {};

    return trasferisciImpianto(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TrasferisciImpiantoMutationResult = NonNullable<
  Awaited<ReturnType<typeof trasferisciImpianto>>
>;
export type TrasferisciImpiantoMutationBody = TrasferisciImpiantoDto;
export type TrasferisciImpiantoMutationError = unknown;

export const useTrasferisciImpianto = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof trasferisciImpianto>>,
    TError,
    { data: TrasferisciImpiantoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof trasferisciImpianto>>,
  TError,
  { data: TrasferisciImpiantoDto },
  TContext
> => {
  const mutationOptions = getTrasferisciImpiantoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const testImport = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/excel/fileImport/test`, method: "POST" },
    options
  );
};

export const getTestImportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof testImport>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof testImport>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof testImport>>,
    void
  > = () => {
    return testImport(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TestImportMutationResult = NonNullable<
  Awaited<ReturnType<typeof testImport>>
>;

export type TestImportMutationError = unknown;

export const useTestImport = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof testImport>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof testImport>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getTestImportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const _import = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/excel/fileImport/import`, method: "POST" },
    options
  );
};

export const getImportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof _import>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof _import>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof _import>>,
    void
  > = () => {
    return _import(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type _ImportMutationResult = NonNullable<
  Awaited<ReturnType<typeof _import>>
>;

export type _ImportMutationError = unknown;

export const useImport = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof _import>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof _import>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getImportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const sendResetEmail = (
  resetPasswordRequestDto: ResetPasswordRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/resetPassword`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: resetPasswordRequestDto,
    },
    options
  );
};

export const getSendResetEmailMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendResetEmail>>,
    TError,
    { data: ResetPasswordRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendResetEmail>>,
  TError,
  { data: ResetPasswordRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendResetEmail>>,
    { data: ResetPasswordRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return sendResetEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendResetEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendResetEmail>>
>;
export type SendResetEmailMutationBody = ResetPasswordRequestDto;
export type SendResetEmailMutationError = unknown;

export const useSendResetEmail = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendResetEmail>>,
    TError,
    { data: ResetPasswordRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendResetEmail>>,
  TError,
  { data: ResetPasswordRequestDto },
  TContext
> => {
  const mutationOptions = getSendResetEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
export const handleResetPassword = (
  resetPermlink: string,
  resetToken: string,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/resetPassword/${resetPermlink}/${resetToken}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getHandleResetPasswordQueryKey = (
  resetPermlink: string,
  resetToken: string
) => {
  return [`/resetPassword/${resetPermlink}/${resetToken}`] as const;
};

export const getHandleResetPasswordQueryOptions = <
  TData = Awaited<ReturnType<typeof handleResetPassword>>,
  TError = unknown
>(
  resetPermlink: string,
  resetToken: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof handleResetPassword>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getHandleResetPasswordQueryKey(resetPermlink, resetToken);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof handleResetPassword>>
  > = ({ signal }) =>
    handleResetPassword(resetPermlink, resetToken, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(resetPermlink && resetToken),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof handleResetPassword>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HandleResetPasswordQueryResult = NonNullable<
  Awaited<ReturnType<typeof handleResetPassword>>
>;
export type HandleResetPasswordQueryError = unknown;

export function useHandleResetPassword<
  TData = Awaited<ReturnType<typeof handleResetPassword>>,
  TError = unknown
>(
  resetPermlink: string,
  resetToken: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof handleResetPassword>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getHandleResetPasswordQueryOptions(
    resetPermlink,
    resetToken,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const handleSetNewPassword = (
  setNewPasswordRequestDto: SetNewPasswordRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/resetPassword/newPassword`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: setNewPasswordRequestDto,
    },
    options
  );
};

export const getHandleSetNewPasswordMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof handleSetNewPassword>>,
    TError,
    { data: SetNewPasswordRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof handleSetNewPassword>>,
  TError,
  { data: SetNewPasswordRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof handleSetNewPassword>>,
    { data: SetNewPasswordRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return handleSetNewPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type HandleSetNewPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof handleSetNewPassword>>
>;
export type HandleSetNewPasswordMutationBody = SetNewPasswordRequestDto;
export type HandleSetNewPasswordMutationError = unknown;

export const useHandleSetNewPassword = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof handleSetNewPassword>>,
    TError,
    { data: SetNewPasswordRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof handleSetNewPassword>>,
  TError,
  { data: SetNewPasswordRequestDto },
  TContext
> => {
  const mutationOptions = getHandleSetNewPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const handleLogin = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/login`, method: "POST" },
    options
  );
};

export const getHandleLoginMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof handleLogin>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof handleLogin>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof handleLogin>>,
    void
  > = () => {
    return handleLogin(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type HandleLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof handleLogin>>
>;

export type HandleLoginMutationError = unknown;

export const useHandleLogin = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof handleLogin>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof handleLogin>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getHandleLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
export const handleLogout = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/logout`, method: "GET", signal },
    options
  );
};

export const getHandleLogoutQueryKey = () => {
  return [`/api/logout`] as const;
};

export const getHandleLogoutQueryOptions = <
  TData = Awaited<ReturnType<typeof handleLogout>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof handleLogout>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHandleLogoutQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof handleLogout>>> = ({
    signal,
  }) => handleLogout(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof handleLogout>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HandleLogoutQueryResult = NonNullable<
  Awaited<ReturnType<typeof handleLogout>>
>;
export type HandleLogoutQueryError = unknown;

export function useHandleLogout<
  TData = Awaited<ReturnType<typeof handleLogout>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof handleLogout>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getHandleLogoutQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const loginAsUser = (
  params: LoginAsUserParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/login_as_user`, method: "GET", params, signal },
    options
  );
};

export const getLoginAsUserQueryKey = (params: LoginAsUserParams) => {
  return [`/api/admin/login_as_user`, ...(params ? [params] : [])] as const;
};

export const getLoginAsUserQueryOptions = <
  TData = Awaited<ReturnType<typeof loginAsUser>>,
  TError = unknown
>(
  params: LoginAsUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof loginAsUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLoginAsUserQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof loginAsUser>>> = ({
    signal,
  }) => loginAsUser(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof loginAsUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LoginAsUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof loginAsUser>>
>;
export type LoginAsUserQueryError = unknown;

export function useLoginAsUser<
  TData = Awaited<ReturnType<typeof loginAsUser>>,
  TError = unknown
>(
  params: LoginAsUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof loginAsUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getLoginAsUserQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getMe = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/account/me`, method: "GET", signal },
    options
  );
};

export const getGetMeQueryKey = () => {
  return [`/api/account/me`] as const;
};

export const getGetMeQueryOptions = <
  TData = Awaited<ReturnType<typeof getMe>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({
    signal,
  }) => getMe(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMe>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMeQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>;
export type GetMeQueryError = unknown;

export function useGetMe<
  TData = Awaited<ReturnType<typeof getMe>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const updateAccount = (
  updateUtenteDto: UpdateUtenteDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/account/update`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateUtenteDto,
    },
    options
  );
};

export const getUpdateAccountMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccount>>,
    TError,
    { data: UpdateUtenteDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAccount>>,
  TError,
  { data: UpdateUtenteDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAccount>>,
    { data: UpdateUtenteDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateAccount(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAccount>>
>;
export type UpdateAccountMutationBody = UpdateUtenteDto;
export type UpdateAccountMutationError = void;

export const useUpdateAccount = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccount>>,
    TError,
    { data: UpdateUtenteDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAccount>>,
  TError,
  { data: UpdateUtenteDto },
  TContext
> => {
  const mutationOptions = getUpdateAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const changePassword = (
  changePasswordDto: ChangePasswordDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/account/changePassword`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: changePasswordDto,
    },
    options
  );
};

export const getChangePasswordMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changePassword>>,
    TError,
    { data: ChangePasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changePassword>>,
  TError,
  { data: ChangePasswordDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changePassword>>,
    { data: ChangePasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return changePassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof changePassword>>
>;
export type ChangePasswordMutationBody = ChangePasswordDto;
export type ChangePasswordMutationError = void;

export const useChangePassword = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changePassword>>,
    TError,
    { data: ChangePasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof changePassword>>,
  TError,
  { data: ChangePasswordDto },
  TContext
> => {
  const mutationOptions = getChangePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createPartner = (
  createPartnerDto: CreatePartnerDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/account/createPartner`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createPartnerDto,
    },
    options
  );
};

export const getCreatePartnerMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPartner>>,
    TError,
    { data: CreatePartnerDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPartner>>,
  TError,
  { data: CreatePartnerDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPartner>>,
    { data: CreatePartnerDto }
  > = (props) => {
    const { data } = props ?? {};

    return createPartner(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePartnerMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPartner>>
>;
export type CreatePartnerMutationBody = CreatePartnerDto;
export type CreatePartnerMutationError = void;

export const useCreatePartner = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPartner>>,
    TError,
    { data: CreatePartnerDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPartner>>,
  TError,
  { data: CreatePartnerDto },
  TContext
> => {
  const mutationOptions = getCreatePartnerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const accettaCondizioniEconomiche = (
  versione: string,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/account/accettaCondizioniEconomiche/${versione}`,
      method: "POST",
    },
    options
  );
};

export const getAccettaCondizioniEconomicheMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accettaCondizioniEconomiche>>,
    TError,
    { versione: string },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accettaCondizioniEconomiche>>,
  TError,
  { versione: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accettaCondizioniEconomiche>>,
    { versione: string }
  > = (props) => {
    const { versione } = props ?? {};

    return accettaCondizioniEconomiche(versione, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccettaCondizioniEconomicheMutationResult = NonNullable<
  Awaited<ReturnType<typeof accettaCondizioniEconomiche>>
>;

export type AccettaCondizioniEconomicheMutationError = unknown;

export const useAccettaCondizioniEconomiche = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accettaCondizioniEconomiche>>,
    TError,
    { versione: string },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof accettaCondizioniEconomiche>>,
  TError,
  { versione: string },
  TContext
> => {
  const mutationOptions =
    getAccettaCondizioniEconomicheMutationOptions(options);

  return useMutation(mutationOptions);
};
export const setVenditore = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/user/setVenditore`, method: "POST" },
    options
  );
};

export const getSetVenditoreMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setVenditore>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setVenditore>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setVenditore>>,
    void
  > = () => {
    return setVenditore(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetVenditoreMutationResult = NonNullable<
  Awaited<ReturnType<typeof setVenditore>>
>;

export type SetVenditoreMutationError = unknown;

export const useSetVenditore = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setVenditore>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setVenditore>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getSetVenditoreMutationOptions(options);

  return useMutation(mutationOptions);
};
export const userControllerSetSogliaEdistribuzione = (
  setSogliaEdistribuzioneDto: SetSogliaEdistribuzioneDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/user/setSogliaEdistribuzione`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: setSogliaEdistribuzioneDto,
    },
    options
  );
};

export const getUserControllerSetSogliaEdistribuzioneMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerSetSogliaEdistribuzione>>,
    TError,
    { data: SetSogliaEdistribuzioneDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerSetSogliaEdistribuzione>>,
  TError,
  { data: SetSogliaEdistribuzioneDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerSetSogliaEdistribuzione>>,
    { data: SetSogliaEdistribuzioneDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerSetSogliaEdistribuzione(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerSetSogliaEdistribuzioneMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerSetSogliaEdistribuzione>>
>;
export type UserControllerSetSogliaEdistribuzioneMutationBody =
  SetSogliaEdistribuzioneDto;
export type UserControllerSetSogliaEdistribuzioneMutationError = unknown;

export const useUserControllerSetSogliaEdistribuzione = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerSetSogliaEdistribuzione>>,
    TError,
    { data: SetSogliaEdistribuzioneDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerSetSogliaEdistribuzione>>,
  TError,
  { data: SetSogliaEdistribuzioneDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerSetSogliaEdistribuzioneMutationOptions(options);

  return useMutation(mutationOptions);
};
export const handleVerifyLink = (
  userId: number,
  verifyToken: string,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/verify/${userId}/${verifyToken}`, method: "GET", signal },
    options
  );
};

export const getHandleVerifyLinkQueryKey = (
  userId: number,
  verifyToken: string
) => {
  return [`/verify/${userId}/${verifyToken}`] as const;
};

export const getHandleVerifyLinkQueryOptions = <
  TData = Awaited<ReturnType<typeof handleVerifyLink>>,
  TError = unknown
>(
  userId: number,
  verifyToken: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof handleVerifyLink>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getHandleVerifyLinkQueryKey(userId, verifyToken);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof handleVerifyLink>>
  > = ({ signal }) =>
    handleVerifyLink(userId, verifyToken, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(userId && verifyToken),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof handleVerifyLink>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HandleVerifyLinkQueryResult = NonNullable<
  Awaited<ReturnType<typeof handleVerifyLink>>
>;
export type HandleVerifyLinkQueryError = unknown;

export function useHandleVerifyLink<
  TData = Awaited<ReturnType<typeof handleVerifyLink>>,
  TError = unknown
>(
  userId: number,
  verifyToken: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof handleVerifyLink>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getHandleVerifyLinkQueryOptions(
    userId,
    verifyToken,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAdminClients = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/clients`, method: "GET", signal },
    options
  );
};

export const getGetAdminClientsQueryKey = () => {
  return [`/api/admin/clients`] as const;
};

export const getGetAdminClientsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminClients>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdminClients>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAdminClientsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminClients>>> = ({
    signal,
  }) => getAdminClients(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminClients>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminClientsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminClients>>
>;
export type GetAdminClientsQueryError = unknown;

export function useGetAdminClients<
  TData = Awaited<ReturnType<typeof getAdminClients>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdminClients>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAdminClientsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const enableUser = (
  userId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/client/${userId}/enable`, method: "GET", signal },
    options
  );
};

export const getEnableUserQueryKey = (userId: number) => {
  return [`/api/admin/client/${userId}/enable`] as const;
};

export const getEnableUserQueryOptions = <
  TData = Awaited<ReturnType<typeof enableUser>>,
  TError = unknown
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof enableUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getEnableUserQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof enableUser>>> = ({
    signal,
  }) => enableUser(userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof enableUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type EnableUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof enableUser>>
>;
export type EnableUserQueryError = unknown;

export function useEnableUser<
  TData = Awaited<ReturnType<typeof enableUser>>,
  TError = unknown
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof enableUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getEnableUserQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const disableUser = (
  userId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/client/${userId}/disable`, method: "GET", signal },
    options
  );
};

export const getDisableUserQueryKey = (userId: number) => {
  return [`/api/admin/client/${userId}/disable`] as const;
};

export const getDisableUserQueryOptions = <
  TData = Awaited<ReturnType<typeof disableUser>>,
  TError = unknown
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof disableUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDisableUserQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof disableUser>>> = ({
    signal,
  }) => disableUser(userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof disableUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DisableUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof disableUser>>
>;
export type DisableUserQueryError = unknown;

export function useDisableUser<
  TData = Awaited<ReturnType<typeof disableUser>>,
  TError = unknown
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof disableUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDisableUserQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const blockCattivoPagatore = (
  userId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/admin/client/${userId}/blockCattivoPagatore`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getBlockCattivoPagatoreQueryKey = (userId: number) => {
  return [`/api/admin/client/${userId}/blockCattivoPagatore`] as const;
};

export const getBlockCattivoPagatoreQueryOptions = <
  TData = Awaited<ReturnType<typeof blockCattivoPagatore>>,
  TError = unknown
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof blockCattivoPagatore>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBlockCattivoPagatoreQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof blockCattivoPagatore>>
  > = ({ signal }) => blockCattivoPagatore(userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof blockCattivoPagatore>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BlockCattivoPagatoreQueryResult = NonNullable<
  Awaited<ReturnType<typeof blockCattivoPagatore>>
>;
export type BlockCattivoPagatoreQueryError = unknown;

export function useBlockCattivoPagatore<
  TData = Awaited<ReturnType<typeof blockCattivoPagatore>>,
  TError = unknown
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof blockCattivoPagatore>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getBlockCattivoPagatoreQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const deleteCliente = (
  userId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/client/${userId}/delete`, method: "GET", signal },
    options
  );
};

export const getDeleteClienteQueryKey = (userId: number) => {
  return [`/api/admin/client/${userId}/delete`] as const;
};

export const getDeleteClienteQueryOptions = <
  TData = Awaited<ReturnType<typeof deleteCliente>>,
  TError = unknown
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof deleteCliente>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDeleteClienteQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof deleteCliente>>> = ({
    signal,
  }) => deleteCliente(userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof deleteCliente>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DeleteClienteQueryResult = NonNullable<
  Awaited<ReturnType<typeof deleteCliente>>
>;
export type DeleteClienteQueryError = unknown;

export function useDeleteCliente<
  TData = Awaited<ReturnType<typeof deleteCliente>>,
  TError = unknown
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof deleteCliente>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDeleteClienteQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const setPartnerRelax = (
  postSetPartnerDto: PostSetPartnerDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/service/setPartnerRelax`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postSetPartnerDto,
    },
    options
  );
};

export const getSetPartnerRelaxMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setPartnerRelax>>,
    TError,
    { data: PostSetPartnerDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setPartnerRelax>>,
  TError,
  { data: PostSetPartnerDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setPartnerRelax>>,
    { data: PostSetPartnerDto }
  > = (props) => {
    const { data } = props ?? {};

    return setPartnerRelax(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetPartnerRelaxMutationResult = NonNullable<
  Awaited<ReturnType<typeof setPartnerRelax>>
>;
export type SetPartnerRelaxMutationBody = PostSetPartnerDto;
export type SetPartnerRelaxMutationError = unknown;

export const useSetPartnerRelax = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setPartnerRelax>>,
    TError,
    { data: PostSetPartnerDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setPartnerRelax>>,
  TError,
  { data: PostSetPartnerDto },
  TContext
> => {
  const mutationOptions = getSetPartnerRelaxMutationOptions(options);

  return useMutation(mutationOptions);
};
export const preregistraUtente = (
  preregistraPartnerDto: PreregistraPartnerDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/user/preregistrazione`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: preregistraPartnerDto,
    },
    options
  );
};

export const getPreregistraUtenteMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof preregistraUtente>>,
    TError,
    { data: PreregistraPartnerDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof preregistraUtente>>,
  TError,
  { data: PreregistraPartnerDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof preregistraUtente>>,
    { data: PreregistraPartnerDto }
  > = (props) => {
    const { data } = props ?? {};

    return preregistraUtente(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PreregistraUtenteMutationResult = NonNullable<
  Awaited<ReturnType<typeof preregistraUtente>>
>;
export type PreregistraUtenteMutationBody = PreregistraPartnerDto;
export type PreregistraUtenteMutationError = unknown;

export const usePreregistraUtente = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof preregistraUtente>>,
    TError,
    { data: PreregistraPartnerDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof preregistraUtente>>,
  TError,
  { data: PreregistraPartnerDto },
  TContext
> => {
  const mutationOptions = getPreregistraUtenteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const completaPreregistrazione = (
  id: number,
  createPartnerDto: CreatePartnerDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/user/${id}/completaPreregistrazione`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: createPartnerDto,
    },
    options
  );
};

export const getCompletaPreregistrazioneMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof completaPreregistrazione>>,
    TError,
    { id: number; data: CreatePartnerDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof completaPreregistrazione>>,
  TError,
  { id: number; data: CreatePartnerDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof completaPreregistrazione>>,
    { id: number; data: CreatePartnerDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return completaPreregistrazione(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompletaPreregistrazioneMutationResult = NonNullable<
  Awaited<ReturnType<typeof completaPreregistrazione>>
>;
export type CompletaPreregistrazioneMutationBody = CreatePartnerDto;
export type CompletaPreregistrazioneMutationError = void;

export const useCompletaPreregistrazione = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof completaPreregistrazione>>,
    TError,
    { id: number; data: CreatePartnerDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof completaPreregistrazione>>,
  TError,
  { id: number; data: CreatePartnerDto },
  TContext
> => {
  const mutationOptions = getCompletaPreregistrazioneMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createNotification = (
  createEmailDto: CreateEmailDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/notification/create`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createEmailDto,
    },
    options
  );
};

export const getCreateNotificationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNotification>>,
    TError,
    { data: CreateEmailDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createNotification>>,
  TError,
  { data: CreateEmailDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createNotification>>,
    { data: CreateEmailDto }
  > = (props) => {
    const { data } = props ?? {};

    return createNotification(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateNotificationMutationResult = NonNullable<
  Awaited<ReturnType<typeof createNotification>>
>;
export type CreateNotificationMutationBody = CreateEmailDto;
export type CreateNotificationMutationError = unknown;

export const useCreateNotification = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNotification>>,
    TError,
    { data: CreateEmailDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createNotification>>,
  TError,
  { data: CreateEmailDto },
  TContext
> => {
  const mutationOptions = getCreateNotificationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateNotification = (
  updateEmailDto: UpdateEmailDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/notification/update`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateEmailDto,
    },
    options
  );
};

export const getUpdateNotificationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotification>>,
    TError,
    { data: UpdateEmailDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateNotification>>,
  TError,
  { data: UpdateEmailDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateNotification>>,
    { data: UpdateEmailDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateNotification(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateNotificationMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateNotification>>
>;
export type UpdateNotificationMutationBody = UpdateEmailDto;
export type UpdateNotificationMutationError = unknown;

export const useUpdateNotification = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotification>>,
    TError,
    { data: UpdateEmailDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateNotification>>,
  TError,
  { data: UpdateEmailDto },
  TContext
> => {
  const mutationOptions = getUpdateNotificationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteNotification = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/notification/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteNotificationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotification>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteNotification>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteNotification>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteNotification(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteNotificationMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteNotification>>
>;

export type DeleteNotificationMutationError = unknown;

export const useDeleteNotification = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotification>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteNotification>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteNotificationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateNotificationSettings = (
  impostazioniEmailDto: ImpostazioniEmailDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/notificationSettings/set`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: impostazioniEmailDto,
    },
    options
  );
};

export const getUpdateNotificationSettingsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotificationSettings>>,
    TError,
    { data: ImpostazioniEmailDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateNotificationSettings>>,
  TError,
  { data: ImpostazioniEmailDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateNotificationSettings>>,
    { data: ImpostazioniEmailDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateNotificationSettings(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateNotificationSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateNotificationSettings>>
>;
export type UpdateNotificationSettingsMutationBody = ImpostazioniEmailDto;
export type UpdateNotificationSettingsMutationError = unknown;

export const useUpdateNotificationSettings = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotificationSettings>>,
    TError,
    { data: ImpostazioniEmailDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateNotificationSettings>>,
  TError,
  { data: ImpostazioniEmailDto },
  TContext
> => {
  const mutationOptions = getUpdateNotificationSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createNotificationDefault = (
  createEmailDto: CreateEmailDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/notification-default/create`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createEmailDto,
    },
    options
  );
};

export const getCreateNotificationDefaultMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNotificationDefault>>,
    TError,
    { data: CreateEmailDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createNotificationDefault>>,
  TError,
  { data: CreateEmailDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createNotificationDefault>>,
    { data: CreateEmailDto }
  > = (props) => {
    const { data } = props ?? {};

    return createNotificationDefault(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateNotificationDefaultMutationResult = NonNullable<
  Awaited<ReturnType<typeof createNotificationDefault>>
>;
export type CreateNotificationDefaultMutationBody = CreateEmailDto;
export type CreateNotificationDefaultMutationError = unknown;

export const useCreateNotificationDefault = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNotificationDefault>>,
    TError,
    { data: CreateEmailDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createNotificationDefault>>,
  TError,
  { data: CreateEmailDto },
  TContext
> => {
  const mutationOptions = getCreateNotificationDefaultMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateNotificationDefault = (
  updateEmailDto: UpdateEmailDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/notification-default/update`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateEmailDto,
    },
    options
  );
};

export const getUpdateNotificationDefaultMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotificationDefault>>,
    TError,
    { data: UpdateEmailDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateNotificationDefault>>,
  TError,
  { data: UpdateEmailDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateNotificationDefault>>,
    { data: UpdateEmailDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateNotificationDefault(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateNotificationDefaultMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateNotificationDefault>>
>;
export type UpdateNotificationDefaultMutationBody = UpdateEmailDto;
export type UpdateNotificationDefaultMutationError = unknown;

export const useUpdateNotificationDefault = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotificationDefault>>,
    TError,
    { data: UpdateEmailDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateNotificationDefault>>,
  TError,
  { data: UpdateEmailDto },
  TContext
> => {
  const mutationOptions = getUpdateNotificationDefaultMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteNotificationDefault = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/notification-default/${id}`, method: "POST" },
    options
  );
};

export const getDeleteNotificationDefaultMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotificationDefault>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteNotificationDefault>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteNotificationDefault>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteNotificationDefault(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteNotificationDefaultMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteNotificationDefault>>
>;

export type DeleteNotificationDefaultMutationError = unknown;

export const useDeleteNotificationDefault = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotificationDefault>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteNotificationDefault>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteNotificationDefaultMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getByFileId = (
  fileId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/notificationIcon/${fileId}`, method: "GET", signal },
    options
  );
};

export const getGetByFileIdQueryKey = (fileId: number) => {
  return [`/notificationIcon/${fileId}`] as const;
};

export const getGetByFileIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getByFileId>>,
  TError = unknown
>(
  fileId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getByFileId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetByFileIdQueryKey(fileId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getByFileId>>> = ({
    signal,
  }) => getByFileId(fileId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!fileId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getByFileId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetByFileIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getByFileId>>
>;
export type GetByFileIdQueryError = unknown;

export function useGetByFileId<
  TData = Awaited<ReturnType<typeof getByFileId>>,
  TError = unknown
>(
  fileId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getByFileId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetByFileIdQueryOptions(fileId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getByFileIdAndFileName = (
  fileId: number,
  fileName: string,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/notificationIcon/${fileId}/${fileName}`, method: "GET", signal },
    options
  );
};

export const getGetByFileIdAndFileNameQueryKey = (
  fileId: number,
  fileName: string
) => {
  return [`/notificationIcon/${fileId}/${fileName}`] as const;
};

export const getGetByFileIdAndFileNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getByFileIdAndFileName>>,
  TError = unknown
>(
  fileId: number,
  fileName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getByFileIdAndFileName>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetByFileIdAndFileNameQueryKey(fileId, fileName);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getByFileIdAndFileName>>
  > = ({ signal }) =>
    getByFileIdAndFileName(fileId, fileName, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(fileId && fileName),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getByFileIdAndFileName>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetByFileIdAndFileNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof getByFileIdAndFileName>>
>;
export type GetByFileIdAndFileNameQueryError = unknown;

export function useGetByFileIdAndFileName<
  TData = Awaited<ReturnType<typeof getByFileIdAndFileName>>,
  TError = unknown
>(
  fileId: number,
  fileName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getByFileIdAndFileName>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetByFileIdAndFileNameQueryOptions(
    fileId,
    fileName,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getByImpiantoId = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/notificationSent/installation/${id}`, method: "GET", signal },
    options
  );
};

export const getGetByImpiantoIdQueryKey = (id: number) => {
  return [`/api/notificationSent/installation/${id}`] as const;
};

export const getGetByImpiantoIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getByImpiantoId>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getByImpiantoId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetByImpiantoIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getByImpiantoId>>> = ({
    signal,
  }) => getByImpiantoId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getByImpiantoId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetByImpiantoIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getByImpiantoId>>
>;
export type GetByImpiantoIdQueryError = unknown;

export function useGetByImpiantoId<
  TData = Awaited<ReturnType<typeof getByImpiantoId>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getByImpiantoId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetByImpiantoIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getLog = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/notificationSent/log`, method: "GET", signal },
    options
  );
};

export const getGetLogQueryKey = () => {
  return [`/api/notificationSent/log`] as const;
};

export const getGetLogQueryOptions = <
  TData = Awaited<ReturnType<typeof getLog>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getLog>>, TError, TData>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLogQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLog>>> = ({
    signal,
  }) => getLog(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLog>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLogQueryResult = NonNullable<Awaited<ReturnType<typeof getLog>>>;
export type GetLogQueryError = unknown;

export function useGetLog<
  TData = Awaited<ReturnType<typeof getLog>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getLog>>, TError, TData>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLogQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const resendEmail = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/notificationSent/${id}/sendEmail`, method: "POST" },
    options
  );
};

export const getResendEmailMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resendEmail>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resendEmail>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resendEmail>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return resendEmail(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResendEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof resendEmail>>
>;

export type ResendEmailMutationError = unknown;

export const useResendEmail = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resendEmail>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof resendEmail>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getResendEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
export const testSmtp = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/notificationEmailTest/testSmtp`, method: "POST" },
    options
  );
};

export const getTestSmtpMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof testSmtp>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof testSmtp>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof testSmtp>>,
    void
  > = () => {
    return testSmtp(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TestSmtpMutationResult = NonNullable<
  Awaited<ReturnType<typeof testSmtp>>
>;

export type TestSmtpMutationError = unknown;

export const useTestSmtp = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof testSmtp>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof testSmtp>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getTestSmtpMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateTaratura = (
  updateTaraturaDto: UpdateTaraturaDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/contatori/updateTaratura`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateTaraturaDto,
    },
    options
  );
};

export const getUpdateTaraturaMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTaratura>>,
    TError,
    { data: UpdateTaraturaDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTaratura>>,
  TError,
  { data: UpdateTaraturaDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTaratura>>,
    { data: UpdateTaraturaDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateTaratura(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTaraturaMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTaratura>>
>;
export type UpdateTaraturaMutationBody = UpdateTaraturaDto;
export type UpdateTaraturaMutationError = void;

export const useUpdateTaratura = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTaratura>>,
    TError,
    { data: UpdateTaraturaDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTaratura>>,
  TError,
  { data: UpdateTaraturaDto },
  TContext
> => {
  const mutationOptions = getUpdateTaraturaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAdminContatori = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/contatori`, method: "GET", signal },
    options
  );
};

export const getGetAdminContatoriQueryKey = () => {
  return [`/api/admin/contatori`] as const;
};

export const getGetAdminContatoriQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminContatori>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdminContatori>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAdminContatoriQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminContatori>>
  > = ({ signal }) => getAdminContatori(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminContatori>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminContatoriQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminContatori>>
>;
export type GetAdminContatoriQueryError = unknown;

export function useGetAdminContatori<
  TData = Awaited<ReturnType<typeof getAdminContatori>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdminContatori>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAdminContatoriQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const importLettureControllerTestImport = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/excel/fileImportLetture/test`, method: "POST" },
    options
  );
};

export const getImportLettureControllerTestImportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importLettureControllerTestImport>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof importLettureControllerTestImport>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof importLettureControllerTestImport>>,
    void
  > = () => {
    return importLettureControllerTestImport(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportLettureControllerTestImportMutationResult = NonNullable<
  Awaited<ReturnType<typeof importLettureControllerTestImport>>
>;

export type ImportLettureControllerTestImportMutationError = unknown;

export const useImportLettureControllerTestImport = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importLettureControllerTestImport>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof importLettureControllerTestImport>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getImportLettureControllerTestImportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const importLettureControllerImport = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/excel/fileImportLetture/import`, method: "POST" },
    options
  );
};

export const getImportLettureControllerImportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importLettureControllerImport>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof importLettureControllerImport>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof importLettureControllerImport>>,
    void
  > = () => {
    return importLettureControllerImport(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportLettureControllerImportMutationResult = NonNullable<
  Awaited<ReturnType<typeof importLettureControllerImport>>
>;

export type ImportLettureControllerImportMutationError = unknown;

export const useImportLettureControllerImport = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importLettureControllerImport>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof importLettureControllerImport>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getImportLettureControllerImportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getFilePrecompilato = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/excel/fileImportLetture/precompilato`, method: "GET", signal },
    options
  );
};

export const getGetFilePrecompilatoQueryKey = () => {
  return [`/api/excel/fileImportLetture/precompilato`] as const;
};

export const getGetFilePrecompilatoQueryOptions = <
  TData = Awaited<ReturnType<typeof getFilePrecompilato>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getFilePrecompilato>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFilePrecompilatoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFilePrecompilato>>
  > = ({ signal }) => getFilePrecompilato(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFilePrecompilato>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFilePrecompilatoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFilePrecompilato>>
>;
export type GetFilePrecompilatoQueryError = unknown;

export function useGetFilePrecompilato<
  TData = Awaited<ReturnType<typeof getFilePrecompilato>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getFilePrecompilato>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetFilePrecompilatoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getContatori = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ContatoreDto[]>(
    { url: `/api/contatore`, method: "GET", signal },
    options
  );
};

export const getGetContatoriQueryKey = () => {
  return [`/api/contatore`] as const;
};

export const getGetContatoriQueryOptions = <
  TData = Awaited<ReturnType<typeof getContatori>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getContatori>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContatoriQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContatori>>> = ({
    signal,
  }) => getContatori(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getContatori>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetContatoriQueryResult = NonNullable<
  Awaited<ReturnType<typeof getContatori>>
>;
export type GetContatoriQueryError = unknown;

export function useGetContatori<
  TData = Awaited<ReturnType<typeof getContatori>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getContatori>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetContatoriQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const upload = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/uploadFiles`, method: "POST" },
    options
  );
};

export const getUploadMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof upload>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof upload>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof upload>>,
    void
  > = () => {
    return upload(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof upload>>
>;

export type UploadMutationError = unknown;

export const useUpload = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof upload>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof upload>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getUploadMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getFileInfo = (
  fileId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/file/${fileId}/info`, method: "GET", signal },
    options
  );
};

export const getGetFileInfoQueryKey = (fileId: number) => {
  return [`/file/${fileId}/info`] as const;
};

export const getGetFileInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getFileInfo>>,
  TError = unknown
>(
  fileId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFileInfo>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFileInfoQueryKey(fileId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFileInfo>>> = ({
    signal,
  }) => getFileInfo(fileId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!fileId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFileInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFileInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFileInfo>>
>;
export type GetFileInfoQueryError = unknown;

export function useGetFileInfo<
  TData = Awaited<ReturnType<typeof getFileInfo>>,
  TError = unknown
>(
  fileId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFileInfo>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetFileInfoQueryOptions(fileId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const downloadFile = (
  fileId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/file/${fileId}/download`, method: "GET", signal },
    options
  );
};

export const getDownloadFileQueryKey = (fileId: number) => {
  return [`/file/${fileId}/download`] as const;
};

export const getDownloadFileQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFile>>,
  TError = unknown
>(
  fileId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFile>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDownloadFileQueryKey(fileId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadFile>>> = ({
    signal,
  }) => downloadFile(fileId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!fileId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadFile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadFileQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFile>>
>;
export type DownloadFileQueryError = unknown;

export function useDownloadFile<
  TData = Awaited<ReturnType<typeof downloadFile>>,
  TError = unknown
>(
  fileId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFile>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadFileQueryOptions(fileId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const downloadFileWithName = (
  fileId: number,
  fileName: string,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/file/${fileId}/download/${fileName}`, method: "GET", signal },
    options
  );
};

export const getDownloadFileWithNameQueryKey = (
  fileId: number,
  fileName: string
) => {
  return [`/file/${fileId}/download/${fileName}`] as const;
};

export const getDownloadFileWithNameQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFileWithName>>,
  TError = unknown
>(
  fileId: number,
  fileName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFileWithName>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDownloadFileWithNameQueryKey(fileId, fileName);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadFileWithName>>
  > = ({ signal }) =>
    downloadFileWithName(fileId, fileName, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(fileId && fileName),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadFileWithName>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadFileWithNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFileWithName>>
>;
export type DownloadFileWithNameQueryError = unknown;

export function useDownloadFileWithName<
  TData = Awaited<ReturnType<typeof downloadFileWithName>>,
  TError = unknown
>(
  fileId: number,
  fileName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFileWithName>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadFileWithNameQueryOptions(
    fileId,
    fileName,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAcconti = (
  dichiarazioneId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<number[]>(
    { url: `/api/report/${dichiarazioneId}/acconti`, method: "GET", signal },
    options
  );
};

export const getGetAccontiQueryKey = (dichiarazioneId: number) => {
  return [`/api/report/${dichiarazioneId}/acconti`] as const;
};

export const getGetAccontiQueryOptions = <
  TData = Awaited<ReturnType<typeof getAcconti>>,
  TError = unknown
>(
  dichiarazioneId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAcconti>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAccontiQueryKey(dichiarazioneId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAcconti>>> = ({
    signal,
  }) => getAcconti(dichiarazioneId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!dichiarazioneId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAcconti>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAccontiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAcconti>>
>;
export type GetAccontiQueryError = unknown;

export function useGetAcconti<
  TData = Awaited<ReturnType<typeof getAcconti>>,
  TError = unknown
>(
  dichiarazioneId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAcconti>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAccontiQueryOptions(dichiarazioneId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAccontiXlsContent = (
  dichiarazioneId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetAccontiXlsContentDto>(
    {
      url: `/api/report/${dichiarazioneId}/accontiXlsContent`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetAccontiXlsContentQueryKey = (dichiarazioneId: number) => {
  return [`/api/report/${dichiarazioneId}/accontiXlsContent`] as const;
};

export const getGetAccontiXlsContentQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccontiXlsContent>>,
  TError = unknown
>(
  dichiarazioneId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccontiXlsContent>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAccontiXlsContentQueryKey(dichiarazioneId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAccontiXlsContent>>
  > = ({ signal }) =>
    getAccontiXlsContent(dichiarazioneId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!dichiarazioneId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccontiXlsContent>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAccontiXlsContentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccontiXlsContent>>
>;
export type GetAccontiXlsContentQueryError = unknown;

export function useGetAccontiXlsContent<
  TData = Awaited<ReturnType<typeof getAccontiXlsContent>>,
  TError = unknown
>(
  dichiarazioneId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccontiXlsContent>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAccontiXlsContentQueryOptions(
    dichiarazioneId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const fileImportTest = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/dichiarazione/fileImport/test`, method: "POST" },
    options
  );
};

export const getFileImportTestMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fileImportTest>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof fileImportTest>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fileImportTest>>,
    void
  > = () => {
    return fileImportTest(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FileImportTestMutationResult = NonNullable<
  Awaited<ReturnType<typeof fileImportTest>>
>;

export type FileImportTestMutationError = unknown;

export const useFileImportTest = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fileImportTest>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof fileImportTest>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getFileImportTestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fileImport = (
  importDichiarazioneRequestDto: ImportDichiarazioneRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/dichiarazione/fileImport/import`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: importDichiarazioneRequestDto,
    },
    options
  );
};

export const getFileImportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fileImport>>,
    TError,
    { data: ImportDichiarazioneRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof fileImport>>,
  TError,
  { data: ImportDichiarazioneRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fileImport>>,
    { data: ImportDichiarazioneRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return fileImport(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FileImportMutationResult = NonNullable<
  Awaited<ReturnType<typeof fileImport>>
>;
export type FileImportMutationBody = ImportDichiarazioneRequestDto;
export type FileImportMutationError = unknown;

export const useFileImport = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fileImport>>,
    TError,
    { data: ImportDichiarazioneRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof fileImport>>,
  TError,
  { data: ImportDichiarazioneRequestDto },
  TContext
> => {
  const mutationOptions = getFileImportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const importXmlFirmati = (
  importDichiarazioneXmlFirmatiRequestDto: ImportDichiarazioneXmlFirmatiRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/report/import/fileXmlFirmati`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: importDichiarazioneXmlFirmatiRequestDto,
    },
    options
  );
};

export const getImportXmlFirmatiMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importXmlFirmati>>,
    TError,
    { data: ImportDichiarazioneXmlFirmatiRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof importXmlFirmati>>,
  TError,
  { data: ImportDichiarazioneXmlFirmatiRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof importXmlFirmati>>,
    { data: ImportDichiarazioneXmlFirmatiRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return importXmlFirmati(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportXmlFirmatiMutationResult = NonNullable<
  Awaited<ReturnType<typeof importXmlFirmati>>
>;
export type ImportXmlFirmatiMutationBody =
  ImportDichiarazioneXmlFirmatiRequestDto;
export type ImportXmlFirmatiMutationError = unknown;

export const useImportXmlFirmati = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importXmlFirmati>>,
    TError,
    { data: ImportDichiarazioneXmlFirmatiRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof importXmlFirmati>>,
  TError,
  { data: ImportDichiarazioneXmlFirmatiRequestDto },
  TContext
> => {
  const mutationOptions = getImportXmlFirmatiMutationOptions(options);

  return useMutation(mutationOptions);
};
export const dichiarazioniControllerCreate = (
  createDichiarazioneRequestDto: CreateDichiarazioneRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/report/create`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createDichiarazioneRequestDto,
    },
    options
  );
};

export const getDichiarazioniControllerCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dichiarazioniControllerCreate>>,
    TError,
    { data: CreateDichiarazioneRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dichiarazioniControllerCreate>>,
  TError,
  { data: CreateDichiarazioneRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dichiarazioniControllerCreate>>,
    { data: CreateDichiarazioneRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return dichiarazioniControllerCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DichiarazioniControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof dichiarazioniControllerCreate>>
>;
export type DichiarazioniControllerCreateMutationBody =
  CreateDichiarazioneRequestDto;
export type DichiarazioniControllerCreateMutationError = unknown;

export const useDichiarazioniControllerCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dichiarazioniControllerCreate>>,
    TError,
    { data: CreateDichiarazioneRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof dichiarazioniControllerCreate>>,
  TError,
  { data: CreateDichiarazioneRequestDto },
  TContext
> => {
  const mutationOptions =
    getDichiarazioniControllerCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const duplica = (
  duplicaDichiarazioneRequestDto: DuplicaDichiarazioneRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/report/duplica`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: duplicaDichiarazioneRequestDto,
    },
    options
  );
};

export const getDuplicaMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof duplica>>,
    TError,
    { data: DuplicaDichiarazioneRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof duplica>>,
  TError,
  { data: DuplicaDichiarazioneRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof duplica>>,
    { data: DuplicaDichiarazioneRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return duplica(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DuplicaMutationResult = NonNullable<
  Awaited<ReturnType<typeof duplica>>
>;
export type DuplicaMutationBody = DuplicaDichiarazioneRequestDto;
export type DuplicaMutationError = unknown;

export const useDuplica = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof duplica>>,
    TError,
    { data: DuplicaDichiarazioneRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof duplica>>,
  TError,
  { data: DuplicaDichiarazioneRequestDto },
  TContext
> => {
  const mutationOptions = getDuplicaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateReport = (
  updateDichiarazioneRequestDto: UpdateDichiarazioneRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/report/update`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateDichiarazioneRequestDto,
    },
    options
  );
};

export const getUpdateReportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReport>>,
    TError,
    { data: UpdateDichiarazioneRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReport>>,
  TError,
  { data: UpdateDichiarazioneRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReport>>,
    { data: UpdateDichiarazioneRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateReport(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateReport>>
>;
export type UpdateReportMutationBody = UpdateDichiarazioneRequestDto;
export type UpdateReportMutationError = unknown;

export const useUpdateReport = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReport>>,
    TError,
    { data: UpdateDichiarazioneRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateReport>>,
  TError,
  { data: UpdateDichiarazioneRequestDto },
  TContext
> => {
  const mutationOptions = getUpdateReportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateSetSignedXml = (
  setSignedXmlRequestDto: SetSignedXmlRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/report/update/setSignedXml`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: setSignedXmlRequestDto,
    },
    options
  );
};

export const getUpdateSetSignedXmlMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSetSignedXml>>,
    TError,
    { data: SetSignedXmlRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSetSignedXml>>,
  TError,
  { data: SetSignedXmlRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSetSignedXml>>,
    { data: SetSignedXmlRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateSetSignedXml(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSetSignedXmlMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSetSignedXml>>
>;
export type UpdateSetSignedXmlMutationBody = SetSignedXmlRequestDto;
export type UpdateSetSignedXmlMutationError = unknown;

export const useUpdateSetSignedXml = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSetSignedXml>>,
    TError,
    { data: SetSignedXmlRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateSetSignedXml>>,
  TError,
  { data: SetSignedXmlRequestDto },
  TContext
> => {
  const mutationOptions = getUpdateSetSignedXmlMutationOptions(options);

  return useMutation(mutationOptions);
};
export const rettificaDichiarazione = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/report/${id}/rettifica`, method: "POST" },
    options
  );
};

export const getRettificaDichiarazioneMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rettificaDichiarazione>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rettificaDichiarazione>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rettificaDichiarazione>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return rettificaDichiarazione(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RettificaDichiarazioneMutationResult = NonNullable<
  Awaited<ReturnType<typeof rettificaDichiarazione>>
>;

export type RettificaDichiarazioneMutationError = unknown;

export const useRettificaDichiarazione = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rettificaDichiarazione>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof rettificaDichiarazione>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getRettificaDichiarazioneMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteReport = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/report/${id}/delete`, method: "POST" },
    options
  );
};

export const getDeleteReportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReport>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReport>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReport>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteReport(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReport>>
>;

export type DeleteReportMutationError = unknown;

export const useDeleteReport = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReport>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReport>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteReportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const sendClientWebsiteLettureLinkByEmail = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/report/${id}/sendClientWebsiteLettureLinkByEmail`,
      method: "POST",
    },
    options
  );
};

export const getSendClientWebsiteLettureLinkByEmailMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendClientWebsiteLettureLinkByEmail>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendClientWebsiteLettureLinkByEmail>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendClientWebsiteLettureLinkByEmail>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return sendClientWebsiteLettureLinkByEmail(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendClientWebsiteLettureLinkByEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendClientWebsiteLettureLinkByEmail>>
>;

export type SendClientWebsiteLettureLinkByEmailMutationError = unknown;

export const useSendClientWebsiteLettureLinkByEmail = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendClientWebsiteLettureLinkByEmail>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendClientWebsiteLettureLinkByEmail>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions =
    getSendClientWebsiteLettureLinkByEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
export const sendClientWebsiteFirmaXmlLinkByEmail = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/report/${id}/sendClientWebsiteFirmaXmlLinkByEmail`,
      method: "POST",
    },
    options
  );
};

export const getSendClientWebsiteFirmaXmlLinkByEmailMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendClientWebsiteFirmaXmlLinkByEmail>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendClientWebsiteFirmaXmlLinkByEmail>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendClientWebsiteFirmaXmlLinkByEmail>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return sendClientWebsiteFirmaXmlLinkByEmail(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendClientWebsiteFirmaXmlLinkByEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendClientWebsiteFirmaXmlLinkByEmail>>
>;

export type SendClientWebsiteFirmaXmlLinkByEmailMutationError = unknown;

export const useSendClientWebsiteFirmaXmlLinkByEmail = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendClientWebsiteFirmaXmlLinkByEmail>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendClientWebsiteFirmaXmlLinkByEmail>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions =
    getSendClientWebsiteFirmaXmlLinkByEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
export const circolareMarzo2024 = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/report/circolareMarzo2024`, method: "POST" },
    options
  );
};

export const getCircolareMarzo2024MutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof circolareMarzo2024>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof circolareMarzo2024>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof circolareMarzo2024>>,
    void
  > = () => {
    return circolareMarzo2024(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CircolareMarzo2024MutationResult = NonNullable<
  Awaited<ReturnType<typeof circolareMarzo2024>>
>;

export type CircolareMarzo2024MutationError = unknown;

export const useCircolareMarzo2024 = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof circolareMarzo2024>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof circolareMarzo2024>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getCircolareMarzo2024MutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadMassivoFilesFinali = (
  commaSeparatedDichiarazioniIds: string,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/download/private/report/file_finali/${commaSeparatedDichiarazioniIds}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getDownloadMassivoFilesFinaliQueryKey = (
  commaSeparatedDichiarazioniIds: string
) => {
  return [
    `/api/download/private/report/file_finali/${commaSeparatedDichiarazioniIds}`,
  ] as const;
};

export const getDownloadMassivoFilesFinaliQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadMassivoFilesFinali>>,
  TError = unknown
>(
  commaSeparatedDichiarazioniIds: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadMassivoFilesFinali>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadMassivoFilesFinaliQueryKey(commaSeparatedDichiarazioniIds);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadMassivoFilesFinali>>
  > = ({ signal }) =>
    downloadMassivoFilesFinali(
      commaSeparatedDichiarazioniIds,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!commaSeparatedDichiarazioniIds,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadMassivoFilesFinali>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadMassivoFilesFinaliQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadMassivoFilesFinali>>
>;
export type DownloadMassivoFilesFinaliQueryError = unknown;

export function useDownloadMassivoFilesFinali<
  TData = Awaited<ReturnType<typeof downloadMassivoFilesFinali>>,
  TError = unknown
>(
  commaSeparatedDichiarazioniIds: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadMassivoFilesFinali>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadMassivoFilesFinaliQueryOptions(
    commaSeparatedDichiarazioniIds,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const downloadPdfPreviewWithReportData = (
  dichiarazione: Dichiarazione,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Blob>(
    {
      url: `/api/download/private/report/report-unsaved/preview/pdf`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: dichiarazione,
      responseType: "blob",
    },
    options
  );
};

export const getDownloadPdfPreviewWithReportDataMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadPdfPreviewWithReportData>>,
    TError,
    { data: Dichiarazione },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof downloadPdfPreviewWithReportData>>,
  TError,
  { data: Dichiarazione },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof downloadPdfPreviewWithReportData>>,
    { data: Dichiarazione }
  > = (props) => {
    const { data } = props ?? {};

    return downloadPdfPreviewWithReportData(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DownloadPdfPreviewWithReportDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof downloadPdfPreviewWithReportData>>
>;
export type DownloadPdfPreviewWithReportDataMutationBody = Dichiarazione;
export type DownloadPdfPreviewWithReportDataMutationError = unknown;

export const useDownloadPdfPreviewWithReportData = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadPdfPreviewWithReportData>>,
    TError,
    { data: Dichiarazione },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof downloadPdfPreviewWithReportData>>,
  TError,
  { data: Dichiarazione },
  TContext
> => {
  const mutationOptions =
    getDownloadPdfPreviewWithReportDataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadRiepilogoAccisewWithReportData = (
  dichiarazione: Dichiarazione,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Blob>(
    {
      url: `/api/download/private/report/report-unsaved/riepilogo_accise`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: dichiarazione,
      responseType: "blob",
    },
    options
  );
};

export const getDownloadRiepilogoAccisewWithReportDataMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadRiepilogoAccisewWithReportData>>,
    TError,
    { data: Dichiarazione },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof downloadRiepilogoAccisewWithReportData>>,
  TError,
  { data: Dichiarazione },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof downloadRiepilogoAccisewWithReportData>>,
    { data: Dichiarazione }
  > = (props) => {
    const { data } = props ?? {};

    return downloadRiepilogoAccisewWithReportData(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DownloadRiepilogoAccisewWithReportDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof downloadRiepilogoAccisewWithReportData>>
>;
export type DownloadRiepilogoAccisewWithReportDataMutationBody = Dichiarazione;
export type DownloadRiepilogoAccisewWithReportDataMutationError = unknown;

export const useDownloadRiepilogoAccisewWithReportData = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadRiepilogoAccisewWithReportData>>,
    TError,
    { data: Dichiarazione },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof downloadRiepilogoAccisewWithReportData>>,
  TError,
  { data: Dichiarazione },
  TContext
> => {
  const mutationOptions =
    getDownloadRiepilogoAccisewWithReportDataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadPdfPreview = (
  reportId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/download/private/report/${reportId}/preview/pdf`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getDownloadPdfPreviewQueryKey = (reportId: number) => {
  return [`/api/download/private/report/${reportId}/preview/pdf`] as const;
};

export const getDownloadPdfPreviewQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadPdfPreview>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadPdfPreview>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDownloadPdfPreviewQueryKey(reportId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadPdfPreview>>
  > = ({ signal }) => downloadPdfPreview(reportId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!reportId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadPdfPreview>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadPdfPreviewQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadPdfPreview>>
>;
export type DownloadPdfPreviewQueryError = unknown;

export function useDownloadPdfPreview<
  TData = Awaited<ReturnType<typeof downloadPdfPreview>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadPdfPreview>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadPdfPreviewQueryOptions(reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const downloadDicPreview = (
  reportId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/download/private/report/${reportId}/preview/dic`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getDownloadDicPreviewQueryKey = (reportId: number) => {
  return [`/api/download/private/report/${reportId}/preview/dic`] as const;
};

export const getDownloadDicPreviewQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadDicPreview>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadDicPreview>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDownloadDicPreviewQueryKey(reportId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadDicPreview>>
  > = ({ signal }) => downloadDicPreview(reportId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!reportId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadDicPreview>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadDicPreviewQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadDicPreview>>
>;
export type DownloadDicPreviewQueryError = unknown;

export function useDownloadDicPreview<
  TData = Awaited<ReturnType<typeof downloadDicPreview>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadDicPreview>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadDicPreviewQueryOptions(reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const downloadC01Preview = (
  reportId: number,
  progressivoGiorno: string,
  datePart: string,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/download/private/report/${reportId}/preview/c01/${progressivoGiorno}/${datePart}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getDownloadC01PreviewQueryKey = (
  reportId: number,
  progressivoGiorno: string,
  datePart: string
) => {
  return [
    `/api/download/private/report/${reportId}/preview/c01/${progressivoGiorno}/${datePart}`,
  ] as const;
};

export const getDownloadC01PreviewQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadC01Preview>>,
  TError = unknown
>(
  reportId: number,
  progressivoGiorno: string,
  datePart: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadC01Preview>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadC01PreviewQueryKey(reportId, progressivoGiorno, datePart);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadC01Preview>>
  > = ({ signal }) =>
    downloadC01Preview(
      reportId,
      progressivoGiorno,
      datePart,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(reportId && progressivoGiorno && datePart),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadC01Preview>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadC01PreviewQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadC01Preview>>
>;
export type DownloadC01PreviewQueryError = unknown;

export function useDownloadC01Preview<
  TData = Awaited<ReturnType<typeof downloadC01Preview>>,
  TError = unknown
>(
  reportId: number,
  progressivoGiorno: string,
  datePart: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadC01Preview>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadC01PreviewQueryOptions(
    reportId,
    progressivoGiorno,
    datePart,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const downloadRiepilogoAccise = (
  reportId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/download/private/report/${reportId}/riepilogo_accise`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getDownloadRiepilogoAcciseQueryKey = (reportId: number) => {
  return [`/api/download/private/report/${reportId}/riepilogo_accise`] as const;
};

export const getDownloadRiepilogoAcciseQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadRiepilogoAccise>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadRiepilogoAccise>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDownloadRiepilogoAcciseQueryKey(reportId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadRiepilogoAccise>>
  > = ({ signal }) => downloadRiepilogoAccise(reportId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!reportId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadRiepilogoAccise>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadRiepilogoAcciseQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadRiepilogoAccise>>
>;
export type DownloadRiepilogoAcciseQueryError = unknown;

export function useDownloadRiepilogoAccise<
  TData = Awaited<ReturnType<typeof downloadRiepilogoAccise>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadRiepilogoAccise>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadRiepilogoAcciseQueryOptions(
    reportId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const downloadFilesFinali = (
  reportId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/download/private/report/${reportId}/file_finali`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getDownloadFilesFinaliQueryKey = (reportId: number) => {
  return [`/api/download/private/report/${reportId}/file_finali`] as const;
};

export const getDownloadFilesFinaliQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFilesFinali>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFilesFinali>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDownloadFilesFinaliQueryKey(reportId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadFilesFinali>>
  > = ({ signal }) => downloadFilesFinali(reportId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!reportId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadFilesFinali>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadFilesFinaliQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFilesFinali>>
>;
export type DownloadFilesFinaliQueryError = unknown;

export function useDownloadFilesFinali<
  TData = Awaited<ReturnType<typeof downloadFilesFinali>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFilesFinali>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadFilesFinaliQueryOptions(reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const downloadFileXml = (
  reportId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/download/private/report/${reportId}/preview/pdf/from-xml/fileXmlId`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getDownloadFileXmlQueryKey = (reportId: number) => {
  return [
    `/api/download/private/report/${reportId}/preview/pdf/from-xml/fileXmlId`,
  ] as const;
};

export const getDownloadFileXmlQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFileXml>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFileXml>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDownloadFileXmlQueryKey(reportId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadFileXml>>> = ({
    signal,
  }) => downloadFileXml(reportId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!reportId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadFileXml>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadFileXmlQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFileXml>>
>;
export type DownloadFileXmlQueryError = unknown;

export function useDownloadFileXml<
  TData = Awaited<ReturnType<typeof downloadFileXml>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFileXml>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadFileXmlQueryOptions(reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const downloadSignedFileXml = (
  reportId: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/download/private/report/${reportId}/preview/pdf/from-xml/fileXmlSignedId`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getDownloadSignedFileXmlQueryKey = (reportId: number) => {
  return [
    `/api/download/private/report/${reportId}/preview/pdf/from-xml/fileXmlSignedId`,
  ] as const;
};

export const getDownloadSignedFileXmlQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadSignedFileXml>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadSignedFileXml>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDownloadSignedFileXmlQueryKey(reportId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadSignedFileXml>>
  > = ({ signal }) => downloadSignedFileXml(reportId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!reportId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadSignedFileXml>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadSignedFileXmlQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadSignedFileXml>>
>;
export type DownloadSignedFileXmlQueryError = unknown;

export function useDownloadSignedFileXml<
  TData = Awaited<ReturnType<typeof downloadSignedFileXml>>,
  TError = unknown
>(
  reportId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadSignedFileXml>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadSignedFileXmlQueryOptions(reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const sendReale = (
  reportId: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/report/${reportId}/send/reale`, method: "POST" },
    options
  );
};

export const getSendRealeMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendReale>>,
    TError,
    { reportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendReale>>,
  TError,
  { reportId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendReale>>,
    { reportId: number }
  > = (props) => {
    const { reportId } = props ?? {};

    return sendReale(reportId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendRealeMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendReale>>
>;

export type SendRealeMutationError = unknown;

export const useSendReale = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendReale>>,
    TError,
    { reportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendReale>>,
  TError,
  { reportId: number },
  TContext
> => {
  const mutationOptions = getSendRealeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const sendProva = (
  reportId: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/report/${reportId}/send/prova`, method: "POST" },
    options
  );
};

export const getSendProvaMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendProva>>,
    TError,
    { reportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendProva>>,
  TError,
  { reportId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendProva>>,
    { reportId: number }
  > = (props) => {
    const { reportId } = props ?? {};

    return sendProva(reportId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendProvaMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendProva>>
>;

export type SendProvaMutationError = unknown;

export const useSendProva = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendProva>>,
    TError,
    { reportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendProva>>,
  TError,
  { reportId: number },
  TContext
> => {
  const mutationOptions = getSendProvaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const singReport = (
  reportId: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/report/${reportId}/sign`, method: "POST" },
    options
  );
};

export const getSingReportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof singReport>>,
    TError,
    { reportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof singReport>>,
  TError,
  { reportId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof singReport>>,
    { reportId: number }
  > = (props) => {
    const { reportId } = props ?? {};

    return singReport(reportId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SingReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof singReport>>
>;

export type SingReportMutationError = unknown;

export const useSingReport = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof singReport>>,
    TError,
    { reportId: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof singReport>>,
  TError,
  { reportId: number },
  TContext
> => {
  const mutationOptions = getSingReportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const exportDichiarazioniAdmin = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/export/dichiarazioni.xlsx`, method: "GET", signal },
    options
  );
};

export const getExportDichiarazioniAdminQueryKey = () => {
  return [`/api/admin/export/dichiarazioni.xlsx`] as const;
};

export const getExportDichiarazioniAdminQueryOptions = <
  TData = Awaited<ReturnType<typeof exportDichiarazioniAdmin>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof exportDichiarazioniAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getExportDichiarazioniAdminQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof exportDichiarazioniAdmin>>
  > = ({ signal }) => exportDichiarazioniAdmin(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exportDichiarazioniAdmin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExportDichiarazioniAdminQueryResult = NonNullable<
  Awaited<ReturnType<typeof exportDichiarazioniAdmin>>
>;
export type ExportDichiarazioniAdminQueryError = unknown;

export function useExportDichiarazioniAdmin<
  TData = Awaited<ReturnType<typeof exportDichiarazioniAdmin>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof exportDichiarazioniAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getExportDichiarazioniAdminQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const exportDichiarazioniPartner = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/partner/export/dichiarazioni.xlsx`, method: "GET", signal },
    options
  );
};

export const getExportDichiarazioniPartnerQueryKey = () => {
  return [`/api/partner/export/dichiarazioni.xlsx`] as const;
};

export const getExportDichiarazioniPartnerQueryOptions = <
  TData = Awaited<ReturnType<typeof exportDichiarazioniPartner>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof exportDichiarazioniPartner>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getExportDichiarazioniPartnerQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof exportDichiarazioniPartner>>
  > = ({ signal }) => exportDichiarazioniPartner(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exportDichiarazioniPartner>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExportDichiarazioniPartnerQueryResult = NonNullable<
  Awaited<ReturnType<typeof exportDichiarazioniPartner>>
>;
export type ExportDichiarazioniPartnerQueryError = unknown;

export function useExportDichiarazioniPartner<
  TData = Awaited<ReturnType<typeof exportDichiarazioniPartner>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof exportDichiarazioniPartner>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getExportDichiarazioniPartnerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getCertificatoMetadata = (
  getCertificatoMetadataParamsDto: GetCertificatoMetadataParamsDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<CertificatoMetadataDto>(
    {
      url: `/api/certificato`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: getCertificatoMetadataParamsDto,
    },
    options
  );
};

export const getGetCertificatoMetadataMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCertificatoMetadata>>,
    TError,
    { data: GetCertificatoMetadataParamsDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getCertificatoMetadata>>,
  TError,
  { data: GetCertificatoMetadataParamsDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCertificatoMetadata>>,
    { data: GetCertificatoMetadataParamsDto }
  > = (props) => {
    const { data } = props ?? {};

    return getCertificatoMetadata(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetCertificatoMetadataMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCertificatoMetadata>>
>;
export type GetCertificatoMetadataMutationBody =
  GetCertificatoMetadataParamsDto;
export type GetCertificatoMetadataMutationError = void;

export const useGetCertificatoMetadata = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCertificatoMetadata>>,
    TError,
    { data: GetCertificatoMetadataParamsDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getCertificatoMetadata>>,
  TError,
  { data: GetCertificatoMetadataParamsDto },
  TContext
> => {
  const mutationOptions = getGetCertificatoMetadataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateAzienda = (
  updateAziendaDto: UpdateAziendaDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/serviceLicense/update`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateAziendaDto,
    },
    options
  );
};

export const getUpdateAziendaMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAzienda>>,
    TError,
    { data: UpdateAziendaDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAzienda>>,
  TError,
  { data: UpdateAziendaDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAzienda>>,
    { data: UpdateAziendaDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateAzienda(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAziendaMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAzienda>>
>;
export type UpdateAziendaMutationBody = UpdateAziendaDto;
export type UpdateAziendaMutationError = unknown;

export const useUpdateAzienda = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAzienda>>,
    TError,
    { data: UpdateAziendaDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAzienda>>,
  TError,
  { data: UpdateAziendaDto },
  TContext
> => {
  const mutationOptions = getUpdateAziendaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteAzienda = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/serviceLicense/${id}/delete`, method: "POST" },
    options
  );
};

export const getDeleteAziendaMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAzienda>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAzienda>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAzienda>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteAzienda(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAziendaMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAzienda>>
>;

export type DeleteAziendaMutationError = unknown;

export const useDeleteAzienda = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAzienda>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAzienda>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteAziendaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateServiziAzienda = (
  servizioType: string,
  updateServiziAziendaRequestDto: UpdateServiziAziendaRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/serviceLicense/updateServizio/${servizioType}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateServiziAziendaRequestDto,
    },
    options
  );
};

export const getUpdateServiziAziendaMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateServiziAzienda>>,
    TError,
    { servizioType: string; data: UpdateServiziAziendaRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateServiziAzienda>>,
  TError,
  { servizioType: string; data: UpdateServiziAziendaRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateServiziAzienda>>,
    { servizioType: string; data: UpdateServiziAziendaRequestDto }
  > = (props) => {
    const { servizioType, data } = props ?? {};

    return updateServiziAzienda(servizioType, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateServiziAziendaMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateServiziAzienda>>
>;
export type UpdateServiziAziendaMutationBody = UpdateServiziAziendaRequestDto;
export type UpdateServiziAziendaMutationError = unknown;

export const useUpdateServiziAzienda = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateServiziAzienda>>,
    TError,
    { servizioType: string; data: UpdateServiziAziendaRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateServiziAzienda>>,
  TError,
  { servizioType: string; data: UpdateServiziAziendaRequestDto },
  TContext
> => {
  const mutationOptions = getUpdateServiziAziendaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAziendePerServizi = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/aziendePerServizi`, method: "GET", signal },
    options
  );
};

export const getGetAziendePerServiziQueryKey = () => {
  return [`/api/admin/aziendePerServizi`] as const;
};

export const getGetAziendePerServiziQueryOptions = <
  TData = Awaited<ReturnType<typeof getAziendePerServizi>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAziendePerServizi>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAziendePerServiziQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAziendePerServizi>>
  > = ({ signal }) => getAziendePerServizi(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAziendePerServizi>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAziendePerServiziQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAziendePerServizi>>
>;
export type GetAziendePerServiziQueryError = unknown;

export function useGetAziendePerServizi<
  TData = Awaited<ReturnType<typeof getAziendePerServizi>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAziendePerServizi>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAziendePerServiziQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getLetturePerAzienda = (
  idAzienda: number,
  idImpianto: number,
  anno: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/azienda/${idAzienda}/impianto/${idImpianto}/letture/${anno}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetLetturePerAziendaQueryKey = (
  idAzienda: number,
  idImpianto: number,
  anno: number
) => {
  return [
    `/api/azienda/${idAzienda}/impianto/${idImpianto}/letture/${anno}`,
  ] as const;
};

export const getGetLetturePerAziendaQueryOptions = <
  TData = Awaited<ReturnType<typeof getLetturePerAzienda>>,
  TError = unknown
>(
  idAzienda: number,
  idImpianto: number,
  anno: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLetturePerAzienda>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLetturePerAziendaQueryKey(idAzienda, idImpianto, anno);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLetturePerAzienda>>
  > = ({ signal }) =>
    getLetturePerAzienda(idAzienda, idImpianto, anno, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(idAzienda && idImpianto && anno),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLetturePerAzienda>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLetturePerAziendaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLetturePerAzienda>>
>;
export type GetLetturePerAziendaQueryError = unknown;

export function useGetLetturePerAzienda<
  TData = Awaited<ReturnType<typeof getLetturePerAzienda>>,
  TError = unknown
>(
  idAzienda: number,
  idImpianto: number,
  anno: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLetturePerAzienda>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLetturePerAziendaQueryOptions(
    idAzienda,
    idImpianto,
    anno,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getLetturePerAziendaAlreadyPaid = (
  idAzienda: number,
  idImpianto: number,
  anno: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/azienda/${idAzienda}/impianto/${idImpianto}/letture/${anno}/paid`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetLetturePerAziendaAlreadyPaidQueryKey = (
  idAzienda: number,
  idImpianto: number,
  anno: number
) => {
  return [
    `/api/azienda/${idAzienda}/impianto/${idImpianto}/letture/${anno}/paid`,
  ] as const;
};

export const getGetLetturePerAziendaAlreadyPaidQueryOptions = <
  TData = Awaited<ReturnType<typeof getLetturePerAziendaAlreadyPaid>>,
  TError = unknown
>(
  idAzienda: number,
  idImpianto: number,
  anno: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLetturePerAziendaAlreadyPaid>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLetturePerAziendaAlreadyPaidQueryKey(idAzienda, idImpianto, anno);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLetturePerAziendaAlreadyPaid>>
  > = ({ signal }) =>
    getLetturePerAziendaAlreadyPaid(
      idAzienda,
      idImpianto,
      anno,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(idAzienda && idImpianto && anno),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLetturePerAziendaAlreadyPaid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLetturePerAziendaAlreadyPaidQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLetturePerAziendaAlreadyPaid>>
>;
export type GetLetturePerAziendaAlreadyPaidQueryError = unknown;

export function useGetLetturePerAziendaAlreadyPaid<
  TData = Awaited<ReturnType<typeof getLetturePerAziendaAlreadyPaid>>,
  TError = unknown
>(
  idAzienda: number,
  idImpianto: number,
  anno: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLetturePerAziendaAlreadyPaid>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLetturePerAziendaAlreadyPaidQueryOptions(
    idAzienda,
    idImpianto,
    anno,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const startEdistribuzioneExportQuartiOrari = (
  edistribuzioneExportQuartiOrariStartDto: EdistribuzioneExportQuartiOrariStartDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<EdistribuzioneExportQuartiOrariGetDto>(
    {
      url: `/api/edistribuzione-export-quarti-orari`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: edistribuzioneExportQuartiOrariStartDto,
    },
    options
  );
};

export const getStartEdistribuzioneExportQuartiOrariMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startEdistribuzioneExportQuartiOrari>>,
    TError,
    { data: EdistribuzioneExportQuartiOrariStartDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof startEdistribuzioneExportQuartiOrari>>,
  TError,
  { data: EdistribuzioneExportQuartiOrariStartDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startEdistribuzioneExportQuartiOrari>>,
    { data: EdistribuzioneExportQuartiOrariStartDto }
  > = (props) => {
    const { data } = props ?? {};

    return startEdistribuzioneExportQuartiOrari(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartEdistribuzioneExportQuartiOrariMutationResult = NonNullable<
  Awaited<ReturnType<typeof startEdistribuzioneExportQuartiOrari>>
>;
export type StartEdistribuzioneExportQuartiOrariMutationBody =
  EdistribuzioneExportQuartiOrariStartDto;
export type StartEdistribuzioneExportQuartiOrariMutationError = unknown;

export const useStartEdistribuzioneExportQuartiOrari = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startEdistribuzioneExportQuartiOrari>>,
    TError,
    { data: EdistribuzioneExportQuartiOrariStartDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof startEdistribuzioneExportQuartiOrari>>,
  TError,
  { data: EdistribuzioneExportQuartiOrariStartDto },
  TContext
> => {
  const mutationOptions =
    getStartEdistribuzioneExportQuartiOrariMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getEdistribuzioneExportQuartiOrari = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<EdistribuzioneExportQuartiOrariGetDto>(
    {
      url: `/api/edistribuzione-export-quarti-orari/${id}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetEdistribuzioneExportQuartiOrariQueryKey = (id: number) => {
  return [`/api/edistribuzione-export-quarti-orari/${id}`] as const;
};

export const getGetEdistribuzioneExportQuartiOrariQueryOptions = <
  TData = Awaited<ReturnType<typeof getEdistribuzioneExportQuartiOrari>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEdistribuzioneExportQuartiOrari>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetEdistribuzioneExportQuartiOrariQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEdistribuzioneExportQuartiOrari>>
  > = ({ signal }) =>
    getEdistribuzioneExportQuartiOrari(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getEdistribuzioneExportQuartiOrari>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEdistribuzioneExportQuartiOrariQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEdistribuzioneExportQuartiOrari>>
>;
export type GetEdistribuzioneExportQuartiOrariQueryError = unknown;

export function useGetEdistribuzioneExportQuartiOrari<
  TData = Awaited<ReturnType<typeof getEdistribuzioneExportQuartiOrari>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEdistribuzioneExportQuartiOrari>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetEdistribuzioneExportQuartiOrariQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPacchettiToken = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/pacchettiToken`, method: "GET", signal },
    options
  );
};

export const getGetPacchettiTokenQueryKey = () => {
  return [`/api/pacchettiToken`] as const;
};

export const getGetPacchettiTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof getPacchettiToken>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPacchettiToken>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPacchettiTokenQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPacchettiToken>>
  > = ({ signal }) => getPacchettiToken(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPacchettiToken>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPacchettiTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPacchettiToken>>
>;
export type GetPacchettiTokenQueryError = unknown;

export function useGetPacchettiToken<
  TData = Awaited<ReturnType<typeof getPacchettiToken>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPacchettiToken>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPacchettiTokenQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createPacchetto = (
  createPacchettoTokenDto: CreatePacchettoTokenDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/pacchettiToken`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createPacchettoTokenDto,
    },
    options
  );
};

export const getCreatePacchettoMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPacchetto>>,
    TError,
    { data: CreatePacchettoTokenDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPacchetto>>,
  TError,
  { data: CreatePacchettoTokenDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPacchetto>>,
    { data: CreatePacchettoTokenDto }
  > = (props) => {
    const { data } = props ?? {};

    return createPacchetto(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePacchettoMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPacchetto>>
>;
export type CreatePacchettoMutationBody = CreatePacchettoTokenDto;
export type CreatePacchettoMutationError = unknown;

export const useCreatePacchetto = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPacchetto>>,
    TError,
    { data: CreatePacchettoTokenDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPacchetto>>,
  TError,
  { data: CreatePacchettoTokenDto },
  TContext
> => {
  const mutationOptions = getCreatePacchettoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPacchettiTokenPaginaPubblica = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/pacchettiToken/public`, method: "GET", signal },
    options
  );
};

export const getGetPacchettiTokenPaginaPubblicaQueryKey = () => {
  return [`/api/pacchettiToken/public`] as const;
};

export const getGetPacchettiTokenPaginaPubblicaQueryOptions = <
  TData = Awaited<ReturnType<typeof getPacchettiTokenPaginaPubblica>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPacchettiTokenPaginaPubblica>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPacchettiTokenPaginaPubblicaQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPacchettiTokenPaginaPubblica>>
  > = ({ signal }) => getPacchettiTokenPaginaPubblica(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPacchettiTokenPaginaPubblica>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPacchettiTokenPaginaPubblicaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPacchettiTokenPaginaPubblica>>
>;
export type GetPacchettiTokenPaginaPubblicaQueryError = unknown;

export function useGetPacchettiTokenPaginaPubblica<
  TData = Awaited<ReturnType<typeof getPacchettiTokenPaginaPubblica>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPacchettiTokenPaginaPubblica>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPacchettiTokenPaginaPubblicaQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const updatePacchetto = (
  id: number,
  updatePacchettoTokenDto: UpdatePacchettoTokenDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/pacchettiToken/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updatePacchettoTokenDto,
    },
    options
  );
};

export const getUpdatePacchettoMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePacchetto>>,
    TError,
    { id: number; data: UpdatePacchettoTokenDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePacchetto>>,
  TError,
  { id: number; data: UpdatePacchettoTokenDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePacchetto>>,
    { id: number; data: UpdatePacchettoTokenDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updatePacchetto(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePacchettoMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePacchetto>>
>;
export type UpdatePacchettoMutationBody = UpdatePacchettoTokenDto;
export type UpdatePacchettoMutationError = unknown;

export const useUpdatePacchetto = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePacchetto>>,
    TError,
    { id: number; data: UpdatePacchettoTokenDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePacchetto>>,
  TError,
  { id: number; data: UpdatePacchettoTokenDto },
  TContext
> => {
  const mutationOptions = getUpdatePacchettoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deletePacchetto = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/pacchettiToken/${id}`, method: "DELETE" },
    options
  );
};

export const getDeletePacchettoMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePacchetto>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePacchetto>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePacchetto>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deletePacchetto(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePacchettoMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePacchetto>>
>;

export type DeletePacchettoMutationError = unknown;

export const useDeletePacchetto = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePacchetto>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deletePacchetto>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeletePacchettoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getTransazioni = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/transazioniToken`, method: "GET", signal },
    options
  );
};

export const getGetTransazioniQueryKey = () => {
  return [`/api/transazioniToken`] as const;
};

export const getGetTransazioniQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransazioni>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTransazioni>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransazioniQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransazioni>>> = ({
    signal,
  }) => getTransazioni(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransazioni>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTransazioniQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransazioni>>
>;
export type GetTransazioniQueryError = unknown;

export function useGetTransazioni<
  TData = Awaited<ReturnType<typeof getTransazioni>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTransazioni>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTransazioniQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createTransazione = (
  createTransazioneTokenDto: CreateTransazioneTokenDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/transazioniToken`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createTransazioneTokenDto,
    },
    options
  );
};

export const getCreateTransazioneMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTransazione>>,
    TError,
    { data: CreateTransazioneTokenDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTransazione>>,
  TError,
  { data: CreateTransazioneTokenDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTransazione>>,
    { data: CreateTransazioneTokenDto }
  > = (props) => {
    const { data } = props ?? {};

    return createTransazione(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTransazioneMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTransazione>>
>;
export type CreateTransazioneMutationBody = CreateTransazioneTokenDto;
export type CreateTransazioneMutationError = unknown;

export const useCreateTransazione = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTransazione>>,
    TError,
    { data: CreateTransazioneTokenDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTransazione>>,
  TError,
  { data: CreateTransazioneTokenDto },
  TContext
> => {
  const mutationOptions = getCreateTransazioneMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getRicaricheAdmin = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/transazioniToken/ricariche`, method: "GET", signal },
    options
  );
};

export const getGetRicaricheAdminQueryKey = () => {
  return [`/api/transazioniToken/ricariche`] as const;
};

export const getGetRicaricheAdminQueryOptions = <
  TData = Awaited<ReturnType<typeof getRicaricheAdmin>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getRicaricheAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRicaricheAdminQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRicaricheAdmin>>
  > = ({ signal }) => getRicaricheAdmin(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRicaricheAdmin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRicaricheAdminQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRicaricheAdmin>>
>;
export type GetRicaricheAdminQueryError = unknown;

export function useGetRicaricheAdmin<
  TData = Awaited<ReturnType<typeof getRicaricheAdmin>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getRicaricheAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetRicaricheAdminQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const confermaDatiDiPagamento = (
  confermaDatiDiPagamentoDto: ConfermaDatiDiPagamentoDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/transazioniToken/confermaDatiDiPagamento`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: confermaDatiDiPagamentoDto,
    },
    options
  );
};

export const getConfermaDatiDiPagamentoMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confermaDatiDiPagamento>>,
    TError,
    { data: ConfermaDatiDiPagamentoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof confermaDatiDiPagamento>>,
  TError,
  { data: ConfermaDatiDiPagamentoDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof confermaDatiDiPagamento>>,
    { data: ConfermaDatiDiPagamentoDto }
  > = (props) => {
    const { data } = props ?? {};

    return confermaDatiDiPagamento(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfermaDatiDiPagamentoMutationResult = NonNullable<
  Awaited<ReturnType<typeof confermaDatiDiPagamento>>
>;
export type ConfermaDatiDiPagamentoMutationBody = ConfermaDatiDiPagamentoDto;
export type ConfermaDatiDiPagamentoMutationError = unknown;

export const useConfermaDatiDiPagamento = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confermaDatiDiPagamento>>,
    TError,
    { data: ConfermaDatiDiPagamentoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof confermaDatiDiPagamento>>,
  TError,
  { data: ConfermaDatiDiPagamentoDto },
  TContext
> => {
  const mutationOptions = getConfermaDatiDiPagamentoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const acquista = (
  acquistaTokenDto: AcquistaTokenDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/transazioniToken/acquisto`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: acquistaTokenDto,
    },
    options
  );
};

export const getAcquistaMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acquista>>,
    TError,
    { data: AcquistaTokenDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof acquista>>,
  TError,
  { data: AcquistaTokenDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acquista>>,
    { data: AcquistaTokenDto }
  > = (props) => {
    const { data } = props ?? {};

    return acquista(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcquistaMutationResult = NonNullable<
  Awaited<ReturnType<typeof acquista>>
>;
export type AcquistaMutationBody = AcquistaTokenDto;
export type AcquistaMutationError = unknown;

export const useAcquista = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acquista>>,
    TError,
    { data: AcquistaTokenDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof acquista>>,
  TError,
  { data: AcquistaTokenDto },
  TContext
> => {
  const mutationOptions = getAcquistaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getStripePublishableKey = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/transazioniToken/stripe/publishableKey`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetStripePublishableKeyQueryKey = () => {
  return [`/api/transazioniToken/stripe/publishableKey`] as const;
};

export const getGetStripePublishableKeyQueryOptions = <
  TData = Awaited<ReturnType<typeof getStripePublishableKey>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getStripePublishableKey>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetStripePublishableKeyQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStripePublishableKey>>
  > = ({ signal }) => getStripePublishableKey(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getStripePublishableKey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetStripePublishableKeyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStripePublishableKey>>
>;
export type GetStripePublishableKeyQueryError = unknown;

export function useGetStripePublishableKey<
  TData = Awaited<ReturnType<typeof getStripePublishableKey>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getStripePublishableKey>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetStripePublishableKeyQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const stripeHook = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/transazioniToken/stripe/webhook`, method: "POST" },
    options
  );
};

export const getStripeHookMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeHook>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stripeHook>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stripeHook>>,
    void
  > = () => {
    return stripeHook(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StripeHookMutationResult = NonNullable<
  Awaited<ReturnType<typeof stripeHook>>
>;

export type StripeHookMutationError = unknown;

export const useStripeHook = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeHook>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof stripeHook>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getStripeHookMutationOptions(options);

  return useMutation(mutationOptions);
};
export const confermaRicaricaBonifico = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/transazioniToken/${id}/conferma`, method: "PUT" },
    options
  );
};

export const getConfermaRicaricaBonificoMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confermaRicaricaBonifico>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof confermaRicaricaBonifico>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof confermaRicaricaBonifico>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return confermaRicaricaBonifico(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfermaRicaricaBonificoMutationResult = NonNullable<
  Awaited<ReturnType<typeof confermaRicaricaBonifico>>
>;

export type ConfermaRicaricaBonificoMutationError = unknown;

export const useConfermaRicaricaBonifico = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confermaRicaricaBonifico>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof confermaRicaricaBonifico>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getConfermaRicaricaBonificoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const segnaComeFatturata = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/transazioniToken/${id}/segnaComeFatturata`, method: "PUT" },
    options
  );
};

export const getSegnaComeFatturataMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof segnaComeFatturata>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof segnaComeFatturata>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof segnaComeFatturata>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return segnaComeFatturata(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SegnaComeFatturataMutationResult = NonNullable<
  Awaited<ReturnType<typeof segnaComeFatturata>>
>;

export type SegnaComeFatturataMutationError = unknown;

export const useSegnaComeFatturata = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof segnaComeFatturata>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof segnaComeFatturata>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getSegnaComeFatturataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const annullaTransazione = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/transazioniToken/${id}/annulla`, method: "POST" },
    options
  );
};

export const getAnnullaTransazioneMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof annullaTransazione>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof annullaTransazione>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof annullaTransazione>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return annullaTransazione(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AnnullaTransazioneMutationResult = NonNullable<
  Awaited<ReturnType<typeof annullaTransazione>>
>;

export type AnnullaTransazioneMutationError = unknown;

export const useAnnullaTransazione = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof annullaTransazione>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof annullaTransazione>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getAnnullaTransazioneMutationOptions(options);

  return useMutation(mutationOptions);
};
export const eliminaTransazione = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/transazioniToken/${id}`, method: "DELETE" },
    options
  );
};

export const getEliminaTransazioneMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eliminaTransazione>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof eliminaTransazione>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof eliminaTransazione>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return eliminaTransazione(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EliminaTransazioneMutationResult = NonNullable<
  Awaited<ReturnType<typeof eliminaTransazione>>
>;

export type EliminaTransazioneMutationError = unknown;

export const useEliminaTransazione = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eliminaTransazione>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof eliminaTransazione>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getEliminaTransazioneMutationOptions(options);

  return useMutation(mutationOptions);
};
export const utilizzaPromozione20PercentoEntroFineSettembre = (
  utilizzaPromozione20PercentoEntroFineSettembreDto: UtilizzaPromozione20PercentoEntroFineSettembreDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/transazioniToken/promozione20PercentoEntroFineSettembre`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: utilizzaPromozione20PercentoEntroFineSettembreDto,
    },
    options
  );
};

export const getUtilizzaPromozione20PercentoEntroFineSettembreMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof utilizzaPromozione20PercentoEntroFineSettembre>
      >,
      TError,
      { data: UtilizzaPromozione20PercentoEntroFineSettembreDto },
      TContext
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof utilizzaPromozione20PercentoEntroFineSettembre>>,
    TError,
    { data: UtilizzaPromozione20PercentoEntroFineSettembreDto },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof utilizzaPromozione20PercentoEntroFineSettembre>
      >,
      { data: UtilizzaPromozione20PercentoEntroFineSettembreDto }
    > = (props) => {
      const { data } = props ?? {};

      return utilizzaPromozione20PercentoEntroFineSettembre(
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type UtilizzaPromozione20PercentoEntroFineSettembreMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof utilizzaPromozione20PercentoEntroFineSettembre>>
  >;
export type UtilizzaPromozione20PercentoEntroFineSettembreMutationBody =
  UtilizzaPromozione20PercentoEntroFineSettembreDto;
export type UtilizzaPromozione20PercentoEntroFineSettembreMutationError =
  unknown;

export const useUtilizzaPromozione20PercentoEntroFineSettembre = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof utilizzaPromozione20PercentoEntroFineSettembre>>,
    TError,
    { data: UtilizzaPromozione20PercentoEntroFineSettembreDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof utilizzaPromozione20PercentoEntroFineSettembre>>,
  TError,
  { data: UtilizzaPromozione20PercentoEntroFineSettembreDto },
  TContext
> => {
  const mutationOptions =
    getUtilizzaPromozione20PercentoEntroFineSettembreMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCostoOperazioni = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/costoOperazioni`, method: "GET", signal },
    options
  );
};

export const getGetCostoOperazioniQueryKey = () => {
  return [`/api/costoOperazioni`] as const;
};

export const getGetCostoOperazioniQueryOptions = <
  TData = Awaited<ReturnType<typeof getCostoOperazioni>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getCostoOperazioni>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCostoOperazioniQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCostoOperazioni>>
  > = ({ signal }) => getCostoOperazioni(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCostoOperazioni>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCostoOperazioniQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCostoOperazioni>>
>;
export type GetCostoOperazioniQueryError = unknown;

export function useGetCostoOperazioni<
  TData = Awaited<ReturnType<typeof getCostoOperazioni>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getCostoOperazioni>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCostoOperazioniQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const updateCostoOperazioni = (
  updateCostoOperazioniBody: string[],
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/costoOperazioni`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateCostoOperazioniBody,
    },
    options
  );
};

export const getUpdateCostoOperazioniMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCostoOperazioni>>,
    TError,
    { data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCostoOperazioni>>,
  TError,
  { data: string[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCostoOperazioni>>,
    { data: string[] }
  > = (props) => {
    const { data } = props ?? {};

    return updateCostoOperazioni(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCostoOperazioniMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCostoOperazioni>>
>;
export type UpdateCostoOperazioniMutationBody = string[];
export type UpdateCostoOperazioniMutationError = unknown;

export const useUpdateCostoOperazioni = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCostoOperazioni>>,
    TError,
    { data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCostoOperazioni>>,
  TError,
  { data: string[] },
  TContext
> => {
  const mutationOptions = getUpdateCostoOperazioniMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCostoOperazioniPaginaPubblica = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/costoOperazioni/public`, method: "GET", signal },
    options
  );
};

export const getGetCostoOperazioniPaginaPubblicaQueryKey = () => {
  return [`/api/costoOperazioni/public`] as const;
};

export const getGetCostoOperazioniPaginaPubblicaQueryOptions = <
  TData = Awaited<ReturnType<typeof getCostoOperazioniPaginaPubblica>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getCostoOperazioniPaginaPubblica>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCostoOperazioniPaginaPubblicaQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCostoOperazioniPaginaPubblica>>
  > = ({ signal }) => getCostoOperazioniPaginaPubblica(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCostoOperazioniPaginaPubblica>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCostoOperazioniPaginaPubblicaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCostoOperazioniPaginaPubblica>>
>;
export type GetCostoOperazioniPaginaPubblicaQueryError = unknown;

export function useGetCostoOperazioniPaginaPubblica<
  TData = Awaited<ReturnType<typeof getCostoOperazioniPaginaPubblica>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getCostoOperazioniPaginaPubblica>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCostoOperazioniPaginaPubblicaQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getLogAttivita = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<LogAttivitaDto[]>(
    { url: `/api/log-attivita`, method: "GET", signal },
    options
  );
};

export const getGetLogAttivitaQueryKey = () => {
  return [`/api/log-attivita`] as const;
};

export const getGetLogAttivitaQueryOptions = <
  TData = Awaited<ReturnType<typeof getLogAttivita>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLogAttivita>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLogAttivitaQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLogAttivita>>> = ({
    signal,
  }) => getLogAttivita(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLogAttivita>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLogAttivitaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLogAttivita>>
>;
export type GetLogAttivitaQueryError = unknown;

export function useGetLogAttivita<
  TData = Awaited<ReturnType<typeof getLogAttivita>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLogAttivita>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLogAttivitaQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAvvisi = (
  params: GetAvvisiParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/avvisi`, method: "GET", params, signal },
    options
  );
};

export const getGetAvvisiQueryKey = (params: GetAvvisiParams) => {
  return [`/api/avvisi`, ...(params ? [params] : [])] as const;
};

export const getGetAvvisiQueryOptions = <
  TData = Awaited<ReturnType<typeof getAvvisi>>,
  TError = unknown
>(
  params: GetAvvisiParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAvvisi>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAvvisiQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAvvisi>>> = ({
    signal,
  }) => getAvvisi(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAvvisi>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAvvisiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvvisi>>
>;
export type GetAvvisiQueryError = unknown;

export function useGetAvvisi<
  TData = Awaited<ReturnType<typeof getAvvisi>>,
  TError = unknown
>(
  params: GetAvvisiParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAvvisi>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAvvisiQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const setAvvisi = (
  putAvvisoDto: PutAvvisoDto[],
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/admin/avvisi`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putAvvisoDto,
    },
    options
  );
};

export const getSetAvvisiMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAvvisi>>,
    TError,
    { data: PutAvvisoDto[] },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setAvvisi>>,
  TError,
  { data: PutAvvisoDto[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setAvvisi>>,
    { data: PutAvvisoDto[] }
  > = (props) => {
    const { data } = props ?? {};

    return setAvvisi(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAvvisiMutationResult = NonNullable<
  Awaited<ReturnType<typeof setAvvisi>>
>;
export type SetAvvisiMutationBody = PutAvvisoDto[];
export type SetAvvisiMutationError = unknown;

export const useSetAvvisi = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAvvisi>>,
    TError,
    { data: PutAvvisoDto[] },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setAvvisi>>,
  TError,
  { data: PutAvvisoDto[] },
  TContext
> => {
  const mutationOptions = getSetAvvisiMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getContext = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetContextDto>(
    { url: `/api/context`, method: "GET", signal },
    options
  );
};

export const getGetContextQueryKey = () => {
  return [`/api/context`] as const;
};

export const getGetContextQueryOptions = <
  TData = Awaited<ReturnType<typeof getContext>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getContext>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContextQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContext>>> = ({
    signal,
  }) => getContext(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getContext>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetContextQueryResult = NonNullable<
  Awaited<ReturnType<typeof getContext>>
>;
export type GetContextQueryError = unknown;

export function useGetContext<
  TData = Awaited<ReturnType<typeof getContext>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getContext>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetContextQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getInitialState = (
  params: GetInitialStateParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/redux/initialState`, method: "GET", params, signal },
    options
  );
};

export const getGetInitialStateQueryKey = (params: GetInitialStateParams) => {
  return [`/redux/initialState`, ...(params ? [params] : [])] as const;
};

export const getGetInitialStateQueryOptions = <
  TData = Awaited<ReturnType<typeof getInitialState>>,
  TError = unknown
>(
  params: GetInitialStateParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInitialState>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInitialStateQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInitialState>>> = ({
    signal,
  }) => getInitialState(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInitialState>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInitialStateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInitialState>>
>;
export type GetInitialStateQueryError = unknown;

export function useGetInitialState<
  TData = Awaited<ReturnType<typeof getInitialState>>,
  TError = unknown
>(
  params: GetInitialStateParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInitialState>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetInitialStateQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAll = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetAllFaqDto>(
    { url: `/api/faq`, method: "GET", signal },
    options
  );
};

export const getGetAllQueryKey = () => {
  return [`/api/faq`] as const;
};

export const getGetAllQueryOptions = <
  TData = Awaited<ReturnType<typeof getAll>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAll>>, TError, TData>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAll>>> = ({
    signal,
  }) => getAll(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAll>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllQueryResult = NonNullable<Awaited<ReturnType<typeof getAll>>>;
export type GetAllQueryError = unknown;

export function useGetAll<
  TData = Awaited<ReturnType<typeof getAll>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAll>>, TError, TData>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAdminFaq = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/faq`, method: "GET", signal },
    options
  );
};

export const getGetAdminFaqQueryKey = () => {
  return [`/api/admin/faq`] as const;
};

export const getGetAdminFaqQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminFaq>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdminFaq>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAdminFaqQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminFaq>>> = ({
    signal,
  }) => getAdminFaq(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminFaq>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminFaqQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminFaq>>
>;
export type GetAdminFaqQueryError = unknown;

export function useGetAdminFaq<
  TData = Awaited<ReturnType<typeof getAdminFaq>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdminFaq>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAdminFaqQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const updateFaqs = (
  updateFaqsBody: string[],
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/admin/faq`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateFaqsBody,
    },
    options
  );
};

export const getUpdateFaqsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFaqs>>,
    TError,
    { data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFaqs>>,
  TError,
  { data: string[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFaqs>>,
    { data: string[] }
  > = (props) => {
    const { data } = props ?? {};

    return updateFaqs(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateFaqsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFaqs>>
>;
export type UpdateFaqsMutationBody = string[];
export type UpdateFaqsMutationError = unknown;

export const useUpdateFaqs = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFaqs>>,
    TError,
    { data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateFaqs>>,
  TError,
  { data: string[] },
  TContext
> => {
  const mutationOptions = getUpdateFaqsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const funzionalitaControllerGetAll = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetAllFunzionalitaDto>(
    { url: `/api/funzionalita`, method: "GET", signal },
    options
  );
};

export const getFunzionalitaControllerGetAllQueryKey = () => {
  return [`/api/funzionalita`] as const;
};

export const getFunzionalitaControllerGetAllQueryOptions = <
  TData = Awaited<ReturnType<typeof funzionalitaControllerGetAll>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof funzionalitaControllerGetAll>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFunzionalitaControllerGetAllQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof funzionalitaControllerGetAll>>
  > = ({ signal }) => funzionalitaControllerGetAll(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof funzionalitaControllerGetAll>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FunzionalitaControllerGetAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof funzionalitaControllerGetAll>>
>;
export type FunzionalitaControllerGetAllQueryError = unknown;

export function useFunzionalitaControllerGetAll<
  TData = Awaited<ReturnType<typeof funzionalitaControllerGetAll>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof funzionalitaControllerGetAll>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFunzionalitaControllerGetAllQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const updateFunzionalita = (
  updateFunzionalitaBody: string[],
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/admin/funzionalita`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateFunzionalitaBody,
    },
    options
  );
};

export const getUpdateFunzionalitaMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFunzionalita>>,
    TError,
    { data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFunzionalita>>,
  TError,
  { data: string[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFunzionalita>>,
    { data: string[] }
  > = (props) => {
    const { data } = props ?? {};

    return updateFunzionalita(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateFunzionalitaMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFunzionalita>>
>;
export type UpdateFunzionalitaMutationBody = string[];
export type UpdateFunzionalitaMutationError = unknown;

export const useUpdateFunzionalita = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFunzionalita>>,
    TError,
    { data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateFunzionalita>>,
  TError,
  { data: string[] },
  TContext
> => {
  const mutationOptions = getUpdateFunzionalitaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getFunzionalitaMostrate = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/partner/funzionalitaMostrate`, method: "GET", signal },
    options
  );
};

export const getGetFunzionalitaMostrateQueryKey = () => {
  return [`/api/partner/funzionalitaMostrate`] as const;
};

export const getGetFunzionalitaMostrateQueryOptions = <
  TData = Awaited<ReturnType<typeof getFunzionalitaMostrate>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getFunzionalitaMostrate>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetFunzionalitaMostrateQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFunzionalitaMostrate>>
  > = ({ signal }) => getFunzionalitaMostrate(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFunzionalitaMostrate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFunzionalitaMostrateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFunzionalitaMostrate>>
>;
export type GetFunzionalitaMostrateQueryError = unknown;

export function useGetFunzionalitaMostrate<
  TData = Awaited<ReturnType<typeof getFunzionalitaMostrate>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getFunzionalitaMostrate>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetFunzionalitaMostrateQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const setFunzionalitaMostrata = (
  params: SetFunzionalitaMostrataParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/partner/funzionalitaMostrate`, method: "POST", params },
    options
  );
};

export const getSetFunzionalitaMostrataMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setFunzionalitaMostrata>>,
    TError,
    { params: SetFunzionalitaMostrataParams },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setFunzionalitaMostrata>>,
  TError,
  { params: SetFunzionalitaMostrataParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setFunzionalitaMostrata>>,
    { params: SetFunzionalitaMostrataParams }
  > = (props) => {
    const { params } = props ?? {};

    return setFunzionalitaMostrata(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetFunzionalitaMostrataMutationResult = NonNullable<
  Awaited<ReturnType<typeof setFunzionalitaMostrata>>
>;

export type SetFunzionalitaMostrataMutationError = unknown;

export const useSetFunzionalitaMostrata = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setFunzionalitaMostrata>>,
    TError,
    { params: SetFunzionalitaMostrataParams },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setFunzionalitaMostrata>>,
  TError,
  { params: SetFunzionalitaMostrataParams },
  TContext
> => {
  const mutationOptions = getSetFunzionalitaMostrataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const abilitaDisabilitaScadenze = (
  scadenzaType: string,
  abilitaDisabilitaScadenzaDto: AbilitaDisabilitaScadenzaDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/serviziAcquistati/${scadenzaType}/abilitaDisabilita`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: abilitaDisabilitaScadenzaDto,
    },
    options
  );
};

export const getAbilitaDisabilitaScadenzeMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof abilitaDisabilitaScadenze>>,
    TError,
    { scadenzaType: string; data: AbilitaDisabilitaScadenzaDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof abilitaDisabilitaScadenze>>,
  TError,
  { scadenzaType: string; data: AbilitaDisabilitaScadenzaDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof abilitaDisabilitaScadenze>>,
    { scadenzaType: string; data: AbilitaDisabilitaScadenzaDto }
  > = (props) => {
    const { scadenzaType, data } = props ?? {};

    return abilitaDisabilitaScadenze(scadenzaType, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AbilitaDisabilitaScadenzeMutationResult = NonNullable<
  Awaited<ReturnType<typeof abilitaDisabilitaScadenze>>
>;
export type AbilitaDisabilitaScadenzeMutationBody =
  AbilitaDisabilitaScadenzaDto;
export type AbilitaDisabilitaScadenzeMutationError = unknown;

export const useAbilitaDisabilitaScadenze = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof abilitaDisabilitaScadenze>>,
    TError,
    { scadenzaType: string; data: AbilitaDisabilitaScadenzaDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof abilitaDisabilitaScadenze>>,
  TError,
  { scadenzaType: string; data: AbilitaDisabilitaScadenzaDto },
  TContext
> => {
  const mutationOptions = getAbilitaDisabilitaScadenzeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getTickets = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/tickets`, method: "GET", signal },
    options
  );
};

export const getGetTicketsQueryKey = () => {
  return [`/api/tickets`] as const;
};

export const getGetTicketsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTickets>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTickets>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTicketsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTickets>>> = ({
    signal,
  }) => getTickets(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTickets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTicketsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTickets>>
>;
export type GetTicketsQueryError = unknown;

export function useGetTickets<
  TData = Awaited<ReturnType<typeof getTickets>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTickets>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTicketsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const venditoriImportControllerTestImport = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/venditore/fileImport/test`, method: "POST" },
    options
  );
};

export const getVenditoriImportControllerTestImportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof venditoriImportControllerTestImport>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof venditoriImportControllerTestImport>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof venditoriImportControllerTestImport>>,
    void
  > = () => {
    return venditoriImportControllerTestImport(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type VenditoriImportControllerTestImportMutationResult = NonNullable<
  Awaited<ReturnType<typeof venditoriImportControllerTestImport>>
>;

export type VenditoriImportControllerTestImportMutationError = unknown;

export const useVenditoriImportControllerTestImport = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof venditoriImportControllerTestImport>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof venditoriImportControllerTestImport>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getVenditoriImportControllerTestImportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const venditoriImportControllerImport = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/venditore/fileImport/import`, method: "POST" },
    options
  );
};

export const getVenditoriImportControllerImportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof venditoriImportControllerImport>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof venditoriImportControllerImport>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof venditoriImportControllerImport>>,
    void
  > = () => {
    return venditoriImportControllerImport(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type VenditoriImportControllerImportMutationResult = NonNullable<
  Awaited<ReturnType<typeof venditoriImportControllerImport>>
>;

export type VenditoriImportControllerImportMutationError = unknown;

export const useVenditoriImportControllerImport = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof venditoriImportControllerImport>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof venditoriImportControllerImport>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getVenditoriImportControllerImportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getDichiarazioniAdmin = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/stats/dichiarazioni`, method: "GET", signal },
    options
  );
};

export const getGetDichiarazioniAdminQueryKey = () => {
  return [`/api/admin/stats/dichiarazioni`] as const;
};

export const getGetDichiarazioniAdminQueryOptions = <
  TData = Awaited<ReturnType<typeof getDichiarazioniAdmin>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDichiarazioniAdminQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDichiarazioniAdmin>>
  > = ({ signal }) => getDichiarazioniAdmin(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniAdmin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDichiarazioniAdminQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDichiarazioniAdmin>>
>;
export type GetDichiarazioniAdminQueryError = unknown;

export function useGetDichiarazioniAdmin<
  TData = Awaited<ReturnType<typeof getDichiarazioniAdmin>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetDichiarazioniAdminQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getDichiarazioniGraphAdmin = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/stats/dichiarazioni/graph`, method: "GET", signal },
    options
  );
};

export const getGetDichiarazioniGraphAdminQueryKey = () => {
  return [`/api/admin/stats/dichiarazioni/graph`] as const;
};

export const getGetDichiarazioniGraphAdminQueryOptions = <
  TData = Awaited<ReturnType<typeof getDichiarazioniGraphAdmin>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniGraphAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDichiarazioniGraphAdminQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDichiarazioniGraphAdmin>>
  > = ({ signal }) => getDichiarazioniGraphAdmin(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniGraphAdmin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDichiarazioniGraphAdminQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDichiarazioniGraphAdmin>>
>;
export type GetDichiarazioniGraphAdminQueryError = unknown;

export function useGetDichiarazioniGraphAdmin<
  TData = Awaited<ReturnType<typeof getDichiarazioniGraphAdmin>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniGraphAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetDichiarazioniGraphAdminQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getDichiarazioniTypesAdmin = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/admin/stats/dichiarazioni/types`, method: "GET", signal },
    options
  );
};

export const getGetDichiarazioniTypesAdminQueryKey = () => {
  return [`/api/admin/stats/dichiarazioni/types`] as const;
};

export const getGetDichiarazioniTypesAdminQueryOptions = <
  TData = Awaited<ReturnType<typeof getDichiarazioniTypesAdmin>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniTypesAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDichiarazioniTypesAdminQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDichiarazioniTypesAdmin>>
  > = ({ signal }) => getDichiarazioniTypesAdmin(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniTypesAdmin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDichiarazioniTypesAdminQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDichiarazioniTypesAdmin>>
>;
export type GetDichiarazioniTypesAdminQueryError = unknown;

export function useGetDichiarazioniTypesAdmin<
  TData = Awaited<ReturnType<typeof getDichiarazioniTypesAdmin>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniTypesAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetDichiarazioniTypesAdminQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getDichiarazioniGraphPartner = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/partner/stats/dichiarazioni/graph`, method: "GET", signal },
    options
  );
};

export const getGetDichiarazioniGraphPartnerQueryKey = () => {
  return [`/api/partner/stats/dichiarazioni/graph`] as const;
};

export const getGetDichiarazioniGraphPartnerQueryOptions = <
  TData = Awaited<ReturnType<typeof getDichiarazioniGraphPartner>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniGraphPartner>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDichiarazioniGraphPartnerQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDichiarazioniGraphPartner>>
  > = ({ signal }) => getDichiarazioniGraphPartner(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniGraphPartner>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDichiarazioniGraphPartnerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDichiarazioniGraphPartner>>
>;
export type GetDichiarazioniGraphPartnerQueryError = unknown;

export function useGetDichiarazioniGraphPartner<
  TData = Awaited<ReturnType<typeof getDichiarazioniGraphPartner>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniGraphPartner>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetDichiarazioniGraphPartnerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getDichiarazioniTypesPartner = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/partner/stats/dichiarazioni/types`, method: "GET", signal },
    options
  );
};

export const getGetDichiarazioniTypesPartnerQueryKey = () => {
  return [`/api/partner/stats/dichiarazioni/types`] as const;
};

export const getGetDichiarazioniTypesPartnerQueryOptions = <
  TData = Awaited<ReturnType<typeof getDichiarazioniTypesPartner>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniTypesPartner>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDichiarazioniTypesPartnerQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDichiarazioniTypesPartner>>
  > = ({ signal }) => getDichiarazioniTypesPartner(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniTypesPartner>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDichiarazioniTypesPartnerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDichiarazioniTypesPartner>>
>;
export type GetDichiarazioniTypesPartnerQueryError = unknown;

export function useGetDichiarazioniTypesPartner<
  TData = Awaited<ReturnType<typeof getDichiarazioniTypesPartner>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDichiarazioniTypesPartner>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetDichiarazioniTypesPartnerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getContratti = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<void>(
    { url: `/api/contratti`, method: "GET", signal },
    options
  );
};

export const getGetContrattiQueryKey = () => {
  return [`/api/contratti`] as const;
};

export const getGetContrattiQueryOptions = <
  TData = Awaited<ReturnType<typeof getContratti>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getContratti>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContrattiQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContratti>>> = ({
    signal,
  }) => getContratti(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getContratti>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetContrattiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getContratti>>
>;
export type GetContrattiQueryError = unknown;

export function useGetContratti<
  TData = Awaited<ReturnType<typeof getContratti>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getContratti>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetContrattiQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createContratto = (
  createContrattoDto: CreateContrattoDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/contratti`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createContrattoDto,
    },
    options
  );
};

export const getCreateContrattoMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createContratto>>,
    TError,
    { data: CreateContrattoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createContratto>>,
  TError,
  { data: CreateContrattoDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createContratto>>,
    { data: CreateContrattoDto }
  > = (props) => {
    const { data } = props ?? {};

    return createContratto(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateContrattoMutationResult = NonNullable<
  Awaited<ReturnType<typeof createContratto>>
>;
export type CreateContrattoMutationBody = CreateContrattoDto;
export type CreateContrattoMutationError = unknown;

export const useCreateContratto = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createContratto>>,
    TError,
    { data: CreateContrattoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createContratto>>,
  TError,
  { data: CreateContrattoDto },
  TContext
> => {
  const mutationOptions = getCreateContrattoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteContratto = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/api/contratti/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteContrattoMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteContratto>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteContratto>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteContratto>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteContratto(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteContrattoMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteContratto>>
>;

export type DeleteContrattoMutationError = unknown;

export const useDeleteContratto = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteContratto>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteContratto>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteContrattoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const toggleModuloAttivo = (
  modulo: string,
  toggleModuloAttivoDto: ToggleModuloAttivoDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/api/moduliAttivi/${modulo}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: toggleModuloAttivoDto,
    },
    options
  );
};

export const getToggleModuloAttivoMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof toggleModuloAttivo>>,
    TError,
    { modulo: string; data: ToggleModuloAttivoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof toggleModuloAttivo>>,
  TError,
  { modulo: string; data: ToggleModuloAttivoDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof toggleModuloAttivo>>,
    { modulo: string; data: ToggleModuloAttivoDto }
  > = (props) => {
    const { modulo, data } = props ?? {};

    return toggleModuloAttivo(modulo, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ToggleModuloAttivoMutationResult = NonNullable<
  Awaited<ReturnType<typeof toggleModuloAttivo>>
>;
export type ToggleModuloAttivoMutationBody = ToggleModuloAttivoDto;
export type ToggleModuloAttivoMutationError = unknown;

export const useToggleModuloAttivo = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof toggleModuloAttivo>>,
    TError,
    { modulo: string; data: ToggleModuloAttivoDto },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof toggleModuloAttivo>>,
  TError,
  { modulo: string; data: ToggleModuloAttivoDto },
  TContext
> => {
  const mutationOptions = getToggleModuloAttivoMutationOptions(options);

  return useMutation(mutationOptions);
};
