function formuleCalcTree(tree, variableValueFunction) {
  switch (tree.type) {
    case "+":
      return (
        formuleCalcTree(tree.left, variableValueFunction) +
        formuleCalcTree(tree.right, variableValueFunction)
      );
    case "-":
      return (
        formuleCalcTree(tree.left, variableValueFunction) -
        formuleCalcTree(tree.right, variableValueFunction)
      );
    case "*":
      return (
        formuleCalcTree(tree.left, variableValueFunction) *
        formuleCalcTree(tree.right, variableValueFunction)
      );
    case "/":
      return (
        formuleCalcTree(tree.left, variableValueFunction) /
        formuleCalcTree(tree.right, variableValueFunction)
      );
    case "^":
      return (
        formuleCalcTree(tree.left, variableValueFunction) **
        formuleCalcTree(tree.right, variableValueFunction)
      );
    case "int":
    case "double":
      return tree.value;
    case "var":
      return variableValueFunction(tree.property);
  }
  throw new Error("Unknown operation: " + tree.type);
}

export default function formuleCalc(formulaParsed, variableValueFunction) {
  return formuleCalcTree(formulaParsed, variableValueFunction);
}
