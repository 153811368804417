import { LettureType } from "src/actions";
import { LetturaObj } from "energix-types/src/utils/edistribuzione.utils";
import { useMemo } from "react";
import {
  curveDiCaricoFornitoriKeyByTipoContatore,
  lettureFornitoriKeyByTipoContatore,
  TipoContatore,
} from "energix-types/src/Contatore";
import { mapKeys } from "lodash";
import numeral from "numeral";
import { Button, Typography } from "@mui/material";
import { ArrowLeftIcon } from "src/components/elements/AppIcons";
import { Input } from "src/components/old/reactstrapFix/reactstrap";
import moment from "moment";

type DatiFornitoreLetturaCellProps = {
  anno: number;
  letture: LettureType;
  lettureGiaInserite: { [key: string]: number | null };
  matricola: string;
  tipoContatore: TipoContatore;
  meseKey: string;
  k: number | null;
  numeroCifreIntere: number | null;
  numeroCifreDecimali: number | null;
  usaValoreSuggerito?: (v: number) => void;
};

export function DatiFornitoreLetturaCell({
  letture: _letture,
  anno,
  lettureGiaInserite,
  matricola,
  tipoContatore,
  meseKey,
  k,
  numeroCifreIntere,
  numeroCifreDecimali,
  usaValoreSuggerito,
}: DatiFornitoreLetturaCellProps) {
  const letture = useMemo(
    () =>
      getLetturePerMatricola(
        _letture,
        matricola,
        tipoContatore,
        lettureGiaInserite,
        k
      ),
    [_letture, matricola, tipoContatore, lettureGiaInserite, k]
  );

  console.log("letture", letture);

  const letturaObj = useMemo(
    () => searchLetturaObjForMese(letture, meseKey, lettureGiaInserite, k),
    [letture, meseKey, lettureGiaInserite, k]
  );

  const shouldNotBeSet = letturaMeseKeyShouldNotBeSet(
    anno,
    meseKey,
    lettureGiaInserite
  );

  return (
    <>
      {letturaObj ? (
        <>
          {usaValoreSuggerito && (
            <Button
              title="Usa questo valore"
              variant="outlined"
              size="small"
              sx={{ p: "3px", minWidth: 0, mr: "8px !important" }}
              onClick={() => {
                const letturaString = formatLettura(
                  letturaObj.lettura!,
                  k,
                  numeroCifreIntere,
                  numeroCifreDecimali
                );
                const v = numeral(letturaString).value();
                if (v) {
                  usaValoreSuggerito?.(v);
                }
              }}
              disabled={
                shouldNotBeSet || typeof letturaObj.lettura !== "number"
              }
            >
              <ArrowLeftIcon />
            </Button>
          )}

          {typeof letturaObj.lettura === "number" && (
            <span>
              {formatLettura(
                letturaObj.lettura,
                k,
                numeroCifreIntere,
                numeroCifreDecimali
              )}
            </span>
          )}
          {typeof letturaObj.kwh === "number" && (
            <span style={{ fontSize: "0.9em" }}>
              ({Math.round(letturaObj.kwh)} kWh
              {/* {typeof letturaObj.lettura !== "number" && (
                <em style={{ fontSize: "0.8em" }}>
                  {" "}
                  Necessaria lettura mese precedente
                </em>
              )} */}
              )
            </span>
          )}

          {shouldNotBeSet && (
            <span
              style={{
                fontSize: 9,
                display: "inline-block",
                maxWidth: 100,
                marginLeft: 15,
              }}
            >
              Attenzione: Questa lettura deve corrispondere a quella inserita
              nella dichiarazione dell'anno precedente
            </span>
          )}
        </>
      ) : (
        "-"
      )}
      {/* <pre style={{ maxHeight: 100, overflowY: "auto" }}>
        {meseKey}
        <br />
        {lettura}
        <br />
        {JSON.stringify(letture, null, 2)}
      </pre> */}
    </>
  );
}

type DatiFornitoreLetturaCellUsaTuttiIMesiProps = {
  anno: number;
  letture: LettureType;
  lettureGiaInserite: { [key: string]: number | null };
  matricola: string;
  matricolaEdistribuzione: string | null;
  setMatricolaEdistribuzione: (matr: string | null) => void;
  tipoContatore: TipoContatore;
  meseKeys: string[];
  k: number | null;
  numeroCifreIntere: number | null;
  numeroCifreDecimali: number | null;
  usaValoriSuggeriti?: (v: { [key: string]: number }) => void;
};

export function DatiFornitoreLetturaCellUsaTuttiIMesi({
  letture: _letture,
  anno,
  lettureGiaInserite,
  matricola,
  matricolaEdistribuzione,
  setMatricolaEdistribuzione,
  tipoContatore,
  meseKeys,
  k,
  numeroCifreIntere,
  numeroCifreDecimali,
  usaValoriSuggeriti,
}: DatiFornitoreLetturaCellUsaTuttiIMesiProps) {
  const letture = useMemo(
    () =>
      getLetturePerMatricola(
        _letture,
        matricolaEdistribuzione || matricola,
        tipoContatore,
        lettureGiaInserite,
        k
      ),
    [
      _letture,
      matricola,
      matricolaEdistribuzione,
      tipoContatore,
      lettureGiaInserite,
      k,
    ]
  );

  console.log("letture", letture);

  const lettureByMeseKey = useMemo(() => {
    const obj: { [key: string]: number } = {};
    meseKeys.forEach((meseKey) => {
      const shouldNotBeSet = letturaMeseKeyShouldNotBeSet(
        anno,
        meseKey,
        lettureGiaInserite
      );
      if (shouldNotBeSet) {
        return;
      }

      const v = searchLetturaObjForMese(
        letture,
        meseKey,
        lettureGiaInserite,
        k
      );
      if (v && typeof v.lettura === "number") {
        obj[meseKey] = v.lettura;
      }
    });
    return obj;
  }, [letture, meseKeys, anno, lettureGiaInserite, k]);

  return (
    <>
      {!letture || !!matricolaEdistribuzione ? (
        // non ho trovato la matricola...
        <>
          <div>Matricola "{matricola}" non trovata</div>
          {Object.keys(_letture.letture).length > 0 && (
            <>
              <Typography variant="caption">
                Scegli il contatore E-Distribuzione dall'elenco:
              </Typography>
              <Input
                type="select"
                value={matricolaEdistribuzione || ""}
                onChange={(e: any) => {
                  setMatricolaEdistribuzione?.(e.target.value || null);
                }}
                style={{ marginBottom: 10 }}
              >
                <option value=""></option>
                {Object.keys(_letture.letture).map((key) => {
                  return (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  );
                })}
              </Input>
            </>
          )}
        </>
      ) : null}

      {!letture &&
      !!matricolaEdistribuzione &&
      typeof lettureByMeseKey === "object" &&
      Object.keys(lettureByMeseKey).length === 0 ? (
        <>
          <span>Nessun valore disponibile</span>
        </>
      ) : null}

      {typeof lettureByMeseKey === "object" &&
      Object.keys(lettureByMeseKey).length > 0 ? (
        <>
          {usaValoriSuggeriti && (
            <Button
              variant="outlined"
              size="small"
              sx={{ p: "3px", minWidth: 0, mr: "8px !important" }}
              onClick={() => {
                const obj: { [key: string]: number } = {};
                for (const key in lettureByMeseKey) {
                  if (
                    Object.prototype.hasOwnProperty.call(lettureByMeseKey, key)
                  ) {
                    const lettura = lettureByMeseKey[key];
                    const letturaString = formatLettura(
                      lettura,
                      k,
                      numeroCifreIntere,
                      numeroCifreDecimali
                    );
                    const v = numeral(letturaString).value();
                    if (v) {
                      obj[key] = v;
                    }
                  }
                }
                if (Object.keys(obj).length > 0) {
                  usaValoriSuggeriti?.(obj);
                }
              }}
            >
              <ArrowLeftIcon />
            </Button>
          )}

          <span>Usa tutti i valori suggeriti</span>
        </>
      ) : null}
    </>
  );
}

type LetturePerMeseResult = {
  [data: string]: {
    lettura: number | null;
    kwh: number | null;
  };
};

function getLetturePerMatricola(
  _letture: LettureType,
  matricola: string,
  tipoContatore: TipoContatore,
  lettureGiaInserite: { [key: string]: number | null },
  k: number | null
): null | LetturePerMeseResult {
  const matricole = Object.keys(_letture.letture).filter((x) => {
    return typeof x === "string" && (x === matricola || x.endsWith(matricola));
  });

  if (matricole.length !== 1) {
    return getLettureFromCurveDiCaricPerMatricola(
      _letture,
      matricola,
      tipoContatore,
      lettureGiaInserite,
      k
    );
  }

  const letture = _letture.letture[matricole[0]];

  const lettureKey = lettureFornitoriKeyByTipoContatore[tipoContatore];

  const letturaObj =
    letture[lettureKey as keyof Omit<typeof letture, "prelevata">] ?? null;

  function mapKeysToLKeys(letturaObj: LetturaObj): LetturePerMeseResult {
    return Object.fromEntries(
      Object.entries(letturaObj).map(([k, v]) => {
        const v2: LetturePerMeseResult[string] = {
          lettura: v.total,
          kwh: null,
        };
        const k2 = "L" + k.replace(/-/g, "");
        return [k2, v2];
      })
    );
  }

  return letturaObj &&
    Object.values(letturaObj).some((x) => typeof x.total === "number")
    ? mapKeysToLKeys(letturaObj)
    : getLettureFromCurveDiCaricPerMatricola(
        _letture,
        matricola,
        tipoContatore,
        lettureGiaInserite,
        k
      );
}

function getLettureFromCurveDiCaricPerMatricola(
  _letture: LettureType,
  matricola: string,
  tipoContatore: TipoContatore,
  lettureGiaInserite: { [key: string]: number | null },
  k: number | null
): null | LetturePerMeseResult {
  const pods = Object.keys(_letture.curveDiCarico).filter((x) => {
    const podToMatricola = _letture.podMapping[x];
    return podToMatricola
      ? Object.keys(podToMatricola).some(
          (x2) =>
            typeof x2 === "string" &&
            (x2 === matricola || x2.endsWith(matricola))
        )
      : false;
  });

  if (pods.length !== 1) {
    return null;
  }

  const curveDiCarico = _letture.curveDiCarico[pods[0]]?.mensili || {};

  const curveDiCaricoKey =
    curveDiCaricoFornitoriKeyByTipoContatore[tipoContatore];

  const curveDiCaricoObj =
    curveDiCarico[
      curveDiCaricoKey as keyof Omit<typeof curveDiCarico, "prelevata">
    ] ?? null;

  function mapKeysToLKeys(letturaObj: {
    [annoMese: string]: number;
  }): LetturePerMeseResult {
    let objEntries: Array<
      [
        string,
        {
          lettura: number | null;
          kwh: number | null;
        }
      ]
    > = Object.entries(letturaObj)
      .sort(([k1], [k2]) => {
        return k1 > k2 ? 1 : -1;
      })
      .map(([k, v]) => {
        // k è tipo "2023-03" -> marzo 2023
        // io voglio trasformare questo in "valore al 1 aprile 2023" -> L20230401
        const x = moment(`${k}-01`).add(1, "month");
        const k2 = "L" + x.format("YYYYMMDD");

        const v2: LetturePerMeseResult[string] = {
          lettura: null,
          kwh: v,
        };

        return [k2, v2];
      });
    objEntries = objEntries.filter((x) => x[0]);

    function getKeyLetturaPrecedente(key: string): string | null {
      if (!lettureGiaInserite) {
        return null;
      }
      const keys2 = Object.keys(lettureGiaInserite)
        .sort()
        .filter((x) => x < key);
      return keys2[keys2.length - 1] ?? null;
    }

    for (let index = 0; index < objEntries.length; index++) {
      let [key, obj] = objEntries[index];

      const objEntryPrev = index > 0 ? objEntries[index - 1] : null;
      const keyPrecedente = objEntryPrev && objEntryPrev[0];
      if (k && typeof obj.lettura !== "number" && typeof obj.kwh === "number") {
        const objPrec = keyPrecedente ? objEntryPrev[1] : null;
        const lettPrec = objPrec?.lettura;
        if (typeof lettPrec === "number") {
          const delta = obj.kwh;
          obj = {
            lettura: lettPrec + delta,
            kwh: obj.kwh,
          };
        } else {
          const meseKeyMesePrec = key && getKeyLetturaPrecedente(key); // getMeseKeyMesePrec(meseKey);
          let lettPrec = meseKeyMesePrec && lettureGiaInserite[meseKeyMesePrec];
          if (typeof lettPrec === "number") {
            // in realtà  lettura deve essere in kwh
            lettPrec = lettPrec * k;
            const delta = obj.kwh;
            obj = {
              lettura: lettPrec + delta,
              kwh: obj.kwh,
            };
          }
        }
      }
      objEntries[index] = [key, obj];
    }

    return Object.fromEntries(objEntries);
  }

  return curveDiCaricoObj ? mapKeysToLKeys(curveDiCaricoObj) : null;
}

function searchLetturaObjForMese(
  letture: LetturePerMeseResult | null,
  meseKey: string,
  lettureGiaInserite: { [key: string]: number | null },
  k: number | null
): {
  lettura: number | null;
  kwh: number | null;
} | null {
  if (!letture) {
    return null;
  }
  const meseKeyFineMesePrec: string | null = getMeseKeyFineMesePrec(meseKey);
  let fineMesePrecFound = false;
  const keys = Object.keys(letture).sort();
  let key = null;
  let keyPrecedente = null;
  for (let index = 0; index < keys.length; index++) {
    const k = keys[index];
    if (k <= meseKey) {
      if (!fineMesePrecFound) {
        key = k;
        keyPrecedente = keys[index - 1] ?? null;
      }
      if (meseKeyFineMesePrec && meseKeyFineMesePrec === k) {
        fineMesePrecFound = true;
      }
    } else {
      break;
    }
  }
  const obj = key ? letture[key] : null;

  if (!obj) {
    return null;
  }

  // function getKeyLetturaPrecedente(key: string): string | null {
  //   const keys2 = Object.keys(lettureGiaInserite).sort();
  //   const index = keys2.indexOf(key);
  //   if (index > 0) {
  //     return keys2[index - 1];
  //   }
  //   return null;
  // }

  // if (k && typeof obj.lettura !== "number" && typeof obj.kwh === "number") {
  //   const objPrec = keyPrecedente ? letture[keyPrecedente] : null;
  //   const lettPrec = objPrec?.lettura;
  //   if (typeof lettPrec === "number") {
  //     const delta = obj.kwh;
  //     return {
  //       lettura: lettPrec + delta,
  //       kwh: obj.kwh,
  //     };
  //   } else {
  //     const meseKeyMesePrec = key && getKeyLetturaPrecedente(key); // getMeseKeyMesePrec(meseKey);
  //     let lettPrec = meseKeyMesePrec && lettureGiaInserite[meseKeyMesePrec];
  //     if (typeof lettPrec === "number") {
  //       // in realtà  lettura deve essere in kwh
  //       lettPrec = lettPrec * k;
  //       const delta = obj.kwh;
  //       return {
  //         lettura: lettPrec + delta,
  //         kwh: obj.kwh,
  //       };
  //     }
  //   }
  // }

  return obj;
}

const getMeseKeyFineMesePrecRegexp = /L([0-9]{4})([0-9]{2})([0-9]{2})/;
function getMeseKeyFineMesePrec(meseKey: string): string | null {
  const match = meseKey.match(getMeseKeyFineMesePrecRegexp);
  if (!match) {
    return null;
  }
  const d = parseInt(match[3]);
  if (d === 1) {
    const x = moment(`${match[1]}-${match[2]}-${match[3]}`).subtract(1, "day");
    return "L" + x.format("YYYYMMDD");
  }
  return null;
}

function getMeseKeyMesePrec(meseKey: string): string | null {
  const match = meseKey.match(getMeseKeyFineMesePrecRegexp);
  if (!match) {
    return null;
  }
  const y = parseInt(match[1]);
  const m = parseInt(match[2]);
  const d = parseInt(match[3]);
  if (d === 1) {
    const x = moment(`${y}-${m}-${d}`).subtract(1, "month");
    return "L" + x.format("YYYYMMDD");
  }
  return null;
}

const zeroString = "00000000000000000000000000000000000000000000000000";

function formatLettura(
  value: number,
  k: number | null,
  numeroCifreIntere: number | null,
  numeroCifreDecimali: number | null
) {
  k = k || 1;

  value = value / k;

  numeroCifreDecimali = numeroCifreDecimali
    ? Math.min(numeroCifreDecimali, 50)
    : 0;

  let decimalPart: string;
  if (numeroCifreDecimali === 0) {
    decimalPart = "";
  } else {
    decimalPart = "." + zeroString.substring(0, numeroCifreDecimali);
  }

  if (numeroCifreIntere && value > 0) {
    let max = parseInt("1" + zeroString.substring(0, numeroCifreIntere), 0);
    value = value % max;
  }

  const format = "0,0" + decimalPart;
  return numeral(value).format(format);
}

function letturaMeseKeyShouldNotBeSet(
  anno: number,
  meseKey: string,
  lettureGiaInserite: { [key: string]: number | null }
) {
  if (
    meseKey &&
    meseKey.startsWith("L" + (anno - 1)) &&
    meseKey.substring(5) === "0101"
  ) {
    return lettureGiaInserite && typeof lettureGiaInserite[meseKey] === "number"
      ? true
      : false;
  }
  return false;
}
