import { CALL_API } from "../middleware/api";

export const CONTEXT_APP_NASCOSTA_FETCH_REQUEST =
  "CONTEXT_APP_NASCOSTA_FETCH_REQUEST";
export const CONTEXT_APP_NASCOSTA_FETCH_SUCCESS =
  "CONTEXT_APP_NASCOSTA_FETCH_SUCCESS";
export const CONTEXT_APP_NASCOSTA_FETCH_FAILURE =
  "CONTEXT_APP_NASCOSTA_FETCH_FAILURE";

export const UPLOAD_REPORT_SIGNED_XML_REQUEST =
  "UPLOAD_REPORT_SIGNED_XML_REQUEST";
export const UPLOAD_REPORT_SIGNED_XML_SUCCESS =
  "UPLOAD_REPORT_SIGNED_XML_SUCCESS";
export const UPLOAD_REPORT_SIGNED_XML_FAILURE =
  "UPLOAD_REPORT_SIGNED_XML_FAILURE";

export const REPORT_SAVE_LETTURE_REQUEST = "REPORT_SAVE_LETTURE_REQUEST";
export const REPORT_SAVE_LETTURE_SUCCESS = "REPORT_SAVE_LETTURE_SUCCESS";
export const REPORT_SAVE_LETTURE_FAILURE = "REPORT_SAVE_LETTURE_FAILURE";

export const fetchContextAppNascosta = (token) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        CONTEXT_APP_NASCOSTA_FETCH_REQUEST,
        CONTEXT_APP_NASCOSTA_FETCH_SUCCESS,
        CONTEXT_APP_NASCOSTA_FETCH_FAILURE,
      ],
      endpoint: `/api/context?token=${token}`,
      method: "GET",
    },
  });
};

export const uploadReportSignedXml =
  (token, idDichiarazione, file) => (dispatch) => {
    const data = new FormData();
    data.append("file", file);
    return dispatch({
      [CALL_API]: {
        types: [
          UPLOAD_REPORT_SIGNED_XML_REQUEST,
          UPLOAD_REPORT_SIGNED_XML_SUCCESS,
          UPLOAD_REPORT_SIGNED_XML_FAILURE,
        ],
        endpoint: `/api/dichiarazione/v2/upload/xmlSigned/${token}/${idDichiarazione}`,
        method: "POST",
        body: data,
      },
    });
  };

export const reportSalvaLetture = (token, report) => (dispatch) => {
  const idDichiarazione = report.id;
  return dispatch({
    [CALL_API]: {
      types: [
        REPORT_SAVE_LETTURE_REQUEST,
        REPORT_SAVE_LETTURE_SUCCESS,
        REPORT_SAVE_LETTURE_FAILURE,
      ],
      endpoint: `/api/dichiarazione/v2/salvaLetture/${token}/${idDichiarazione}`,
      method: "POST",
      body: report,
    },
  });
};
