import moment from "moment/moment";
import { Contatore } from "energix-types/src/Contatore";

export default function getScadenzaTaraturaContatori({
  ultimaTaratura,
  frequenzaTaratura,
}: Contatore): string | null {
  if (!ultimaTaratura || !frequenzaTaratura) {
    return null;
  }
  return moment(ultimaTaratura)
    .add(frequenzaTaratura, "years")
    .format("YYYY-MM-DD");
}
