import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import HeaderPaginaClienteFinale from "../../../imgs/HeaderPaginaClienteFinale.png";
import AnagraficaImpiantoBox from "src/components/features/pagina-cliente-finale/AnagraficaImpiantoBox";
import DownloadDichiarazioniBox from "src/components/features/pagina-cliente-finale/DownloadDichiarazioniBox";
import {
  Impianto,
  ReportEdistribuzione,
  ReportGSE,
} from "energix-types/src/Impianto";
import ServiziInScadenzaCard from "src/components/scadenze/ServiziInScadenzaCard";
import NumeroScadenzeCerchio from "src/components/scadenze/counter-card/NumeroScadenzeCerchio";
import { useFindScadenze } from "src/components/scadenze/useFindScadenze";
import { Contatore } from "energix-types/src/Contatore";
import Header from "src/components/features/pagina-cliente-finale/common/Header";
import { Dichiarazione } from "energix-types/src/Dichiarazione";
import ConsumiByQuadroCard from "src/components/consumo-by-quadro/ConsumiByQuadroCard";
import { Azienda } from "energix-types/src/Azienda";
import FirmaDichiarazione from "src/components/features/pagina-cliente-finale/FirmaDichiarazione";
import InserisciLettureDichiarazione, {
  SaveDichiarazioneFunction,
} from "../features/pagina-cliente-finale/InserisciLettureDichiarazione";
import { DownloadReportButton } from "./green/GreenImpianto";
import {
  getReportEdistribuzioneDownloadUrl,
  getReportGSEDownloadUrl,
} from "../features/pagina-cliente-finale/common/links";

export type ClienteFinaleProps = {
  impianto: Impianto;
  contatori: Contatore[];
  azienda: Azienda;
  dichiarazioni: Dichiarazione[];
  uploadSignedXml: (idDichiarazione: number, file: File) => Promise<boolean>;
  saveDichiarazione: SaveDichiarazioneFunction;
  token: string;
  reportEdistribuzione: ReportEdistribuzione[] | null;
  reportGse: ReportGSE[] | null;
};

export default function ClienteFinale({
  impianto,
  contatori,
  azienda,
  dichiarazioni,
  uploadSignedXml,
  saveDichiarazione,
  token,
  reportEdistribuzione,
  reportGse,
}: ClienteFinaleProps) {
  const scadenze = useFindScadenze(contatori, [impianto], [azienda]);

  const sortedDichiarazioni = dichiarazioni.sort(
    ({ anno: annoA }, { anno: annoB }) => annoA - annoB
  );

  const ultimaDichiarazione =
    sortedDichiarazioni.length > 0
      ? sortedDichiarazioni[sortedDichiarazioni.length - 1]
      : undefined;

  const dichiarazioneAnnoPrecedente =
    sortedDichiarazioni.length > 1
      ? sortedDichiarazioni[sortedDichiarazioni.length - 2]
      : undefined;

  return (
    <Container sx={{ py: 4 }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={HeaderPaginaClienteFinale}
            style={{ maxHeight: 200 }}
            alt="Logo pagina cliente finale"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AnagraficaImpiantoBox impianto={impianto} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DownloadDichiarazioniBox
            token={token}
            dichiarazioni={dichiarazioni}
            impianto={impianto}
          />
        </Grid>

        {reportEdistribuzione && reportEdistribuzione.length > 0 && (
          <Grid item xs={12} sm={6}>
            <ReportGestoreCard
              title="Report e-distribuzione"
              description="Utilizza il bottone qui sotto per selezionare e scaricare i report mensili generati appositamente per il tuo impianto utilizzando i dati prelevati da e-distribuzione"
              reports={reportEdistribuzione}
              getLinkFunction={(r) =>
                getReportEdistribuzioneDownloadUrl(token, r)
              }
            />
          </Grid>
        )}
        {reportGse && reportGse.length > 0 && (
          <Grid item xs={12} sm={6}>
            <ReportGestoreCard
              title="Report GSE"
              description="Utilizza il bottone qui sotto per selezionare e scaricare i report mensili generati appositamente per il tuo impianto utilizzando i dati prelevati dal GSE"
              reports={reportGse}
              getLinkFunction={(r) => getReportGSEDownloadUrl(token, r)}
            />
          </Grid>
        )}

        {ultimaDichiarazione && (
          <Grid item sm={12}>
            <InserisciLettureDichiarazione
              dichiarazione={ultimaDichiarazione}
              impianto={impianto}
              saveDichiarazione={saveDichiarazione}
            />
          </Grid>
        )}

        {ultimaDichiarazione && (
          <Grid item sm={12}>
            <FirmaDichiarazione
              dichiarazione={ultimaDichiarazione}
              impianto={impianto}
              uploadSignedXml={uploadSignedXml}
              token={token}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <ServiziInScadenzaCard
            scadenze={scadenze}
            header={<Header title="Prossime Scadenze" />}
            Component={NumeroScadenzeCerchio}
          />
        </Grid>

        {ultimaDichiarazione && (
          <Grid item xs={12}>
            <ConsumiByQuadroCard
              dichiarazione={ultimaDichiarazione}
              impianto={impianto}
              azienda={azienda}
              dichiarazioneAnnoPrecedente={dichiarazioneAnnoPrecedente}
              header={
                <Header
                  title={`Riepilogo dichiarazione anno ${
                    ultimaDichiarazione.anno - 1
                  }`}
                />
              }
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

function ReportGestoreCard<T extends ReportEdistribuzione | ReportGSE>({
  reports,
  title,
  description,
  getLinkFunction,
}: {
  reports: T[];
  title: string;
  description: string;
  getLinkFunction?: (r: T) => string;
}) {
  return (
    <Card style={{ height: "100%" }}>
      <CardHeader title={title} />

      <CardContent style={{ paddingTop: "12px" }}>
        <Typography sx={{ mb: 2 }}>{description}</Typography>
        <DownloadReportButton
          reports={reports}
          getLinkFunction={getLinkFunction}
        />
      </CardContent>
    </Card>
  );
}
