import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { ScadenzaIcon } from "src/components/elements/AppIcons";
import { CardColorName, NumeroScadenzeCardProps } from "./common";

export default function NumeroScadenzeCerchio({
  title,
  inScadenza,
  color = "lavender",
  total,
}: NumeroScadenzeCardProps) {
  const nonInScadenza = total && total - inScadenza;
  const circularValue = nonInScadenza ? (nonInScadenza / total) * 100 : 25;
  return (
    <Card style={{ maxWidth: 400 }}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Stack direction="row" justifyContent="space-between" mt={5}>
          <CircularCounterWithIcon value={circularValue} color={color} />
          <Stack direction="column">
            <Typography textAlign="center">In scadenza</Typography>
            <Typography textAlign="center" variant="h3" component="span">
              {inScadenza}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

function CircularCounterWithIcon({
  value,
  color,
}: {
  value: number;
  color: CardColorName;
}) {
  return (
    <Box>
      <Box style={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          style={{ color: "lightgray" }}
          value={100}
          size={60}
        />
        <CircularProgress
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            color: colors[color],
          }}
          variant="determinate"
          value={value}
          size={60}
        />
        <Box
          style={{
            position: "absolute",
            top: -4,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ScadenzaIcon />
        </Box>
      </Box>
    </Box>
  );
}

const colors: { [key in CardColorName]: string } = {
  lavender: "#1A90FD",
  papaya: "#FFBF00",
  white: "#EB5933",
  softPeach: "#57AC57",
};
