import React, { useEffect } from "react";

import MCard from "@mui/material/Card";
import MCardHeader from "@mui/material/CardHeader";

import { Modal as BModal } from "reactstrap";

export {
  Alert,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  ButtonDropdown,
  ButtonToolbar,
  //Card,
  CardBody,
  CardFooter,
  //CardHeader,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  FormGroup,
  Label,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
  NavbarToggler,
  Popover,
  PopoverHeader,
  PopoverBody,
  Progress,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

export function Card(props) {
  const newProps = {
    children: props.children,
    style: props.style,
  };
  return <MCard {...newProps} />;
}

export function CardHeader(props) {
  const newProps = {
    title: props.children,
  };
  return <MCardHeader {...newProps} />;
}

let modalCount = 0;
let modalOpenBodyClassSet = false;

function onModalCountChange() {
  console.log("MODAL COUNT: " + modalCount);
  if (modalCount) {
    if (!modalOpenBodyClassSet) {
      modalOpenBodyClassSet = true;
      document.body.classList.add("energix-old-frontend-wrapper-modal-open");
    }
  } else {
    if (modalOpenBodyClassSet) {
      modalOpenBodyClassSet = false;
      document.body.classList.remove("energix-old-frontend-wrapper-modal-open");
    }
  }
}

export function Modal(props) {
  useEffect(() => {
    if (props.isOpen) {
      modalCount++;
      onModalCountChange();
      return () => {
        modalCount--;
        onModalCountChange();
      };
    }
  }, [props.isOpen]);

  const wrapClassName =
    (props.wrapClassName || "") + " energix-old-frontend-wrapper";
  const newProps = {
    ...props,
    wrapClassName,
  };
  return <BModal {...newProps} />;
}
