import { some } from "lodash";

const dirittoDiLicenzaConUsiCommerciali = 77.47;
const dirittoDiLicenzaSenzaUsiCommerciali = 23.24;

export default function generateRiepilogoAccise(
  installation,
  serviceLicense,
  report,
  info,
  meseConguaglio
) {
  const accisePerMese = {};
  let conguaglioADicembre = 0;
  let creditoPregressoNonUtilizzato = undefined;
  let creditoPregressoNonUtilizzato3112 = undefined;
  let dirittoDiLicenza;
  var valoreMesi = {};
  let conguaglioCredito = undefined;
  let conguaglioDebito = undefined;

  if (installation.type === "CESSIONE_PARZIALE_COMMERCIALE") {
    dirittoDiLicenza = dirittoDiLicenzaConUsiCommerciali;
  } else if (installation.type === "USO_PROPRIO") {
    // controlla se ha usi commerciali guardando i seguenti quadri, se hanno isUsiCommerciali = true
    const haUsiCommerciali = some(["J", "L", "M"], (quadroKey) => {
      const quadro = info.quadri && info.quadri[quadroKey];
      return some(quadro || [], (x) => {
        if (x.usaDatiQuadro) {
          const usiCommerciali = x.consumiUsiCommerciali || 0;
          return usiCommerciali > 0;
        }
        return x.isUsiCommerciali;
      });
    });
    if (haUsiCommerciali) {
      dirittoDiLicenza = dirittoDiLicenzaConUsiCommerciali;
    } else {
      dirittoDiLicenza = dirittoDiLicenzaSenzaUsiCommerciali;
    }
  } else {
    dirittoDiLicenza = dirittoDiLicenzaSenzaUsiCommerciali;
  }

  var hasAccise = info.quadri.Q && info.quadri.Q.length > 0;

  if (hasAccise) {
    var conguaglio = 0;

    // considerare il creditoPregressoNonUtilizzato se inserito!
    // per prima cosa, controllare nel quadro x se c'è
    if (info.frontespizio.anno === 2021) {
      // Importo X5
      const xmlQuadroX =
        info.quadri &&
        info.quadri.Q &&
        info.quadri.Q[1] &&
        info.quadri.Q[1].xml;
      const X5 = ((xmlQuadroX && xmlQuadroX.children) || []).filter((x) => {
        return (
          x.xmlType === "RiepSaldo" &&
          x.children &&
          x.children[0] &&
          x.children[0].content === 5
        );
      })[0];
      let x5Value = X5 && X5.children[1] && X5.children[1].content;
      x5Value = x5Value ? parseFloat(x5Value) : undefined;
      if (typeof x5Value === "number" && !isNaN(x5Value)) {
        creditoPregressoNonUtilizzato = x5Value;
      }
    }
    // guardare in creditoPregressoNonUtilizzato
    if (typeof creditoPregressoNonUtilizzato === "undefined") {
      creditoPregressoNonUtilizzato = info.creditoPregressoNonUtilizzato || 0;
    }

    creditoPregressoNonUtilizzato3112 =
      (report.reportInfo &&
        report.reportInfo.creditoPregressoNonUtilizzato3112) ||
      undefined;

    conguaglio = -creditoPregressoNonUtilizzato;
    // se c'è un creditoPregressoNonUtilizzato questo si sapeva già l'anno prima, quindi a gen/feb/... (i primi mesi)
    // già si è usato questo credito...
    let conguaglioPreMeseConguaglio = -creditoPregressoNonUtilizzato;

    info.quadri.Q.forEach((row) => {
      const rowNum = row.progressivoRigo;
      const rowValue = row.importo;
      if (rowNum === 3) {
        //debito
        conguaglio += rowValue;
        conguaglioDebito = rowValue;
      } else if (rowNum === 4) {
        //credito
        conguaglio -= rowValue;
        conguaglioCredito = rowValue;
      } else if (rowNum > 900) {
        const mese = rowNum - 900;
        valoreMesi[mese] = rowValue;
      }
    });

    // prendere X3 e X4 come debito e credito
    if (info.frontespizio.anno === 2021) {
      // Importo X3 e X4
      const xmlQuadroX =
        info.quadri &&
        info.quadri.Q &&
        info.quadri.Q[1] &&
        info.quadri.Q[1].xml;
      const X3 = ((xmlQuadroX && xmlQuadroX.children) || []).filter((x) => {
        return (
          x.xmlType === "RiepSaldo" &&
          x.children &&
          x.children[0] &&
          x.children[0].content === 3
        );
      })[0];
      const X4 = ((xmlQuadroX && xmlQuadroX.children) || []).filter((x) => {
        return (
          x.xmlType === "RiepSaldo" &&
          x.children &&
          x.children[0] &&
          x.children[0].content === 4
        );
      })[0];
      let x3Value = X3 && X3.children[1] && X3.children[1].content;
      let x4Value = X4 && X4.children[1] && X4.children[1].content;
      x3Value = x3Value ? parseFloat(x3Value) : undefined;
      x4Value = x4Value ? parseFloat(x4Value) : undefined;
      if (typeof x3Value === "number" && !isNaN(x3Value)) {
        conguaglio += x3Value;
      }
      // NON USARE X4 perchè c'è anche in Q4 per quest'anno
      // if(typeof x4Value === 'number' && !isNaN(x4Value)){
      //   conguaglio -= x4Value
      // }
    }

    let spalmaConguaglio = false;
    // 2020 - covid - reportInfo.conguaglioRateale2020
    if (report.reportInfo && report.reportInfo.conguaglioRateale2020) {
      spalmaConguaglio = true;
    }
    // 2020 - covid - reportInfo.importoNonPagato
    if (report.anno === 2021 && report.reportInfo) {
      // debito = q3 - "Importo sostegno previsto emergenza COVID" + "Importo sostegno fruito"
      // 10% da maggio (5) a settembre (9)
      const accontiAnnoPrecedente =
        report.reportInfo.accontiAnnoPrecedente || [];
      const importoSostegno = accontiAnnoPrecedente.reduce((s, v, i) => {
        if (i >= 5 && i <= 9) {
          return s + (v || 0) * 0.1;
        }
        return s;
      }, 0);
      const importoNonPagato =
        typeof report.reportInfo.importoNonPagato === "number"
          ? report.reportInfo.importoNonPagato
          : 0;
      conguaglio = conguaglio - importoSostegno + importoNonPagato;
    }

    const rateoAccontoOgniNumeroMesi =
      report.reportInfo.rateoAccontoOgniNumeroMesi || 1; // 1 mensile, 6 semestrale, 12 annuale
    let meseConguaglio2 = report.reportInfo.meseConguaglio2 || 11; // 2° rata per semestrale

    if (rateoAccontoOgniNumeroMesi === 1) {
      //normale: mensile
      for (var i = 1; i <= 14; i++) {
        let value = valoreMesi[i > 12 ? 12 : i];

        if (i >= meseConguaglio && conguaglio) {
          if (spalmaConguaglio && conguaglio > 0) {
            value += conguaglio / (12 - meseConguaglio + 1);
          } else {
            value += conguaglio;
            if (value >= 0) {
              conguaglio = 0;
            } else {
              conguaglio = value;
              value = 0;
            }
          }
        } else if (i < meseConguaglio && conguaglioPreMeseConguaglio < 0) {
          value += conguaglioPreMeseConguaglio;
          if (value >= 0) {
            conguaglio -= conguaglioPreMeseConguaglio;
            conguaglioPreMeseConguaglio = 0;
          } else {
            conguaglio -= conguaglioPreMeseConguaglio - value;
            conguaglioPreMeseConguaglio = value;
            value = 0;
          }
        }

        accisePerMese[i] = value;
        if (i === 12) {
          conguaglioADicembre = conguaglio;
        }
      }
    } else if (
      rateoAccontoOgniNumeroMesi === 6 ||
      rateoAccontoOgniNumeroMesi === 12
    ) {
      // semestrale o annuale
      if (rateoAccontoOgniNumeroMesi === 12) {
        meseConguaglio2 = meseConguaglio;
      }

      for (var i = 1; i <= 14; i++) {
        let value = valoreMesi[i > 12 ? 12 : i];
        if (i !== meseConguaglio && i !== meseConguaglio2) {
          accisePerMese[i] = 0;
        } else {
          if (i >= meseConguaglio && conguaglio) {
            value += conguaglio;
            if (value >= 0) {
              conguaglio = 0;
            } else {
              conguaglio = value;
              value = 0;
            }
          } else if (i < meseConguaglio && conguaglioPreMeseConguaglio < 0) {
            value += conguaglioPreMeseConguaglio;
            if (value >= 0) {
              conguaglio -= conguaglioPreMeseConguaglio;
              conguaglioPreMeseConguaglio = 0;
            } else {
              conguaglio -= conguaglioPreMeseConguaglio - value;
              conguaglioPreMeseConguaglio = value;
              value = 0;
            }
          }
          accisePerMese[i] = value;
        }
        if (i === 12) {
          conguaglioADicembre = conguaglio;
        }
      }
    }
  }

  const creditoResiduoAFineAnno =
    conguaglioADicembre < 0 ? -conguaglioADicembre : 0;

  const result = {
    reportAnno: report.anno,
    accisePerMese: accisePerMese,
    creditoResiduoAFineAnno,
    creditoPregressoNonUtilizzato,
    creditoPregressoNonUtilizzato3112,
    dirittoDiLicenza: dirittoDiLicenza,
    valoreMesi,
    conguaglioCredito,
    conguaglioDebito,
    meseConguaglio,
  };

  return result;
}
