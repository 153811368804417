import { Box, Stack, Typography } from "@mui/material";
import { Impianto } from "energix-types/src/Impianto";
import { impiantiByType } from "energix-types/src/DefinizioneImpianto";
import { textTypographyStyle } from "./common/style";
import Header from "src/components/features/pagina-cliente-finale/common/Header";

export type AnagraficaImpiantoBoxProps = {
  impianto: Impianto;
};

export default function AnagraficaImpiantoBox({
  impianto,
}: AnagraficaImpiantoBoxProps) {
  return (
    <Box>
      <Stack direction="column">
        <Header title="Anagrafica Impianto" />

        <Typography sx={textTypographyStyle}>
          <strong>Ragione Sociale:</strong> {impianto.nomeSoggetto}
        </Typography>

        <Typography sx={textTypographyStyle}>
          <strong>Codice Ditta:</strong> {impianto.installationCode}
        </Typography>

        <Typography sx={textTypographyStyle}>
          <strong>Ubicazione Impianto:</strong> {impianto.indirizzoImpianto}
        </Typography>

        <Typography sx={textTypographyStyle}>
          <strong>Tipologia Impianto:</strong>{" "}
          {impiantiByType[impianto.type]?.name}
        </Typography>
      </Stack>
    </Box>
  );
}
