import { Contatore } from "energix-types/src/Contatore";
import { Impianto } from "energix-types/src/Impianto";
import { Azienda } from "energix-types/src/Azienda";
import { useMemo } from "react";
import { getScadenze, Scadenza } from "src/getters/scadenze/getScadenze";

export function useFindScadenze(
  contatori?: Contatore[],
  impianti?: Impianto[],
  aziende?: Azienda[]
): Scadenza[] {
  return useMemo(
    () => getScadenze(contatori, impianti, aziende),
    [contatori, impianti, aziende]
  );
}
