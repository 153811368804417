import {
  parteComune,
  stringToLength,
  intToString,
  numToString_IntegerAndDecimalPart,
  xml_numToString_IntegerAndDecimalPart,
  getQuadroTypeForQuadro,
  getRootXmlTypeForQuadro,
  isPre2021,
} from "./c01Utils";
import { getDatiQuadri, getDiffLettureTotaleAnno } from "./lettureUtils";

// help function
function sommaKwhContatori(contatori, fail) {
  if (!contatori) {
    return 0;
  }
  let total_chilowattora = 0;
  contatori.forEach(({ contatore, from, to }) => {
    const { chilowattora } = getDiffLettureTotaleAnno(contatore, fail);

    total_chilowattora += chilowattora;
  });
  return total_chilowattora;
}

function sommaFormulaValues(formula) {
  let totale = 0;
  for (let index = 1; index <= 12; index++) {
    const v = (formula && formula[index]) || 0;
    totale += v;
  }
  return totale;
}

export default function generateQuadroPerdite(
  quadroType,
  installation,
  serviceLicense,
  report,
  calcoloPerdite,
  calcoloPerUsoProprio,
  fail
) {
  const datiQuadri = getDatiQuadri(
    report,
    "contatoreQuantita",
    "contatoreQuantita"
  );
  let perdite;
  if (datiQuadri.length === 1) {
    perdite = datiQuadri[0].chilowattora;
  } else if (calcoloPerUsoProprio) {
    perdite = sommaFormulaValues(calcoloPerUsoProprio.formulaPerdite);
  } else if (calcoloPerdite) {
    const sommaProduzione = sommaKwhContatori(
      calcoloPerdite.contatoriProduzione,
      fail
    );
    const sommaCessione = sommaKwhContatori(
      calcoloPerdite.contatoriCessione,
      fail
    );
    const sommaConsumiPropriEsenti = sommaKwhContatori(
      calcoloPerdite.contatoriConsumiPropriEsenti,
      fail
    );
    perdite = sommaProduzione - sommaCessione - sommaConsumiPropriEsenti;
  } else {
    return [];
  }

  const result = [];
  const contatoriXmls = [];

  perdite = Math.round(perdite);

  if (perdite <= 0) {
    //ignora se è zero o negativa...
    return [];
  }

  const perditaInfo = {
    totale: true,
    chilowattora: perdite,
  };
  perditaInfo.c01 =
    parteComune("PERDITE", installation, serviceLicense, report, 0, fail) +
    " " + //quadroType per perdite è uno spazio...
    "               " +
    " " +
    stringToLength("", 16) +
    intToString(perditaInfo.chilowattora, 13) +
    stringToLength("", 8) +
    intToString(0, 3) +
    stringToLength("", 8) +
    intToString(0, 3) +
    intToString(0, 8) +
    intToString(0, 3) +
    intToString(0, 7) +
    intToString(0, 2) +
    stringToLength("", 4);

  result.push(perditaInfo);

  if (isPre2021(report)) {
    contatoriXmls.push({
      xmlType: "ContatoreQuantita",
      children: [
        {
          xmlType: "Chilowattora",
          content: perditaInfo.chilowattora,
        },
      ],
    });

    // xml
    result[0].xml = {
      xmlType: getRootXmlTypeForQuadro(quadroType, isPre2021(report)),
      children: [
        {
          xmlType: "TipoRecord",
          content: getQuadroTypeForQuadro(quadroType),
        },
        {
          xmlType: "Mese",
          content: 0,
        },
        ...contatoriXmls,
      ],
    };
  } else {
    // xml
    result[0].xml = {
      xmlType: getRootXmlTypeForQuadro(quadroType, isPre2021(report)),
      children: [
        {
          xmlType: "Kwh",
          content: perditaInfo.chilowattora,
        },
      ],
    };
  }

  return result;
}
