import { useTheme } from "@mui/material";
import { Global } from "@emotion/react";
import { ApexOptions } from "apexcharts";
import { useMemo } from "react";

export function ChartStyle() {
  // possiamo includere questo in modo automatico sia nell'app che in storybook?
  const theme = useTheme();
  return (
    <Global
      styles={{
        // Tooltip
        ".apexcharts-tooltip,.apexcharts-xaxistooltip": {
          border: "0 !important",
          boxShadow: `${theme.customShadows.z24} !important`,
          color: `${theme.palette.text.primary} !important`,
          borderRadius: `${theme.shape.borderRadiusSm}px !important`,
          backgroundColor: `${theme.palette.background.default} !important`,
        },
        ".apexcharts-tooltip-title": {
          border: "0 !important",
          fontWeight: theme.typography.fontWeightBold,
          backgroundColor: `${theme.palette.grey[50016]} !important`,
          color:
            theme.palette.text[
              theme.palette.mode === "light" ? "secondary" : "primary"
            ],
        },
        ".apexcharts-xaxistooltip-bottom": {
          "&:before": {
            borderBottomColor: "transparent !important",
          },
          "&:after": {
            borderBottomColor: `${theme.palette.background.paper} !important`,
          },
        },

        // Legend
        ".apexcharts-legend": {
          padding: "0 !important",
        },
        ".apexcharts-legend-series": {
          alignItems: "center",
          display: "flex !important",
        },
        ".apexcharts-legend-marker": {
          marginTop: "-2px !important",
          marginRight: "8px !important",
        },
        ".apexcharts-legend-text": {
          lineHeight: "18px",
          textTransform: "capitalize",
        },
      }}
    />
  );
}

export function useBaseOptionCharts(): ApexOptions {
  const theme = useTheme();

  const LABEL_TOTAL = useMemo(
    () => ({
      show: true,
      label: "Total",
      color: theme.palette.text.secondary,
      ...theme.typography.subtitle2,
      fontSize: `${theme.typography.subtitle2.fontSize}`,
    }),
    [theme]
  );

  const LABEL_VALUE = useMemo(
    () => ({
      offsetY: 8,
      color: theme.palette.text.primary,
      ...theme.typography.h3,
      fontSize: `${theme.typography.h3.fontSize}`,
    }),
    [theme]
  );

  return useMemo(
    () => ({
      // Colors
      colors: [
        theme.palette.primary.main,
        theme.palette.chart.yellow[0],
        theme.palette.chart.blue[0],
        theme.palette.chart.violet[0],
        theme.palette.chart.green[0],
        theme.palette.chart.red[0],
      ],

      // Chart
      chart: {
        toolbar: { show: false },
        zoom: { enabled: false },
        // animations: { enabled: false },
        foreColor: theme.palette.text.disabled,
        fontFamily: theme.typography.fontFamily,
      },

      // States
      states: {
        hover: {
          filter: {
            type: "lighten",
            value: 0.04,
          },
        },
        active: {
          filter: {
            type: "darken",
            value: 0.88,
          },
        },
      },

      // Fill
      fill: {
        opacity: 1,
        gradient: {
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },

      // Datalabels
      dataLabels: { enabled: false },

      // Stroke
      stroke: {
        width: 3,
        curve: "smooth",
        lineCap: "round",
      },

      // Grid
      grid: {
        strokeDashArray: 3,
        borderColor: theme.palette.divider,
      },

      // Xaxis
      xaxis: {
        axisBorder: { show: false },
        axisTicks: { show: false },
      },

      // Markers
      markers: {
        size: 0,
        strokeColors: theme.palette.background.paper,
      },

      // Tooltip
      tooltip: {
        x: {
          show: false,
        },
      },

      // Legend
      legend: {
        show: true,
        fontSize: "13px",
        position: "top",
        horizontalAlign: "right",
        markers: {
          radius: 12,
        },
        fontWeight: 500,
        itemMargin: { horizontal: 12 },
        labels: {
          colors: theme.palette.text.primary,
        },
      },

      // plotOptions
      plotOptions: {
        // Bar
        bar: {
          columnWidth: "28%",
          borderRadius: 4,
        },
        // Pie + Donut
        pie: {
          donut: {
            labels: {
              show: true,
              value: LABEL_VALUE,
              total: LABEL_TOTAL,
            },
          },
        },
        // Radialbar
        radialBar: {
          track: {
            strokeWidth: "100%",
            background: theme.palette.grey[50016],
          },
          dataLabels: {
            value: LABEL_VALUE,
            total: LABEL_TOTAL,
          },
        },
        // Radar
        radar: {
          polygons: {
            fill: { colors: ["transparent"] },
            strokeColors: theme.palette.divider,
            connectorColors: theme.palette.divider,
          },
        },
        // polarArea
        polarArea: {
          rings: {
            strokeColor: theme.palette.divider,
          },
          spokes: {
            connectorColors: theme.palette.divider,
          },
        },
      },

      // Responsive
      responsive: [
        {
          // sm
          breakpoint: theme.breakpoints.values.sm,
          options: {
            plotOptions: { bar: { columnWidth: "40%" } },
          },
        },
        {
          // md
          breakpoint: theme.breakpoints.values.md,
          options: {
            plotOptions: { bar: { columnWidth: "32%" } },
          },
        },
      ],
    }),
    [theme, LABEL_TOTAL, LABEL_VALUE]
  );
}
