import generateFrontespizio from "./frontespizio";
import generateQuadroABCE from "./quadroABCE";
import generateQuadroGH from "./quadroGH";
import generateQuadroI from "./quadroI";
import generateQuadroPerdite from "./quadroPerdite";
import { generateQuadroJ, generateQuadroL, generateQuadroM } from "./quadroJLM";
import generateQuadroP from "./quadroP";
import generateQuadroQ from "./quadroQ";
import generateQuadroEF from "./quadroEF";
import generateTestata from "./testata";
import doCalcoloPerUsoProprio from "./calcoloPerUsoProprio";
import generateRiepilogoAccise from "./riepilogoAccise";

function extractContatoriInfo(report, type) {
  const result = [];
  const reportInfo = report.reportInfo;
  const contatori = reportInfo.contatori || [];
  const n = contatori.length;
  for (var i = 0; i < n; i++) {
    const contatore = contatori[i];
    if (contatore.type !== type) {
      continue;
    }
    result.push({
      contatore: contatore,
      from: contatore.startDate || report.anno - 1 + "-01-01",
      to: contatore.endDate || report.anno - 1 + "-12-31",
    });
  }
  return result;
}

export default function generateReportInfo(
  installation,
  serviceLicense,
  report,
  fail = true
) {
  let contatoriProduzione = [];
  let contatoriCessione = [];
  // uso proprio:
  let contatoriPromiscuo = [];
  let contatoriConsumiPropriEsenti = [];
  let contatoriConsumiPropriAssoggettati = [];
  let contatoriRicevuta = [];
  let calcoloPerUsoProprio = null;
  let calcoloPerdite = null;

  const type =
    report.type === "USO_PROPRIO" &&
    report.reportInfo.metodoDiCompilazioneSemplificato
      ? "CESSIONE_PARZIALE_COMMERCIALE"
      : report.type;
  switch (type) {
    case "CESSIONE_TOTALE":
      contatoriProduzione = extractContatoriInfo(report, "produzione");
      // controllare se ha perdite
      if (report.reportInfo && report.reportInfo.haContatoriDiversi) {
        contatoriCessione = extractContatoriInfo(report, "ceduta");
        calcoloPerdite = {
          contatoriProduzione,
          contatoriCessione,
        };
      } else {
        // stessi contatori
        contatoriCessione = contatoriProduzione;
      }
      break;
    case "CESSIONE_PARZIALE_NON_COMMERCIALE":
      contatoriProduzione = extractContatoriInfo(report, "produzione");
      contatoriCessione = extractContatoriInfo(report, "ceduta");
      contatoriConsumiPropriEsenti = extractContatoriInfo(
        report,
        "consumiPropriEsenti"
      );
      contatoriRicevuta = extractContatoriInfo(report, "ricevuta");
      if (
        report.reportInfo &&
        report.reportInfo.tipologiaDiConsumoEsente === "Perdite"
      ) {
        calcoloPerdite = {
          contatoriProduzione,
          contatoriCessione,
          contatoriConsumiPropriEsenti,
        };
      }
      break;
    case "CESSIONE_PARZIALE_COMMERCIALE":
      contatoriProduzione = extractContatoriInfo(report, "produzione");
      contatoriCessione = extractContatoriInfo(report, "ceduta");
      break;
    default:
      contatoriProduzione = extractContatoriInfo(report, "produzione");
      contatoriCessione = extractContatoriInfo(report, "ceduta");
      contatoriPromiscuo = extractContatoriInfo(report, "promiscuo");
      contatoriConsumiPropriEsenti = extractContatoriInfo(
        report,
        "consumiPropriEsenti"
      );
      contatoriConsumiPropriAssoggettati = extractContatoriInfo(
        report,
        "consumiPropriAssoggettati"
      );
      contatoriRicevuta = extractContatoriInfo(report, "ricevuta");
      try {
        calcoloPerUsoProprio = doCalcoloPerUsoProprio(
          installation,
          serviceLicense,
          report
        );
      } catch (err) {
        if (fail) {
          throw err;
        }
        calcoloPerUsoProprio = null;
      }
      break;
  }

  const meseConguaglio = report.reportInfo.meseConguaglio || 3;

  const info = {
    quadri: {
      A: generateQuadroABCE(
        "A",
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        fail
      ),
      B: generateQuadroABCE(
        "B",
        installation,
        serviceLicense,
        report,
        contatoriPromiscuo,
        fail
      ),
      C: generateQuadroABCE(
        "C",
        installation,
        serviceLicense,
        report,
        contatoriConsumiPropriEsenti,
        fail
      ),
      E: generateQuadroABCE(
        "E",
        installation,
        serviceLicense,
        report,
        contatoriConsumiPropriAssoggettati,
        fail
      ),
      G: generateQuadroGH(
        "G",
        installation,
        serviceLicense,
        report,
        contatoriCessione,
        fail
      ),
      H: generateQuadroGH(
        "H",
        installation,
        serviceLicense,
        report,
        contatoriRicevuta,
        fail
      ),
      PERDITE: generateQuadroPerdite(
        "PERDITE",
        installation,
        serviceLicense,
        report,
        calcoloPerdite,
        calcoloPerUsoProprio,
        fail
      ),
      I: generateQuadroI(
        "I",
        installation,
        serviceLicense,
        report,
        calcoloPerUsoProprio,
        fail
      ),
      J: generateQuadroJ(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        calcoloPerUsoProprio,
        fail
      ),
      L: generateQuadroL(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        contatoriConsumiPropriEsenti,
        calcoloPerUsoProprio,
        fail
      ),
      M: generateQuadroM(
        installation,
        serviceLicense,
        report,
        contatoriProduzione,
        contatoriCessione,
        calcoloPerUsoProprio,
        fail
      ),
      EF: generateQuadroEF(installation, serviceLicense, report, fail),
    },
  };
  info.quadri.P = generateQuadroP(
    installation,
    serviceLicense,
    report,
    info.quadri.M,
    fail
  );
  info.quadri.Q = generateQuadroQ(
    installation,
    serviceLicense,
    report,
    info.quadri.P,
    meseConguaglio,
    fail
  );

  info.frontespizio = generateFrontespizio(
    installation,
    serviceLicense,
    report,
    info,
    fail
  );

  info.testata = generateTestata(installation, serviceLicense, report, info);

  info.accise = generateRiepilogoAccise(
    installation,
    serviceLicense,
    report,
    info,
    meseConguaglio,
    fail
  );

  return info;
}
