import {
  USER_LOGOUT_SUCCESS,
  SERVER_CONNECTED,
  SERVER_DISCONNECTED,
  REDUX_INITIAL_STATE_FETCH_SUCCESS,
} from "../actions";

export default function serverReducer(state = {}, action) {
  switch (action.type) {
    case REDUX_INITIAL_STATE_FETCH_SUCCESS:
      return action.response.preloadedState.server || {};

    case SERVER_CONNECTED:
      return {
        ...state,
        connected: true,
      };
    case SERVER_DISCONNECTED:
      return {
        ...state,
        connected: false,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        connected: false,
      };

    default:
      return state;
  }
}
