const randomstring = require("randomstring");

export function verifyPermlinkForUser(user) {
  return user.id.toString();
}

export function userIdFromVerifyPermlink(tokenPermlink) {
  return parseInt(tokenPermlink, 10);
}

export function newVerifyToken() {
  return randomstring.generate(15);
}

export function resetPasswordPermlinkForUser(user) {
  return user.id.toString();
}

export function userIdFromResetPasswordPermlink(resetPermlink) {
  return parseInt(resetPermlink, 10);
}

export function newResetPasswordToken() {
  return randomstring.generate(15);
}

export function isXmlSigned(fileContent) {
  return (
    typeof fileContent === "string" &&
    fileContent.indexOf("<ds:Signature") !== -1
  );
}
