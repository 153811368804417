import { Notifica } from "energix-types/src/Notifica";
import { CALL_API } from "src/middleware/api";
import { useDispatch } from "react-redux";
// TODO: use update, instead of save, everywhere

// types
export type SaveNotificaFunction = (
  notifica: Notifica
) => Promise<SaveNotificaResult>;

export type SaveNotificaResult =
  | SaveNotificationSuccess
  | SaveNotificationError;

export type SaveNotificationSuccess = {
  resultType: "success";
  notifica: Notifica;
};
export type SaveNotificationError = {
  resultType: "error";
};

// API Calls
const NOTIFICA_PARTNER_UPDATE_REQUEST = "NOTIFICA_PARTNER_UPDATE_REQUEST";
export const NOTIFICA_PARTNER_UPDATE_SUCCESS =
  "NOTIFICA_PARTNER_UPDATE_SUCCESS";
const NOTIFICA_PARTNER_UPDATE_FAILURE = "NOTIFICA_PARTNER_UPDATE_FAILURE";

const updateNotificaPartnerApiCall =
  (notifica: Notifica) => (dispatch: any) => {
    return dispatch({
      [CALL_API]: {
        types: [
          NOTIFICA_PARTNER_UPDATE_REQUEST,
          NOTIFICA_PARTNER_UPDATE_SUCCESS,
          NOTIFICA_PARTNER_UPDATE_FAILURE,
        ],
        endpoint: "/api/notification/update",
        method: "POST",
        body: notifica,
      },
    });
  };

const NOTIFICA_ADMIN_UPDATE_REQUEST = "NOTIFICA_ADMIN_UPDATE_REQUEST";
export const NOTIFICA_ADMIN_UPDATE_SUCCESS = "NOTIFICA_ADMIN_UPDATE_SUCCESS";
const NOTIFICA_ADMIN_UPDATE_FAILURE = "NOTIFICA_ADMIN_UPDATE_FAILURE";

export const updateNotificaAdminApiCall =
  (notifica: Notifica) => (dispatch: any) => {
    return dispatch({
      [CALL_API]: {
        types: [
          NOTIFICA_ADMIN_UPDATE_REQUEST,
          NOTIFICA_ADMIN_UPDATE_SUCCESS,
          NOTIFICA_ADMIN_UPDATE_FAILURE,
        ],
        endpoint: "/api/notification-default/update",
        method: "POST",
        body: notifica,
      },
    });
  };

// Wrappers
export function useSaveNotificaPartner(): SaveNotificaFunction {
  const dispatch = useDispatch();
  return async function saveNotificaPartner(notifica: Notifica) {
    const result = await dispatch(updateNotificaPartnerApiCall(notifica));
    if (result.type !== NOTIFICA_PARTNER_UPDATE_SUCCESS) {
      return { resultType: "error" };
    }
    return {
      resultType: "success",
      notifica: result.response.notification,
    };
  };
}

export function useSaveNotificaAdmin(): SaveNotificaFunction {
  const dispatch = useDispatch();
  return async function saveNotificaAdmin(notifica: Notifica) {
    const result = await dispatch(updateNotificaAdminApiCall(notifica));
    if (result.type !== NOTIFICA_ADMIN_UPDATE_SUCCESS) {
      return { resultType: "error" };
    }
    return {
      resultType: "success",
      notifica: result.response.notification,
    };
  };
}
