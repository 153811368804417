import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles/createTheme";
import {
  CircularProgress,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { CloseIcon } from "./AppIcons";
import React from "react";

export type SelectedFileBoxProps = {
  file: { name: string; id?: number } | null;
  removeFile?: () => void;
  disabled?: boolean;
  loading?: boolean;
  sx?: SxProps<Theme>;
};

export default function SelectedFileBox({
  file,
  removeFile,
  disabled = false,
  loading = false,
  sx,
}: SelectedFileBoxProps) {
  if (!file) {
    return <div />;
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        borderRadius: "10px",
        padding: ".5em",
        border: (theme: Theme) => `1px solid ${theme.palette.primary.main}`,
        ...sx,
      }}
    >
      {loading && <CircularProgress size={20} />}

      {file.id ? (
        <Link
          href={`/file/${file.id}/download`}
          target="_blank"
          sx={{ flex: 1 }}
          onClick={(e) => e.stopPropagation()}
        >
          {file.name}
        </Link>
      ) : (
        <Typography color="primary" sx={{ flex: 1 }}>
          {file.name}
        </Typography>
      )}

      {removeFile && (
        <IconButton
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            removeFile();
          }}
        >
          <CloseIcon color="primary" style={{ fontSize: "20px" }} />
        </IconButton>
      )}
    </Stack>
  );
}
