import {combineReducers} from 'redux'

import authReducer from './auth';
import uiReducer from './ui';
import verifyReducer from './verify';
import serverReducer from './server'
import contextReducer from './context'

const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  verify: verifyReducer,
  server: serverReducer,
  context: contextReducer,
})

export default rootReducer
