import { CALL_API } from "src/middleware/api";
import { useDispatch } from "react-redux";

// types
export type CompleteResetPasswordAction = (
  password: string,
  confirmPassword: string
) => Promise<CompleteResetPasswordResult>;

export type CompleteResetPasswordResult =
  | CompleteResetPasswordSuccess
  | CompleteResetPasswordValidationError
  | CompleteResetPasswordError
  | CompleteResetPasswordUnknownError;

export type CompleteResetPasswordSuccess = {
  resultType: "success";
};

export type CompleteResetPasswordValidationError = {
  resultType: "validationError";
  message: string;
  errors: ConfirmPasswordValidationErrors;
};

export type ConfirmPasswordValidationErrors = {
  password?: string;
  confirmPassword?: string;
};

export type CompleteResetPasswordError = {
  resultType: "error";
  message: string;
};

export type CompleteResetPasswordUnknownError = {
  resultType: "unknownError";
};

// API call

export const USER_RESET_PASSWORD_NEW_PASSWORD_REQUEST =
  "USER_RESET_PASSWORD_NEW_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_NEW_PASSWORD_SUCCESS =
  "USER_RESET_PASSWORD_NEW_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_NEW_PASSWORD_FAILURE =
  "USER_RESET_PASSWORD_NEW_PASSWORD_FAILURE";

export const completeResetPasswordApiCall =
  (params: { password: string; confirmPassword: string }) =>
  (dispatch: any) => {
    return dispatch({
      [CALL_API]: {
        types: [
          USER_RESET_PASSWORD_NEW_PASSWORD_REQUEST,
          USER_RESET_PASSWORD_NEW_PASSWORD_SUCCESS,
          USER_RESET_PASSWORD_NEW_PASSWORD_FAILURE,
        ],
        endpoint: "/api/resetPassword/newPassword",
        method: "POST",
        body: params,
      },
    });
  };

// Wrapper
export function useCompleteResetPasswordAction() {
  const dispatch = useDispatch();
  return function completeResetPassword(
    password: string,
    confirmPassword: string
  ) {
    // we don't need to use the token to reset the password, because the
    // server already has it in the session object
    return new Promise(
      (resolve: (result: CompleteResetPasswordResult) => void) => {
        dispatch(
          completeResetPasswordApiCall({ password, confirmPassword })
        ).then((result: any) => {
          if (result.type === USER_RESET_PASSWORD_NEW_PASSWORD_FAILURE) {
            const error = result.response;
            const isValidationError = !!error.errors;
            resolve({
              resultType: isValidationError ? "validationError" : "error",
              message: error.message,
              errors: error.errors,
            });
          } else {
            resolve({ resultType: "success" });
          }
        });
      }
    );
  };
}
