import {
  parteComune,
  stringToLength,
  intToString,
  numToString_IntegerAndDecimalPart,
  xml_numToString_IntegerAndDecimalPart,
  getQuadroTypeForQuadro,
  getRootXmlTypeForQuadro,
  isPre2021,
} from "./c01Utils";
import {
  getDiffLettureTotaleAnno,
  getMaxValuePerContatore,
  getDatiQuadri,
} from "./lettureUtils";

export default function generateQuadroABCE(
  quadroType,
  installation,
  serviceLicense,
  report,
  contatoriDiQuestoQuadro,
  fail
) {
  let nomeQuadro;
  switch (quadroType) {
    case "A":
      nomeQuadro = "contatoriProduzione";
      break;
    case "B":
      nomeQuadro = "contatoriUsoPromiscuo";
      break;
    case "C":
      nomeQuadro = "contatoriConsumiEsentiDaAccisa";
      break;
    case "E":
      nomeQuadro = "contatoriConsumiAssoggettatiAdAccisa";
      break;
  }
  const datiQuadri = getDatiQuadri(report, nomeQuadro, "contatore");
  if (contatoriDiQuestoQuadro.length === 0 && datiQuadri.length === 0) {
    return [];
  }
  const result = [];
  const contatoriXmls = [];

  const arrayRighe = [];
  contatoriDiQuestoQuadro.forEach((x) => {
    const { contatore, from, to } = x;
    const {
      letturaAttuale,
      letturaPrecedente,
      diff,
      k,
      chilowattora,
      numeroDiAzzeramenti,
    } = getDiffLettureTotaleAnno(contatore, fail);
    if (numeroDiAzzeramenti) {
      // azzerato, fare n righe (n = numeroDiAzzeramenti)
      const anno = report.anno;
      const k2 = "L" + anno + "0101";
      const k1 = "L" + (anno - 1) + "0101";
      let maxV = getMaxValuePerContatore(contatore);
      // se sono già 8 cifre devo fare 99,999,999.9999
      if (maxV >= 100000000) {
        maxV = 99999999.9999;
      }

      const x1 = {
        ...x,
        contatore: {
          ...x.contatore,
          letture: {
            [k1]: letturaPrecedente,
            [k2]: maxV,
          },
        },
      };
      arrayRighe.push(x1);

      for (let i = 0; i < numeroDiAzzeramenti - 1; i++) {
        const xi = {
          ...x,
          contatore: {
            ...x.contatore,
            letture: {
              [k1]: 0,
              [k2]: maxV,
            },
          },
        };
        arrayRighe.push(xi);
      }

      const xN = {
        ...x,
        contatore: {
          ...x.contatore,
          letture: {
            [k1]: 0,
            [k2]: letturaAttuale,
          },
        },
      };
      arrayRighe.push(xN);
    } else {
      arrayRighe.push(x);
    }
  });

  let total_chilowattora = 0;

  const func = (dataFromLetture, dataFromQuadro) => {
    let letturaAttuale,
      letturaPrecedente,
      diff,
      k,
      chilowattora,
      matricola,
      numeroCifreIntere,
      numeroCifreDecimali;
    if (dataFromLetture) {
      const { contatore, from, to } = dataFromLetture;
      ({ letturaAttuale, letturaPrecedente, diff, k, chilowattora } =
        getDiffLettureTotaleAnno(contatore, fail));
      ({ matricola, numeroCifreIntere, numeroCifreDecimali } = contatore);
    } else {
      ({
        matricola,
        letturaAttuale,
        letturaPrecedente,
        differenzaLettura: diff,
        costanteLettura: k,
      } = dataFromQuadro);
      numeroCifreIntere = 8;
      numeroCifreDecimali = 4;
      diff = letturaAttuale - letturaPrecedente;
      if (typeof diff !== "number" || isNaN(diff)) {
        if (fail) {
          throw new Error("Errore letture");
        }
        return;
      }
      if (diff < 0) {
        if (fail) {
          throw new Error("Letture decrescenti");
        }
        return;
      }
      chilowattora = Math.round(diff * k);
      if (typeof chilowattora !== "number" || isNaN(chilowattora)) {
        if (fail) {
          throw new Error("Errore letture");
        }
        return;
      }
      if (chilowattora < 0) {
        if (fail) {
          throw new Error("Letture decrescenti");
        }
        return;
      }
    }

    total_chilowattora += chilowattora;

    const info = {
      matricola: matricola,
      chilowattora: chilowattora,
      letturaAttuale: letturaAttuale,
      letturaPrecedente: letturaPrecedente,
      differenzaLetture: diff,
      numeroCifreIntere: numeroCifreIntere,
      numeroCifreDecimali: numeroCifreDecimali,
      k: k,
    };

    info.c01 =
      parteComune(quadroType, installation, serviceLicense, report, 0, fail) +
      quadroType +
      stringToLength(info.matricola, 15) +
      intToString(info.chilowattora, 13) +
      numToString_IntegerAndDecimalPart(info.letturaAttuale, 8, 3) +
      numToString_IntegerAndDecimalPart(info.letturaPrecedente, 8, 3) +
      numToString_IntegerAndDecimalPart(info.differenzaLetture, 8, 3) +
      numToString_IntegerAndDecimalPart(info.k, 7, 2);

    result.push(info);

    contatoriXmls.push({
      xmlType: "Contatore",
      children: [
        {
          xmlType: isPre2021(report) ? "Matricola" : "Matr",
          content: info.matricola,
        },
        {
          xmlType: isPre2021(report) ? "Chilowattora" : "Kwh",
          content: info.chilowattora,
        },
        {
          xmlType: isPre2021(report) ? "LetturaAttuale" : "LettA",
          content: xml_numToString_IntegerAndDecimalPart(
            info.letturaAttuale,
            8,
            4
          ),
        },
        {
          xmlType: isPre2021(report) ? "LetturaPrecedente" : "LettP",
          content: xml_numToString_IntegerAndDecimalPart(
            info.letturaPrecedente,
            8,
            4
          ),
        },
        {
          xmlType: isPre2021(report) ? "DifferenzaLettura" : "DiffLett",
          content: xml_numToString_IntegerAndDecimalPart(
            info.differenzaLetture,
            8,
            4
          ),
        },
        {
          xmlType: isPre2021(report) ? "CostanteLettura" : "CostLett",
          content: xml_numToString_IntegerAndDecimalPart(info.k, 7, 2),
        },
      ],
    });
  };

  datiQuadri.forEach((d) => {
    func(null, d);
  });
  arrayRighe.forEach((x) => {
    func(x);
  });

  const totalInfo = {
    totale: true,
    chilowattora: total_chilowattora,
  };
  totalInfo.c01 =
    parteComune(quadroType, installation, serviceLicense, report, 0, fail) +
    quadroType +
    "999999999999999" +
    intToString(totalInfo.chilowattora, 13) +
    stringToLength("", 8) +
    intToString(0, 3) +
    stringToLength("", 8) +
    intToString(0, 3) +
    intToString(0, 8) +
    intToString(0, 3) +
    intToString(0, 7) +
    intToString(0, 2);

  result.push(totalInfo);

  if (isPre2021(report)) {
    contatoriXmls.push({
      xmlType: "Contatore",
      children: [
        {
          xmlType: "Matricola",
          content: "999999999999999",
        },
        {
          xmlType: "Chilowattora",
          content: totalInfo.chilowattora,
        },
        {
          xmlType: "LetturaAttuale",
          content: xml_numToString_IntegerAndDecimalPart(0, 8, 4),
        },
        {
          xmlType: "LetturaPrecedente",
          content: xml_numToString_IntegerAndDecimalPart(0, 8, 4),
        },
        {
          xmlType: "DifferenzaLettura",
          content: xml_numToString_IntegerAndDecimalPart(0, 8, 4),
        },
        {
          xmlType: "CostanteLettura",
          content: xml_numToString_IntegerAndDecimalPart(0, 7, 2),
        },
      ],
    });

    // xml
    result[0].xml = {
      xmlType: getRootXmlTypeForQuadro(quadroType, isPre2021(report)),
      children: [
        {
          xmlType: "TipoRecord",
          content: getQuadroTypeForQuadro(quadroType),
        },
        {
          xmlType: "Mese",
          content: 0,
        },
        ...contatoriXmls,
      ],
    };
  } else {
    // xml
    result[0].xml = {
      xmlType: getRootXmlTypeForQuadro(quadroType, isPre2021(report)),
      children: [
        ...contatoriXmls,
        {
          xmlType: "Totale",
          content: totalInfo.chilowattora,
        },
      ],
    };
  }

  return result;
}
