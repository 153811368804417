import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "src/components/old/reactstrapFix/reactstrap";
import NumberInput from "./NumberInput";
import DatetimePicker from "./DatetimePicker";
import moment from "moment";
import DropzoneComponent from "react-dropzone-component";
import { find, get, set } from "lodash";
import { datiComuni } from "common/datiIstat";
import HelpPopover from "./HelpPopover";
import { Autocomplete, TextField } from "@mui/material";

var djsConfig = {
  dictDefaultMessage: "Clicca o trascina i tuoi file qui",
  addRemoveLinks: true,
};

class AbstractCard extends Component {
  static propTypes = {
    initialData: PropTypes.object,
    isModal: PropTypes.bool.isRequired,
    modalZIndex: PropTypes.number,
    modalClassName: PropTypes.string,
    isModalOpen: PropTypes.bool,
    onSaveSuccess: PropTypes.func,
    onSaveError: PropTypes.func,
    onCancel: PropTypes.func,
    renderSaveSuccess: PropTypes.func,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    isModal: false,
  };

  constructor(props, initialData) {
    super(props);

    initialData = initialData || props.initialData || {};

    this.removeShowSaveSuccess = this.removeShowSaveSuccess.bind(this);
    this.submit = this.submit.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this._initialData = initialData;
    this.state = {
      data: { ...initialData },
      errorMessage: null,
      errors: {},
      showSaveSuccess: false,
    };
  }

  submitAction(payload) {
    throw new Error("NOT_IMPLEMENTED");
  }

  onSuccessData(payload) {
    return {};
  }

  renderTitle() {
    throw new Error("NOT_IMPLEMENTED");
  }

  renderBody() {
    throw new Error("NOT_IMPLEMENTED");
  }

  renderSaveSuccess() {
    return this.props.renderSaveSuccess
      ? this.props.renderSaveSuccess(this.removeShowSaveSuccess)
      : null;
  }

  renderButtons() {
    const { errorMessage } = this.state;
    const { onCancel, readOnly } = this.props;

    const disabledButtons = this.disabledButtons && this.disabledButtons();

    if (readOnly) {
      return [
        onCancel && (
          <Button
            key="2"
            color="secondary"
            onClick={this.onCancel}
            disabled={disabledButtons || this.state.loading}
          >
            Ok
          </Button>
        ),
      ];
    }

    return [
      errorMessage && (
        <div
          key="error"
          className="invalid-feedback"
          style={{
            display: "block",
            fontSize: 15,
          }}
        >
          {errorMessage}
        </div>
      ),
      <Button
        key="1"
        color="primary"
        onClick={this.submit}
        disabled={disabledButtons || this.state.loading}
      >
        Salva
      </Button>,
      onCancel && " ",
      onCancel && (
        <Button
          key="2"
          color="secondary"
          onClick={this.onCancel}
          disabled={disabledButtons || this.state.loading}
        >
          Annulla
        </Button>
      ),
    ];
  }

  getValue(key) {
    return get(this.state.data, key);
  }

  setValue(key, value) {
    const newData = { ...this.state.data };
    set(newData, key, value);
    this.setState({
      data: newData,
    });
  }

  addFile(key, file) {
    const fileId = file.__fileId || JSON.parse(file.xhr.response).fileId;
    let files = this.getValue(key) || [];
    files = [...files, fileId];
    this.setValue(key, files);
  }

  removeFile(key, file) {
    const fileId = file.__fileId || JSON.parse(file.xhr.response).fileId;
    let files = this.getValue(key) || [];
    files = files.filter((f) =>
      typeof f === "number" ? f !== fileId : f.id !== fileId
    );
    this.setValue(key, files);
  }

  removeShowSaveSuccess() {
    this.setState({
      showSaveSuccess: false,
    });
  }

  onCancel() {
    if (this.props.onCancel) {
      this.setState({
        data: {},
        errorMessage: null,
        errors: {},
      });
      this.props.onCancel();
    }
  }

  submit() {
    const payload = {
      ...this.state.data,
    };

    this.setState({
      loading: true,
      showSaveSuccess: false,
    });

    this.submitAction(payload).then((action) => {
      if (!action) {
        this.setState({
          loading: false,
        });
        return;
      }
      if (
        !action.callApiSuccess ||
        (action.response && action.response.success === false)
      ) {
        const { message, error, errors } = action.response;
        this.setState({
          loading: false,
          errorMessage: message || error,
          errors: errors || {},
        });
        this.props.onSaveError && this.props.onSaveError(action.response);
      } else {
        this.setState({
          loading: false,
          errorMessage: null,
          data: this.onSuccessData(action.response),
          errors: {},
          showSaveSuccess: true,
        });
        this.props.onSaveSuccess && this.props.onSaveSuccess(action.response);
      }
    });
  }

  renderHelpPopover(what, inline = false) {
    return <HelpPopover what={what} inline={inline} />;
  }

  renderPureInput(
    key,
    {
      type = "text",
      placeholder = null,
      options = [],
      decimalDigits = 3,
      showThousandSeparator = false,
      radioLabels = ["Sì", "No"],
      disabled = false,
      istatType,
      maxLength,
      showEmptyOption = true,
      disabledDays = null,
      onPaste = null,
      _setValue = (key, value) => {
        this.setValue(key, value);
      },
    }
  ) {
    let inputEl;
    disabled = disabled || this.state.loading || this.props.readOnly;

    if (type === "istat") {
      type = "select-search";
      switch (istatType) {
        case "comune":
        case "codiceCatastale":
          options = datiComuni.map((d) => ({
            label: d[istatType],
            value: d[istatType],
          }));
          break;
        case "codiceCatastale+comune":
          options = datiComuni.map((d) => ({
            label: d["codiceCatastale"] + " - " + d["comune"],
            value: d["codiceCatastale"],
          }));
          break;
        default:
          throw new Error("input type istat unknown: " + istatType);
      }
    }

    if (type === "select-search") {
      const __getValue = () => {
        const v = this.getValue(key) || "";
        if (!v) {
          return null;
        }
        return find(options, (o) => o.value === v) || null;
      };
      inputEl = (
        <Autocomplete
          id={"input-" + key}
          disablePortal
          options={options}
          fullWidth
          placeholder={placeholder}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              // error={!!validationError}
              // helperText={validationError}
              {...params}
              variant="outlined"
            />
          )}
          value={__getValue()}
          onChange={(_, v) => _setValue(key, (v && v.value) || undefined)}
          size="small"
        />

        // <Select
        //   id={"input-" + key}
        //   wrapperStyle={{ width: "100%" }}
        //   placeholder={placeholder}
        //   disabled={disabled}
        //   onChange={(value) => _setValue(key, value && value.value)}
        //   value={this.getValue(key) || ""}
        //   options={options}
        // />
      );
    } else if (type === "select") {
      let v = this.getValue(key);
      if (typeof v === "undefined" || v === null) {
        v = "";
      }
      inputEl = (
        <Input
          type={type}
          id={"input-" + key}
          placeholder={placeholder}
          value={v}
          onChange={(e) => _setValue(key, e.target.value)}
          onPaste={onPaste}
          disabled={disabled}
        >
          {showEmptyOption && <option value=""></option>}
          {options.map(({ label, value, disabled = false }) => (
            <option key={value} value={value} disabled={disabled}>
              {label}
            </option>
          ))}
        </Input>
      );
    } else if (type === "number") {
      let value = this.getValue(key);
      if (typeof value === "undefined") {
        value = null;
      }
      inputEl = (
        <NumberInput
          id={"input-" + key}
          value={value}
          onChange={(event, value) => _setValue(key, value)}
          onPaste={onPaste}
          disabled={disabled}
          storeNumberAsString={false}
          decimalDigits={decimalDigits}
          showThousandSeparator={showThousandSeparator}
          placeholder={placeholder}
          min={0}
        />
      );
    } else if (type === "checkbox") {
      inputEl = (
        <Input
          type={type}
          id={"input-" + key}
          checked={this.getValue(key) || false}
          onChange={(e) => _setValue(key, e.target.checked)}
          disabled={disabled}
        />
      );
    } else if (type === "checkbox-radio") {
      inputEl = (
        <div style={{ display: "inline-block" }}>
          <FormGroup check inline>
            <Input
              type="radio"
              id={"input-" + key + "-true"}
              name={"input-" + key}
              checked={this.getValue(key) || false}
              onChange={(e) => _setValue(key, e.target.value === "true")}
              disabled={disabled}
              value="true"
            />
            <Label
              className="form-check-label"
              check
              htmlFor={"input-" + key + "-true"}
            >
              {radioLabels[0]}
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Input
              type="radio"
              id={"input-" + key + "-false"}
              name={"input-" + key}
              checked={this.getValue(key) === false}
              onChange={(e) => _setValue(key, e.target.value === "true")}
              disabled={disabled}
              value="false"
            />
            <Label
              className="form-check-label"
              check
              htmlFor={"input-" + key + "-false"}
            >
              {radioLabels[1]}
            </Label>
          </FormGroup>
        </div>
      );
    } else if (type === "date") {
      const value = this.getValue(key);
      inputEl = (
        <DatetimePicker
          id={"input-" + key}
          disabled={disabled}
          date={true}
          time={false}
          value={value ? moment(value).toDate() : null}
          disabledDays={disabledDays}
          onChange={(value) =>
            _setValue(key, value ? moment(value).format("YYYY-MM-DD") : null)
          }
        />
      );
    } else {
      inputEl = (
        <Input
          type={type}
          id={"input-" + key}
          value={this.getValue(key) || ""}
          onChange={(e) => _setValue(key, e.target.value)}
          onPaste={onPaste}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder}
        />
      );
    }
    return inputEl;
  }

  renderError(key) {
    const error = this.state.errors && get(this.state.errors, key);
    const errorEl = error && (
      <div className="invalid-feedback" style={{ display: "inline-block" }}>
        {error}
      </div>
    );
    return errorEl;
  }

  renderInput(
    label,
    key,
    {
      type = "text",
      size = "12",
      horizontal = false,
      help = false,
      options = [],
      placeholder = null,
      decimalDigits = 3,
      radioLabels,
      disabled,
      istatType,
      showEmptyOption = true,
      disabledDays = null,
      onPaste = null,
    } = {}
  ) {
    if (label && typeof label === "string" && !/[.:?!]$/.test(label)) {
      label = label + ":";
    }
    if (help) {
      if (help === true) {
        help = key;
      }
      label = (
        <span>
          {label}
          {this.renderHelpPopover(help, true)}
        </span>
      );
    }

    const labelEl = <Label htmlFor={"input-" + key}>{label}</Label>;

    const inputEl = this.renderPureInput(key, {
      type,
      placeholder,
      options,
      decimalDigits,
      radioLabels,
      disabled,
      istatType,
      showEmptyOption,
      disabledDays,
      onPaste,
    });
    const errorEl = this.renderError(key);

    if (horizontal) {
      return (
        <Col md={size}>
          <FormGroup row>
            <Col md="5" lg="6">
              {labelEl}
            </Col>
            <Col xs="12" md="7" lg="6">
              {inputEl}
              {errorEl}
            </Col>
          </FormGroup>
        </Col>
      );
    }
    return (
      <Col md={size}>
        <FormGroup>
          {labelEl}
          {inputEl}
          {errorEl}
        </FormGroup>
      </Col>
    );
  }

  renderFileListForAdmin(key) {
    const files = this.getValue(key) || [];
    if (files.length === 0) {
      return "Nessun file caricato";
    }
    return files.map((file) => {
      const { name, id } = file;
      return (
        <div key={id}>
          <a
            href={"/file/" + id + "/download"}
            target="_blank"
            rel="noreferrer"
          >
            {name}
          </a>
        </div>
      );
    });
  }

  renderFileInput(
    key,
    { postUrl = "/api/uploadFiles", postUrlQuery = "" } = {}
  ) {
    var fileConfig = {
      iconFiletypes: [".pdf"],
      showFiletypeIcon: true,
      postUrl: postUrl + postUrlQuery,
    };

    const inputEl = (
      <DropzoneComponent
        config={fileConfig}
        eventHandlers={{
          success: (file) => this.addFile(key, file),
          removedfile: (file) => this.removeFile(key, file),
          init: (dropzone) => {
            const myDropzone = dropzone;
            const initialData = this._initialData;
            const files = get(initialData, key);
            var existingFiles = [];
            ((files && Array.isArray(files) && files) || []).forEach(
              (file, i) => {
                if (typeof file === "object") {
                  existingFiles.push({
                    name: file.name,
                    size: null,
                    __fileId: file.id,
                  });
                } else {
                  existingFiles.push({
                    name: "File " + (i + 1),
                    size: null,
                    __fileId: file,
                  });
                }
              }
            );

            for (var i = 0; i < existingFiles.length; i++) {
              myDropzone.emit("addedfile", existingFiles[i]);
              myDropzone.emit(
                "thumbnail",
                existingFiles[i],
                "/file/" + existingFiles[i].__fileId + "/download"
              );
              myDropzone.emit("complete", existingFiles[i]);
            }
          },
        }}
        djsConfig={djsConfig}
      />
    );

    const errorEl = this.renderError(key);

    return (
      <Col md={12}>
        <FormGroup>
          {inputEl}
          {errorEl}
        </FormGroup>
      </Col>
    );
  }

  render() {
    const { showSaveSuccess } = this.state;
    const { isModal, isModalOpen, onCancel, modalClassName } = this.props;
    if (isModal) {
      return (
        <Modal
          isOpen={isModalOpen || false}
          className={classNames("modal-lg", modalClassName)}
          wrapClassName={this.props.modalWrapClassName}
          zIndex={this.props.modalZIndex}
        >
          <ModalHeader toggle={onCancel && this.onCancel}>
            {isModalOpen && this.renderTitle()}
          </ModalHeader>
          <ModalBody>
            {showSaveSuccess && this.renderSaveSuccess()}
            {isModalOpen && this.renderBody()}
          </ModalBody>
          {(!this.showFooter || this.showFooter()) && (
            <ModalFooter>{isModalOpen && this.renderButtons()}</ModalFooter>
          )}
        </Modal>
      );
    }
    return (
      <div className="abstract-card">
        {showSaveSuccess && this.renderSaveSuccess()}
        <div>{this.renderBody()}</div>
        <div>{this.renderButtons()}</div>
      </div>
    );
  }
}

export default AbstractCard;
