import moment from "moment/moment";
import { Moment } from "moment";

export default function getScadenzaFuelMix(
  ultimoTaraturaFuelMix: Date | string | null,
  today: Moment = moment()
): Date | null {
  const anno = ultimoTaraturaFuelMix
    ? moment(ultimoTaraturaFuelMix).year() + 1
    : today.year();

  if (anno <= today.year()) {
    return moment(`${anno}-04-30`, "YYYY-MM-DD").toDate();
  }

  return null;
}
